//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import moment from 'moment';
import jwt from 'jsonwebtoken'; // services

import { PatientService } from '@/services/patient.service';
import { PrescriptionService } from '@/services/prescription.service'; // components

import PatientHistory from '@/components/patients/partials/PatientHistory';
import IntakeModal from '@/components/modals/IntakeModal';
import AirtableOrderView from '@/components/infoview/AirtableOrderView';
import ModalPricingList from '@/components/modals/ModalPricingList'; // Componentized Prescriptions

import PrescriptionCard from '@/components/infoview/PrescriptionCard'; // utils

import { getRef } from '@/utils/getRef';
import { mapState, mapGetters } from 'vuex';
export default {
  components: {
    PatientHistory,
    IntakeModal,
    AirtableOrderView,
    PrescriptionCard,
    ModalPricingList,
    // Dialog imports
    ActionDialog: () => import('@/components/actions/ActionDialog'),
    ActionHistory: () => import('@/components/actions/ActionHistory'),
    ResetPasswordDialog: () => import('@/components/patients/ResetPasswordDialog'),
    SetPasswordDialog: () => import('@/components/patients/SetPasswordDialog'),
    PatientInfoEdit: () => import('@/components/patients/PatientInfoEdit'),
    PatientAddressEdit: () => import('@/components/patients/PatientAddressEdit'),
    HSAReceiptDialog: () => import('@/components/patients/HSAReceiptDialog'),
    FSAReceiptDialog: () => import('@/components/patients/FSAReceiptDialog'),
    NDCDialog: () => import('@/components/patients/NDCDialog'),
    UploadDialog: () => import('@/components/patients/UploadDialog'),
    ResetIntakeDialog: () => import('@/components/patients/ResetIntakeDialog'),
    ReceiveUSPSDialog: () => import('@/components/patients/ReceiveUSPSDialog'),
    CancelPatientDialog: () => import('@/components/patients/CancelPatientDialog'),
    ResetEmailDialog: () => import('@/components/patients/ResetEmailDialog'),
    AssignPatientDialog: () => import('@/components/patients/AssignPatientDialog'),
    ReassignPatientDialog: () => import('@/components/patients/ReassignPatientDialog'),
    CancelPrescriptionDialog: () => import('@/components/patients/CancelPrescriptionDialog'),
    CancelSinglePrescriptionDialog: () => import('@/components/patients/CancelSinglePrescriptionDialog'),
    PrescriptionHistoryDialog: () => import('@/components/patients/PrescriptionHistoryDialog'),
    RushSinglePrescriptionDialog: () => import('@/components/patients/RushSinglePrescriptionDialog'),
    PushEscriptDialog: () => import('@/components/patients/PushEscriptDialog'),
    CancelOrderDialog: () => import('@/components/patients/CancelOrderDialog'),
    RefillDialog: () => import('@/components/patients/RefillDialog'),
    MoveRefillDateDialog: () => import('@/components/patients/MoveRefillDateDialog'),
    MovePrescriptionStartDateDialog: () => import('@/components/patients/MovePrescriptionStartDateDialog'),
    RemovePatientDialog: () => import('@/components/patients/RemovePatientDialog'),
    PausePrescriptionDialog: () => import('@/components/patients/PausePrescriptionDialog'),
    UpdatePauseDialog: () => import('@/components/patients/UpdatePauseDialog'),
    ResumePrescriptionDialog: () => import('@/components/patients/ResumePrescriptionDialog'),
    RetryPrescriptionDialog: () => import('@/components/patients/RetryPrescriptionDialog'),
    RestartPrescriptionDialog: () => import('@/components/patients/RestartPrescriptionDialog'),
    TransferRXDialog: () => import('@/components/patients/TransferRXDialog'),
    MigrateStateDialog: () => import('@/components/patients/MigrateStateDialog'),
    DelayTimerDialog: () => import('@/components/patients/DelayTimerDialog'),
    ShippingChargeDialog: () => import('@/components/patients/ShippingChargeDialog'),
    DismissDialog: () => import('@/components/patients/DismissDialog'),
    PushScheduledPrescriptionDialog: () => import('@/components/patients/PushScheduledPrescriptionDialog'),
    ReportIssueDialog: () => import('@/components/admins/ReportIssueDialog'),
    BanPatientDialog: () => import('@/components/patients/BanPatientDialog'),
    LiftBanPatientDialog: () => import('@/components/patients/LiftBanPatientDialog'),
    SendReplacementDialog: () => import('@/components/patients/SendReplacementDialog')
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    updateUrl: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isVisible: false,
      viewMode: 'info',
      addressLocation: '',
      addressDisplay: '',
      billingAddressDisplay: '',
      patientData: null,
      prescriptionData: [],
      loadedPrescriptions: false,
      timers: {},
      selectedPrescription: {},
      showPricingList: false,
      selectedAirtableOrder: null,
      refreshPrescriptionCounter: 0,
      hasWaitingBeforeCleanup: false,
      hasWaitingOnCleanup: false,
      showIntakeModal: false,
      toast: {
        message: '',
        variant: 'warning'
      },
      tab: 'all'
    };
  },

  computed: {
    actionsDropdownItems() {
      return [[{
        label: 'ORDER RECEIPTS',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'FSAReceiptDialog')
      }, {
        label: 'HSA RECEIPT',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'HSAReceiptDialog')
      }, {
        label: 'NDC DOCUMENT',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'NDCDialog')
      }], [{
        label: 'VIEW INTAKE HISTORY',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: () => this.showIntakeModal = true
      }, {
        label: 'APPLY SHIPPING CHARGE',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'ShippingChargeDialog')
      }, {
        label: 'TRANSFER RX',
        render: this.checkCompleteOnboarding,
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'TransferRXDialog')
      }, {
        label: 'TRANSFER STATE',
        render: this.checkCompleteOnboarding,
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'MigrateStateDialog')
      }, {
        label: 'SET PASSWORD',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'SetPasswordDialog')
      }, {
        label: 'RESET PASSWORD',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'ResetPasswordDialog')
      }, {
        label: 'RESET INTAKE',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'ResetIntakeDialog')
      }, {
        label: 'CANNOT RECEIVE USPS',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'ReceiveUSPSDialog')
      }, {
        label: 'REPORT ISSUE',
        buttonClass: 'text-warning',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'ReportIssueDialog')
      }], [{
        label: 'CANCEL',
        buttonClass: 'text-danger',
        disabled: !this.patientData,
        onClick: this.openDialog.bind(null, 'CancelPatientDialog')
      }, {
        label: 'DELETE',
        buttonClass: 'text-danger',
        disabled: this.hasActivePrescriptions,
        onClick: this.openDialog.bind(null, 'RemovePatientDialog')
      }]];
    },

    checkCompleteOnboarding() {
      return this.patientData && this.patientData.onboardingstatus === 'DONE';
    },

    // selectedPrescriptionId - used for check if prescription is selected
    ...mapState('infoview', {
      isOrderManagerOpen: 'displayAirtable'
    }),
    ...mapGetters({
      selectedPrescriptionId: 'infoview/getSelectedPrescriptionId'
    }),

    hasActivePrescriptions() {
      return this.prescriptionData.some(item => ['PRESCRIBED', 'PAUSED', 'SCHEDULED'].includes(item.prescriptionstatus));
    },

    ongoingItems() {
      return this.prescriptionData.filter(item => !['REFILLED', 'CANCELLED'].includes(item.prescriptionstatus) && !(item.refills === item.paidrefills && item.refilluponrequest));
    },

    bgModal() {
      return {
        color: this.showIntakeModal ? 'rgba(12, 53, 35, 0.2)' : 'rgba(12, 53, 35, 0.0)',
        show: this.showIntakeModal ? 'block' : 'none'
      };
    },

    showHistory() {
      return this.viewMode == 'history';
    },

    stripeLink() {
      if (process.env.VUE_APP_SERVER == 'production') {
        return `https://dashboard.stripe.com/customers/${this.patientData.customerid}`;
      } else {
        return `https://dashboard.stripe.com/customers/test/${this.patientData.customerid}`;
      }
    },

    airtableLink() {
      if (process.env.VUE_APP_SERVER == 'production') {
        return `https://airtable.com/appW2m2BM4AQ6cqqQ/tblUvfIWrn4CxOxeC/${this.patientData.airtableid}?blocks=hide`;
      } else {
        return `https://airtable.com/appfdaCiD9JG40MHR/tbldMJesrSVC90nTx/${this.patientData.airtableid}?blocks=hide`;
      }
    }

  },
  watch: {
    isVisible(show) {
      if (!show) {
        this.$store.dispatch('infoview/closeAirtableDisplay');

        if (this.updateUrl) {
          history.pushState({}, null, '/');
        }
      }
    }

  },

  created() {
    addEventListener('popstate', this.popStateHandler);
  },

  destroyed() {
    removeEventListener('popstate', this.popStateHandler);
  },

  methods: {
    reset() {
      this.patientData = null;
      this.prescriptionData = [];
      this.addressLocation = '';
      this.addressDisplay = '';
      this.billingAddressDisplay = '';
      this.loadedPrescriptions = false;
      this.viewMode = 'info';
      this.refreshPrescriptionCounter = 0;
      this.hasWaitingBeforeCleanup = false;
      this.hasWaitingOnCleanup = false;
      this.selectedPrescription = {};
      this.selectedAirtableOrder = null;
      this.isVisible = false;
      this.showIntakeModal = false;
    },

    /** @public */
    async show() {
      this.reset();
      this.isVisible = true;
      await this.refreshData();
      this.initiateWaitingInterval();
    },

    showToast(message, variant = 'danger') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('dialogToast');
    },

    getTimeUntilActive(prescription) {
      if (prescription.prescriptionstatus == 'WAITING') {
        if (this.timers[prescription.id]) {
          return this.timers[prescription.id].time;
        }

        if (this.timers[prescription.id]) {
          return this.timers[prescription.id].time;
        } else {
          return 0;
        }
      }
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },

    getFollowUpDate() {
      return moment(this.patientData.followupdate).format('MMMM D, YYYY');
    },

    async close(event) {
      const slidePanel = await getRef.call(this, 'base-slide-panel');
      slidePanel.hide();
      this.$emit('exit', event);
    },

    async openDialog(refname) {
      if (refname == 'DelayTimerDialog') {
        this.$refs.DelayTimerDialog.patientid = this.patientData.id;
      }

      if (refname == 'PausePrescriptionDialog') {
        await this.$nextTick();
        this.$refs.PausePrescriptionDialog.rowData.firstname = this.patientData.firstname;
        this.$refs.PausePrescriptionDialog.rowData.lastname = this.patientData.lastname;
      }

      if (refname == 'ReceiveUSPSDialog') {
        this.$refs.ReceiveUSPSDialog.receiveUSPS = this.patientData.receiveUSPS;
      }

      this.$refs[refname].showDialog = true;
    },

    // Is used as a ref
    // eslint-disable-next-line vue/no-unused-properties
    triggerToggleDialog(refname) {
      this.$refs[refname].toggleDialog();
    },

    popStateHandler(event) {
      if (this.$store.state.global.isModalOpen) {
        this.close(event);
      }
    },

    async refreshData() {
      const patientData = await PatientService.getPatient(this.patientId);
      this.patientData = patientData.data;
      const prescriptionData = await PrescriptionService.getPrescription(this.patientId);
      this.prescriptionData = prescriptionData.data;
      this.loadedPrescriptions = true;
      const address = patientData.data.shippingaddress;

      if (Object.entries(address).length === 0 || !address) {
        this.addressDisplay = '(No address)';
        this.addressLocation = this.patientData.state ? this.patientData.state : '(No address)';
      } else {
        let addressString = '';
        if (address.address1) addressString = addressString.concat(`${address.address1}, `);
        if (address.address2) addressString = addressString.concat(`${address.address2}, `);
        if (address.city) addressString = addressString.concat(`${address.city}, `);
        this.addressDisplay = addressString.substr(0, addressString.length - 2);
        this.addressLocation = `${address.state}, ${address.zipcode}`;
      }

      const billingaddress = patientData.data.billingaddress;

      if (!billingaddress || Object.entries(billingaddress).length === 0) {
        this.billingAddressDisplay = '(No address)';
      } else {
        let bAddress = '';
        if (billingaddress.address1) bAddress = bAddress.concat(`${billingaddress.address1}, `);
        if (billingaddress.address2) bAddress = bAddress.concat(`${billingaddress.address2}, `);
        if (billingaddress.city) bAddress = bAddress.concat(`${billingaddress.city}, `);
        this.billingAddressDisplay = bAddress.substr(0, bAddress.length - 2);
      } // Update URL


      if (this.updateUrl) {
        history.pushState({}, null, '/');
        history.pushState({}, null, `${window.location.href}patients/profile/${patientData.data.id}`);
      }
    },

    dateToDisplay(date, showTime = false) {
      if (!date) return 'No messages yet.';
      let format = 'MM/DD/YY';

      if (showTime) {
        format += ' LT';
      }

      const display = moment(date).format(format);
      return display;
    },

    async refreshPrescriptions(refreshAirtable = false) {
      var _this$patientData, _this$selectedPrescri;

      if (!((_this$patientData = this.patientData) !== null && _this$patientData !== void 0 && _this$patientData.id)) return;
      this.loadedPrescriptions = false;
      const prescriptionData = await PrescriptionService.getPrescription(this.patientData.id);
      this.prescriptionData = prescriptionData.data;
      this.loadedPrescriptions = true;

      if ((_this$selectedPrescri = this.selectedPrescription) !== null && _this$selectedPrescri !== void 0 && _this$selectedPrescri.id) {
        this.selectedPrescription = prescriptionData.data.find(prescription => {
          var _this$selectedPrescri2;

          return prescription.id === ((_this$selectedPrescri2 = this.selectedPrescription) === null || _this$selectedPrescri2 === void 0 ? void 0 : _this$selectedPrescri2.id);
        });

        if (refreshAirtable) {
          await this.$refs.AirtableOrderView.getAirtableOrders(this.selectedPrescription.id);
        }
      }

      if (!this.hasWaitingOnCleanup) {
        this.initiateWaitingInterval();
      }
    },

    redirectToIntercom(id) {
      // ! ONLY WORKS ON PROD
      const token = jwt.sign({}, 'NTNv7j0TuYARvmNMmWXo6fKvM4o6nv/aUi9ryX38ZH+L1bkrnD1ObOQ8JAUmHCBq7Iy7otZcyAagBLHVKvvYaIpmMuxmARQ97jUVG16Jkpkp1wXOPsrF9zwew6TpczyHkHgX5EuLg2MeBuiT/qJACs1J0apruOOJCg/gOtkjB4c=', {
        header: {
          predicates: [{
            attribute: 'role',
            comparison: 'eq',
            type: 'role',
            value: 'user_role'
          }, {
            attribute: 'user_id',
            comparison: 'eq',
            type: 'string',
            value: id
          }]
        }
      });
      const toSubmit = token.split('.');
      window.open(`https://app.intercom.com/a/apps/dfvodm6i/users/segments/all-users:${toSubmit[0]}`, '_blank');
    },

    initiateWaitingInterval() {
      const now = new moment();
      const hasDelayUntilItems = this.prescriptionData.filter(item => item.delayuntil && item.delayuntil >= now.unix());

      if (hasDelayUntilItems.length === 0) {
        const hasWaitingItems = this.prescriptionData.filter(item => item.prescriptionstatus === 'WAITING');

        if (hasWaitingItems.length > 0) {
          this.hasWaitingBeforeCleanup = true;
          this.cleanupWaitingInterval();
        }
      } else {
        hasDelayUntilItems.forEach(prescription => {
          const end = new moment(prescription.delayuntil * 1000);
          const secondsDiff = Math.round(moment.duration(end.diff(now)).asSeconds());

          if (secondsDiff >= 0) {
            this.$set(this.timers, prescription.id, {
              time: secondsDiff
            });
          }
        });
      }

      if (!this.hasWaitingBeforeCleanup && hasDelayUntilItems.length > 0) {
        setTimeout(this.initiateWaitingInterval, 1000);
      }
    },

    async cleanupWaitingInterval() {
      const hasWaitingItems = this.prescriptionData.filter(item => ['WAITING', 'DRAFTED', 'PROCESSING'].includes(item.prescriptionstatus));

      if (this.refreshPrescriptionCounter > 5) {
        this.refreshPrescriptionCounter = 0;
        this.hasWaitingOnCleanup = false;
        this.hasWaitingBeforeCleanup = false;
        return;
      }

      if (hasWaitingItems.length > 0) {
        this.hasWaitingOnCleanup = true;
        await this.refreshPrescriptions();
      } else {
        this.refreshPrescriptionCounter = 0;
        this.hasWaitingOnCleanup = false;
        this.hasWaitingBeforeCleanup = false;
      }

      this.refreshPrescriptionCounter += 1;

      if (this.hasWaitingOnCleanup) {
        setTimeout(this.cleanupWaitingInterval, 3000);
      }
    },

    openPrescriptionHistory(prescription) {
      if (prescription) {
        this.$refs.PrescriptionHistoryDialog.selectedPrescription = prescription;
      }

      this.openDialog('PrescriptionHistoryDialog');
    },

    openPrescriptionDialog(refname, prescription) {
      this.selectedPrescription = prescription;
      this.openDialog(refname);
    },

    setDoctorName(name) {
      // Note: If more properties need to be updated, make this more generic
      this.$set(this.patientData, 'doctorname', name);
    },

    async openTogglePrescription(resume, prescription) {
      // TODO refactor this to put the logic in the dialog if possible
      if (prescription.prescriptionstatus === 'SCHEDULED') {
        this.openPrescriptionDialog('PushScheduledPrescriptionDialog', prescription);
        return;
      }

      if (prescription.prescriptionstatus === 'FAILED') {
        if (Object.hasOwn(prescription, 'subscriptionid')) {
          this.openPrescriptionDialog('RetryPrescriptionDialog', prescription);
        } else {
          console.log('push scheduled prescription');
          await PrescriptionService.pushScheduledPrescription(prescription.patientid, prescription.id);
          setTimeout(() => {
            this.refreshData();
          }, 3000);
        }

        return;
      }

      if (prescription.can_restart) {
        this.openPrescriptionDialog('RestartPrescriptionDialog', prescription);
      } else {
        prescription.firstname = this.patientData.firstname;
        prescription.lastname = this.patientData.lastname;

        if (!resume) {
          this.openPrescriptionDialog('PausePrescriptionDialog', prescription);
        } else {
          this.openPrescriptionDialog('ResumePrescriptionDialog', prescription);
        }
      }
    },

    async pauseTimer(prescription) {
      await PrescriptionService.stopTimerDelay(this.patientData.id, prescription.id);
      await this.refreshPrescriptions();
    },

    getPrescriptionCardPatientData(data) {
      return {
        firstname: data.firstname,
        lastname: data.lastname,
        hysterectomy: data.hysterectomy ? data.hysterectomy : false
      };
    }

  }
};