//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { DoctorService } from '@/services/doctor.service';
import EarningSearch from '@/components/doctors/EarningSearch';
export default {
  components: {
    EarningSearch
  },
  props: {
    currentFilter: {
      type: String,
      default: 'Last Month'
    },
    hideZeroValues: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      headers: [{
        key: 'lastname',
        label: 'Doctor',
        sortable: true,
        formatter: (key, value, item) => {
          return `${item.lastname} ${item.firstname}`;
        },
        sortByFormatted: true
      }, {
        key: 'monthyear',
        label: 'Billing Period',
        sortable: true
      }, {
        key: 'totalnewpatients',
        label: 'New Patients'
      }, {
        key: 'transferredpatients',
        label: 'Transferred Patients'
      }, {
        key: 'messageadjusted',
        label: 'Messaged Patients (Adjusted)'
      }, {
        key: 'tenweekfollowups',
        label: '10 Week Follow Ups'
      }, {
        key: 'oneyearfollowups',
        label: '1 Year Follow Ups'
      }, {
        key: 'totalfollowups',
        label: 'Total Follow Ups'
      }, {
        key: 'grandtotal',
        label: 'Grand Total'
      }],
      items: [],
      nextPage: '',
      selectedRow: null
    };
  },

  computed: {
    searchResults() {
      return this.$store.state.earnings.searchResults;
    },

    filteredItems() {
      if (this.hideZeroValues) {
        return this.items.filter(item => item.earning.total > 0);
      } else {
        return this.items;
      }
    },

    totals() {
      let totalPatients = 0;
      let totalTransferPatients = 0;
      let totalPatientsMessageAdjusted = 0;
      let totalTenWeek = 0;
      let totalOneYear = 0;
      let grandTotal = 0;
      let totalFollowups = 0;
      this.items.forEach(item => {
        grandTotal += item.earning.total || 0;
        totalPatients += item.patients.new || 0;
        totalTransferPatients += item.patients.transferfollowup || 0;
        totalPatientsMessageAdjusted += item.patients.messageadjusted || 0;
        totalTenWeek += item.patients.followuptenweek || 0;
        totalOneYear += item.patients.followuponeyear || 0;
        totalFollowups += item.patients.followup || 0;
      });
      const totals = {
        grandTotal,
        totalPatients,
        totalTransferPatients,
        totalPatientsMessageAdjusted,
        totalTenWeek,
        totalOneYear,
        totalFollowups
      };
      return totals;
    }

  },
  watch: {
    searchResults() {
      if (this.searchResults.length == 0) {
        this.getEarning(this.$store.state.earnings.from, this.$store.state.earnings.to);
      } else {
        this.items = this.searchResults;
      }
    },

    async currentFilter(val) {
      // this.$store.commit('earnings/SET_DATE_FILTER', val);
      if (val == 'Last Month') {
        const lastMonth = moment().subtract(1, 'months').format('YYYYMM');
        this.$store.commit('earnings/SET_FILTER_FROM', lastMonth);
        this.$store.commit('earnings/SET_FILTER_TO', lastMonth);
        this.getEarning(lastMonth, lastMonth);
      } else if (val == 'Month to Date') {
        const currentMonth = moment().format('YYYYMM');
        this.$store.commit('earnings/SET_FILTER_FROM', currentMonth);
        this.$store.commit('earnings/SET_FILTER_TO', currentMonth);
        this.getEarning(currentMonth, currentMonth);
      } else if (val == 'Year to Date') {
        const startOfYear = moment().startOf('year').format('YYYYMM');
        const currentMonth = moment().format('YYYYMM');
        this.$store.commit('earnings/SET_FILTER_FROM', startOfYear);
        this.$store.commit('earnings/SET_FILTER_TO', currentMonth);
        this.getEarning(startOfYear, currentMonth);
      } else if (val == 'All Time') {
        // ? Jan 2021 - Earliest possible month
        const start = '202101';
        const currentMonth = moment().format('YYYYMM');
        this.$store.commit('earnings/SET_FILTER_FROM', start);
        this.$store.commit('earnings/SET_FILTER_TO', currentMonth);
        this.getEarning(start, currentMonth);
      }
    }

  },

  async mounted() {
    const currentMonth = moment().format('YYYYMM');
    this.$store.commit('earnings/SET_FILTER_FROM', currentMonth);
    this.$store.commit('earnings/SET_FILTER_TO', currentMonth);
    this.getEarning(currentMonth, currentMonth);
  },

  methods: {
    async getEarning(start, end) {
      const {
        data
      } = await DoctorService.getDoctorEarning(start, end);
      this.items = data;

      if (data.nextpage) {
        this.nextPage = data.nextpage;
      }
    },

    getDate(date) {
      if (!date) {
        return 'Indefinite';
      } else {
        return moment(date * 1000).format('MM/DD/YYYY LT');
      }
    },

    getTotalLimit(states) {
      let max = 0;

      for (let state of states) {
        max += Number(state.limit);
      }

      return max;
    },

    updateStates(id, data) {
      this.items = this.items.map(o => {
        if (o.id == id) {
          o.coveredstate = data.coveredstate;
        }
      });
    },

    getStateList(states) {
      if (states.length == 1) {
        return states[0].state;
      } else {
        let output = '';

        for (let state of states) {
          output += `${state.state}, `;
        }

        return output.slice(0, -1);
      }
    },

    removeItem(id) {
      this.items.splice(this.items.findIndex(o => o.id == id), 1);
    },

    addRow(row) {
      this.items.push(row);
    },

    updateRow(id, row) {
      this.items = this.items.map(o => {
        if (o.id == id) {
          return row;
        }
      });
    },

    getBillingPeriodString(month, year) {
      const startOfMonth = moment(`${year}-${month}`).startOf('month').format('MM/DD/YYYY');
      const endOfMonth = moment(`${year}-${month}`).endOf('month').format('MM/DD/YYYY');
      return `${startOfMonth} - ${endOfMonth}`;
    }

  }
};