/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isShow
      ? _c("div", {
          staticClass: "base-side-slide__screen tw-transition",
          on: {
            click: function($event) {
              _vm.isShow = false
            }
          }
        })
      : _vm._e(),
    _c(
      "div",
      _vm._b(
        {
          staticClass: "base-side-slide tw-shadow-lg tw-p-0",
          class: {
            "base-side-slide--open": _vm.isShow,
            "base-side-slide__fixed-width": _vm.isFixedWidth,
            "base-side-slide__full": _vm.isFull
          }
        },
        "div",
        _vm.$attrs,
        false
      ),
      [_vm._t("default", null, null, { show: _vm.show, hide: _vm.hide })],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }