/**
 * Usage
 *
 * const camera = await getRef.call(this, "camera");
 */

export async function getRef (name, timeout = 5000) {
  let ref;

  const startTime = Date.now();
  while (!ref) {
    if (Date.now() - startTime >= timeout) {
      throw new Error(`Timeout while waiting for ref ${name}`);
    }

    ref = this.$refs[name];

    await new Promise((resolve) => {
      requestAnimationFrame(resolve);
    });
  }

  return ref;
}
