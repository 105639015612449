//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    attachments() {
      const attachmentRaw = this.message.attachment || [];
      const attachments = attachmentRaw.map(attachment => {
        return {
          url: attachment.url || URL.createObjectURL(attachment.file),
          filename: attachment.filename || attachment.file.name,
          type: attachment.type
        };
      });
      return attachments.sort((a, b) => {
        const aIsValid = this.isValidImage(a);
        const bIsValid = this.isValidImage(b);

        if (aIsValid && !bIsValid) {
          return -1; // a comes first
        }

        if (!aIsValid && bIsValid) {
          return 1; // b comes first
        }

        return 0;
      });
    }

  },
  methods: {
    isValidImage(attachment) {
      const isImage = attachment.type.includes('image');
      const isInvalidType = ['tiff', 'heic', 'svg'].some(type => {
        return attachment.type.includes(type);
      });
      return isImage && !isInvalidType;
    }

  }
};