import { io } from 'socket.io-client';

class Socket {
  ctx

  connect () {
    if (this.ctx) {
      return
    }

    this.ctx = io(process.env.VUE_APP_WEBSOCKET_URL, {
      auth: {
        token: localStorage.getItem('accessToken'),
      },
      transports: ['websocket', 'polling'],
    });

    console.log('Successfully connected to socket')
  }

  disconnect () {
    if (!this.ctx) {
      return
    }

    this.ctx.disconnect()

    console.log('Successfully disconnected to socket')
  }
}

export default new Socket()