//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { ActionService } from '@/services/action.service'; // TODO once this changes to message, refactor to change variable names

export default {
  props: {
    patientId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      disableInput: false,
      note: ''
    };
  },

  computed: {
    iconColor() {
      return this.note.length > 0 ? 'primary' : 'muted';
    }

  },
  methods: {
    async send() {
      console.log(this.note);

      if (this.note.trim().length <= 0) {
        return;
      }

      if (this.note.trim().length > 2000) {
        this.$emit('show-toast', 'Each note can only be 2000 characters long.');
        return;
      }

      this.disableInput = true;
      let note = this.note.trim();
      const body = {
        actionid: 'all',
        notes: note,
        action: 'Other'
      };
      this.note = '';

      try {
        const {
          data
        } = await ActionService.addActionNote(this.patientId, body);

        if (data) {
          this.$emit('add-event', {
            messagebody: note,
            event: 'Note',
            eventcategory: 'Notes',
            id: data.id,
            timestampinteraction: data.timestampcreated,
            noteby: data.noteby
          });
        }
      } catch (err) {
        this.$emit('show-toast', err);
      } finally {
        this.disableInput = false;
      }
    }

  }
};