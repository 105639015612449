/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hydrated
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.formControl
            ? [
                _c(
                  "b-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "b-row",
                      { staticStyle: { height: "100vh" } },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", lg: "4" } },
                          [_c("vue-page-transition", [_c("router-view")], 1)],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "landing-page tw-flex tw-justify-center tw-items-center",
                            attrs: { cols: "0", lg: "8" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "773",
                                  height: "773",
                                  viewBox: "0 0 773 773",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      filter: "url(#filter0_f_3255_6246)"
                                    }
                                  },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        cx: "386.26",
                                        cy: "386.26",
                                        r: "186.26",
                                        fill: "#F5F3F8"
                                      }
                                    }),
                                    _c("circle", {
                                      attrs: {
                                        cx: "386.26",
                                        cy: "386.26",
                                        r: "186.26",
                                        fill: "url(#paint0_linear_3255_6246)"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "defs",
                                  [
                                    _c(
                                      "filter",
                                      {
                                        attrs: {
                                          id: "filter0_f_3255_6246",
                                          x: "0",
                                          y: "0",
                                          width: "772.52",
                                          height: "772.52",
                                          filterUnits: "userSpaceOnUse",
                                          "color-interpolation-filters": "sRGB"
                                        }
                                      },
                                      [
                                        _c("feFlood", {
                                          attrs: {
                                            "flood-opacity": "0",
                                            result: "BackgroundImageFix"
                                          }
                                        }),
                                        _c("feBlend", {
                                          attrs: {
                                            mode: "normal",
                                            in: "SourceGraphic",
                                            in2: "BackgroundImageFix",
                                            result: "shape"
                                          }
                                        }),
                                        _c("feGaussianBlur", {
                                          attrs: {
                                            stdDeviation: "100",
                                            result:
                                              "effect1_foregroundBlur_3255_6246"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_3255_6246",
                                          x1: "555.654",
                                          y1: "386.259",
                                          x2: "28.8199",
                                          y2: "386.259",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.15605",
                                            "stop-color": "#37514D"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#37514D"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [_c("vue-page-transition", [_c("router-view")], 1)]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }