export default {
  namespaced: true,
  state: {
    searchResults: [],
    searchQuery: '',
    from: null,
    to: null,
  },
  mutations: {
    EMPTY_SEARCH_RESULTS(state) {
      if (state.searchResults.length > 0) {
        state.searchResults = [];
        state.searchQuery = '';
      }
    },
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results;
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query;
    },
    SET_FILTER_FROM(state, from) {
      state.from = from;
    },
    SET_FILTER_TO(state, to) {
      state.to = to;
    },
  },
};
