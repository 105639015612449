//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import Vue from 'vue';
import { format, fromUnixTime } from 'date-fns';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment'; // services

import { ChatService } from '@/services/chat.service.js';
import { PatientService } from '@/services/patient.service.js'; // assets

import audio from '@/assets/notifications/pristine.mp3';
const notificationsound = new Audio(audio); // mixins

import { messageHeightMixin } from "@/mixins/messageHeightMixin";
import heightObserver from '@/assets/js/observeHeight.js'; // components

import PatientsInfoSidePanel from '@/components/patients/PatientsInfoSidePanel';
import DoctorRequestDialog from '@/components/patients/DoctorRequestDialog';
export default {
  components: {
    DoctorRequestDialog,
    PatientsInfoSidePanel
  },
  mixins: [heightObserver, messageHeightMixin],
  props: {
    userid: {
      type: String,
      default: localStorage.getItem('userid')
    },
    conversationlist: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      patientName: '',
      isSending: false,
      preUpload: false,
      newMessage: null,
      filter: null,
      attachtments: [],
      files: [],
      loading: false,
      nextPage: 1,
      messages: [],
      conversationmemberlist: [],
      conversation: {},
      conversationdoctor: {
        id: ''
      },
      conversationId: '',
      // showCannedReplyModal: false,
      isSearching: false,
      selectedForwardedRequest: null,
      patientid: '',
      patientData: {},
      toastMessage: '',
      socket: null,
      fetchingNextPage: false
    };
  },

  computed: {
    resultQuery() {
      return _.orderBy(this.conversationlist, ['lastactivity'], ['desc']);
    },

    paginatedConversationList() {
      const itemsPerPage = 50;
      const conversationListPageNumber = this.$store.state.message.conversationListPageNumber;
      return this.resultQuery.slice((conversationListPageNumber - 1) * itemsPerPage, conversationListPageNumber * itemsPerPage);
    },

    hasUndefinedNextPage() {
      return this.$store.state.message.conversationListNextPage.some(item => item === undefined || item === '');
    },

    isMessageBodyEmpty() {
      if (this.newMessage) {
        return this.newMessage.trim() === '';
      } else {
        // Check if there's a file included during sending
        return !(this.files.length > 0);
      }
    }

  },
  watch: {
    conversationlist: {
      deep: true,

      handler() {
        if (this.$route.query.patientid) {
          this.conversationId = this.conversationlist[0].id;
        }
      }

    },
    conversationId: {
      handler(id) {
        this.listMessage(id);
        const foundIndex = this.conversationlist.findIndex(x => x.id == id);

        if (foundIndex > -1) {
          Vue.set(this.conversationlist[foundIndex], 'unreadcount', false);
        }

        if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
          ChatService.markConversationAsRead(id);
        }
      }

    }
  },

  mounted() {
    this.connectToSocket();
  },

  destroyed() {
    if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
      var _this$socket;

      (_this$socket = this.socket) === null || _this$socket === void 0 ? void 0 : _this$socket.disconnect();
    }
  },

  methods: {
    viewPatientInfo() {
      this.$refs.PatientsInfoSidePanel.show();
    },

    redirectToForwardedMessage(fwd) {
      this.selectedForwardedRequest = fwd;
      this.$refs.DoctorRequestDialog.showDialog = true;
    },

    getDate(timestamp) {
      return moment(timestamp).format('MMM D, YYYY');
    },

    getTime(timestamp) {
      return moment(timestamp).format('LT');
    },

    openUpload() {
      this.$refs.fileInput.click();
    },

    handleFilesUpload() {
      const uploadedFiles = this.$refs.fileInput.files;
      this.preUpload = true;
      const maxFileSize = 30 * 1024 * 1024; //30mb

      for (let i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i] && uploadedFiles[i].size > maxFileSize) {
          this.$bvToast.toast('Maximum file size is 7mb', {
            variant: 'warning',
            noCloseButton: true,
            autoHideDelay: 2000,
            toaster: 'b-toaster-bottom-center'
          });
        } else {
          this.files.push(uploadedFiles[i]);
          uploadedFiles[i].url = URL.createObjectURL(uploadedFiles[i]);
          this.attachtments.push(uploadedFiles[i]);
        }
      }
    },

    removeFile(key) {
      this.attachtments.splice(key, 1);

      if (this.attachtments.length == 0) {
        this.attachtments = [];
        this.files = [];
        this.preUpload = false;
      }
    },

    async sendMessage() {
      try {
        if (this.isMessageBodyEmpty) {
          if (!(this.files.length > 0)) {
            return;
          }
        }

        if (this.isSending === false) {
          this.isSending = true;

          if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
            const obj = {
              id: uuidv4(),
              conversationId: this.conversation.id,
              sentAt: Math.floor(Date.now() / 1000),
              message: this.newMessage
            };

            if (this.files.length > 0) {
              obj.attachment = Object.keys(this.files).map(key => ({
                name: this.files[key].name,
                size: this.files[key].size,
                type: this.files[key].type,
                file: this.files[key]
              }));
              this.socket.emit('new_message', obj);
            } else {
              this.socket.emit('new_message', obj);
            }
          } else {
            const obj = {
              id: this.conversation.id,
              message: this.newMessage
            };

            if (this.files.length > 0) {
              const formData = new FormData();

              for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i];
                formData.append('attachment', file, file.name);
              }

              formData.append('message', this.newMessage);
              obj.attachment = formData;
              await ChatService.sendMessageWithAttachment(this.conversation.id, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
            } else {
              await ChatService.sendMessage(obj);
            }
          }

          const chatBox = document.querySelector('.card-chat-body');
          chatBox.scrollTop = 9999;
          this.$refs.conversationListRef.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error.message);
        return false;
      } finally {
        this.isSending = false;
        this.newMessage = '';
        this.attachtments = [];
        this.files = [];
        this.preUpload = false;
      }
    },

    async setConversationId(conversation) {
      this.conversationId = conversation.id;
      this.patientName = conversation.name;
      this.patientid = conversation.patientid;
      const {
        data
      } = await PatientService.getPatient(conversation.patientid);
      this.patientData = data;
    },

    async listMessage(conversationid) {
      try {
        this.newMessage = '';
        this.nextPage = null;
        this.loading = true;
        this.loadingMessage = false;
        this.conversation = await this.conversationlist.find(x => x.id === conversationid);
        ChatService.listMessage(conversationid, this.nextPage).then(({
          data: messages
        }) => {
          this.messages = messages.items;
          this.nextPage = messages.nextpage;
          this.loading = false;
        });
        ChatService.listConversationMember(conversationid).then(({
          data: members
        }) => {
          this.conversationmemberlist = members;

          for (const member of members) {
            if (member.type === 'DOCTOR') {
              localStorage.setItem('patientId', this.conversation.userid);
              this.conversationdoctor = member;
            }
          }
        });
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },

    getMemberProfileUrl(userid) {
      const member = this.conversationmemberlist.find(x => x.userId === userid);

      if (member) {
        return member.profileUrl || require('@/assets/images/anon.jpeg');
      } else {
        return '';
      }
    },

    async chatMessage() {
      const {
        data: messages
      } = await ChatService.listMessage(this.conversationId, this.nextPage);

      for (let i = 0; i < messages.items.length; i++) {
        const message = messages.items[i];
        this.messages.push(message);
      }

      if (messages.nextpage) {
        this.nextPage = messages.nextpage;
      } else {
        this.nextPage = null;
      }
    },

    messageScroll(e) {
      // let scroll = e.target.scrollTop + e.target.clientHeight === e.target.offsetHeight <-- OLD VERSION (THIS IS WRONG ILONG)
      const scroll = e.target.scrollTop + e.target.scrollHeight === e.target.clientHeight;
      const scrollwithallowance1 = e.target.scrollTop + e.target.scrollHeight < e.target.clientHeight + 10;

      try {
        if (scroll || scrollwithallowance1) {
          if (this.nextPage && !this.loadingMessage) {
            this.loadingMessage = true;
            this.chatMessage();
            this.loadingMessage = false;
          }
        } else {// console.log(e.target.scrollTop + e.target.scrollHeight)
          // console.log(e.target.clientHeight)
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingMessage = false;
      }
    },

    async nextConversationList() {
      if (this.fetchingNextPage) return;
      this.$refs.conversationListRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.fetchingNextPage = true;
      await this.$store.dispatch('message/getConversationList');
      this.fetchingNextPage = false;
      this.$store.dispatch('message/nextConversationListPage');
    },

    async previousConversationList() {
      this.$refs.conversationListRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.$store.dispatch('message/previousConversationListPage');
    },

    onConversationSearch: _.debounce(async function () {
      await this.$store.dispatch('message/searchConversationList', this.filter);

      if (this.filter) {
        this.isSearching = true;
      } else {
        this.isSearching = false;
      }
    }, 350),

    onLoadAllConversations() {
      this.$router.push('/messages');
      this.$emit('fetch-conversations');
    },

    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },

    onMarkUnread(conversationid) {
      const foundIndex = this.conversationlist.findIndex(x => x.id == conversationid);

      if (foundIndex > -1) {
        Vue.set(this.conversationlist[foundIndex], 'unreadcount', true);
      }

      this.$store.dispatch('message/markAsUnread', {
        conversationid: conversationid
      });
    },

    getMemberName(userid) {
      const selectedUser = this.conversationmemberlist.find(member => member.userId === userid);
      const fullName = `${selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.firstName} ${selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.lastName}`;

      if (selectedUser !== null && selectedUser !== void 0 && selectedUser.firstName && selectedUser !== null && selectedUser !== void 0 && selectedUser.lastName) {
        return (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.type) === 'ADMIN' ? `${fullName} (Admin)` : `Dr. ${fullName}`;
      } else {
        return '';
      }
    },

    connectToSocket() {
      if (localStorage.getItem('accessToken')) {
        this.socket = io(process.env.VUE_APP_WEBSOCKET_URL, {
          auth: {
            token: localStorage.getItem('accessToken')
          },
          transports: ['websocket', 'polling']
        });
        this.socket.on('connect', () => {
          console.log('Connected to server');
        });
        this.socket.on('connect_error', async err => {
          console.log(err);

          if (err.message === 'Invalid token') {
            this.socket.disconnect();
            this.socket.auth.token = localStorage.getItem('accessToken');
            this.socket.connect();
          } else {
            this.socket.io.opts.transports = ['polling', 'websocket'];
          }
        });
        this.socket.on('request_error', err => {
          console.log(err);
        });
        this.socket.on('validation_error', err => {
          console.log(err);
        });
        this.socket.on('message_delivery_confirmed', message => {
          if (message.conversationid === this.conversationId) {
            console.log('Confirmed: ', message);
            this.onMessageReceived(message);
          }

          this.onConversationUpdate(message);
        });
        this.socket.on('message', message => {
          if (message.conversationid === this.conversationId) {
            this.onMessageReceived(message);
            console.log('Received message', message);
          } // Prevent updating conversation when there's a patientid url params


          if (!this.$route.query.patientid) {
            this.onConversationUpdate(message);
          }
        });
      }
    },

    async onConversationUpdate(message) {
      this.loading = false;
      const chatBox = document.querySelector('.card-chat-body');
      setTimeout(() => {
        var _this$$refs$conversat;

        chatBox === null || chatBox === void 0 ? void 0 : chatBox.scrollTo({
          top: 9999,
          behavior: 'smooth'
        });
        (_this$$refs$conversat = this.$refs.conversationListRef) === null || _this$$refs$conversat === void 0 ? void 0 : _this$$refs$conversat.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 300); // needs reply

      const foundIndex = this.conversationlist.findIndex(x => x.id == message.conversationid);

      if (foundIndex > -1) {
        var _this$conversationlis;

        if (this.conversationId !== message.conversationid) {
          this.conversationlist[foundIndex]['unreadcount'] = 1;
        }

        this.conversationlist[foundIndex]['lastactivity'] = message === null || message === void 0 ? void 0 : message.sentat;
        this.conversationlist[foundIndex]['lastmessage'] = message === null || message === void 0 ? void 0 : message.message;

        if ((_this$conversationlis = this.conversationlist) !== null && _this$conversationlis !== void 0 && _this$conversationlis[foundIndex]) {
          const removedItem = this.conversationlist.splice(foundIndex, 1);
          this.conversationlist.splice(0, 0, removedItem[0]);
        }
      } else {
        const {
          data: conversationData
        } = await ChatService.getConversationInfo(message.conversationid);
        const conversation = conversationData;

        if (this.conversationId !== message.conversationid) {
          conversation.unreadcount = 1;
        }

        this.conversationlist.splice(0, 0, conversation);
      }
    },

    onMessageReceived(message) {
      if (message.action === 'SENT') {
        const newmessage = message;
        const foundIndex = this.conversationlist.findIndex(x => x.id == newmessage.conversationid);

        if (foundIndex > -1) {
          Vue.set(this.conversationlist[foundIndex], 'unreadcount', false);
        }

        if (newmessage.userid !== this.userid) {
          notificationsound.play();
          this.$store.dispatch('message/markAsRead', {
            conversationid: newmessage.conversationid,
            messageid: newmessage.id
          });
        }

        if (newmessage.type === 'prescription_confirmation') {
          if (newmessage.custommessage.constructor.name === 'String') {
            newmessage.custommessage = JSON.parse(newmessage.custommessage);
          }
        }

        this.messages.splice(0, 0, newmessage);
        this.loading = false;
        this.resultQuery.find(element => {
          if (element.id == newmessage.conversationid) {
            element.lastmessage = newmessage.message;
          }
        });
        const chatBox = document.querySelector('.card-chat-body');
        setTimeout(() => {
          chatBox.scrollTo({
            top: 9999,
            behavior: 'smooth'
          });
          this.$refs.conversationListRef.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }, 300);
        return this.messages;
      } else if (message.action === 'UPDATED') {
        const messageupdate = message;
        const messagetoupdate = this.messages.find(x => x.id === messageupdate.id);

        if (messagetoupdate != undefined) {
          if (messagetoupdate.type === 'prescription_confirmation') {
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          } else if (messagetoupdate.type === 'prescription_followup') {
            messagetoupdate.custommessage = JSON.parse(messageupdate.custommessage);
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          }
        }
      }
    }

  }
};