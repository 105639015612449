/* unplugin-vue-components disabled */var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass:
        "tw-relative tw-m-0 tw-rounded-full tw-transition tw-[-webkit-tap-highlight-color:_transparent] tw-select-none",
      class: [
        _vm.isChecked ? "tw-bg-posey-green" : "tw-bg-gray-300",
        _vm.disabled
          ? "tw-opacity-50 tw-cursor-not-allowed"
          : "tw-cursor-pointer"
      ],
      style: {
        width: _vm.containerWidth + "px",
        height: _vm.containerHeight + "px"
      },
      attrs: { for: _vm.id }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked"
          }
        ],
        staticClass: "tw-peer tw-sr-only",
        attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
        domProps: {
          checked: Array.isArray(_vm.isChecked)
            ? _vm._i(_vm.isChecked, null) > -1
            : _vm.isChecked
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          },
          change: function($event) {
            var $$a = _vm.isChecked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.isChecked = $$c
            }
          }
        }
      }),
      _c(
        "span",
        {
          staticClass:
            "tw-absolute tw-top-[50%] tw-translate-y-[-50%] tw-uppercase tw-text-white",
          style:
            ((_obj = {
              fontSize: _vm.fontSize + "px",
              lineHeight: _vm.fontSize + "px"
            }),
            (_obj[_vm.isChecked ? "left" : "right"] = _vm.margin * 2 + "px"),
            _obj)
        },
        [
          _vm._v(
            " " + _vm._s(_vm.isChecked ? _vm.truthyLabel : _vm.falsyLabel) + " "
          )
        ]
      ),
      _c("span", {
        staticClass:
          "tw-absolute tw-inset-y-0 tw-rounded-full tw-transition-all",
        class: [
          _vm.isChecked ? "tw-bg-commet-streak" : "tw-bg-white",
          "tw-absolute tw-rounded-full tw-transition-all"
        ],
        style: {
          margin: _vm.margin + "px",
          width: _vm.size + "px",
          height: _vm.size + "px",
          insetInlineStart: _vm.insetInlineStart + "px"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }