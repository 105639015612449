/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SideModal", {
    attrs: {
      show: _vm.show,
      "show-header": false,
      "body-class": "macro-modal-body",
      title: "Macros"
    },
    on: {
      close: _vm.close,
      exit: function($event) {
        return _vm.$emit("update:show", false)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column align-items-end",
                class: _vm.show ? "ml-n3" : "",
                staticStyle: { height: "100%" }
              },
              [
                _c(
                  "b-row",
                  {
                    staticClass:
                      "tw-border-0 tw-border-b tw-border-solid tw-border-slate-300",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300",
                        attrs: { cols: "3" }
                      },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "ml-2 pl-1 mt-4 pt-2 tw-font-bold tw-text-[26px]"
                          },
                          [_vm._v(" All Macros ")]
                        )
                      ]
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "ml-3 mt-2 mb-3" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c(
                                  "b-input-group-text",
                                  [
                                    _c("b-icon-search", {
                                      staticClass: "tw-text-[#353148] py-0"
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              ref: "searchMacrosInput",
                              staticClass: "h1",
                              staticStyle: { "padding-bottom": "0" },
                              attrs: {
                                id: "search",
                                placeholder: "Search Macros"
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "tw-w-full tw-h-full" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300 px-0",
                        attrs: { cols: "3" }
                      },
                      [
                        _c(
                          "perfect-scrollbar",
                          {
                            staticClass: "ps tw-max-h-[calc(100vh-172px)]",
                            attrs: { options: _vm.scrollbarOptions }
                          },
                          [
                            _c("div", {
                              ref: "topOfModal",
                              attrs: { id: "topOfModal" }
                            }),
                            _vm.search && _vm.filteredMacros.length > 0
                              ? _c(
                                  "b-list-group",
                                  _vm._l(_vm.filteredMacros, function(macro) {
                                    return _c(
                                      "b-list-group-item",
                                      {
                                        key: macro.id,
                                        staticClass:
                                          "text-truncate tw-cursor-pointer",
                                        class: {
                                          "tw-bg-[#f1f1f1]":
                                            _vm.selectedMacro &&
                                            _vm.selectedMacro.id === macro.id
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectMacro(macro)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(macro.name) + " ")]
                                    )
                                  }),
                                  1
                                )
                              : _vm.search && _vm.filteredMacros.length == 0
                              ? _c("div", { staticClass: "mt-4 ml-4" }, [
                                  _vm._v(" No results found. ")
                                ])
                              : _c(
                                  "b-list-group",
                                  _vm._l(_vm.macros, function(macro) {
                                    return _c(
                                      "b-list-group-item",
                                      {
                                        key: macro.id,
                                        staticClass:
                                          "text-truncate tw-cursor-pointer",
                                        class: {
                                          "tw-bg-[#f1f1f1]":
                                            _vm.selectedMacro &&
                                            _vm.selectedMacro.id === macro.id
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectMacro(macro)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(macro.name) + " ")]
                                    )
                                  }),
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "mt-5 ml-3 mr-5" },
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.alert.show,
                              variant: _vm.alert.variant
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "tw-font-bold tw-text-lg tw-mb-2" },
                          [_vm._v(" Title ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-border tw-border-solid tw-border-slate-300 tw-rounded-lg"
                          },
                          [
                            _c("b-form-input", {
                              ref: "macroTitle",
                              staticClass:
                                "focus:tw-border-none tw-border-none tw-bg-transparent",
                              attrs: { maxlength: "300" },
                              model: {
                                value: _vm.selectedMacro.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectedMacro, "name", $$v)
                                },
                                expression: "selectedMacro.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "h2",
                          {
                            staticClass: "mt-5 tw-font-bold tw-text-lg tw-mb-2"
                          },
                          [_vm._v(" Body ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-border tw-border-solid tw-border-slate-300 tw-rounded-lg"
                          },
                          [
                            _c("b-form-textarea", {
                              ref: "macroContent",
                              staticClass:
                                "focus:tw-border-none tw-border-none tw-bg-transparent tw-max-h-[calc(100vh-600px)]",
                              attrs: { rows: "20" },
                              model: {
                                value: _vm.selectedMacro.content,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectedMacro, "content", $$v)
                                },
                                expression: "selectedMacro.content"
                              }
                            }),
                            _c(
                              "b-dropdown",
                              {
                                ref: "mergeTag",
                                attrs: {
                                  "toggle-class": "invisible-dropdown",
                                  "no-caret": "",
                                  dropleft: "",
                                  offset: "-30"
                                }
                              },
                              [
                                _c(
                                  "b-dropdown-header",
                                  { staticClass: "text-muted" },
                                  [_vm._v(" Insert merge tag ")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.insertMergeTag("{patient}")
                                      }
                                    }
                                  },
                                  [_vm._v(" Patient First Name ")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.insertMergeTag("{doctor}")
                                      }
                                    }
                                  },
                                  [_vm._v(" Doctor Last Name (Dr. xxx) ")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.insertMergeTag("{admin}")
                                      }
                                    }
                                  },
                                  [_vm._v(" Admin First Name ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-p-2 tw-flex tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-slate-300 tw-border-solid"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer tw-mr-3.5",
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.mergeTag.show()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 22.44 18.31",
                                          width: "18",
                                          height: "18",
                                          fill: "#828282"
                                        }
                                      },
                                      [
                                        _c(
                                          "g",
                                          { attrs: { id: "Layer_1-2" } },
                                          [
                                            _c(
                                              "g",
                                              { attrs: { id: "jkgAR3.tif" } },
                                              [
                                                _c("path", {
                                                  staticClass: "cls-1",
                                                  attrs: {
                                                    d:
                                                      "M0,8.91c.2-.47.56-.7,1.05-.81.77-.17,1.01-.5,1.01-1.3,0-1.28-.01-2.56,0-3.84C2.08,1.33,3.42,0,5.02,0c.6,0,1.02.34,1.07.88.06.57-.29,1-.9,1.12-.92.18-1.12.41-1.12,1.32,0,1.01-.02,2.03,0,3.04.02,1.02-.09,1.99-.85,2.79.72.79.87,1.72.85,2.71-.02,1.1,0,2.19,0,3.29,0,.73.3,1.05,1.02,1.15.68.09,1.05.52.99,1.13-.05.55-.49.89-1.13.88-1.53-.03-2.84-1.28-2.89-2.81-.04-1.3-.01-2.59-.02-3.89,0-.93-.2-1.2-1.11-1.42-.46-.11-.76-.37-.95-.79v-.5Z"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "cls-1",
                                                  attrs: {
                                                    d:
                                                      "M12.5,9.15c1.02,1.31,2.03,2.59,3.02,3.88.55.72.22,1.61-.62,1.7-.42.05-.73-.14-.98-.47-.88-1.14-1.77-2.28-2.7-3.47-.79,1.02-1.56,2.01-2.34,3-.14.18-.28.37-.43.55-.38.45-.99.53-1.42.19-.43-.34-.53-.95-.17-1.42.88-1.16,1.79-2.31,2.69-3.47.12-.16.24-.32.38-.5-.97-1.25-1.94-2.49-2.9-3.74-.14-.18-.28-.38-.34-.6-.13-.46.09-.93.5-1.14.44-.23.95-.12,1.29.31.83,1.06,1.66,2.12,2.48,3.18.08.1.17.2.27.33.89-1.15,1.77-2.28,2.65-3.4.44-.56,1.03-.7,1.51-.34.5.37.54,1,.09,1.58-.97,1.26-1.96,2.52-2.97,3.82Z"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "cls-1",
                                                  attrs: {
                                                    d:
                                                      "M19.21,9.16c-.71-.77-.87-1.68-.85-2.66.02-1.1,0-2.19,0-3.29,0-.77-.28-1.09-1.03-1.2-.69-.11-1.05-.52-.99-1.14.05-.57.5-.89,1.18-.87,1.48.05,2.77,1.28,2.83,2.77.05,1.31.02,2.63.03,3.94,0,.92.2,1.18,1.07,1.4.64.17.97.51.98,1.03.01.51-.32.87-.95,1.04-.91.25-1.1.49-1.1,1.44,0,1.28.02,2.56-.02,3.84-.04,1.56-1.36,2.82-2.9,2.85-.64.01-1.06-.33-1.11-.89-.05-.6.31-1.02.95-1.12.8-.12,1.06-.42,1.07-1.21,0-1.06.02-2.13,0-3.19-.02-1.01.1-1.96.85-2.74Z"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex tw-items-center tw-space-x-2 tw-cursor-not-allowed tw-text-slate-300 tw-mr-3.5"
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 20.82 18.49",
                                          width: "18",
                                          height: "18",
                                          fill: "currentColor"
                                        }
                                      },
                                      [
                                        _c(
                                          "g",
                                          { attrs: { id: "Layer_1-2" } },
                                          [
                                            _c(
                                              "g",
                                              { attrs: { id: "OLsa4B.tif" } },
                                              [
                                                _c("path", {
                                                  staticClass: "cls-1",
                                                  attrs: {
                                                    d:
                                                      "M20.81,8.05c0,1.98.01,3.96,0,5.94,0,1.41-.54,1.92-1.95,1.92-2.23,0-4.48-.12-6.41,1.32-.33.24-.6.57-.93.8-.7.5-1.52.69-2.15.03-1.87-1.96-4.23-2.19-6.72-2.16C.29,15.93,0,15.64,0,13.31c0-3.71,0-7.42,0-11.14C0,.51.46.04,2.13.04c1.85,0,3.71-.1,5.45.8,1.14.59,1.71,1.4,1.69,2.74-.06,3.22-.05,6.44.02,9.65.01.52.34,1.24.75,1.51.71.46,1.48-.21,1.51-1.31.05-1.85.02-3.71.02-5.57,0-1.53.02-3.05,0-4.58-.02-1.02.46-1.7,1.31-2.21C15.02-.23,17.38-.07,19.72.16c.9.09,1.08.91,1.09,1.7,0,2.06,0,4.12,0,6.19Z"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex tw-items-center tw-space-x-2 tw-cursor-not-allowed tw-text-slate-300 tw-mr-3.5"
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 20.82 17.82",
                                          width: "18",
                                          height: "18",
                                          fill: "currentColor"
                                        }
                                      },
                                      [
                                        _c(
                                          "g",
                                          { attrs: { id: "Layer_1-2" } },
                                          [
                                            _c(
                                              "g",
                                              { attrs: { id: "GxNHew.tif" } },
                                              [
                                                _c("path", {
                                                  staticClass: "cls-1",
                                                  attrs: {
                                                    d:
                                                      "M10.51,0c2.31,0,4.63-.01,6.94,0,2.21.02,3.35,1.14,3.36,3.36.02,3.71.02,7.42,0,11.14-.01,2.14-1.14,3.28-3.3,3.29-4.75.03-9.5.02-14.25,0-2.06-.01-3.22-1.15-3.23-3.23-.03-3.79-.03-7.59,0-11.38C.04,1.15,1.21.04,3.32.02c2.39-.03,4.79,0,7.19,0ZM2.5,11.31c1.14-.63,2.07-1.11,2.98-1.65,1.19-.7,2.37-.88,3.62-.16.68.39,1.33.87,2.06,1.13.94.34,2.05.89,2.89.65,1.42-.4,2.7-1.33,4.02-2.07.16-.09.26-.41.26-.63.02-1.73.05-3.47-.03-5.2-.02-.31-.55-.86-.85-.86-4.67-.06-9.34-.05-14.01,0-.31,0-.89.45-.89.7-.07,2.62-.04,5.24-.04,8.1Z"
                                                  }
                                                }),
                                                _c("path", {
                                                  staticClass: "cls-1",
                                                  attrs: {
                                                    d:
                                                      "M15.12,6.13c-.03.84-.8,1.56-1.63,1.53-.82-.03-1.58-.83-1.55-1.63.03-.8.86-1.58,1.65-1.55.83.03,1.56.82,1.53,1.65Z"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.selectedMacro && !_vm.isNewMacro
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between mt-3",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    " Created by: " +
                                      _vm._s(_vm.selectedMacro.created_by) +
                                      " " +
                                      _vm._s(
                                        _vm.getTime(
                                          _vm.selectedMacro.timestampcreated
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm.selectedMacro.updated_by
                                    ? _c("span", [
                                        _vm._v(
                                          "Updated last: " +
                                            _vm._s(
                                              _vm.selectedMacro.updated_by
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.getTime(
                                                _vm.selectedMacro
                                                  .timestampupdated
                                              )
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "used " +
                                      _vm._s(_vm.selectedMacro.times_used) +
                                      " times"
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  {
                    staticClass:
                      "mt-auto tw-border-0 tw-border-t tw-border-solid tw-border-slate-300",
                    staticStyle: { width: "100%", height: "10%" }
                  },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300",
                        attrs: { cols: "3" }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-4 px-6",
                            class: {
                              "tw-bg-slate-200 tw-text-slate-500 tw-border-transparent hover:tw-border-transparent":
                                _vm.isNewMacro
                            },
                            attrs: {
                              variant: "outline-dark",
                              size: "lg",
                              disabled: _vm.isNewMacro,
                              block: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.clear()
                              }
                            }
                          },
                          [_vm._v(" New ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "d-flex py-4 pr-5" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "mr-auto align-self-center ml-4",
                            class: {
                              "hover:tw-underline":
                                _vm.selectedMacro.id != null,
                              "tw-cursor-pointer": _vm.selectedMacro.id != null,
                              "tw-cursor-not-allowed":
                                _vm.selectedMacro.id == null,
                              "text-muted": _vm.selectedMacro.id == null
                            },
                            on: {
                              click: function($event) {
                                _vm.showDeleteDialog = true
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "align-self-center mx-6",
                            class: {
                              "hover:tw-underline": !_vm.isCancelDisabled,
                              "tw-cursor-pointer": !_vm.isCancelDisabled,
                              "tw-cursor-not-allowed": _vm.isCancelDisabled,
                              "text-muted": _vm.isCancelDisabled
                            },
                            on: {
                              click: function($event) {
                                return _vm.cancelMacro()
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "px-6 ml-2",
                            attrs: {
                              variant: "secondary",
                              size: "lg",
                              disabled: _vm.isSaveDisabled
                            },
                            on: { click: _vm.save }
                          },
                          [
                            _vm.loading
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _c("div", [
                                  _vm.isNewMacro
                                    ? _c("b", [_vm._v("Save")])
                                    : _c("b", [_vm._v("Update")])
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-modal",
              {
                staticStyle: { "z-index": "700" },
                attrs: {
                  "hide-header": "",
                  "hide-footer": "",
                  centered: "",
                  "no-stacking": "",
                  size: "lg"
                },
                model: {
                  value: _vm.showDeleteDialog,
                  callback: function($$v) {
                    _vm.showDeleteDialog = $$v
                  },
                  expression: "showDeleteDialog"
                }
              },
              [
                _c(
                  "b-row",
                  { staticClass: "mb-4", attrs: { "align-h": "between" } },
                  [
                    _c("b-col", { attrs: { cols: "10" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icon_logo_violet.svg"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("b-col", { staticClass: "text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "tw-text-eclipse",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              _vm.showDeleteDialog = false
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "x-circle", "font-scale": "1.5" }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c("h2", { staticClass: "heading tw-mb-2 tw-text-2xl" }, [
                        _vm._v(" Deleting macro ")
                      ]),
                      _c("p", { staticClass: "tw-text-muted tw-text-base" }, [
                        _vm._v(" Are you sure you want to delete this macro? ")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "mb-3",
                        attrs: { offset: "4", cols: "4" }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "secondary",
                              disabled: _vm.deleteLoading
                            },
                            on: { click: _vm.deleteMacro }
                          },
                          [_vm._v(" Delete ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-dark",
                              disabled: _vm.deleteLoading
                            },
                            on: {
                              click: function($event) {
                                _vm.showDeleteDialog = false
                              }
                            }
                          },
                          [_vm._v(" Cancel ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-modal",
              {
                staticStyle: { "z-index": "700" },
                attrs: {
                  "hide-header": "",
                  "hide-footer": "",
                  centered: "",
                  "no-stacking": "",
                  size: "lg"
                },
                model: {
                  value: _vm.showDiscardDialog,
                  callback: function($$v) {
                    _vm.showDiscardDialog = $$v
                  },
                  expression: "showDiscardDialog"
                }
              },
              [
                _c(
                  "b-row",
                  { staticClass: "mb-4", attrs: { "align-h": "between" } },
                  [
                    _c("b-col", { attrs: { cols: "10" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icon_logo_violet.svg"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("b-col", { staticClass: "text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "tw-text-eclipse",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              _vm.showDiscardDialog = false
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "x-circle", "font-scale": "1.5" }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("h2", { staticClass: "heading tw-mb-2 tw-text-2xl" }, [
                        _vm._v(" You have unsaved changes ")
                      ]),
                      _c("p", { staticClass: "tw-text-muted tw-text-base" }, [
                        _vm._v(
                          " You have edited this macro. Do you want to discard these changes? "
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "mb-3",
                        attrs: { offset: "4", cols: "4" }
                      },
                      [
                        _vm.discardAction == "switch"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  block: "",
                                  variant: "outline-dark"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectMacro(
                                      _vm.macroToSelect,
                                      true
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Discard ")]
                            )
                          : _vm._e(),
                        _vm.discardAction == "cancel"
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  block: "",
                                  variant: "outline-dark"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.cancelMacro(true)
                                  }
                                }
                              },
                              [_vm._v(" Discard ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "secondary"
                            },
                            on: {
                              click: function($event) {
                                _vm.showDiscardDialog = false
                              }
                            }
                          },
                          [_vm._v(" Continue Editing ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }