//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false
    };
  }

};