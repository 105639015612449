//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import md5 from 'js-md5';
import { PatientService } from '@/services/patient.service';
import { DoctorService } from '@/services/doctor.service';
import { AdminsService } from '@/services/admins.service';
import AddNoteHistory from '@/components/patients/partials/AddNoteHistory';
import { parseHtml } from '@/utils/parseHtml';
export default {
  components: {
    AddNoteHistory
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    patientId: {
      type: String,
      default: ''
    },
    patientName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      doctors: [],
      admins: [],
      historyData: [],
      dataloaded: false,
      alert: {
        show: false,
        message: ''
      },
      pillIds: {
        SMS: 'badge-sms',
        Intercom: 'badge-intercom',
        Event: 'badge-default',
        'Information Update': 'badge-information',
        'Admin Update': 'badge-information',
        Onboarding: 'badge-primary',
        Orders: 'badge-portal',
        Refund: 'badge-refund',
        'Follow Up': 'badge-primary',
        'Doctor Reassigned': 'badge-reassign',
        Notes: 'badge-information',
        JustCall: 'badge-information',
        'Support Interaction': 'badge-intercom'
      },
      sortBy: 'DESC',
      filterFrom: '',
      filterTo: '',
      selectedFilter: [],
      messageFilters: ['SMS', 'Intercom', 'Support Interaction', 'JustCall', 'Notes'],
      closeable: false
    };
  },

  computed: {
    maxDate() {
      return new Date();
    },

    maxFrom() {
      if (this.filterTo) {
        return this.filterTo;
      } else {
        return this.maxDate;
      }
    },

    eventsHidden() {
      return this.selectedFilter.every(o => this.messageFilters.includes(o)) && this.selectedFilter.length > 0;
    }

  },
  watch: {
    async show(show) {
      if (show) {
        const {
          data
        } = await PatientService.getHistory(this.patientId);
        this.historyData = this.removeDuplicates(data);
        this.selectedFilter = Object.keys(this.pillIds);
        this.dataloaded = true;
        await this.$nextTick();
        this.$refs.bottomOfPage.scrollIntoView();
      } else {
        this.historyData = [];
        this.filterFrom = '';
        this.filterTo = '';
        this.sortBy = 'DESC';
        this.dataloaded = false;
      }
    }

  },

  async mounted() {
    let {
      data
    } = await DoctorService.getAllDoctors();
    this.doctors = data;
    ({
      data
    } = await AdminsService.getAdmins());
    this.admins = data;
  },

  methods: {
    parseHtml,

    async toggleEvents() {
      if (!this.eventsHidden) {
        this.selectedFilter = this.messageFilters;
        await this.setOptions();
      } else {
        this.reset();
      }
    },

    getAlignment(entry) {
      if (entry.platform == 'INTERCOM' && entry.eventname == 'Outgoing' || entry.eventcategory == 'Notes' || entry.eventcategory == 'SMS' && entry.eventname == 'Outgoing') {
        return 'end';
      } else {
        return 'start';
      }
    },

    getStyle(entry) {
      if (entry.platform == 'INTERCOM' && entry.eventname == 'Outgoing' || entry.eventcategory == 'SMS' && entry.eventname == 'Outgoing') {
        return 'message-admin';
      } else if (entry.platform == 'INTERCOM' && entry.eventname == 'Incoming') {
        return 'message-patient';
      } else if (entry.eventcategory == 'Notes') {
        return 'message-notes';
      } else {
        return 'message-event';
      }
    },

    getIcon(entry) {
      // Intercom override
      if (entry.eventcategory == 'Intercom') {
        return this.patientName.charAt(0);
      } else if (entry.eventcategory == 'Information Update' || entry.eventcategory == 'Admin Updated') {
        return '&#128221;';
      }

      switch (entry.eventname) {
        case 'Account Created':
          return '&#127874;';

        case 'Visit Started':
        case 'Questionnaire Completed':
        case 'Treatment Chosen':
        case 'Verification Started':
        case 'ID Uploaded':
        case 'Photo Uploaded':
        case 'Checkout Started':
          return '&#9989;';

        case 'ID Upload Skipped':
        case 'Photo Upload Skipped':
        case 'Identity Verification: Denied':
        case 'Follow Up Cancelled':
          return '&#9888;';

        case 'Checkout Complete':
        case 'Onboarding Completed':
          return '&#127881;';

        case 'Patient Accepted':
        case 'Identity Verification: Accepted':
          return '&#129351;';

        case 'Follow Up Started':
        case 'Follow Up Completed':
        case 'Follow Up Sent':
          return '&#128138;';

        case 'Dr Sent a Message':
          return '&#129658;';

        case 'Patient Sent a Message':
        case 'Patient Messaged Doctor':
          return '&#128105;';

        case 'Photo Updated':
        case 'ID Updated':
          return '&#128247;';

        case 'Treatment Shipped':
          return '&#x1f4e6;';

        case 'Payment Cleared:':
          return '&#x1f4b5;';

        case 'Payment Failed':
          return '&#9940;';

        default:
          return '&#9889;';
      }
    },

    getAssignerName(id) {
      const admin = this.admins.find(o => o.id == id);

      if (admin) {
        return `Admin ${admin.firstname} ${admin.lastname}`;
      } else {
        return this.getDoctorName(id);
      }
    },

    getDoctorName(id) {
      const doctor = this.doctors.find(o => o.id == id);

      if (doctor) {
        return `Dr. ${doctor.firstname} ${doctor.lastname}`;
      } else {
        return id;
      }
    },

    getDateTime(date) {
      return moment(date).format('LT MM/DD/YY');
    },

    getDate(date) {
      return moment(date).format('MM/DD/YY');
    },

    showAlert(msg) {
      this.$bvToast.show('historyToast');
      this.alert.message = msg;
    },

    handleHide(bvEvent) {
      if (!this.closeable) {
        bvEvent.preventDefault();
      } else {
        this.$refs.dropdown.hide();
      }
    },

    removeDuplicates(arr) {
      const noDupes = [];

      for (const obj of arr) {
        var _obj$timestampinterac;

        // Generate unique key as object IDs are unreliable
        const hashInput = ((obj === null || obj === void 0 ? void 0 : (_obj$timestampinterac = obj.timestampinteraction) === null || _obj$timestampinterac === void 0 ? void 0 : _obj$timestampinterac.toString()) || '') + obj.messagebody;
        const generatedKey = md5(hashInput); // Check if key exists in new array (i.e. a dupe)

        const exists = noDupes.find(o => o.key == generatedKey); // Key does not exist, therefore a unique object

        if (!exists) {
          obj.key = generatedKey;
          noDupes.push(obj);
        }
      }

      return noDupes.reverse();
    },

    async setOptions() {
      this.alert.show = false;
      const options = {
        eventCategory: this.selectedFilter,
        sortDirection: this.sortBy
      };

      if (this.filterFrom && !this.filterTo || !this.filterFrom && this.filterTo) {
        this.showAlert('Please specify both dates.');
        return;
      } else if (this.filterFrom && this.filterTo) {
        const start = moment(this.filterFrom).unix();
        const end = moment(this.filterTo + ' 23:59:59').unix();
        options.startDate = start;
        options.endDate = end;
      }

      this.dataloaded = false;

      if (this.selectedFilter.length == 0) {
        this.dataloaded = true;
        this.historyData = [];
        return;
      }

      try {
        const {
          data
        } = await PatientService.getHistory(this.patientId, options);
        this.historyData = this.removeDuplicates(data);
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.dataloaded = true;
      }
    },

    close() {
      this.closeable = true;
      this.$refs.dropdown.hide();
    },

    async reset() {
      const {
        data
      } = await PatientService.getHistory(this.patientId);
      this.historyData = this.removeDuplicates(data);
      this.selectedFilter = Object.keys(this.pillIds);
      this.filterFrom = '';
      this.filterTo = '';
      this.sortBy = 'DESC';
    },

    getKeyName(key) {
      switch (key) {
        case 'firstname':
          return 'First Name';

        case 'lastname':
          return 'Last Name';

        case 'phoneno':
          return 'Phone Number';

        case 'address1':
          return 'Street Address';

        case 'address2':
          return 'Apartment/Suite';

        default:
          return key;
      }
    },

    formatInfoUpdateBirthday(input) {
      const date = new moment(input, 'YYYY-MM-DD');
      return date.format('MM-DD-YYYY');
    },

    selectAllFilters() {
      this.selectedFilter = Object.keys(this.pillIds);
    },

    clearFilters() {
      this.selectedFilter = [];
    },

    clearDateFilters() {
      this.filterFrom = '';
      this.filterTo = '';
    },

    // Async to allow the scroll to have correct timing
    async addEvent(body) {
      this.historyData.push(body);
      await this.$nextTick();
      this.$refs.bottomOfPage.scrollIntoView();
    }

  }
};