//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { InventoryService } from '@/services/inventory.service';
export default {
  data() {
    return {
      rowData: null,
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      loading: false,
      label: '',
      inventory_id: null,
      inventoryOptions: [],
      amount: null,
      pharmacy_id: null,
      pharmacyOptions: []
    };
  },

  watch: {
    rowData(val) {
      if (val) {
        this.label = val.label;
        this.inventory_id = val.inventory_item_id;
        this.pharmacy_id = val.pharmacy_id;
        this.amount = val.amount;
      }
    },

    async showDialog(val) {
      if (!val) {
        this.loading = false;
        this.label = '';
        this.inventory_id = null;
        this.amount = null;
        this.pharmacy_id = null;
        this.rowData = null;
        this.inventoryOptions = [];
        this.pharmacyOptions = [];
        this.alert.show = false;
      } else {
        this.inventoryOptions = [];
        this.pharmacyOptions = [];
        const {
          data
        } = await InventoryService.getInventoryOptions();

        for (let item of data.inventoryItems) {
          this.inventoryOptions.push({
            value: item.id,
            text: item.name
          });
        }

        for (let item of data.pharmacies) {
          this.pharmacyOptions.push({
            value: item.id,
            text: item.name
          });
        }
      }
    }

  },
  methods: {
    async update() {
      this.loading = true;
      this.alert.show = false;

      if (this.label.trim().length == 0 || !this.inventory_id || !this.pharmacy_id || !this.amount) {
        this.alert.message = 'Please fill in all the fields.';
        this.alert.show = true;
        this.loading = false;
        return;
      } else {
        try {
          const {
            data
          } = await InventoryService.updateInventoryRestock(this.rowData.id, {
            inventory_item_id: this.inventory_id,
            pharmacy_id: this.pharmacy_id,
            amount: this.amount,
            label: this.label
          });
          this.$emit('refresh');
          this.loading = false;
          this.showDialog = false;
        } catch (err) {
          this.alert.message = err;
          this.alert.show = true;
          this.loading = false;
        }
      }
    }

  }
};