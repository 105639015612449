//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import jwt from 'jsonwebtoken';
import { RequestService } from '@/services/request.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    },
    showMessageButton: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      processing: false,
      message: '',
      showDialog: false,
      forwardedMessageData: {},
      adminNotes: '',
      alert: {
        show: false,
        message: ''
      },
      idNames: {// id: name,
      }
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        await this.refreshData();
      } else {
        this.alert.show = false;
        this.adminNotes = '';
        this.forwardedMessageData = {};
      }
    }

  },
  computed: {
    viewOnly() {
      return this.forwardedMessageData.messagestatus == 'COMPLETED' || this.forwardedMessageData.messagestatus == 'DISCARDED';
    },

    reversedMessages() {
      if (this.forwardedMessageData && this.forwardedMessageData.messagelist) {
        return this.forwardedMessageData.messagelist.slice().reverse();
      } else {
        return [];
      }
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },

    getTime(timestamp) {
      return moment(timestamp).format('LT');
    },

    getDateTime(timestamp) {
      return moment(timestamp).format('ll');
    },

    nextDay(date1, date2) {
      const d1 = moment(date1);
      const d2 = moment(date2);
      return d1.diff(d2, 'days') >= 1;
    },

    async refreshData() {
      await this.$nextTick();
      const {
        data
      } = await RequestService.getForwardedRequest(this.rowData.patientid, this.rowData.id);
      this.forwardedMessageData = data;

      for (let message of this.forwardedMessageData.messagelist) {
        if (!this.idNames[message.userid] && message.firstname) {
          this.idNames[message.userid] = `${message.firstname} ${message.lastname}`;
        }
      }
    },

    isSentByPatient(id) {
      return id == this.forwardedMessageData.patientid;
    },

    close() {
      this.showDialog = false;
      this.$emit('close');
    },

    getDate(date) {
      if (date == '-') {
        return '-';
      }

      return new Date(date * 1000).toDateString();
    },

    async redirectToMessages() {
      this.processing = true;
      await RequestService.openForwardToChat(this.rowData.patientid, this.rowData.id);
      this.processing = false;
      this.$router.push({
        name: 'messages',
        query: {
          patientid: this.rowData.patientid
        }
      });
    },

    async discardRequest() {
      if (!this.adminNotes) {
        this.showAlert('Please fill in all fields.');
        return;
      }

      const body = {
        adminnotes: this.adminNotes
      };
      await RequestService.discardRequest(this.forwardedMessageData.patientid, this.forwardedMessageData.id, body);
      this.close();
    },

    async completeRequest() {
      const body = {
        adminnotes: this.adminNotes
      };
      this.processing = true;
      await RequestService.completeRequest(this.forwardedMessageData.patientid, this.forwardedMessageData.id, body);
      this.processing = false;
      this.close();
    },

    redirectToIntercom(id) {
      // ! ONLY WORKS ON PROD
      const token = jwt.sign({}, 'NTNv7j0TuYARvmNMmWXo6fKvM4o6nv/aUi9ryX38ZH+L1bkrnD1ObOQ8JAUmHCBq7Iy7otZcyAagBLHVKvvYaIpmMuxmARQ97jUVG16Jkpkp1wXOPsrF9zwew6TpczyHkHgX5EuLg2MeBuiT/qJACs1J0apruOOJCg/gOtkjB4c=', {
        header: {
          predicates: [{
            attribute: 'role',
            comparison: 'eq',
            type: 'role',
            value: 'user_role'
          }, {
            attribute: 'user_id',
            comparison: 'eq',
            type: 'string',
            value: id
          }]
        }
      });
      const toSubmit = token.split('.');
      window.open(`https://app.intercom.com/a/apps/dfvodm6i/users/segments/all-users:${toSubmit[0]}`, '_blank');
    }

  }
};