/* unplugin-vue-components disabled */import __unplugin_components_3 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/base/BaseImage.vue';
import __unplugin_components_2 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconArrowRight.vue';
import __unplugin_components_1 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconArrowLeft.vue';
import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconSearch.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "tw-bg-alice-blue chat-sidebar tw-m-0 tw-border-0",
      attrs: { id: "chat-sidebar", "no-body": "" }
    },
    [
      _c("b-card-header", { attrs: { id: _vm.$_chat_CHAT_HEADER_ID } }, [
        _c(
          "div",
          { staticClass: "tw-flex-wrap tw-flex tw-items-center tw-gap-1" },
          [
            _c(__unplugin_components_0, { staticClass: "tw-text-muted tw-size-[20px]" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter"
                }
              ],
              staticClass:
                "reset tw-text-[14px] tw-leading-[14px] tw-ml-2 tw-flex-grow",
              attrs: { type: "search", placeholder: "Search" },
              domProps: { value: _vm.filter },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filter = $event.target.value
                }
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "tw-flex tw-items-center tw-justify-center tw-ml-auto"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-items-center tw-justify-between tw-gap-1"
                  },
                  [
                    _vm.$route.query.page > 1
                      ? _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.$router.push({
                                  query: Object.assign({}, _vm.$route.query, {
                                    page: Number(_vm.$route.query.page) - 1
                                  })
                                })
                              }
                            }
                          },
                          [_c(__unplugin_components_1)],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "small",
                      {
                        staticClass: "text-muted tw-text-[12px]",
                        staticStyle: { "line-height": "0" }
                      },
                      [
                        _vm._v(
                          " Page " + _vm._s(_vm.$route.query.page || 1) + " "
                        )
                      ]
                    ),
                    !!_vm.hasNextConversations
                      ? _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.$router.push({
                                  query: Object.assign({}, _vm.$route.query, {
                                    page: Number(_vm.$route.query.page || 1) + 1
                                  })
                                })
                              }
                            }
                          },
                          [_c(__unplugin_components_2)],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$_chat_bubbleListHeight,
              expression: "$_chat_bubbleListHeight"
            }
          ],
          ref: "conversationListRef",
          staticClass:
            "tw-mb-0 tw-p-0 tw-list-none tw-overflow-x-hidden tw-overflow-y-auto scrollable",
          style: {
            maxHeight: _vm.$_chat_conversationListHeight + "px",
            height: _vm.$_chat_conversationListHeight + "px"
          }
        },
        [
          !_vm.filteredConversations.length
            ? _c(
                "li",
                {
                  staticClass:
                    "tw-p-3 tw-flex tw-justify-center tw-items-center"
                },
                [_vm._v(" No conversations found ")]
              )
            : _vm._l(_vm.filteredConversations, function(conv, i) {
                return _c(
                  "li",
                  {
                    key: conv.id + "-" + i,
                    staticClass:
                      "tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-p-3 tw-box-border tw-transition tw-border tw-border-solid-red-500",
                    class: {
                      blink: !!_vm.blinkingConversations[conv.id],
                      "tw-bg-primary/10":
                        _vm.selectedConversation &&
                        conv.id == _vm.selectedConversation.id
                    },
                    on: {
                      animationend: function($event) {
                        return _vm.onBlinkAnimationEnd(conv)
                      },
                      click: function($event) {
                        return _vm.onConversationClick(conv)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-size-[35px] tw-rounded-[40px] tw-overflow-hidden tw-shrink-0"
                      },
                      [
                        _c(__unplugin_components_3, {
                          staticClass: "tw-object-cover tw-w-full tw-h-full",
                          attrs: {
                            src: conv.patient.profileUrl,
                            alt: "",
                            fallback: _vm.avatarFallbackImage
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-overflow-hidden tw-w-full tw-max-w-full"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tw-flex tw-gap-2 tw-justify-between"
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "tw-mb-0 tw-text-nowrap tw-text-ellipsis tw-overflow-hidden tw-text-[14px]",
                                attrs: { title: _vm.getPatientName(conv) }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getPatientName(conv)) + " "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "tw-mb-0 tw-text-muted tw-shrink-0 tw-text-[10px]"
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getDoctorName(conv)) + " "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-start"
                          },
                          [
                            conv.lastMessageType === "attachment"
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 text-truncate tw-text-onyx tw-text-[13px]"
                                  },
                                  [_vm._v(" Patient sent an attachment ")]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 text-truncate tw-text-onyx tw-text-[13px]"
                                  },
                                  [_vm._v(" " + _vm._s(conv.lastMessage) + " ")]
                                ),
                            !conv.isAdminReviewRead
                              ? _c("b-badge", {
                                  staticClass:
                                    "ml-2 tw-rounded-full tw-inline-block tw-bg-primary tw-p-[5px]"
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }