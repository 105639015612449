export const parseHtml = (body) => {
  if (body && body.includes('img')) {
    const addedAlt = body.replace(
      /img/g,
      'img alt="If you are seeing this, then the image sent in this message has expired." '
    );
    return addedAlt;
  } else {
    return body;
  }
}