/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.attachments.length
    ? _c(
        "div",
        { staticClass: "tw-flex tw-flex-col tw-gap-1" },
        _vm._l(_vm.attachments, function(attachment, attachmentKey) {
          return _c(
            "div",
            { key: attachmentKey, staticClass: "tw-bg-transparent" },
            [
              _vm.isValidImage(attachment)
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: attachment.url,
                        variant: "link",
                        download: "",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          thumbnail: "",
                          fluid: "",
                          src: attachment.url,
                          width: "250px",
                          loading: "lazy"
                        }
                      })
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass:
                        "tw-flex tw-gap-2 tw-items-center tw-text-sm",
                      attrs: {
                        href: attachment.url,
                        variant: "link",
                        download: ""
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "arrow-down-circle-fill",
                          variant: "primary"
                        }
                      }),
                      _vm._v(" " + _vm._s(attachment.filename) + " ")
                    ],
                    1
                  )
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }