/**
import { chatMixin } from "@/mixins/chat";
 */

import Vue from "vue";
import _ from 'lodash'

import { watchResizeObserver } from "@/utils/watchResizeObserver";
import { getElementHeight } from '@/utils/getElementHeight'
import { querySelectorAsync } from '@/utils/querySelectorAsync'
import { prefixObservable } from '@/utils/prefixObservable'
import { prefixMethods } from '@/utils/prefixMethods'
import { scrollToBottom } from '@/utils/scrollToBottom'

const PREFIX = "$_chat_";
const PAGE_TITLE_ID = "base-page-title";
const CHAT_ACTIONS_ID = "chat-actions";
const CHAT_CONTAINER_ID = "chat-container";
const CHAT_BUBBLE_CONTAINER_ID = "message-dialogues";
const CHAT_HEADER_ID = "chat-header";

const data = Vue.observable({
  chatHeaderHeight: 0,
  pageTitleHeight: 0,
  chatActionsHeight: 0,
  chatContainerMargins: 0,
  PAGE_TITLE_ID,
  CHAT_ACTIONS_ID,
  CHAT_CONTAINER_ID,
  CHAT_HEADER_ID,
  CHAT_BUBBLE_CONTAINER_ID,
  bubbleListHeight: 0,
  conversationListHeight: 0
});

async function initHeightObserver () {
  await Vue.nextTick()

  watchResizeObserver(
    [
      `#${PAGE_TITLE_ID}`,
      `#${CHAT_ACTIONS_ID}`,
      `#${CHAT_CONTAINER_ID}`,
      `#${CHAT_HEADER_ID}`,
    ],
    entries => {
      const [title, actions, container, header] = entries;

      const pageTitleHeight = getElementHeight(title.target);
      const actionsHeight = getElementHeight(actions.target);
      const chatHeaderHeight = getElementHeight(header.target);
      const chatContainerYOffsets = _.sum([
        parseInt(container.styles.paddingTop),
        parseInt(container.styles.paddingBottom),
        parseInt(container.styles.marginTop),
        parseInt(container.styles.marginBottom)
      ])

      data.bubbleListHeight = window.innerHeight - _.sum([
        chatContainerYOffsets,
        pageTitleHeight,
        actionsHeight,
        chatHeaderHeight,
      ]);

      data.conversationListHeight = window.innerHeight - _.sum([
        chatContainerYOffsets,
        pageTitleHeight,
        actionsHeight,
        chatHeaderHeight,
      ])
    }
  )
}

export const chatMixin = {
  computed: prefixObservable(data, PREFIX),

  methods: prefixMethods({
    initHeights () {
      initHeightObserver();
    },

    async openConversation (conversation) {
      if (!conversation.isAdminReviewRead) {
        this.$store.dispatch('doctorMessageRouter/markConversationAsRead', conversation.id)
      }

      this.$store.commit('doctorMessageRouter/SET_STATE', {
        state: 'selectedConversation',
        value: conversation
      })

      this.$store.commit('doctorMessageRouter/SET_CONVERSATION_ATTRS', {
        conversationId: conversation.id,
        attrs: {
          isAdminReviewRead: true
        }
      })

      await this.$store.dispatch(
        'doctorMessageRouter/getConversationMessages',
        {
          conversationId: conversation.id
        }
      )

      await this.$_chat_scrollToBottom()
    },

    async scrollToBottom () {
      const el = await querySelectorAsync(`#${CHAT_BUBBLE_CONTAINER_ID}`)

      scrollToBottom(el);
    },
  }, PREFIX)
};