/* unplugin-vue-components disabled */import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/patients/PatientsInfoSidePanel.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-pl-0 tw-bg-[#F7F7F7]" },
    [
      _c(__unplugin_components_0, {
        ref: "PatientsInfoSidePanel",
        attrs: { "patient-id": _vm.selectedRow.id }
      }),
      _c(
        "b-row",
        { staticClass: "tw-m-0" },
        [
          _vm.showTableList
            ? _c(
                "b-col",
                {
                  staticClass:
                    "tw-p-6 !tw-max-w-[283px] tw-w-full tw-overflow-y-auto tw-max-h-screen"
                },
                [
                  _c("TableList", {
                    attrs: { count: _vm.count, tab: _vm.tab },
                    on: {
                      "set-tab": _vm.setTab,
                      "toggle-table-list": function($event) {
                        _vm.showTableList = !_vm.showTableList
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            {
              staticClass: "bg-white tw-p-6 tw-pb-0",
              attrs: { id: _vm.$_patientView_MAIN_ID }
            },
            [
              _vm.patientInfoViewCheck
                ? _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        {
                          staticClass: "tw-mb-2",
                          attrs: { id: _vm.$_patientView_TITLE_BAR_ID }
                        },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c("h1", { staticClass: "h1 tw-mb-0" }, [
                              !_vm.showTableList
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTableList = !_vm.showTableList
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "layout-sidebar",
                                          "font-scale": "0.9",
                                          variant: "muted"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.tabName) + " ")
                            ])
                          ]),
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        [
                                          _c("b-icon-search", {
                                            staticClass: "tw-text-[#353148]"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    ref: "searchInput",
                                    staticClass: "tw-p-0 flex-grow-1",
                                    attrs: {
                                      placeholder: "Find a Patient",
                                      type: "search",
                                      autocomplete: "off"
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.searchPatients.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  }),
                                  _vm.search.length > 0
                                    ? _c(
                                        "b-input-group-prepend",
                                        { staticClass: "p-0" },
                                        [
                                          _c(
                                            "b-input-group-text",
                                            { staticClass: "p-0" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "border-0 align-self-center p-1",
                                                  attrs: {
                                                    variant: "outline-white"
                                                  },
                                                  on: { click: _vm.clearSearch }
                                                },
                                                [
                                                  _c("b-icon-x-circle", {
                                                    staticClass:
                                                      "tw-text-[#353148]"
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "warning",
                            show: _vm.showEmptyResultsAlert
                          }
                        },
                        [_vm._v(" No search results turned up. ")]
                      ),
                      _c(
                        "b-alert",
                        { attrs: { variant: "warning", show: _vm.alert.show } },
                        [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                      ),
                      _c(
                        "b-toast",
                        {
                          attrs: {
                            id: "viewToast",
                            variant: _vm.toast.variant,
                            "no-close-button": "",
                            "auto-hide-delay": "2500",
                            toaster: "b-toaster-top-center",
                            solid: ""
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]
                      ),
                      _c("ActionDialog", {
                        ref: "ActionDialog",
                        attrs: {
                          "row-data": _vm.selectedRow,
                          "action-id": _vm.tab
                        },
                        on: {
                          "update-time": _vm.updateTime,
                          "show-toast": _vm.showToast
                        }
                      }),
                      _c("ActionHistory", {
                        ref: "ActionHistory",
                        attrs: { "row-data": _vm.selectedRow },
                        on: {
                          "open-action": function($event) {
                            return _vm.$refs.ActionDialog.toggleDialog()
                          }
                        }
                      }),
                      _c(
                        "b-card",
                        {
                          staticClass: "tw-border-0 tw-mb-0",
                          attrs: { "no-body": "" }
                        },
                        [
                          _vm.tab == "all"
                            ? _c("AllTable", {
                                ref: "AllTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "active"
                            ? _c("ActiveTable", {
                                ref: "ActiveTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "inactive"
                            ? _c("InactiveTable", {
                                ref: "ActiveTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "failedtransaction"
                            ? _c("FailedTable", {
                                ref: "FailedTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "needspatientreply"
                            ? _c("NeedsReplyTable", {
                                ref: "NeedsReplyTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "followupstart"
                            ? _c("FollowUpStartTable", {
                                key: "followupstart",
                                ref: "FollowUpStartTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "followupcompletion"
                            ? _c("FollowUpCompleteTable", {
                                key: "followupcompletion",
                                ref: "FollowUpCompleteTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "fullycancelled"
                            ? _c("FullyCancelledTable", {
                                ref: "FullyCancelledTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "onboarding"
                            ? _c("OnboardingTable", {
                                ref: "OnboardingTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "verificationrequired"
                            ? _c("VerificationRequiredTable", {
                                ref: "VerificationRequiredTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "verificationrequireddoctor"
                            ? _c("VerificationRequiredDoctorTable", {
                                ref: "VerificationRequiredDoctorTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "doctorrequest"
                            ? _c("DoctorRequestTable", {
                                ref: "DoctorRequestTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "refundrequest"
                            ? _c("RefundRequestTable", {
                                ref: "RefundRequestTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "notaccepted"
                            ? _c("NotAcceptedTable", {
                                ref: "NotAcceptedTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "deleted"
                            ? _c("RemovedPatientsTable", {
                                ref: "RemovedPatientsTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "cs-hold"
                            ? _c("OrderHoldsTable", {
                                ref: "OrderHoldsTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "card-expired"
                            ? _c("CardExpiredTable", {
                                ref: "CardExpiredTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "transferrx"
                            ? _c("TransferRxTable", {
                                ref: "TransferRxTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "failedaddress"
                            ? _c("AddressVerificationRequiredTable", {
                                ref: "AddressVerificationRequiredTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "voicemail"
                            ? _c("VoicemailTable", {
                                ref: "VoicemailTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "no-follow-up"
                            ? _c("NoFollowUpTable", {
                                ref: "NoFollowUpTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "banned"
                            ? _c("BannedTable", {
                                ref: "BannedTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "cancelled-by-stripe"
                            ? _c("CancelledByStripeTable", {
                                ref: "CancelledByStripeTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "prescription-end-before-followup"
                            ? _c("PrescriptionEndBeforeFollowupTable", {
                                ref: "PrescriptionEndBeforeFollowupTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "script-end-before-followup-no-dhea"
                            ? _c("ScriptEndBeforeFollowupNoDhea", {
                                ref: "ScriptEndBeforeFollowupNoDhea",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e(),
                          _vm.tab == "card-updated-without-prescription-restart"
                            ? _c("CardUpdatedWithoutPrescriptionRestartTable", {
                                ref:
                                  "CardUpdatedWithoutPrescriptionRestartTable",
                                on: {
                                  "view-info": _vm.viewInfo,
                                  "get-status-count": _vm.getStatusCount
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                {
                  staticClass: "mt-3",
                  attrs: { id: _vm.$_patientView_FOOTER_ACTIONS_ID }
                },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex py-2 justify-content-between" },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  variant: "link",
                                  block: "",
                                  "no-caret": "",
                                  dropright: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "three-dots",
                                            "font-scale": "2"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              },
                              [
                                _c(
                                  "b-dropdown-item-button",
                                  {
                                    attrs: { variant: "secondary" },
                                    on: {
                                      click: function($event) {
                                        _vm.$refs.ExportFiltersDialog.showDialog = true
                                      }
                                    }
                                  },
                                  [_vm._v(" Export ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "overflow-auto" },
                          [
                            _vm.currentTableRowCount
                              ? _c("b-pagination", {
                                  attrs: {
                                    pills: "",
                                    "total-rows": _vm.currentTableRowCount,
                                    "per-page": 25
                                  },
                                  on: {
                                    "page-click": _vm.handlePaginationClick
                                  },
                                  model: {
                                    value: _vm.currentPage,
                                    callback: function($$v) {
                                      _vm.currentPage = $$v
                                    },
                                    expression: "currentPage"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }