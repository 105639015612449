//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { startOfMonth, endOfMonth, getUnixTime, isBefore } from 'date-fns';
import DoctorTable from '@/components/tables/DoctorTable';
import DoctorReassignTable from '@/components/tables/DoctorReassignTable';
import DoctorAnalyticsTable from '@/components/tables/DoctorAnalyticsTable';
import EditMaxDailyDialog from '@/components/doctors/EditMaxDailyDialog';
import NewReassignmentDialog from '@/components/doctors/NewReassignmentDialog';
import UpdateReassignmentDialog from '@/components/doctors/UpdateReassignmentDialog';
import ConfirmDeleteCancelDialog from '@/components/doctors/ConfirmDeleteCancelDialog';
export default {
  components: {
    DoctorTable,
    DoctorReassignTable,
    DoctorAnalyticsTable,
    EditMaxDailyDialog,
    NewReassignmentDialog,
    UpdateReassignmentDialog,
    ConfirmDeleteCancelDialog
  },

  data() {
    return {
      tab: 'all',
      count: {
        all: '-',
        doctorreassignment: '-'
      },
      refList: {
        all: 'DoctorTable',
        doctorreassignment: 'DoctorReassignTable'
      },
      toast: {
        message: '',
        variant: ''
      },
      dialogMode: 'edit',
      selectedRow: {},
      isViewingAnalytics: false,
      currentFilter: 'Month to Date',
      showCustom: false,
      months: [{
        text: 'January',
        value: 0
      }, {
        text: 'February',
        value: 1
      }, {
        text: 'March',
        value: 2
      }, {
        text: 'April',
        value: 3
      }, {
        text: 'May',
        value: 4
      }, {
        text: 'June',
        value: 5
      }, {
        text: 'July',
        value: 6
      }, {
        text: 'August',
        value: 7
      }, {
        text: 'September',
        value: 8
      }, {
        text: 'October',
        value: 9
      }, {
        text: 'November',
        value: 10
      }, {
        text: 'December',
        value: 11
      }],
      years: [],
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null
    };
  },

  mounted() {
    for (let i = 2021; i <= moment().format('YYYY'); i++) {
      this.years.push(i);
    }
  },

  methods: {
    async fetchDoctors(done) {
      try {
        await this.$refs[this.refList[this.tab]].getCurrentDoctorFilters();
        done();
      } catch (e) {
        console.error(e);
      }
    },

    editMaxDaily(row) {
      this.selectedRow = row;
      this.$refs.EditMaxDailyDialog.showDialog = true;
    },

    openUpdateReassign(row) {
      this.selectedRow = row;
      this.dialogMode = 'edit';
      this.$refs.UpdateReassignmentDialog.showDialog = true;
    },

    openApproveReassign(row) {
      this.selectedRow = row;
      this.dialogMode = 'approve';
      this.$refs.UpdateReassignmentDialog.showDialog = true;
    },

    openDeleteReassign(row) {
      this.selectedRow = row;
      this.dialogMode = 'deny';
      this.$refs.ConfirmDeleteCancelDialog.showDialog = true;
    },

    openCancelReassign(row) {
      this.selectedRow = row;
      this.dialogMode = 'cancel';
      this.$refs.ConfirmDeleteCancelDialog.showDialog = true;
    },

    addRow(row) {
      this.$refs[this.refList[this.tab]].addRow(row);
    },

    refresh() {
      this.$refs[this.refList[this.tab]].refresh();
    },

    showToast(message, variant = 'error') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('alertToast');
    },

    getEarningData() {
      this.currentFilter = 'Custom';
      const from = getUnixTime(startOfMonth(new Date(this.fromYear, this.fromMonth, 1)));
      const to = getUnixTime(endOfMonth(new Date(this.toYear, this.toMonth, 1)));

      if (isBefore(from, to)) {
        this.$store.commit('doctors/SET_FILTER_FROM', from);
        this.$store.commit('doctors/SET_FILTER_TO', to);
        this.$refs.DoctorAnalyticsTable.getDoctorAnalytics(from, to);
      } else {
        this.$bvToast.toast(`Kindly enter a valid date range`, {
          title: 'Invalid date range',
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-bottom-center'
        });
      }
    }

  }
};