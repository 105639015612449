/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": ""
      },
      on: { hide: _vm.onHide },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-alert",
        {
          attrs: { variant: "warning" },
          model: {
            value: _vm.alert.show,
            callback: function($$v) {
              _vm.$set(_vm.alert, "show", $$v)
            },
            expression: "alert.show"
          }
        },
        [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { offset: "2" } },
            [
              _c(
                "b-row",
                { staticClass: "mt-4 pr-4" },
                [
                  _c("b-col", [
                    _c("h2", { staticClass: "h2 heading" }, [
                      _vm._v(
                        " Are you sure you want to " +
                          _vm._s(_vm.mode) +
                          " this reassignment? "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v("Doctor")
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        "Dr. " +
                          _vm._s(_vm.rowData.firstname) +
                          " " +
                          _vm._s(_vm.rowData.lastname)
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-muted" }, [_vm._v("From")]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getDateTime(_vm.rowData.startdatestamp))
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-muted" }, [_vm._v("To")]),
                    _c("br"),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getDateTime(_vm.rowData.enddatestamp)))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: { click: _vm.removeEntry }
                        },
                        [_vm._v(" Confirm ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }