/* unplugin-vue-components disabled */import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/patients/PatientsInfoSidePanel.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-toast",
        {
          attrs: {
            id: "viewToast",
            variant: "danger",
            "no-close-button": "",
            "auto-hide-delay": "2500",
            toaster: "b-toaster-top-center",
            solid: ""
          }
        },
        [_vm._v(" " + _vm._s(_vm.toastMessage) + " ")]
      ),
      _c(
        "b-row",
        {
          staticClass: "content d-flex mx-4",
          attrs: { id: _vm.$_message_CHAT_CONTAINER_ID }
        },
        [
          _c(
            "b-col",
            { staticClass: "pr-0", attrs: { cols: "12", lg: "3" } },
            [
              _c(
                "b-card",
                { staticClass: "chat-patient-list", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    { attrs: { id: _vm.$_message_CHAT_HEADER_ID } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              { staticClass: "input-group input-group-flush" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fe fe-search text-primary"
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filter,
                                      expression: "filter"
                                    }
                                  ],
                                  staticClass: "form-control list-search",
                                  attrs: {
                                    type: "search",
                                    placeholder: "Search"
                                  },
                                  domProps: { value: _vm.filter },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.filter = $event.target.value
                                      },
                                      _vm.onConversationSearch
                                    ]
                                  }
                                })
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.paginatedConversationList.length > 0 &&
                      !_vm.isSearching &&
                      _vm.$store.state.message.conversationListNextPage.length >
                        0
                        ? _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-2 py-2 d-flex justify-content-center align-items-center"
                                  },
                                  [
                                    _vm.$store.state.message
                                      .conversationListPageNumber > 1
                                      ? _c(
                                          "a",
                                          {
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.previousConversationList.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px"
                                                },
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d: "M15 19l-7-7 7-7"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "small",
                                      {
                                        staticClass: "text-muted px-1",
                                        staticStyle: { "line-height": "0" }
                                      },
                                      [
                                        _vm._v(
                                          " Page " +
                                            _vm._s(
                                              _vm.$store.state.message
                                                .conversationListPageNumber
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    !(
                                      _vm.hasUndefinedNextPage &&
                                      _vm.$store.state.message
                                        .conversationListPageNumber ===
                                        _vm.$store.state.message.maxPageNumber
                                    )
                                      ? _c(
                                          "a",
                                          {
                                            class: {
                                              "tw-pointer-events-none tw-cursor-not-allowed":
                                                _vm.fetchingNextPage
                                            },
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.nextConversationList.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm.fetchingNextPage
                                              ? _c("b-spinner", {
                                                  staticClass: "tw-ml-1",
                                                  attrs: { small: "" }
                                                })
                                              : _c(
                                                  "svg",
                                                  {
                                                    staticStyle: {
                                                      width: "20px",
                                                      height: "20px"
                                                    },
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      fill: "none",
                                                      viewBox: "0 0 24 24",
                                                      stroke: "currentColor"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "stroke-linecap":
                                                          "round",
                                                        "stroke-linejoin":
                                                          "round",
                                                        "stroke-width": "2",
                                                        d: "M9 5l7 7-7 7"
                                                      }
                                                    })
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "ul",
                    {
                      ref: "conversationListRef",
                      staticClass: "list-unstyled",
                      style: {
                        height: _vm.$_message_bubbleListHeight + "px"
                      }
                    },
                    [
                      _vm._l(_vm.paginatedConversationList, function(conv, i) {
                        return _c(
                          "li",
                          {
                            key: conv.id + "-" + i,
                            class: [
                              _vm.conversation.id == conv.id ? "selected" : "",
                              "patient-list"
                            ],
                            on: {
                              click: function($event) {
                                return _vm.setConversationId(conv)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "list-img" },
                              [
                                _c("b-avatar", {
                                  attrs: {
                                    variant: "tertiary",
                                    src: conv.profileUrl
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "list-content" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between"
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      directives: [
                                        {
                                          name: "line-clamp",
                                          rawName: "v-line-clamp",
                                          value: 1,
                                          expression: "1"
                                        }
                                      ],
                                      staticClass:
                                        "mt-0 mb-1 text-capitalize text-truncate"
                                    },
                                    [_vm._v(" " + _vm._s(conv.name) + " ")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-0 ml-2 !tw-text-[#19191c]",
                                      staticStyle: {
                                        "flex-shrink": "0",
                                        "font-size": "0.75rem"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " Dr. " + _vm._s(conv.doctorname) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              !conv.unreadcount && conv.lastmessage
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between"
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "mb-0 text-truncate" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(conv.lastmessage) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-dropdown",
                                        {
                                          staticStyle: { padding: "0" },
                                          attrs: {
                                            variant: "link",
                                            "toggle-class":
                                              "text-decoration-none",
                                            "no-caret": "",
                                            size: "sm"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function() {
                                                  return [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon: "three-dots"
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onMarkUnread(
                                                    conv.id
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Mark as Unread ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-start"
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "mb-0 text-truncate" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(conv.lastmessage) + " "
                                          )
                                        ]
                                      ),
                                      conv.unreadcount
                                        ? _c("b-badge", {
                                            staticClass: "ml-2",
                                            staticStyle: {
                                              padding: "5px",
                                              "border-radius": "9999px",
                                              display: "inline-block",
                                              "background-color":
                                                "#396b70 !important"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                            ])
                          ]
                        )
                      }),
                      _vm.$route.query.patientid
                        ? _c("li", { staticClass: "text-center" }, [
                            _c(
                              "a",
                              {
                                staticClass: "py-3 d-inline-block",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onLoadAllConversations.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Load all conversations ")]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-none d-sm-none d-lg-block",
              attrs: { cols: "12", lg: "9" }
            },
            [
              _vm.conversationdoctor.id != ""
                ? [
                    _c(
                      "b-card",
                      {
                        staticClass: "mb-0 chats",
                        staticStyle: {
                          "border-left": "0",
                          "border-right": "0",
                          "border-bottom": "0"
                        },
                        attrs: { "no-body": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: { click: _vm.viewPatientInfo }
                                      },
                                      [
                                        _c("h3", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " + _vm._s(_vm.patientName) + " "
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          687266443
                        )
                      },
                      [
                        _c(
                          "b-card-body",
                          {
                            style: {
                              height:
                                _vm.$_message_conversationListHeight + "px"
                            },
                            attrs: { "body-class": "card-chat-body" },
                            on: {
                              "&scroll": function($event) {
                                return _vm.messageScroll.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                class: ["chat-box"],
                                attrs: { id: "chat-box" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "chat-inner" },
                                  _vm._l(_vm.messages, function(message, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: "parent-" + i,
                                        staticStyle: { padding: "0 1.5rem" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            key: i,
                                            class: [
                                              message.userid != _vm.userid
                                                ? "patient"
                                                : "doctor",
                                              "chat-bubble"
                                            ]
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "chat-img" },
                                              [
                                                _c("b-img", {
                                                  attrs: {
                                                    src: _vm.getMemberProfileUrl(
                                                      message.userid
                                                    ),
                                                    alt: ""
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "position-relative",
                                                class: [
                                                  message.userid != _vm.userid
                                                    ? "patient"
                                                    : "doctor",
                                                  "chat",
                                                  message.type ==
                                                  "forward_message"
                                                    ? "fwdmsg-bg"
                                                    : ""
                                                ]
                                              },
                                              [
                                                message.attachment &&
                                                message.attachment.length > 0
                                                  ? _c(
                                                      "div",
                                                      _vm._l(
                                                        message.attachment,
                                                        function(
                                                          chatAttachment,
                                                          i
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: i,
                                                              staticStyle: {
                                                                "background-color":
                                                                  "transparent"
                                                              }
                                                            },
                                                            [
                                                              chatAttachment.type.includes(
                                                                "image"
                                                              ) &&
                                                              ![
                                                                "tiff",
                                                                "heic",
                                                                "svg"
                                                              ].some(function(
                                                                type
                                                              ) {
                                                                return chatAttachment.type.includes(
                                                                  type
                                                                )
                                                              })
                                                                ? _c(
                                                                    "b-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs: {
                                                                                href:
                                                                                  chatAttachment.url,
                                                                                variant:
                                                                                  "link",
                                                                                download:
                                                                                  "",
                                                                                target:
                                                                                  "_blank"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-img",
                                                                                {
                                                                                  attrs: {
                                                                                    thumbnail:
                                                                                      "",
                                                                                    fluid:
                                                                                      "",
                                                                                    src:
                                                                                      chatAttachment.url,
                                                                                    width:
                                                                                      "250px"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "b-row",
                                                                    {
                                                                      attrs: {
                                                                        "no-gutters":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              chatAttachment.url,
                                                                            variant:
                                                                              "link",
                                                                            download:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-icon",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "arrow-down-circle-fill",
                                                                                variant:
                                                                                  "primary"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                chatAttachment.filename
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                                message.type ==
                                                "forward_message"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "text-muted",
                                                                attrs: {
                                                                  offset: "4",
                                                                  cols: "5"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getDate(
                                                                        message
                                                                          .custommessage
                                                                          .forwardmessage
                                                                          .timestampcreated *
                                                                          1000
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "text-right",
                                                                attrs: {
                                                                  cols: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:;"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.redirectToForwardedMessage(
                                                                          message
                                                                            .custommessage
                                                                            .forwardmessage
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " VIEW "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column-reverse"
                                                          },
                                                          _vm._l(
                                                            message
                                                              .custommessage
                                                              .forwardmessage
                                                              .messagelist,
                                                            function(fwdmsg) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    message.id +
                                                                    "-" +
                                                                    fwdmsg.id,
                                                                  staticClass:
                                                                    "fwdmsg-box"
                                                                },
                                                                [
                                                                  _c(
                                                                    "small",
                                                                    {
                                                                      staticClass:
                                                                        "text-muted mt-3",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "10px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Sent by " +
                                                                          _vm._s(
                                                                            fwdmsg.firstname
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            fwdmsg.lastname
                                                                          ) +
                                                                          " at " +
                                                                          _vm._s(
                                                                            _vm.getTime(
                                                                              fwdmsg.sentat *
                                                                                1000
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: [
                                                                        fwdmsg.userid ==
                                                                        message
                                                                          .custommessage
                                                                          .forwardmessage
                                                                          .doctorid
                                                                          ? "doctor-fwdmsg"
                                                                          : "patient-fwdmsg"
                                                                      ]
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "my-3 mx-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                fwdmsg.message
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      fwdmsg.attachment &&
                                                                      fwdmsg
                                                                        .attachment
                                                                        .length >
                                                                        0
                                                                        ? _c(
                                                                            "div",
                                                                            _vm._l(
                                                                              fwdmsg.attachment,
                                                                              function(
                                                                                chatAttachment,
                                                                                attachmentKey
                                                                              ) {
                                                                                return _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: attachmentKey,
                                                                                    staticStyle: {
                                                                                      "background-color":
                                                                                        "transparent"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    chatAttachment.type !=
                                                                                    "application/pdf"
                                                                                      ? _c(
                                                                                          "b-row",
                                                                                          {
                                                                                            attrs: {
                                                                                              "no-gutters":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "b-col",
                                                                                              [
                                                                                                _c(
                                                                                                  "a",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      href:
                                                                                                        chatAttachment.url,
                                                                                                      variant:
                                                                                                        "link",
                                                                                                      download:
                                                                                                        "",
                                                                                                      target:
                                                                                                        "_blank"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "b-img",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          thumbnail:
                                                                                                            "",
                                                                                                          fluid:
                                                                                                            "",
                                                                                                          src:
                                                                                                            chatAttachment.url,
                                                                                                          width:
                                                                                                            "250px"
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _c(
                                                                                          "b-row",
                                                                                          {
                                                                                            attrs: {
                                                                                              "no-gutters":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                attrs: {
                                                                                                  href:
                                                                                                    chatAttachment.url,
                                                                                                  variant:
                                                                                                    "link",
                                                                                                  download:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "b-icon",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      icon:
                                                                                                        "arrow-down-circle-fill",
                                                                                                      variant:
                                                                                                        "primary"
                                                                                                    }
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      chatAttachment.filename
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(message.message) +
                                                      " "
                                                  )
                                                ]),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "position-absolute d-inline-block",
                                                    class: [
                                                      message.userid !=
                                                      _vm.userid
                                                        ? "patient"
                                                        : "doctor",
                                                      "time-stamp"
                                                    ],
                                                    style:
                                                      message.userid !=
                                                      _vm.userid
                                                        ? "left: 0; bottom: -28px; width: 150px;"
                                                        : "right: 0; bottom: -14px; width: 150px; text-align: right; margin-right: 0"
                                                  },
                                                  [
                                                    message.userid != _vm.userid
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-block"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getMemberName(
                                                                    message.userid
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.parseDate(
                                                            message.sentat,
                                                            "MMMM d, yyyy h:mmaaa"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "b-card-footer",
                          {
                            attrs: {
                              id: _vm.$_message_CHAT_FOOTER_ID,
                              "footer-class": "custom-footer"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pre-upload" },
                              _vm._l(_vm.attachtments, function(
                                attachtment,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "img-holder" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "close-btn",
                                      attrs: { icon: "x " },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFile(index)
                                        }
                                      }
                                    }),
                                    !attachtment.type.includes("image") ||
                                    attachtment.type.includes("heic")
                                      ? _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "32",
                                              height: "32",
                                              viewBox: "0 0 24 24"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                fill: "currentColor",
                                                d:
                                                  "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
                                              }
                                            })
                                          ]
                                        )
                                      : _c("img", {
                                          attrs: {
                                            src: attachtment.url,
                                            alt: attachtment.name
                                          }
                                        })
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "mr-0",
                                    attrs: { "align-v": "start" }
                                  },
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group input-group-flush"
                                        },
                                        [
                                          _c("b-form-textarea", {
                                            ref: "messageArea",
                                            attrs: {
                                              "max-rows": "4",
                                              "no-resize": "",
                                              placeholder: "Write a message..."
                                            },
                                            model: {
                                              value: _vm.newMessage,
                                              callback: function($$v) {
                                                _vm.newMessage = $$v
                                              },
                                              expression: "newMessage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "auto" }
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: { variant: "link" },
                                            on: { click: _vm.openUpload }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/paperclip.svg"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        ),
                                        _c("input", {
                                          ref: "fileInput",
                                          staticStyle: { display: "none" },
                                          attrs: { type: "file", multiple: "" },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleFilesUpload()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "px-0",
                                        attrs: { cols: "auto" }
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "link",
                                              disabled: _vm.isMessageBodyEmpty
                                            },
                                            on: { click: _vm.sendMessage }
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "cursor-fill",
                                                rotate: "45",
                                                variant: "primary"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.conversationdoctor.id == ""
                ? [
                    _c(
                      "b-card",
                      {
                        staticClass: "mb-0",
                        staticStyle: {
                          "background-color": "transparent",
                          "border-left": "0",
                          "border-right": "0"
                        },
                        attrs: { "no-body": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c("b-col", [
                                        _c("h3", { staticClass: "mb-0" }, [
                                          _vm._v(" Conversation ")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          93233649
                        )
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "chat-box",
                            staticStyle: {
                              "justify-content": "center",
                              "align-items": "center",
                              height: "calc(100vh - 232px)"
                            }
                          },
                          [
                            _vm.loading
                              ? _c("h3", { staticClass: "text-muted" }, [
                                  _vm._v(" Loading conversation details ")
                                ])
                              : _c("h3", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    " Select patient to view conversation "
                                  )
                                ])
                          ]
                        )
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("DoctorRequestDialog", {
        ref: "DoctorRequestDialog",
        attrs: {
          "row-data": _vm.selectedForwardedRequest,
          "show-message-button": false
        }
      }),
      _vm.conversation.patientid
        ? _c(__unplugin_components_0, {
            ref: "PatientsInfoSidePanel",
            attrs: {
              "patient-id": _vm.conversation.patientid,
              "update-url": false
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }