/**
import { patientViewMixin } from "@/mixins/patientView";
 */

import Vue from 'vue';

const MAIN_ID = 'patient-view-main';
const TITLE_BAR_ID = 'patient-view-title-bar';
const FOOTER_ACTIONS_ID = 'patient-view-footer-actions';

import { watchResizeObserver } from '@/utils/watchResizeObserver';
import { prefixObservable } from '@/utils/prefixObservable';
import { getElementHeight } from '@/utils/getElementHeight';

const data = Vue.observable({
  MAIN_ID,
  TITLE_BAR_ID,
  FOOTER_ACTIONS_ID,
  contentHeight: 0,
});

async function initHeightObserver() {
  await Vue.nextTick();

  watchResizeObserver(
    [`#${MAIN_ID}`, `#${TITLE_BAR_ID}`, `#${FOOTER_ACTIONS_ID}`],
    (entries) => {
      const [main, titleBar, footerActions] = entries;

      const mainPaddingY =
        parseInt(main.styles.paddingTop) + parseInt(main.styles.paddingBottom);
      const titleBarHeight = getElementHeight(titleBar.target);
      const footerActionsHeight = getElementHeight(footerActions.target);

      data.contentHeight =
        window.innerHeight -
        (mainPaddingY + titleBarHeight + footerActionsHeight);
    }
  );
}

export const patientViewMixin = {
  computed: {
    ...prefixObservable(data, '$_patientView_'),
  },

  methods: {
    async $_patientView_initHeights() {
      initHeightObserver();
    },
  },
};
