//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'; // mixins

import { chatMixin } from "@/mixins/chat";
export default {
  mixins: [chatMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedForwardedRequest: null
    };
  },

  computed: { ...mapState('doctorMessageRouter', ['messages', 'isFetchingMessages', 'selectedConversation']),
    ...mapState('auth', ['currentUser'])
  },

  mounted() {
    this.$_chat_scrollToBottom();
  },

  methods: {
    redirectToForwardedMessage(fwd) {
      this.selectedForwardedRequest = fwd;
      this.$refs.DoctorRequestDialog.showDialog = true;
    },

    messageScroll(e) {
      const scroll = e.target.scrollTop + e.target.scrollHeight === e.target.clientHeight;
      const scrollwithallowance1 = e.target.scrollTop + e.target.scrollHeight < e.target.clientHeight + 10;

      try {
        if (scroll || scrollwithallowance1) {
          if (this.nextPage && !this.loadingMessage) {
            this.loadingMessage = true;
            this.chatMessage();
            this.loadingMessage = false;
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingMessage = false;
      }
    }

  }
};