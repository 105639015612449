/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        { attrs: { variant: "warning", show: _vm.showEmptyResultsAlert } },
        [_vm._v(" No search results turned up. ")]
      ),
      _c(
        "b-input-group",
        { staticClass: "d-flex" },
        [
          _c(
            "b-input-group-prepend",
            [
              _c(
                "b-input-group-text",
                [_c("b-icon-search", { staticClass: "tw-text-[#353148]" })],
                1
              )
            ],
            1
          ),
          _c("b-form-input", {
            ref: "searchInput",
            staticClass: "tw-p-0 flex-grow-1",
            attrs: {
              placeholder: "Search...",
              type: "search",
              autocomplete: "off"
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _vm.search.length > 0
            ? _c(
                "b-input-group-prepend",
                { staticClass: "p-0" },
                [
                  _c(
                    "b-input-group-text",
                    { staticClass: "p-0" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "border-0 align-self-center p-0",
                          attrs: { variant: "outline-white" },
                          on: { click: _vm.clearSearch }
                        },
                        [
                          _c("b-icon-x-circle", {
                            staticClass: "tw-text-[#353148]"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("b-table", {
        ref: "table",
        staticClass: "card-table",
        staticStyle: {
          "max-height": "calc(100vh - 300px) !important",
          height: "calc(100vh - 300px) !important"
        },
        attrs: {
          "no-border-collapse": "",
          responsive: "",
          "select-mode": "single",
          fields: _vm.headers,
          items: _vm.items,
          "sticky-header": "",
          "show-empty": ""
        },
        scopedSlots: _vm._u([
          {
            key: "empty",
            fn: function(data) {
              return [
                _vm.fetching
                  ? _c("p", { staticClass: "tw-text-center" }, [
                      _vm._v("Fetching doctors...")
                    ])
                  : _c("p", { staticClass: "tw-text-center" }, [
                      _vm._v(" " + _vm._s(data.emptyText) + " ")
                    ])
              ]
            }
          },
          {
            key: "cell(photo)",
            fn: function(row) {
              return [
                _c("b-avatar", { attrs: { size: "md", src: row.item.avatar } })
              ]
            }
          },
          {
            key: "cell(name)",
            fn: function(row) {
              return [
                _c("span", { staticClass: "tw-text-secondary-light" }, [
                  _vm._v(
                    " Dr. " +
                      _vm._s(row.item.firstname) +
                      " " +
                      _vm._s(row.item.lastname) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "cell(maxdailyaccept)",
            fn: function(row) {
              return [
                row.item.coveredstate && row.item.coveredstate.length == 1
                  ? _c("span", [
                      _vm._v(" " + _vm._s(row.item.coveredstate[0].limit) + " ")
                    ])
                  : row.item.coveredstate && row.item.coveredstate.length > 1
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getTotalLimit(row.item.coveredstate)) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(coveredstate)",
            fn: function(row) {
              return [
                row.item.coveredstate && row.item.coveredstate.length == 1
                  ? _c("span", [
                      _vm._v(" " + _vm._s(row.item.coveredstate[0].state) + " ")
                    ])
                  : row.item.coveredstate && row.item.coveredstate.length > 1
                  ? _c("span", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: { click: row.toggleDetails }
                        },
                        [
                          !row.detailsShowing
                            ? _c("b-icon", { attrs: { icon: "chevron-down" } })
                            : _c("b-icon", { attrs: { icon: "chevron-up" } })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(tenweekfollowupversionname)",
            fn: function(row) {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(row.item.tenweekfollowupversionname) + " "
                  )
                ])
              ]
            }
          },
          {
            key: "cell(oneyearfollowupversionname)",
            fn: function(row) {
              return [
                _c("span", [
                  _vm._v(
                    " " + _vm._s(row.item.oneyearfollowupversionname) + " "
                  )
                ])
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "b-row",
                  { attrs: { "align-h": "center" } },
                  [
                    _c("b-col", { attrs: { cols: "2" } }, [
                      _c("b", [_vm._v("State")])
                    ]),
                    _c("b-col", { attrs: { cols: "2" } }, [
                      _c("b", [_vm._v("Max Daily Accept")])
                    ])
                  ],
                  1
                ),
                _vm._l(row.item.coveredstate, function(state) {
                  return _c(
                    "b-row",
                    {
                      key: row.item.id + "-" + state.state,
                      attrs: { "align-h": "center" }
                    },
                    [
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _vm._v(" " + _vm._s(state.state) + " ")
                      ]),
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _vm._v(" " + _vm._s(state.limit) + " ")
                      ])
                    ],
                    1
                  )
                })
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c(
                  "b-link",
                  {
                    attrs: {
                      href: "javascript:;",
                      disabled: !row.item.coveredstate
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("edit-max-daily", row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "pencil" }
                    }),
                    _vm._v(" Edit ")
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "tw-flex py-2 justify-content-end tw-mt-10" }, [
        _c(
          "div",
          { staticClass: "overflow-auto" },
          [
            _vm.currentTableRowCount
              ? _c("b-pagination", {
                  attrs: {
                    pills: "",
                    "total-rows": _vm.currentTableRowCount,
                    "per-page": 25
                  },
                  on: { "page-click": _vm.handlePaginationClick },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }