/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sideModal",
      staticClass: "shadow-sm",
      class: [
        "side-modal",
        _vm.show ? "show-modal" : "",
        _vm.side == "left" ? "left" : ""
      ],
      style: { "z-index": _vm.zIndex, overflow: _vm.overflow }
    },
    [
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHeader,
              expression: "showHeader"
            }
          ],
          ref: "modalHeader",
          staticClass: "px-4 py-4",
          class: ["custom-header", _vm.headerClass]
        },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "center" } },
            [
              _vm._t("header", function() {
                return [
                  _c("h2", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.title))])
                ]
              })
            ],
            2
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _vm.showClose
                ? _c("b-icon", {
                    staticClass: "close-icon",
                    attrs: { icon: "x ", variant: "primary" },
                    on: { click: _vm.close }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          class: ["custom-body", _vm.bodyClass],
          style: { height: _vm.height }
        },
        [_vm._t("body")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }