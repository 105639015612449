/* unplugin-vue-components disabled */import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/base/BaseSlidePanel.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(__unplugin_components_0,
    {
      ref: "base-slide-panel",
      attrs: { "is-full": _vm.isOrderManagerOpen, "is-fixed-width": true },
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex" },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-relative tw-border-0 tw-border-r tw-border-solid tw-border-slate-300",
              class: _vm.isOrderManagerOpen ? "open-table" : "close-table"
            },
            [
              _vm.patientId
                ? _c(
                    "div",
                    { staticClass: "tw-relative tw-flex tw-flex-col" },
                    [
                      _vm.patientData && _vm.patientData.hastransferpharmacy
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-center tw-text-white tw-bg-[#ff7a37] tw-py-1 tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-z-10"
                            },
                            [_vm._v(" Transfer RX Patient ")]
                          )
                        : _vm._e(),
                      _vm.patientData && _vm.patientData.isbanned
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-center tw-text-white tw-bg-[#e63757] tw-py-1 tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-z-10"
                            },
                            [
                              _vm._v(
                                " Patient Banned - " +
                                  _vm._s(_vm.patientData.banned_reason) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-px-8 tw-py-5 tw-flex tw-justify-between tw-bg-white tw-z-[2] tw-gap-5",
                          class: {
                            "tw-pt-10":
                              _vm.patientData &&
                              (_vm.patientData.hastransferpharmacy ||
                                _vm.patientData.isbanned)
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tw-flex tw-items-center tw-shrink-0"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "tw-mr-2" },
                                [
                                  _vm.patientData
                                    ? _c("b-avatar", {
                                        attrs: {
                                          src: _vm.patientData.profileUrl,
                                          size: "60px"
                                        }
                                      })
                                    : _c("b-skeleton", {
                                        attrs: { type: "avatar", size: "60px" }
                                      })
                                ],
                                1
                              ),
                              _c("div", [
                                _vm.patientData
                                  ? _c("p", { staticClass: "mb-0" }, [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.patientData.firstname) +
                                            " " +
                                            _vm._s(_vm.patientData.lastname)
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.patientData
                                  ? _c(
                                      "span",
                                      { staticClass: "text-muted text-small" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDate(
                                                _vm.patientData.birthday,
                                                "MMMM D, YYYY"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "mx-2" }, [
                                _vm.patientData
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "ml-2 mb-1 text-small tw-block",
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function($event) {
                                            return _vm.triggerToggleDialog(
                                              "UploadDialog"
                                            )
                                          }
                                        }
                                      },
                                      [_c("u", [_vm._v("VIEW ID")])]
                                    )
                                  : _vm._e(),
                                _vm.patientData
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "ml-2 text-small tw-block",
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDialog(
                                              "PatientInfoEdit"
                                            )
                                          }
                                        }
                                      },
                                      [_c("u", [_vm._v("EDIT")])]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "tw-flex tw-items-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "tw-mr-3" },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      attrs: {
                                        id: "moreActions",
                                        variant: "outline-dark",
                                        pill: "",
                                        block: "",
                                        "no-caret": "",
                                        dropleft: "",
                                        offset: "-10",
                                        size: "sm"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function() {
                                              return [
                                                _c("b-icon", {
                                                  attrs: { icon: "three-dots" }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        2038261878
                                      )
                                    },
                                    [
                                      _vm._l(_vm.actionsDropdownItems, function(
                                        buttonGroup,
                                        groupIndex
                                      ) {
                                        return [
                                          _vm._l(buttonGroup, function(
                                            buttonData,
                                            index
                                          ) {
                                            return _c(
                                              "b-dropdown-item-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: buttonData.render
                                                      ? buttonData.render
                                                      : true,
                                                    expression:
                                                      "buttonData.render ? buttonData.render : true"
                                                  }
                                                ],
                                                key:
                                                  "btn-" +
                                                  groupIndex +
                                                  "-" +
                                                  index,
                                                attrs: {
                                                  "button-class":
                                                    buttonData.buttonClass
                                                },
                                                on: {
                                                  click: buttonData.onClick
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(buttonData.label) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          _c("b-dropdown-divider", {
                                            key: groupIndex
                                          })
                                        ]
                                      }),
                                      _vm.patientData &&
                                      _vm.patientData.isbanned
                                        ? _c(
                                            "b-dropdown-item-button",
                                            {
                                              attrs: {
                                                "button-class": "text-warning"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openDialog(
                                                    "LiftBanPatientDialog"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" LIFT BAN ")]
                                          )
                                        : _vm._e(),
                                      _vm.patientData &&
                                      !_vm.patientData.isbanned
                                        ? _c(
                                            "b-dropdown-item-button",
                                            {
                                              attrs: {
                                                "button-class": "text-danger"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openDialog(
                                                    "BanPatientDialog"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" BAN PATIENT ")]
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-mr-3" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "outline-primary",
                                        pill: "",
                                        block: "",
                                        disabled: !_vm.patientData
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openDialog("ActionHistory")
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: { icon: "newspaper" }
                                      }),
                                      _vm._v(" Notes ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-mr-3" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "outline-primary",
                                        pill: "",
                                        block: "",
                                        disabled: !_vm.patientData
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openDialog("ActionDialog")
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: { icon: "pencil" }
                                      }),
                                      _vm._v(" Action ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: { click: _vm.close }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/dialog_close.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-relative tw-flex-wrap tw-flex-grow tw-px-8"
                        },
                        [
                          _vm.viewMode == "info"
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex tw-flex-col tw-flex-1 tw-pr-4"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tw-overflow-auto",
                                        style:
                                          _vm.patientData &&
                                          (_vm.patientData
                                            .hastransferpharmacy ||
                                            _vm.patientData.isbanned)
                                            ? "height: calc(100vh - 180px)"
                                            : "height: calc(100vh - 160px)"
                                      },
                                      [
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted text-small"
                                                },
                                                [_vm._v("Location")]
                                              ),
                                              _c("br"),
                                              _vm.patientData
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.addressLocation
                                                      )
                                                    )
                                                  ])
                                                : _c("b-skeleton", {
                                                    attrs: { width: "30%" }
                                                  })
                                            ],
                                            1
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-flex tw-flex-wrap tw-mb-3 tw-justify-between"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "tw-flex-1 tw-mr-4"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-between"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted text-small"
                                                      },
                                                      [_vm._v("Email Address")]
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-small",
                                                        attrs: {
                                                          href: "javascript:;"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "PatientInfoEdit",
                                                              _vm.patientData
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" EDIT ")]
                                                    )
                                                  ]
                                                ),
                                                _vm.patientData
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.patientData.email
                                                        )
                                                      )
                                                    ])
                                                  : _c("b-skeleton")
                                              ],
                                              1
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "tw-flex-1 tw-ml-4"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-between"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted text-small"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Contact Number "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-small",
                                                        attrs: {
                                                          href: "javascript:;"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDialog(
                                                              "PatientInfoEdit",
                                                              _vm.patientData
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" EDIT ")]
                                                    )
                                                  ]
                                                ),
                                                _vm.patientData
                                                  ? _c("span", [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "tel:" +
                                                              _vm.patientData
                                                                .phoneno
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.patientData
                                                                  .phoneno
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _c("b-skeleton")
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm.patientData &&
                                        _vm.patientData.hasOwnProperty("card")
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tw-flex tw-flex-wrap tw-mb-3 tw-justify-between"
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "tw-flex-1 tw-mr-4"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-muted text-small"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Card Last 4 Digits "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.patientData.card
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.patientData
                                                                  .card.last4
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("b-skeleton", {
                                                          attrs: {
                                                            width: "20%"
                                                          }
                                                        })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "tw-flex-1 tw-ml-4"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-muted text-small"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Card Expiry"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.patientData.card
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.patientData
                                                                  .card.expdate
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("b-skeleton", {
                                                          attrs: {
                                                            width: "30%"
                                                          }
                                                        })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted text-small"
                                                },
                                                [_vm._v("Timezone")]
                                              ),
                                              _c("br"),
                                              _vm.patientData
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.patientData
                                                            .shippingaddress &&
                                                            _vm.patientData
                                                              .shippingaddress
                                                              .timezone
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _c("b-skeleton", {
                                                    attrs: { width: "10%" }
                                                  })
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted text-small"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Shipping Address "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "text-small",
                                                      attrs: {
                                                        href: "javascript:;"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDialog(
                                                            "PatientAddressEdit",
                                                            _vm.patientData
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" EDIT ")]
                                                  )
                                                ]
                                              ),
                                              _vm.patientData
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.addressDisplay)
                                                    )
                                                  ])
                                                : _c("b-skeleton")
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted text-small"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Billing Address "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "text-small",
                                                      attrs: {
                                                        href: "javascript:;"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDialog(
                                                            "PatientAddressEdit",
                                                            _vm.patientData
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" EDIT ")]
                                                  )
                                                ]
                                              ),
                                              _vm.patientData
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.billingAddressDisplay
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _c("b-skeleton")
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted text-small"
                                                    },
                                                    [
                                                      _vm._v(" Doctor "),
                                                      _vm.patientData
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-inline-block ml-2"
                                                            },
                                                            [
                                                              _vm.patientData.hasOwnProperty(
                                                                "doctorname"
                                                              )
                                                                ? _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "text-small",
                                                                      attrs: {
                                                                        href:
                                                                          "javascript:;"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openDialog(
                                                                            "ReassignPatientDialog",
                                                                            _vm.patientData
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " REASSIGN "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.patientData
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            pill: "",
                                                            size: "sm",
                                                            variant: "secondary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                "/messages?patientid=" +
                                                                  _vm
                                                                    .patientData
                                                                    .id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" Message ")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm.patientData
                                                ? _c("span", [
                                                    _vm.patientData.hasOwnProperty(
                                                      "doctorname"
                                                    )
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " Dr. " +
                                                              _vm._s(
                                                                _vm.patientData
                                                                  .doctorname
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            "No doctor assigned yet."
                                                          )
                                                        ])
                                                  ])
                                                : _c("b-skeleton", {
                                                    attrs: { width: "40%" }
                                                  })
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted text-small"
                                                },
                                                [
                                                  _vm._v(
                                                    " Last Message with Doctor Sent "
                                                  )
                                                ]
                                              ),
                                              _c("br"),
                                              _vm.patientData
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.dateToDisplay(
                                                            _vm.patientData
                                                              .lastmessagesentbydoctor *
                                                              1000,
                                                            true
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _c("b-skeleton", {
                                                    attrs: { width: "80%" }
                                                  })
                                            ],
                                            1
                                          )
                                        ]),
                                        _vm.patientData &&
                                        _vm.patientData.hasOwnProperty(
                                          "failedreason"
                                        )
                                          ? _c(
                                              "div",
                                              { staticClass: "tw-mb-3" },
                                              [
                                                _c(
                                                  "p",
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted text-small"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Rejection Reason "
                                                        )
                                                      ]
                                                    ),
                                                    _c("br"),
                                                    _vm.patientData
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.patientData
                                                                  .failedreason
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("b-skeleton", {
                                                          attrs: {
                                                            width: "80%"
                                                          }
                                                        })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c("p", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-muted text-small"
                                              },
                                              [_vm._v("Accounts")]
                                            ),
                                            _c("br"),
                                            _vm.patientData
                                              ? _c("span", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:;"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.redirectToIntercom(
                                                            _vm.patientData.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Intercom ")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: _vm.stripeLink,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [_vm._v("Stripe")]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: _vm.airtableLink,
                                                        target: "_blank"
                                                      }
                                                    },
                                                    [_vm._v("Airtable")]
                                                  )
                                                ])
                                              : _c(
                                                  "span",
                                                  [
                                                    _c("b-skeleton", {
                                                      attrs: { width: "12%" }
                                                    }),
                                                    _c("b-skeleton", {
                                                      attrs: { width: "10%" }
                                                    }),
                                                    _c("b-skeleton", {
                                                      attrs: { width: "11%" }
                                                    })
                                                  ],
                                                  1
                                                )
                                          ])
                                        ]),
                                        _c("div", { staticClass: "tw-mb-3" }, [
                                          _c(
                                            "p",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted text-small"
                                                },
                                                [_vm._v("Price Version")]
                                              ),
                                              _c("br"),
                                              _vm.patientData
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-secondary tw-cursor-pointer",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showPricingList = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.patientData
                                                              .pricingversion ===
                                                              "v2"
                                                              ? "Version 2"
                                                              : "Version 1"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _c("b-skeleton", {
                                                    attrs: { width: "80%" }
                                                  })
                                            ],
                                            1
                                          )
                                        ]),
                                        _vm.patientData &&
                                        _vm.patientData.hastransferpharmacy
                                          ? _c(
                                              "div",
                                              { staticClass: "tw-mb-3" },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-muted text-small"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Transfer RX (Active) "
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c("div", [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.patientData
                                                                .pharmacy.name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.patientData
                                                                .pharmacy
                                                                .address ||
                                                                _vm.patientData
                                                                  .pharmacy
                                                                  .address1
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.patientData
                                                                .pharmacy.city
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm.patientData
                                                                .pharmacy.state
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm.patientData
                                                                .pharmacy
                                                                .zipcode
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "tw-mb-3" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                pill: "",
                                                block: "",
                                                variant: "outline-secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.viewMode = "history"
                                                }
                                              }
                                            },
                                            [_vm._v(" PATIENT ACTIVITY FEED ")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex-1 tw-pl-4 tw-overflow-y-auto tw-overflow-x-hidden",
                                    style:
                                      _vm.patientData &&
                                      (_vm.patientData.hastransferpharmacy ||
                                        _vm.patientData.isbanned)
                                        ? "height: calc(100vh - 140px)"
                                        : "height: calc(100vh - 120px)"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-flex tw-justify-between"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tw-flex-1" },
                                          [
                                            _vm.prescriptionData.length > 0
                                              ? _c("div", [
                                                  _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "h3 text-primary"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Active prescription"
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm.loadedPrescriptions &&
                                                _vm.prescriptionData.length == 0
                                              ? _c("div", [
                                                  _c("h3", [
                                                    _vm._v(
                                                      "This patient has no prescriptions."
                                                    )
                                                  ])
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-flex tw-justify-between tw-mb-2 tw-items-center"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-muted text-small tw-inline-block tw-mr-1"
                                          },
                                          [_vm._v(" Next Follow-up ")]
                                        ),
                                        _vm.prescriptionData.length > 0
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.patientData
                                                      .followuptext ||
                                                      _vm.getFollowUpDate()
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._l(_vm.prescriptionData, function(
                                      prescription
                                    ) {
                                      return _c("PrescriptionCard", {
                                        key: prescription.id,
                                        attrs: {
                                          prescription: prescription,
                                          "enable-controls": true,
                                          selected:
                                            _vm.selectedPrescriptionId ===
                                            prescription.id,
                                          timer: _vm.getTimeUntilActive(
                                            prescription
                                          ),
                                          "patient-data": _vm.getPrescriptionCardPatientData(
                                            _vm.patientData
                                          ),
                                          "ongoing-items": _vm.ongoingItems
                                        },
                                        on: {
                                          "open-prescription-history":
                                            _vm.openPrescriptionHistory,
                                          "open-refill": function($event) {
                                            return _vm.openPrescriptionDialog(
                                              "RefillDialog",
                                              $event
                                            )
                                          },
                                          "open-move-refill": function($event) {
                                            return _vm.openPrescriptionDialog(
                                              "MoveRefillDateDialog",
                                              $event
                                            )
                                          },
                                          "open-move-prescription-start-date": function(
                                            $event
                                          ) {
                                            return _vm.openPrescriptionDialog(
                                              "MovePrescriptionStartDateDialog",
                                              $event
                                            )
                                          },
                                          "open-escript": function($event) {
                                            return _vm.openPrescriptionDialog(
                                              "PushEscriptDialog",
                                              $event
                                            )
                                          },
                                          "open-rush": function($event) {
                                            return _vm.openPrescriptionDialog(
                                              "RushSinglePrescriptionDialog",
                                              $event
                                            )
                                          },
                                          "open-cancel-order": function(
                                            $event
                                          ) {
                                            return _vm.openPrescriptionDialog(
                                              "CancelOrderDialog",
                                              $event
                                            )
                                          },
                                          "open-send-replacement": function(
                                            $event
                                          ) {
                                            return _vm.openPrescriptionDialog(
                                              "SendReplacementDialog",
                                              $event
                                            )
                                          },
                                          "open-stop-delay": function($event) {
                                            return _vm.openPrescriptionDialog(
                                              "DelayTimerDialog",
                                              $event
                                            )
                                          },
                                          "open-pause-prescription": function(
                                            $event
                                          ) {
                                            return _vm.openPrescriptionDialog(
                                              "PausePrescriptionDialog",
                                              $event
                                            )
                                          },
                                          "open-single-cancel": function(
                                            $event
                                          ) {
                                            return _vm.openPrescriptionDialog(
                                              "CancelSinglePrescriptionDialog",
                                              $event
                                            )
                                          },
                                          "open-toggle-prescription":
                                            _vm.openTogglePrescription,
                                          "pause-timer": _vm.pauseTimer,
                                          "open-cancel-single": function(
                                            $event
                                          ) {
                                            return _vm.openPrescriptionDialog(
                                              "CancelSinglePrescriptionDialog",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    }),
                                    !_vm.loadedPrescriptions
                                      ? _c("b-skeleton-img")
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            : _vm._e(),
                          _c("PatientHistory", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showHistory,
                                expression: "showHistory"
                              }
                            ],
                            staticClass: "tw-w-full",
                            attrs: {
                              show: _vm.showHistory,
                              "patient-id":
                                _vm.patientData && _vm.patientData.id
                            },
                            on: {
                              "switch-mode": function($event) {
                                _vm.viewMode = "info"
                              }
                            }
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c("AirtableOrderView", {
            ref: "AirtableOrderView",
            attrs: { "patient-data": _vm.patientData },
            on: {
              "refresh-prescriptions": _vm.refreshPrescriptions,
              "open-rush": function($event) {
                return _vm.openPrescriptionDialog(
                  "RushSinglePrescriptionDialog",
                  $event
                )
              }
            }
          })
        ],
        1
      ),
      _c("ModalPricingList", {
        attrs: {
          "show-pricing-modal": _vm.showPricingList,
          "patient-id": _vm.patientData && _vm.patientData.id,
          pricingversion:
            _vm.patientData && (_vm.patientData.pricingversion || "v1")
        },
        on: {
          "update:showPricingModal": function($event) {
            _vm.showPricingList = $event
          },
          "update:show-pricing-modal": function($event) {
            _vm.showPricingList = $event
          }
        }
      }),
      _c("IntakeModal", {
        attrs: {
          "show-intake-modal": _vm.showIntakeModal,
          patientid: _vm.patientData && _vm.patientData.id
        },
        on: {
          "update:showIntakeModal": function($event) {
            _vm.showIntakeModal = $event
          },
          "update:show-intake-modal": function($event) {
            _vm.showIntakeModal = $event
          }
        }
      }),
      _c("div", {
        staticClass: "background",
        style: {
          "background-color": _vm.bgModal.color,
          display: _vm.bgModal.show
        },
        on: {
          click: function($event) {
            _vm.showIntakeModal = false
          }
        }
      }),
      _vm.patientData
        ? _c(
            "div",
            [
              _c("ActionDialog", {
                ref: "ActionDialog",
                attrs: { "row-data": _vm.patientData, "action-id": _vm.tab },
                on: { "show-toast": _vm.showToast }
              }),
              _c("ActionHistory", {
                ref: "ActionHistory",
                attrs: { "row-data": _vm.patientData },
                on: {
                  "open-action": function($event) {
                    return _vm.openDialog("ActionDialog")
                  }
                }
              }),
              _c("ResetPasswordDialog", {
                ref: "ResetPasswordDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("SetPasswordDialog", {
                ref: "SetPasswordDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("PatientInfoEdit", {
                ref: "PatientInfoEdit",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-self": _vm.refreshData }
              }),
              _c("PatientAddressEdit", {
                ref: "PatientAddressEdit",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-self": _vm.refreshData }
              }),
              _c("MigrateStateDialog", {
                ref: "MigrateStateDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-self": _vm.refreshData }
              }),
              _c("TransferRXDialog", {
                ref: "TransferRXDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-self": _vm.refreshData }
              }),
              _c("ResetEmailDialog", {
                ref: "ResetEmailDialog",
                attrs: { "row-data": _vm.patientData }
              }),
              _c("HSAReceiptDialog", {
                ref: "HSAReceiptDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("FSAReceiptDialog", {
                ref: "FSAReceiptDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("NDCDialog", {
                ref: "NDCDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("UploadDialog", {
                ref: "UploadDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-data": _vm.refreshData }
              }),
              _c("ResetIntakeDialog", {
                ref: "ResetIntakeDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("ReceiveUSPSDialog", {
                ref: "ReceiveUSPSDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "show-toast": _vm.showToast }
              }),
              _c("CancelPatientDialog", {
                ref: "CancelPatientDialog",
                attrs: { "row-data": _vm.patientData }
              }),
              _c("CancelPrescriptionDialog", {
                ref: "CancelPrescriptionDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("CancelSinglePrescriptionDialog", {
                ref: "CancelSinglePrescriptionDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("RushSinglePrescriptionDialog", {
                ref: "RushSinglePrescriptionDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: {
                  "refresh-prescriptions": function($event) {
                    return _vm.$emit("refresh-with-airtable")
                  }
                }
              }),
              _c("PushEscriptDialog", {
                ref: "PushEscriptDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("CancelOrderDialog", {
                ref: "CancelOrderDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("RefillDialog", {
                ref: "RefillDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("DelayTimerDialog", {
                ref: "DelayTimerDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("MoveRefillDateDialog", {
                ref: "MoveRefillDateDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("MovePrescriptionStartDateDialog", {
                ref: "MovePrescriptionStartDateDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("AssignPatientDialog", {
                ref: "AssignPatientDialog",
                attrs: { "row-data": _vm.patientData }
              }),
              _c("ReassignPatientDialog", {
                ref: "ReassignPatientDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "set-doctor-name": _vm.setDoctorName }
              }),
              _c("PrescriptionHistoryDialog", {
                ref: "PrescriptionHistoryDialog",
                attrs: { "prescription-data": _vm.prescriptionData },
                on: {
                  "open-cancel-single": function($event) {
                    return _vm.openDialog("CancelSinglePrescriptionDialog")
                  }
                }
              }),
              _c("RemovePatientDialog", {
                ref: "RemovePatientDialog",
                attrs: { "row-data": _vm.patientData },
                on: {
                  "close-view": function($event) {
                    _vm.showInfoViewModal = false
                  }
                }
              }),
              _c("PausePrescriptionDialog", {
                ref: "PausePrescriptionDialog",
                attrs: { "row-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("ResumePrescriptionDialog", {
                ref: "ResumePrescriptionDialog",
                attrs: { "row-data": _vm.selectedPrescription },
                on: {
                  "refresh-prescriptions": _vm.refreshPrescriptions,
                  "open-pause-prescription": function($event) {
                    return _vm.openDialog("PausePrescriptionDialog")
                  }
                }
              }),
              _c("RetryPrescriptionDialog", {
                ref: "RetryPrescriptionDialog",
                attrs: { "row-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("RestartPrescriptionDialog", {
                ref: "RestartPrescriptionDialog",
                attrs: { "row-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("PushScheduledPrescriptionDialog", {
                ref: "PushScheduledPrescriptionDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              }),
              _c("BanPatientDialog", {
                ref: "BanPatientDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-self": _vm.refreshData }
              }),
              _c("LiftBanPatientDialog", {
                ref: "LiftBanPatientDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-self": _vm.refreshData }
              }),
              _c("UpdatePauseDialog", {
                ref: "UpdatePauseDialog",
                attrs: { "row-data": _vm.patientData },
                on: { "refresh-prescription": _vm.refreshPrescriptions }
              }),
              _c("ShippingChargeDialog", {
                ref: "ShippingChargeDialog",
                attrs: { "row-data": _vm.patientData }
              }),
              _c("DismissDialog", {
                ref: "DismissDialog",
                attrs: { tab: _vm.tab, "row-data": _vm.patientData }
              }),
              _c("ReportIssueDialog", {
                ref: "ReportIssueDialog",
                attrs: { "row-data": _vm.patientData }
              }),
              _c("SendReplacementDialog", {
                ref: "SendReplacementDialog",
                attrs: { "prescription-data": _vm.selectedPrescription },
                on: { "refresh-prescriptions": _vm.refreshPrescriptions }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-toast",
        {
          attrs: {
            id: "dialogToast",
            variant: _vm.toast.variant,
            "no-close-button": "",
            "auto-hide-delay": "2500",
            toaster: "b-toaster-top-center",
            solid: ""
          }
        },
        [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }