//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import constants from '@/constants';
import { mapState } from 'vuex';
import { MacroService } from '@/services/macro.service';
export default {
  data() {
    return {
      scrollbarOptions: {
        suppressScrollX: true
      },
      frequentlyUsedMacros: [],
      macros: [],
      selectedMacro: {},
      activeItemID: 0,
      categoryId: null,
      search: '',
      filteredMacros: []
    };
  },

  computed: { ...mapState('chat', {
      showDialogStore: 'macroDialog'
    }),
    showDialog: {
      get() {
        return this.showDialogStore;
      },

      set(value) {
        this.$store.commit('chat/SET_OPEN_MACRO', value);
      }

    },

    displayMacros() {
      if (this.search.length > 0) {
        return this.filteredMacros;
      } else {
        return this.macros;
      }
    },

    displayFrequentlyUsedMacros() {
      if (this.search.length == 0) {
        return this.frequentlyUsedMacros;
      } else {
        return [];
      }
    }

  },
  watch: {
    async showDialog(show) {
      this.selectedMacro = {};

      if (!show) {
        this.activeItemID = 0;
        this.search = '';
      } else {
        // Load in first macro if macros are already present
        if (this.macros.length > 0) {
          this.selectedMacro = this.frequentlyUsedMacros[0] || this.macros[0];
        } // Get frequently used macros


        const frequentlyUsedCall = await MacroService.getFrequentlyUsedMacros(this.categoryId);
        const frequentlyUsed = frequentlyUsedCall.data.favorites.slice().sort((a, b) => b.times_used - a.times_used).slice(0, 10); // Get all macros

        const {
          data
        } = await MacroService.getAllMacros(this.categoryId);
        this.macros = data; // Get all IDs of frequently used macros

        const frequentlyUsedIDs = frequentlyUsed.map(macro => macro.id); // Filter out frequently used macros from all macros

        this.macros = this.macros.filter(macro => !frequentlyUsedIDs.includes(macro.id));
        this.frequentlyUsedMacros = frequentlyUsed; // Check if macro is not yet selected

        if (Object.keys(this.selectedMacro).length == 0) {
          this.selectedMacro = this.frequentlyUsedMacros[0] || this.macros[0];
        }

        this.$refs.searchMacrosInput.focus();
      }
    },

    search(val) {
      this.selectedMacro = {};
      this.activeItemID = 0;

      if (val) {
        // Checks both name and content, sorts by times used
        this.filteredMacros = this.macros.concat(this.frequentlyUsedMacros).filter(macro => macro.name.toLowerCase().includes(val.toLowerCase()) || macro.content.toLowerCase().includes(val.toLowerCase())).sort((a, b) => b.times_used - a.times_used);
      } else {
        this.filteredMacros = this.macros;
      }
    }

  },

  mounted() {
    this.categoryId = constants.macroCategoryId;
  },

  methods: {
    setActiveItem(id) {
      this.activeItemID = id;
      const messageObject = this.macros.concat(this.frequentlyUsedMacros).find(o => o.id == id);
      this.selectedMacro = messageObject;
    },

    sendToChat() {
      this.$store.commit('chat/SET_MESSAGE_TEMPLATE', {
        id: this.selectedMacro.id,
        content: this.selectedMacro.content
      });
      this.$store.commit('chat/SET_OPEN_MACRO', false);
    },

    close() {
      this.$store.commit('chat/SET_OPEN_MACRO', false);
    },

    openEdit() {
      this.$emit('open-edit');
      this.close();
    }

  }
};