/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "lg"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4 pr-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "2" } },
            [
              _c("h2", { staticClass: "heading tw-mb-2 tw-text-2xl" }, [
                _vm._v(" Cancel Patient ")
              ]),
              _c("p", { staticClass: "tw-text-muted tw-text-base" }, [
                _vm._v(
                  " You are about to cancel " +
                    _vm._s(_vm.patientData.firstname) +
                    " " +
                    _vm._s(_vm.patientData.lastname) +
                    ", this will automatically cancel the following subscriptions: "
                )
              ]),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v("Subscriptions")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "3" } },
                    [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Refills Remaining")
                      ])
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "3" } },
                    [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Last Payment Date")
                      ])
                    ]
                  ),
                  _c("b-col", { attrs: { cols: "2" } }, [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v("Amount")
                    ])
                  ])
                ],
                1
              ),
              _vm._l(_vm.prescriptionData, function(prescription) {
                return _c(
                  "b-row",
                  { key: prescription.id, staticClass: "mt-2" },
                  [
                    _c("b-col", { attrs: { cols: "3" } }, [
                      _vm._v(" " + _vm._s(prescription.medicinename) + " ")
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "text-center", attrs: { cols: "3" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.getRefillString(prescription)) + " "
                        )
                      ]
                    ),
                    _c(
                      "b-col",
                      { staticClass: "text-center", attrs: { cols: "3" } },
                      [
                        prescription.lastpaymentmade
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDate(prescription.lastpaymentmade)
                                  ) +
                                  " "
                              )
                            ])
                          : _c("span", [_vm._v("-")])
                      ]
                    ),
                    _c(
                      "b-col",
                      { staticClass: "text-center", attrs: { cols: "2" } },
                      [_vm._v(" $" + _vm._s(prescription.refundamount) + " ")]
                    )
                  ],
                  1
                )
              }),
              _c("hr"),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c("b", [_vm._v("Total")])
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right",
                      attrs: { offset: "3", cols: "2" }
                    },
                    [_c("b", [_vm._v("$" + _vm._s(_vm.total))])]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "cancellationReason" } }, [
                        _vm._v("Reason")
                      ]),
                      _c("b-form-select", {
                        attrs: {
                          id: "cancellationReason",
                          options: _vm.cancellationReasons
                        },
                        model: {
                          value: _vm.chosenReason,
                          callback: function($$v) {
                            _vm.chosenReason = $$v
                          },
                          expression: "chosenReason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "cancellationNotes" } }, [
                        _vm._v("Notes")
                      ]),
                      _c("b-form-textarea", {
                        attrs: { id: "cancellationNotes" },
                        model: {
                          value: _vm.cancellationNotes,
                          callback: function($$v) {
                            _vm.cancellationNotes = $$v
                          },
                          expression: "cancellationNotes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-dark",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "secondary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: { click: _vm.cancelPatient }
                        },
                        [_vm._v(" Confirm ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }