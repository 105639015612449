//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex'; // Services

import { AirtableService } from '@/services/airtable.service';
import { PrescriptionService } from '@/services/prescription.service'; // Components

import CardAirtableOrder from '@/components/patients/partials/CardAirtableOrder';
import AirtableAddressDialog from '@/components/patients/AirtableAddressDialog';
import AirtableShippingDialog from '@/components/patients/AirtableShippingDialog';
import AirtableOrderSkeleton from '@/components/infoview/AirtableOrderSkeleton';
export default {
  components: {
    CardAirtableOrder,
    AirtableAddressDialog,
    AirtableShippingDialog,
    AirtableOrderSkeleton
  },
  props: {
    patientData: {
      type: Object,

      default() {
        return {
          id: '',
          firstname: '',
          lastname: ''
        };
      }

    }
  },

  data() {
    return {
      airtableOrders: [],
      selectedAirtableOrder: {},
      loading: false
    };
  },

  computed: { ...mapState('infoview', {
      isOrderManagerOpen: 'displayAirtable',
      selectedPrescription: 'airtableDisplayPrescription'
    })
  },
  watch: {
    async isOrderManagerOpen(show) {
      if (show) {
        await this.getAirtableOrders(this.selectedPrescription.id);
      } else {
        this.airtableOrders = [];
      }
    },

    async selectedPrescription(val) {
      if (val) {
        this.airtableOrders = [];
        await this.getAirtableOrders(val.id);
      }
    }

  },
  methods: {
    async getAirtableOrders(prescriptionid, refetch = false) {
      this.loading = true;
      const prescriptionDataPromises = [AirtableService.getAirtableOrders(prescriptionid).then(airtableOrders => this.airtableOrders = airtableOrders.data)];

      if (refetch) {
        prescriptionDataPromises.push(PrescriptionService.getPrescription(this.patientData.id).then(prescriptionData => this.prescriptionData = prescriptionData.data));
        this.$emit('refresh-prescriptions');
      }

      await Promise.allSettled(prescriptionDataPromises);
      this.loading = false;
    },

    close() {
      this.$store.dispatch('infoview/closeAirtableDisplay');
    },

    onAirtableAddressUpdate(order) {
      this.$refs.AirtableAddressDialog.showDialog = true;
      this.selectedAirtableOrder = order;
    },

    onAirtableShippingUpdate(order) {
      this.$refs.AirtableShippingDialog.showDialog = true;
      this.selectedAirtableOrder = order;
    }

  }
};