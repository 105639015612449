//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import moment from 'moment';
import { DoctorService } from '@/services/doctor.service';
export default {
  data() {
    return {
      headers: [{
        key: 'startdatestamp',
        label: 'Start Date'
      }, {
        key: 'enddatestamp',
        label: 'End Date'
      }, {
        key: 'name',
        label: 'Doctor'
      }, {
        key: 'coveredstate',
        label: 'State'
      }, {
        key: 'doctorlistdetails',
        label: 'Reassign To'
      }, {
        key: 'actions',
        label: 'Actions'
      }, {
        key: 'delete',
        label: ''
      }],
      items: [],
      currentPage: 1
    };
  },

  computed: { ...mapGetters({
      currentPageStore: 'tables/getCurrentPage',
      currentTableRowCount: 'tables/getRowCount'
    })
  },
  watch: {
    currentPageStore(newVal) {
      this.currentPage = newVal;
      this.$store.commit('tables/SET_CURRENT_PAGE', newVal);
      this.getDoctorReassignments({
        page: newVal
      });
    }

  },

  async mounted() {
    this.$store.commit('tables/SET_CURRENT_PAGE', 1);
    await this.getDoctorReassignments({
      page: 1
    });
  },

  methods: {
    async getDoctorReassignments({
      page = 1
    }) {
      this.items = [];
      this.fetching = true;
      const {
        data
      } = await DoctorService.getReassignments({
        page
      });
      this.fetching = false;
      this.items = data.items;
      this.$store.commit('tables/SET_ROW_COUNT', Number(data.count));
      return data;
    },

    handlePaginationClick(event, pageNumber) {
      this.$store.commit('tables/SET_CURRENT_PAGE', pageNumber);
    },

    showEdit(row) {
      return row.reassignmentstatus == 'PENDING' || row.reassignmentstatus == 'APPROVED' || row.reassignmentstatus == 'TRANSFERRED';
    },

    showDelete(row) {
      return row.reassignmentstatus == 'PENDING';
    },

    showCancel(row) {
      return row.reassignmentstatus == 'APPROVED' || row.reassignmentstatus == 'TRANSFERRED';
    },

    async refresh() {
      const {
        data
      } = await DoctorService.getReassignments({
        page: this.currentPage
      });
      this.items = data.items;
      this.$store.commit('tables/SET_ROW_COUNT', Number(data.count));
    },

    getDate(date) {
      if (!date) {
        return 'Indefinite';
      } else {
        return moment(date * 1000).format('MM/DD/YYYY LT');
      }
    },

    getTotalLimit(states) {
      let max = 0;

      for (const state of states) {
        max += Number(state.limit);
      }

      return max;
    },

    updateStates(id, data) {
      this.items = this.items.map(o => {
        if (o.id == id) {
          o.coveredstate = data.coveredstate;
        }
      });
    },

    getStateList(states) {
      if (states && states.length == 1) {
        return states[0].state;
      } else {
        let output = '';

        for (const state of states) {
          output += `${state.state}, `;
        }

        return output.slice(0, -1);
      }
    },

    removeItem(id) {
      this.items.splice(this.items.findIndex(o => o.id == id), 1);
    },

    addRow(row) {
      this.items.push(row);
    },

    async updateRow(id, row) {
      const idArray = this.items.map(o => o.id);
      const index = idArray.indexOf(id);
      this.$set(this.items, index, row);
      await this.$nextTick();
    },

    async approveReassignment(id) {
      const {
        data
      } = await DoctorService.approveReassignment(id);

      if (data) {
        this.refresh();
      }
    }

  }
};