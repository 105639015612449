export default {
  data() {
    return {
      height: undefined,
      mHeight: undefined,
      messageHeight: 0,
      mobileHeight: 0,
    };
  },

  methods: {
    observeTextarea() {
      const element =
        document.querySelector('.custom-footer').clientHeight - 60;
      this.messageHeight = element;
    },
    matchHeight() {
      this.height = document.querySelector('.content').clientHeight - (60 + 84);
    },
  },

  mounted() {
    this.matchHeight();
  },
};
