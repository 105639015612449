/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-flex tw-flex-col tw-flex-grow" }, [
    _c(
      "div",
      {
        staticClass:
          "tw-sticky tw-justify-between tw-top-0 tw-flex tw-px-6 tw-py-6 tw-bg-white"
      },
      [
        _c("div", { staticClass: "tw-flex tw-max-w-[350px]" }, [
          _c(
            "div",
            { staticClass: "tw-mr-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [_c("b-skeleton", { attrs: { width: "200px" } })],
                    1
                  ),
                  _c(
                    "b-col",
                    [_c("b-skeleton", { attrs: { width: "75px" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "tw-flex" }, [
          _c(
            "span",
            { staticClass: "card-status tw-text-lg tw-mr-4" },
            [_c("b-skeleton")],
            1
          ),
          _c(
            "svg",
            {
              staticClass: "tw-w-6 tw-h-6 tw-cursor-not-allowed",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "#aaa"
              }
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  d: "M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                }
              })
            ]
          )
        ])
      ]
    ),
    _c(
      "div",
      { staticClass: "tw-flex-grow tw-px-6" },
      [_c("b-skeleton-img", { attrs: { width: "100%", height: "250px" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }