export default {
  namespaced: true,
  state: {
    from: null,
    to: null,
  },
  mutations: {
    SET_FILTER_FROM(state, from) {
      state.from = from;
    },
    SET_FILTER_TO(state, to) {
      state.to = to;
    },
    SET_FILTERS(state, from, to) {
      state.from = from;
      state.to = to;
    },
  },
};
