//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }

    }
  },

  data() {
    return {
      alert: {
        show: false,
        message: ''
      },
      revertNotes: '',
      buttonDisabled: false,
      showDialog: false
    };
  },

  methods: {
    async revert() {
      try {
        this.buttonDisabled = true;
        this.alert.show = false;

        if (!this.revertNotes) {
          throw 'You must fill in all the fields.';
        }

        const dataToSubmit = {
          email: this.rowData.email,
          reason: this.revertNotes
        };
        const {
          data
        } = await PatientService.revertRemove(dataToSubmit);

        if (data) {
          this.buttonDisabled = false;
          this.$emit('delete-self', this.rowData.id);
          this.showDialog = false;
        }
      } catch (err) {
        this.buttonDisabled = false;
        this.alert.show = true;
        this.alert.message = err;
      }
    }

  }
};