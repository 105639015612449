/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "svg-triangle",
      class: [_vm.positionClass, _vm.colorClass],
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        version: "1.1",
        height: "70",
        width: "70"
      }
    },
    [
      _c("path", {
        attrs: { d: "M 40,10 70,60 10,60 z", fill: "currentColor" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }