//
//
//
//
//
//
//
//
//
import Main from '@/components/tables/partials/Main';
export default {
  components: {
    Main
  },

  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        sortable: true
      }, {
        key: 'birthday',
        label: 'Birthday'
      }, {
        key: 'phoneno',
        label: 'Contact'
      }, {
        key: 'stage',
        label: 'Status'
      }, {
        key: 'datetimelastaction',
        label: 'Last Action'
      }, {
        key: 'upload',
        label: 'Actions'
      }, {
        key: 'actions',
        label: ''
      }, {
        key: 'history',
        label: ''
      }, // { key: 'patientinfo', label: '' },
      {
        key: 'links',
        label: ''
      }],
      type: 'all'
    };
  },

  methods: {
    viewInfo(row) {
      this.$emit('view-info', row);
    },

    getStatusCount() {
      this.$emit('get-status-count');
    }

  }
};