//
//
//
//
//
//
//
//
//
import Main from '@/components/tables/partials/Main';
export default {
  components: {
    Main
  },

  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        sortable: true
      }, {
        key: 'bannedReason',
        label: 'Banned Reason'
      }, {
        key: 'bannedNote',
        label: 'Banned Note'
      }, {
        key: 'bannedDate',
        label: 'Banned Date'
      }, {
        key: 'history',
        label: 'Actions'
      }, // { key: 'patientinfo', label: '' },
      {
        key: 'links',
        label: ''
      }],
      type: 'banned'
    };
  },

  methods: {
    viewInfo(row) {
      this.$emit('view-info', row);
    },

    getStatusCount() {
      this.$emit('get-status-count');
    }

  }
};