/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    { staticClass: "d-flex" },
    [
      _c(
        "b-input-group-prepend",
        [
          _c(
            "b-input-group-text",
            [_c("b-icon-search", { staticClass: "tw-text-[#353148]" })],
            1
          )
        ],
        1
      ),
      _c("b-form-input", {
        ref: "searchInput",
        staticClass: "tw-p-0 flex-grow-1",
        attrs: {
          placeholder: "Search Doctor Earning",
          type: "search",
          autocomplete: "off"
        },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.searchEarning.apply(null, arguments)
          }
        },
        model: {
          value: _vm.search,
          callback: function($$v) {
            _vm.search = $$v
          },
          expression: "search"
        }
      }),
      _vm.search.length > 0
        ? _c(
            "b-input-group-prepend",
            { staticClass: "p-0" },
            [
              _c(
                "b-input-group-text",
                { staticClass: "p-0" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "border-0 align-self-center p-0",
                      attrs: { variant: "outline-white" },
                      on: { click: _vm.clearSearch }
                    },
                    [
                      _c("b-icon-x-circle", {
                        staticClass: "tw-text-[#353148]"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }