import toDate from 'date-fns/toDate/index';
import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class InventoryService extends BaseService {
  static async getAdmins() {
    try {
      let response = await this.request({ auth: true }).get(`/admin`);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getInventory() {
    try {
      let response = await this.request({ auth: true, v2: true }).get(
        '/admin/inventory'
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getInventoryRestocks(page = null) {
    try {
      let response = await this.request({ auth: true, v2: true }).get(
        `/admin/inventory/restock/${page}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getInventoryUsage(from = null, to = null) {
    try {
      // startTimestamp=1&endTimestamp=2
      let baseURL = '/admin/inventory/usage';
      let params = [];
      if (from) {
        params.push(`startTimestamp=${from}`);
      }
      if (to) {
        params.push(`endTimestamp=${to}`);
      }
      if (params.length > 0) {
        baseURL += '?' + params.join('&');
      }
      let response = await this.request({ auth: true, v2: true }).get(baseURL);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addInventoryRestock(body) {
    try {
      let response = await this.request({ auth: true, v2: true }).post(
        '/admin/inventory/restock',
        body
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateInventoryRestock(id, body) {
    try {
      let response = await this.request({ auth: true, v2: true }).put(
        // TEMPORARY
        `/admin/inventory/restock/${id}`,
        body
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteInventoryRestock(id) {
    try {
      let response = await this.request({ auth: true, v2: true }).delete(
        `/admin/inventory/restock/${id}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getInventoryOptions() {
    try {
      let response = await this.request({ auth: true, v2: true }).get(
        '/admin/inventory/options'
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
