/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-inline" }, [
    _c(
      "div",
      {
        staticClass:
          "tw-bg-white tw-border-solid tw-inline-flex tw-w-full tw-border-b tw-border-x-0 tw-border-t-0",
        class: [_vm.error ? "tw-border-red-500" : "tw-border-gray-200 "]
      },
      [
        _vm._t("pre", function() {
          return [_vm._v(" " + _vm._s(_vm.pre) + " ")]
        }),
        _c(
          "input",
          _vm._b(
            {
              staticClass:
                "tw-border-0 tw-bg-transparent focus:tw-ring-0 focus:tw-outline-0 tw-w-full",
              attrs: { type: "text" },
              domProps: { value: _vm.value },
              on: { input: _vm.onInput }
            },
            "input",
            _vm.$attrs,
            false
          )
        ),
        _vm._t("post", function() {
          return [_vm._v(" " + _vm._s(_vm.post) + " ")]
        })
      ],
      2
    ),
    _vm.error
      ? _c("div", { staticClass: "tw-text-xs tw-text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.error) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }