/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UploadDialog", {
        ref: "UploadDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "update-photo": _vm.updatePhoto, "delete-self": _vm.deleteItem }
      }),
      _c("ActionDialog", {
        ref: "ActionDialog",
        attrs: { "row-data": _vm.selectedRow, "action-id": _vm.type },
        on: { "update-time": _vm.updateTime, "show-toast": _vm.showToast }
      }),
      _c("ActionHistory", {
        ref: "ActionHistory",
        attrs: { "row-data": _vm.selectedRow },
        on: {
          "open-action": function($event) {
            return _vm.openActionDialog(_vm.selectedRow)
          }
        }
      }),
      _c("ResetPasswordDialog", {
        ref: "ResetPasswordDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "show-toast": _vm.showToast }
      }),
      _c("ResetEmailDialog", {
        ref: "ResetEmailDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "refresh-email": _vm.refreshEmail }
      }),
      _c("HSAReceiptDialog", {
        ref: "HSAReceiptDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "show-toast": _vm.showToast }
      }),
      _c("ResetIntakeDialog", {
        ref: "ResetIntakeDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "show-toast": _vm.showToast }
      }),
      _c("RetryPaymentDialog", {
        ref: "RetryPaymentDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "show-toast": _vm.showToast, "delete-self": _vm.deleteItem }
      }),
      _c("CancelPatientDialog", {
        ref: "CancelPatientDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "delete-self": _vm.deleteItem }
      }),
      _c("DismissDialog", {
        ref: "DismissDialog",
        attrs: { tab: _vm.type, "row-data": _vm.selectedRow },
        on: { dismiss: _vm.dequeueItem, "dismiss-type": _vm.dequeueType }
      }),
      _c("DoctorRequestListDialog", {
        ref: "DoctorRequestListDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "delete-self": _vm.deleteItem, "view-request": _vm.openRequest }
      }),
      _c("DoctorRequestDialog", {
        ref: "DoctorRequestDialog",
        attrs: { "row-data": _vm.selectedRow }
      }),
      _c("AssignPatientDialog", {
        ref: "AssignPatientDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "delete-self": _vm.deleteItem }
      }),
      _c("RefundRequestDialog", {
        ref: "RefundRequestDialog",
        attrs: { "row-data": _vm.selectedRow }
      }),
      _c("RefundConfirmationDialog", {
        ref: "RefundConfirmationDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "delete-self": _vm.deleteItem }
      }),
      _c("RevertRemovalDialog", {
        ref: "RevertRemovalDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "delete-self": _vm.deleteItem }
      }),
      _c(
        "b-toast",
        {
          attrs: {
            id: "alertToast",
            variant: _vm.toast.variant,
            "no-close-button": "",
            "auto-hide-delay": "2500",
            toaster: "b-toaster-top-center",
            solid: ""
          }
        },
        [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]
      ),
      _c(
        "b-alert",
        { attrs: { variant: "warning", show: _vm.showEmptyResultsAlert } },
        [_vm._v(" No search results turned up. ")]
      ),
      _c("b-table", {
        ref: "table",
        staticClass: "card-table",
        staticStyle: { "background-color": "#fff" },
        style: {
          maxHeight: _vm.$_patientView_contentHeight + "px",
          height: _vm.$_patientView_contentHeight + "px"
        },
        attrs: {
          id: "patient-table",
          "no-border-collapse": "",
          responsive: "",
          "select-mode": "single",
          fields: _vm.headers,
          items: _vm.items,
          "sticky-header": ""
        },
        on: { "sort-changed": _vm.sortChanged },
        scopedSlots: _vm._u([
          {
            key: "cell(photo)",
            fn: function(row) {
              return [
                _c("b-avatar", {
                  staticClass: "tw-mr-4",
                  attrs: { src: row.item.avatar }
                })
              ]
            }
          },
          {
            key: "cell(name)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    staticClass: "tw-underline",
                    attrs: { href: "patients/profile/" + row.item.id },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$emit("view-info", row.item)
                      }
                    }
                  },
                  [
                    !row.item.firstname && !row.item.lastname
                      ? _c("span", [_vm._v(" (No name) ")])
                      : _c("span", [
                          _vm._v(
                            _vm._s(row.item.firstname) +
                              " " +
                              _vm._s(row.item.lastname)
                          )
                        ])
                  ]
                ),
                row.item.doctorname
                  ? _c("p", { staticClass: "text-small tw-mb-0" }, [
                      _vm._v(" Dr. " + _vm._s(row.item.doctorname) + " ")
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(email)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-overflow-hidden tw-break-words tw-max-w-[300px]"
                  },
                  [_vm._v(" " + _vm._s(row.item.email) + " ")]
                )
              ]
            }
          },
          {
            key: "cell(birthday)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.formatBirthdayForDisplay(row.item.birthday)) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(phoneno)",
            fn: function(row) {
              return [
                _c("a", { attrs: { href: "tel:" + row.item.phoneno } }, [
                  _vm._v(_vm._s(row.item.phoneno))
                ])
              ]
            }
          },
          {
            key: "cell(dateonboarded)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.getDays(row.item.dateonboarded, "stage")) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(dateonboarded2)",
            fn: function(row) {
              return [
                _vm._v(" " + _vm._s(_vm.getDate(row.item.dateonboarded)) + " ")
              ]
            }
          },
          {
            key: "cell(datetimelastaction)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.getDays(row.item.datetimelastaction, "action")) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(upload)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.openUploadDialog(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "upload" }
                    }),
                    _vm._v(" Upload ")
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.openActionDialog(row.item)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "pencil" }
                      }),
                      _vm._v(" Action ")
                    ],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "cell(history)",
            fn: function(row) {
              return [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.openHistoryDialog(row.item)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "newspaper" }
                      }),
                      _vm._v(" History ")
                    ],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "cell(failedtransactionreason)",
            fn: function(row) {
              return [
                row.item.failedtransactionreason
                  ? _c("span", [
                      _c(
                        "b",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.html",
                              value: {
                                title:
                                  "\n              <p class='mb-0'>\n                <strong>" +
                                  row.item.failedtransactiondesc +
                                  "</strong>\n              </p>\n              <small style='font-size: 11px'>" +
                                  row.item.failedtransactionnextstep +
                                  "</small>\n            ",
                                customClass: "failed-transaction-reason"
                              },
                              expression:
                                "{\n            title: `\n              <p class='mb-0'>\n                <strong>${row.item.failedtransactiondesc}</strong>\n              </p>\n              <small style='font-size: 11px'>${row.item.failedtransactionnextstep}</small>\n            `,\n            customClass: 'failed-transaction-reason',\n          }",
                              modifiers: { hover: true, html: true }
                            }
                          ],
                          staticClass: "text-primary"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(row.item.failedtransactionreason) + " "
                          )
                        ]
                      )
                    ])
                  : _c("span", [_vm._v("N/A")])
              ]
            }
          },
          {
            key: "cell(failedtransactiondatetime)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDays(row.item.failedtransactiondatetime, "stage")
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(patientinfo)",
            fn: function(row) {
              return [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "link",
                      "toggle-class": "text-decoration-none",
                      "no-caret": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function() {
                            return [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/info.svg")
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("b-dropdown-item", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              return _vm.openPasswordResetDialog(row.item)
                            }
                          }
                        },
                        [_vm._v(" Reset Password ")]
                      )
                    ]),
                    _c("b-dropdown-item", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.openResetEmail(row.item)
                            }
                          }
                        },
                        [_vm._v("Reset Email")]
                      )
                    ]),
                    _c(
                      "b-dropdown-item",
                      [
                        _c(
                          "b-link",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.openHSADialog(row.item)
                              }
                            }
                          },
                          [_vm._v(" Download HSA Receipt ")]
                        )
                      ],
                      1
                    ),
                    _c("b-dropdown-item", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.openResetIntakeDialog(row.item)
                            }
                          }
                        },
                        [_vm._v(" Restart Intake ")]
                      )
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(links)",
            fn: function(row) {
              return [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: { click: row.toggleDetails }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "17",
                            height: "17",
                            viewBox: "0 0 17 17",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("circle", {
                            attrs: {
                              cx: "8.47656",
                              cy: "8.31055",
                              r: "7.4",
                              stroke: "currentColor",
                              "stroke-width": "1.2"
                            }
                          }),
                          _c("circle", {
                            attrs: {
                              cx: "8.47656",
                              cy: "5.31055",
                              r: "1",
                              fill: "currentColor"
                            }
                          }),
                          _c("circle", {
                            attrs: {
                              cx: "8.47656",
                              cy: "11.3105",
                              r: "1",
                              fill: "currentColor"
                            }
                          }),
                          _c("circle", {
                            attrs: {
                              cx: "8.47656",
                              cy: "8.31055",
                              r: "1",
                              fill: "currentColor"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ])
              ]
            }
          },
          {
            key: "cell(timesContactedFailedTransact)",
            fn: function(row) {
              return [
                row.item.timesContactedFailedTransact
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(row.item.timesContactedFailedTransact) +
                          " "
                      )
                    ])
                  : _c("span", [_vm._v("0")])
              ]
            }
          },
          {
            key: "cell(retry)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openRetryDialog(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "credit-card" }
                    }),
                    _vm._v(" Retry ")
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(cancel)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openCancelDialog(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "x-square" }
                    }),
                    _vm._v(" Cancel ")
                  ],
                  1
                ),
                _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("|")]),
                _c(
                  "a",
                  {
                    staticClass: "ml-2",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openDismissDialog(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "dash-circle" }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(needspatientreplydatetime)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDays(row.item.needspatientreplydatetime, "stage")
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(timesContacted)",
            fn: function(row) {
              return [
                row.item.timesContacted
                  ? _c("span", [
                      _vm._v(" " + _vm._s(row.item.timesContacted) + " ")
                    ])
                  : _c("span", [_vm._v("0")])
              ]
            }
          },
          {
            key: "cell(timesContactedFollowupComplete)",
            fn: function(row) {
              return [
                row.item.timesContactedFollowupComplete
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(row.item.timesContactedFollowupComplete) +
                          " "
                      )
                    ])
                  : _c("span", [_vm._v("0")])
              ]
            }
          },
          {
            key: "cell(timesContactedFollowupStart)",
            fn: function(row) {
              return [
                row.item.timesContactedFollowupStart
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(row.item.timesContactedFollowupStart) + " "
                      )
                    ])
                  : _c("span", [_vm._v("0")])
              ]
            }
          },
          {
            key: "cell(dismiss)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    staticClass: "ml-2",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openDismissDialog(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "dash-circle" }
                    }),
                    _vm._v(" Dismiss ")
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(followupdatetime)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.getDays(row.item.followupdatetime, "stage")) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(signupdate)",
            fn: function(row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.getDays(row.item.signupdate, "stage")) + " "
                )
              ]
            }
          },
          {
            key: "cell(status)",
            fn: function(row) {
              return [
                row.item.status == "Identity Denied"
                  ? _c(
                      "a",
                      {
                        staticStyle: { color: "#ea4e00" },
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.openUploadDialog(row.item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.item.status) + " ")]
                    )
                  : row.item.status == "Identity Incomplete"
                  ? _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.openUploadDialog(row.item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(row.item.status) + " ")]
                    )
                  : _c("span", [_vm._v(_vm._s(row.item.status))])
              ]
            }
          },
          {
            key: "cell(datetimestamp)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.getDays(row.item.datetimestamp, "stage")) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(fullycancelleddatetime)",
            fn: function(row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDays(row.item.fullycancelleddatetime, "stage")
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(request)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openRequestList(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "envelope-open" }
                    })
                  ],
                  1
                ),
                _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("|")]),
                _c(
                  "a",
                  {
                    staticClass: "ml-2",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openDismissDialog(row.item)
                      }
                    }
                  },
                  [_c("b-icon", { attrs: { icon: "dash-circle" } })],
                  1
                )
              ]
            }
          },
          {
            key: "cell(assign)",
            fn: function(row) {
              return [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.openAcceptDialog(row.item)
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "plus-circle" }
                      })
                    ],
                    1
                  ),
                  _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("|")]),
                  _c(
                    "a",
                    {
                      staticClass: "ml-2",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.openDismissDialog(row.item)
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "dash-circle" } })],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "cell(availabledoctor)",
            fn: function(row) {
              return [
                row.item.availabledoctor
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(row.item.availabledoctor.length) + " "
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(timestampcancelled)",
            fn: function(row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.getDate(row.item.timestampcancelled)) + " "
                )
              ]
            }
          },
          {
            key: "cell(view)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openRefundRequest(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "eye" }
                    })
                  ],
                  1
                ),
                _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("|")]),
                _c(
                  "a",
                  {
                    staticClass: "ml-2",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openDismissDialog(row.item)
                      }
                    }
                  },
                  [_c("b-icon", { attrs: { icon: "dash-circle" } })],
                  1
                )
              ]
            }
          },
          {
            key: "cell(refund)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openRefundConfirmation(row.item)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      [
                        _c("b-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "cash" }
                        }),
                        _vm._v(" Refund ")
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "cell(revert)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openRevertDialog(row.item)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "arrow-counterclockwise" }
                    }),
                    _vm._v(" Revert ")
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(bannedDate)",
            fn: function(row) {
              return [
                _vm._v(" " + _vm._s(_vm.getDate(row.item.bannedDate)) + " ")
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c("TableLinks", {
                  attrs: { row: row.item },
                  on: { "show-toast": _vm.showToast }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }