//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    pre: {
      type: String,
      default: ''
    },
    post: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput(event) {
      switch (this.type) {
        case 'number':
          {
            let value = event.target.value.replace(/[^0-9.]/g, '') // remove non-numeric characters
            .replace(/(\..*)\./g, '$1') // remove multiple dots
            .replace(/^0+(?=\.)|^(0+)(\d)/, '$2'); // remove leading zeroes

            if (value.charAt(0) === '.') {
              value = '0' + value;
            }

            event.target.value = value;
            this.$emit('input', value);
            break;
          }

        default:
          this.$emit('input', event.target.value);
          break;
      }
    }

  }
};