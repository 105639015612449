/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "my-3 mx-2",
      attrs: {
        id: "macro-dialog",
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-stacking": "",
        size: "lg"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
        },
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "div",
              { staticClass: "tw-flex tw-flex-1 tw-items-center" },
              [
                _c("div", { staticClass: "tw-px-3 tw-py-2" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 14.84 18.51",
                        width: "20",
                        height: "20",
                        fill: "#353148"
                      }
                    },
                    [
                      _c("path", {
                        staticClass: "cls-1",
                        attrs: {
                          d:
                            "M.19,6.01h14.64c0,1.63,0,3.23,0,4.82,0,2.06.02,4.13,0,6.19-.02,1.45-.75,1.86-1.99,1.14-1.32-.76-2.65-1.51-3.94-2.31-.89-.55-1.74-.64-2.67-.1-1.49.87-2.99,1.72-4.51,2.53-.89.48-1.68.13-1.69-.83-.04-3.76,0-7.51.01-11.27,0-.06.11-.12.16-.17Z"
                        }
                      }),
                      _c("path", {
                        staticClass: "cls-1",
                        attrs: {
                          d:
                            "M14.75,3.63H.08C-.13,1.4,1.01.04,3.13.02c2.85-.03,5.7-.03,8.55,0,2.13.02,3.27,1.36,3.08,3.61Z"
                        }
                      })
                    ]
                  )
                ]),
                _c("b-form-input", {
                  ref: "searchMacrosInput",
                  staticClass: "h2 tw-border-none tw-pt-0 tw-pl-3 tw-mt-1.5",
                  staticStyle: { "max-width": "100%", "padding-bottom": "0" },
                  attrs: { id: "search", placeholder: "Search Macros" },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tw-flex tw-items-center tw-px-3 tw-py-2" },
              [
                _c("b-icon", {
                  staticClass: "tw-cursor-pointer text-muted",
                  attrs: { icon: "gear", "font-scale": "1.75" },
                  on: { click: _vm.openEdit }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c("div", { staticClass: "tw-flex", staticStyle: { height: "90%" } }, [
        _c(
          "div",
          {
            staticClass:
              "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300 pt-3 tw-w-2/5",
            staticStyle: { height: "100%" }
          },
          [
            _vm.search && _vm.displayMacros.length == 0
              ? _c("div", { staticClass: "ml-4 mt-3" }, [
                  _vm._v(" No results found. ")
                ])
              : _vm._e(),
            _c(
              "perfect-scrollbar",
              {
                staticClass: "ps",
                staticStyle: { "max-height": "100%" },
                attrs: { options: _vm.scrollbarOptions }
              },
              [
                _vm.search.length == 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "span",
                          { staticClass: "text-muted text-small tw-px-4" },
                          [_vm._v("Frequently Used")]
                        ),
                        _vm._l(_vm.displayFrequentlyUsedMacros, function(
                          macro
                        ) {
                          return _c(
                            "div",
                            {
                              key: "f-" + macro.id,
                              staticClass:
                                "hover:tw-bg-[#f1f1f1] tw-cursor-pointer",
                              class: {
                                "text-bold": macro.id == _vm.activeItemID
                              },
                              on: {
                                mouseover: function($event) {
                                  return _vm.setActiveItem(macro.id)
                                },
                                click: _vm.sendToChat
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-truncate tw-px-4 tw-py-2.5"
                                },
                                [_vm._v(" " + _vm._s(macro.name) + " ")]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _vm.search.length == 0
                      ? _c(
                          "span",
                          { staticClass: "text-muted text-small tw-px-4" },
                          [_vm._v(" Others ")]
                        )
                      : _vm._e(),
                    _vm._l(_vm.displayMacros, function(macro) {
                      return _c(
                        "div",
                        {
                          key: macro.id,
                          staticClass:
                            "hover:tw-bg-[#f1f1f1] tw-cursor-pointer",
                          class: { "text-bold": macro.id == _vm.activeItemID },
                          on: {
                            mouseover: function($event) {
                              return _vm.setActiveItem(macro.id)
                            },
                            click: _vm.sendToChat
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-truncate tw-px-4 tw-py-2.5" },
                            [_vm._v(" " + _vm._s(macro.name) + " ")]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-w-3/5 tw-px-4", staticStyle: { height: "100%" } },
          [
            _c(
              "perfect-scrollbar",
              {
                staticClass: "ps tw-flex tw-flex-col",
                staticStyle: { "max-height": "100%" },
                attrs: { options: _vm.scrollbarOptions }
              },
              [
                _c("h1", { staticClass: "mt-4" }, [
                  _vm._v(" " + _vm._s(_vm.selectedMacro.name) + " ")
                ]),
                _c("div", { staticClass: "tw-flex-1 tw-whitespace-pre-wrap" }, [
                  _vm._v(" " + _vm._s(_vm.selectedMacro.content) + " ")
                ])
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }