import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class AdminsService extends BaseService {
  static async getAdmins() {
    try {
      let response = await this.request({ auth: true }).get(`/admin`);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async createNewAdmin(data) {
    try {
      let response = await this.request({ auth: true }).post(`/admin`, data);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateAdminDetails(adminid, data) {
    try {
      let response = await this.request({ auth: true }).put(
        `/admin/${adminid}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async reportIssue(patientid, data) {
    try {
      let response = await this.request({ auth: true }).post(
        `/admin/patient/${patientid}/report`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
