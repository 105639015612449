/* unplugin-vue-components disabled */import __unplugin_components_3 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatProfileDisplay.vue';
import __unplugin_components_2 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatMessagesColumn.vue';
import __unplugin_components_1 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatMessageListColumn.vue';
import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatCategoryColumn.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-flex tw-h-screen" },
    [
      _c(__unplugin_components_0, {
        staticClass: "tw-w-[200px] tw-bg-slate-100 tw-p-6",
        attrs: { category: _vm.selectedCategory },
        on: {
          "update:category": function($event) {
            _vm.selectedCategory = $event
          },
          "category-update": _vm.onCategoryUpdate
        }
      }),
      _c(__unplugin_components_1, {
        ref: "chatMessageListColumn",
        staticClass:
          "tw-w-[280px] tw-border-x tw-border-y-0 tw-border-solid tw-border-slate-300",
        attrs: {
          category: _vm.selectedCategory,
          "is-processing": _vm.isProcessing
        },
        on: { select: _vm.onConversationSelect }
      }),
      _vm.selectedConversation
        ? _c(__unplugin_components_2, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedConversation,
                expression: "selectedConversation"
              }
            ],
            key: _vm.chatMessageColumnKey,
            staticClass: "tw-flex-1",
            attrs: {
              conversation: _vm.selectedConversation,
              "is-messages-loaded": _vm.isMessagesLoaded,
              socket: _vm.socket,
              category: _vm.selectedCategory,
              "online-status-response": _vm.onlineStatusResponse
            }
          })
        : _vm._e(),
      !_vm.selectedConversation
        ? _c(
            "div",
            {
              staticClass:
                "tw-flex-1 tw-flex tw-justify-center tw-items-center tw-text-xl tw-text-gray-500 tw-col-span-7"
            },
            [_vm._v(" No chats selected ")]
          )
        : _vm._e(),
      _c(__unplugin_components_3, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showProfile,
            expression: "showProfile"
          }
        ],
        staticClass:
          "tw-w-[260px] tw-border-x tw-border-y-0 tw-border-solid tw-border-slate-300"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }