//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { isValidEmail } from '@/utils/string';
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    admin: {
      type: Object || null,
      default: null
    },
    isTransfer: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showDialog: false,
      showConfirmationDialog: false,
      alert: {
        show: false,
        message: ''
      },
      roleOptions: [{
        label: 'Admin',
        code: 'ADMIN'
      }, {
        label: 'Super Admin',
        code: 'SUPER ADMIN'
      }],
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      role: '',
      isProcessing: false,
      selectedAdmin: ''
    };
  },

  computed: {
    capitalizedRole() {
      if (this.role) {
        const role = this.roleOptions.find(option => option.code === this.role);
        return role.label;
      } else {
        return '';
      }
    },

    filteredAdmins() {
      return this.adminList.filter(admin => admin.roletype === 'ADMIN').map(admin => ({
        label: `${admin.firstname} ${admin.lastname} (${admin.email})`,
        code: admin.id
      }));
    },

    ...mapState('admins', ['adminList'])
  },
  watch: {
    admin(val) {
      if (val) {
        var _val$phoneno;

        this.firstname = val.firstname;
        this.lastname = val.lastname;
        this.email = val.email;
        this.phone = val === null || val === void 0 ? void 0 : (_val$phoneno = val.phoneno) === null || _val$phoneno === void 0 ? void 0 : _val$phoneno.substring(2);
        this.role = val.roletype;
      }
    }

  },
  methods: {
    async onSubmit() {
      try {
        if (this.admin) {
          if (this.admin.roletype !== this.role) {
            this.showConfirmationDialog = true;
          } else {
            await this.$store.dispatch('admins/updateAdminDetails', {
              adminid: this.admin.id,
              payload: {
                firstname: this.firstname,
                lastname: this.lastname,
                phoneno: this.phone,
                roletype: this.role
              }
            });
            this.showDialog = false;
            this.isProcessing = false;
            this.resetForm();
          }
        } else {
          if (!isValidEmail(this.email)) {
            this.alert = {
              show: true,
              message: 'Invalid email address'
            };
            return;
          }

          await this.$store.dispatch('admins/createNewAdmin', {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            phoneno: this.phone,
            roletype: this.role
          });
          this.showDialog = false;
          this.showConfirmationDialog = false;
          this.isProcessing = false;
          this.resetForm();
        }
      } catch (e) {
        this.alert = {
          show: true,
          message: e.message
        };
      }
    },

    onSubmitTransfer() {
      this.showConfirmationDialog = true;
    },

    async onConfirmationSubmit() {
      try {
        await this.$store.dispatch('admins/updateAdminDetails', {
          adminid: this.admin.id,
          payload: {
            firstname: this.firstname,
            lastname: this.lastname,
            phoneno: this.phone,
            roletype: this.role
          }
        });
        this.showDialog = false;
        this.showConfirmationDialog = false;
        this.isProcessing = false;
        this.resetForm();
      } catch (e) {
        this.alert = {
          show: true,
          message: e.message
        };
      }
    },

    async onConfirmationTransfer() {
      try {
        const chosenAdmin = this.adminList.find(admin => admin.id === this.selectedAdmin);

        if (chosenAdmin) {
          var _chosenAdmin$phoneno;

          await this.$store.dispatch('admins/updateAdminDetails', {
            adminid: this.selectedAdmin,
            payload: {
              firstname: chosenAdmin.firstname,
              lastname: chosenAdmin.lastname,
              phoneno: (_chosenAdmin$phoneno = chosenAdmin.phoneno) === null || _chosenAdmin$phoneno === void 0 ? void 0 : _chosenAdmin$phoneno.substring(2),
              roletype: 'SUPER ADMIN'
            }
          });
          await this.$store.dispatch('admins/updateAdminDetails', {
            adminid: this.admin.id,
            payload: {
              firstname: this.firstname,
              lastname: this.lastname,
              phoneno: this.phone,
              roletype: 'ADMIN'
            }
          });
          this.showDialog = false;
          this.showConfirmationDialog = false;
          this.isProcessing = false;
          this.resetForm();
          this.$store.dispatch('auth/resetState');
          window.location.href = '/';
        }
      } catch (e) {
        this.alert = {
          show: true,
          message: e.message
        };
      }
    },

    resetForm() {
      this.firstname = '';
      this.lastname = '';
      this.email = '';
      this.role = '';
      this.phone = '';
      this.alert = {
        show: false,
        message: ''
      };
    },

    onHide() {
      this.resetForm();
      this.$emit('hide');
    }

  }
};