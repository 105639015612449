/* unplugin-vue-components disabled */import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconLoading.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-relative tw-overflow-hidden" }, [
    _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "tw-absolute tw-top-[50%] tw-left-[50%] -tw-translate-y-1/2 -tw-translate-x-1/2 tw-size-full tw-bg-black/50 tw-flex tw-justify-center tw-items-center tw-text-white"
          },
          [_c(__unplugin_components_0)],
          1
        )
      : _vm._e(),
    _c(
      "img",
      _vm._b(
        {
          staticClass: "tw-size-full",
          class: [_vm.objectFitClass],
          attrs: { src: _vm.srcOrFallback, alt: _vm.alt }
        },
        "img",
        _vm.$attrs,
        false
      )
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }