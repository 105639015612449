//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { ActionService } from '@/services/action.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }

    },
    customId: {
      type: String,
      default: ''
    },
    actionId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      actionText: '',
      buttonDisabled: false,
      selectedPurpose: '',
      purposeList: ['ID/Photo Upload', 'Failed Payment', 'Follow Up', 'Needs Reply', 'Verify Address', 'Onboarding', 'Other', 'Refund'],
      selectedAction: 'Phone Call',
      actionList: ['Phone Call', 'Other', 'SMS', 'Email'],
      selectedExtras: '',
      resultList: ['Left VM', 'Spoke to Patient', 'Mailbox Full']
    };
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.actionText = '';
        this.selectedPurpose = '';
        this.selectedAction = 'Phone Call';
        this.selectedExtras = '';
      } else {
        if (this.actionId === 'verificationrequired') {
          this.selectedPurpose = 'ID/Photo Upload';
        } else if (this.actionId === 'failedtransaction') {
          this.selectedPurpose = 'Failed Payment';
        } else if (this.actionId === 'followupstart' || this.actionId === 'followupcompletion') {
          this.selectedPurpose = 'Follow Up';
        } else if (this.actionId === 'needspatientreply') {
          this.selectedPurpose = 'Needs Reply';
        } else if (this.actionId === 'failedaddress') {
          this.selectedPurpose = 'Verify Address';
        } else if (this.actionId === 'onboarding') {
          this.selectedPurpose = 'Onboarding';
        } else {
          this.selectedPurpose = 'Other';
        }
      }
    }

  },
  computed: {
    currentDate() {
      return new Date().toDateString();
    },

    ...mapState('auth', ['currentUser'])
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },

    async save() {
      let actionId = this.actionId == 'all' ? 'all' : this.actionId;
      const body = {
        actionid: actionId,
        notes: this.actionText,
        purpose: this.selectedPurpose,
        action: this.selectedAction,
        ...(this.selectedExtras && {
          extras: [this.selectedExtras]
        })
      };
      const id = this.customId ? this.customId : this.rowData.id;

      try {
        const {
          data
        } = await ActionService.addActionNote(id, body);

        if (data) {
          this.$emit('show-toast', 'Note added successfully!', 'success');
          this.$emit('update-time', this.rowData.id);
          this.toggleDialog();
        }
      } catch (error) {
        console.log(error);
        this.$emit('show-toast', error);
      }
    }

  }
};