export function getElementHeight (target) {
  const style = window.getComputedStyle(target)
  const marginTop = parseInt(style.marginTop)
  const marginBottom = parseInt(style.marginBottom)

  const isSvg = target instanceof SVGElement

  const height = isSvg ? target.getBBox().height : target.offsetHeight

  return marginTop + marginBottom + height
}
