/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": ""
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4 pr-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "text-primary h3" }, [
                    _vm._v(" Reassign Patients ")
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("span", { staticClass: "text-small text-primary" }, [
                        _vm._v("Original Doctor")
                      ]),
                      _c("br"),
                      !_vm.doctorData.firstname
                        ? _c("b-skeleton", { attrs: { width: "60%" } })
                        : _c("span", [
                            _vm._v(
                              " Dr. " +
                                _vm._s(_vm.doctorData.firstname) +
                                " " +
                                _vm._s(_vm.doctorData.lastname) +
                                " "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("span", { staticClass: "text-small text-primary" }, [
                        _vm._v("State")
                      ]),
                      _c("br"),
                      !_vm.doctorData.coveredstate
                        ? _c("b-skeleton", { attrs: { width: "70%" } })
                        : _c("span", [
                            _vm._v(" " + _vm._s(_vm.getStateNames()) + " ")
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("span", { staticClass: "text-small text-primary" }, [
                        _vm._v("Reassignment Type")
                      ]),
                      !_vm.doctorData
                        ? _c("b-skeleton", { attrs: { width: "30%" } })
                        : _c("b-form-select", {
                            attrs: { options: _vm.reassignmentOptions },
                            model: {
                              value: _vm.reassignmentType,
                              callback: function($$v) {
                                _vm.reassignmentType = $$v
                              },
                              expression: "reassignmentType"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("span", { staticClass: "text-small text-primary" }, [
                        _vm._v("Out of Office")
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "text-small text-muted" }, [
                        _vm._v("From")
                      ]),
                      _c("br"),
                      !_vm.doctorData.startdatestamp
                        ? _c("b-skeleton", { attrs: { width: "60%" } })
                        : _c(
                            "div",
                            [
                              _c("b-form-datepicker", {
                                model: {
                                  value: _vm.outFrom,
                                  callback: function($$v) {
                                    _vm.outFrom = $$v
                                  },
                                  expression: "outFrom"
                                }
                              }),
                              _c("b-form-timepicker", {
                                attrs: { "minutes-step": "15" },
                                model: {
                                  value: _vm.outFromTime,
                                  callback: function($$v) {
                                    _vm.outFromTime = $$v
                                  },
                                  expression: "outFromTime"
                                }
                              })
                            ],
                            1
                          ),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "text-small text-muted mt-2" },
                        [_vm._v("To")]
                      ),
                      _c("br"),
                      !_vm.doctorData.startdatestamp
                        ? _c("b-skeleton", { attrs: { width: "60%" } })
                        : _c(
                            "div",
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  disabled: _vm.reassignmentType == "PERMANENT"
                                },
                                model: {
                                  value: _vm.outTo,
                                  callback: function($$v) {
                                    _vm.outTo = $$v
                                  },
                                  expression: "outTo"
                                }
                              }),
                              _c("b-form-timepicker", {
                                attrs: {
                                  disabled: _vm.reassignmentType == "PERMANENT",
                                  "minutes-step": "15"
                                },
                                model: {
                                  value: _vm.outToTime,
                                  callback: function($$v) {
                                    _vm.outToTime = $$v
                                  },
                                  expression: "outToTime"
                                }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "text-muted text-small",
                          attrs: { value: true, "unchecked-value": false },
                          model: {
                            value: _vm.selectedAll,
                            callback: function($$v) {
                              _vm.selectedAll = $$v
                            },
                            expression: "selectedAll"
                          }
                        },
                        [_vm._v(" Doctor ")]
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "text-muted text-small" }, [
                    _vm._v(" State ")
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticStyle: { "max-height": "320px", overflow: "auto" }
                    },
                    _vm._l(_vm.doctorsByState, function(doctor, index) {
                      return _c(
                        "b-row",
                        { key: index },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    value: doctor.id,
                                    "unchecked-value": null
                                  },
                                  model: {
                                    value: _vm.selectedDoctors[index],
                                    callback: function($$v) {
                                      _vm.$set(_vm.selectedDoctors, index, $$v)
                                    },
                                    expression: "selectedDoctors[index]"
                                  }
                                },
                                [
                                  _vm._v(
                                    " Dr. " +
                                      _vm._s(doctor.firstname) +
                                      " " +
                                      _vm._s(doctor.lastname) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("b-col", [_vm._v(_vm._s(_vm.getStates(doctor)))]),
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c("hr", {
                              staticStyle: {
                                "margin-top": "8px",
                                "margin-bottom": "8px"
                              }
                            })
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "4" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "secondary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: { click: _vm.updateReassignment }
                        },
                        [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-dark",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }