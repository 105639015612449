//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
import moment from 'moment';
import { DoctorService } from '@/services/doctor.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    },
    mode: {
      type: String,
      default: 'edit'
    }
  },

  data() {
    return {
      showDialog: false,
      doctorData: {},
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      selectedAll: false,
      doctorsByState: [],
      selectedDoctors: [],
      reassignmentOptions: [{
        text: 'Temporary',
        value: 'TEMPORARY'
      }, {
        text: 'Permanent',
        value: 'PERMANENT'
      }],
      reassignmentType: '',
      outFrom: '',
      outFromTime: '',
      outTo: '',
      outToTime: ''
    };
  },

  computed: {
    submitButtonText() {
      return this.mode == 'approve' ? 'Approve' : 'Confirm';
    }

  },
  watch: {
    async showDialog(show) {
      if (show) {
        const {
          data
        } = await DoctorService.getReassignment(this.rowData.id);
        this.doctorData = data;
        this.reassignmentType = this.doctorData.assignmenttype; // Get Date and Time

        this.outFrom = moment(this.doctorData.startdatestamp * 1000).format('YYYY-MM-DD');
        this.outFromTime = moment(this.doctorData.startdatestamp * 1000).format('HH:mm:ss');
        this.outTo = moment(this.doctorData.enddatestamp * 1000).format('YYYY-MM-DD');
        this.outToTime = moment(this.doctorData.enddatestamp * 1000).format('HH:mm:ss'); // Get list of doctors to choose from

        for (const state of this.doctorData.coveredstate) {
          const {
            data
          } = await DoctorService.getDoctorsByState(state.state);
          this.doctorsByState = [...this.doctorsByState, ...data];
        }

        await this.$nextTick(); // Remove the currently selected doctor

        const docToRemove = this.doctorData.doctorid;
        this.doctorsByState = this.doctorsByState.filter(function (val) {
          return val.id !== docToRemove;
        }); // Search the list of doctors for the ones selected, then select them

        for (const index in this.doctorsByState) {
          if (this.doctorData.doctorlistdetails.find(o => o.doctorid == this.doctorsByState[index].id)) {
            this.selectedDoctors[index] = this.doctorsByState[index].id;
          }
        }

        await this.$nextTick();
      } else {
        this.alert.show = false;
        this.doctorData = {};
        this.doctorsByState = [];
        this.selectedDoctors = [];
        this.selectedAll = false;
        this.reassignmentType = '';
        this.outFrom = '';
        this.outTo = '';
        this.outFromTime = '';
        this.outToTime = '';
      }
    },

    selectedAll(selected) {
      if (selected) {
        for (let i = 0; i < this.doctorsByState.length; i++) {
          this.selectedDoctors[i] = this.doctorsByState[i].id;
        }
      } else {
        this.selectedDoctors = [];
      }
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },

    getSecondsFromTime(time) {
      const timeArray = time.split(':');
      const hours = timeArray[0];
      const minutes = timeArray[1];
      const secondsInHours = Number(hours) * 60 * 60;
      const secondsInMinutes = Number(minutes) * 60;
      return secondsInHours + secondsInMinutes;
    },

    getDate(date) {
      if (!date) {
        return 'Indefinite';
      } else {
        return moment(date * 1000).format('MM/DD/YYYY LT');
      }
    },

    getStates(doctor) {
      let str = '';

      for (const state of doctor.coveredstate) {
        str += `${state.state}, `;
      }

      return str.substr(0, str.length - 2);
    },

    getStateNames() {
      let str = '';

      if (!this.doctorData.coveredstate) {
        return '';
      } else {
        for (const state of this.doctorData.coveredstate) {
          str += `${state.statename}, `;
        }

        return str.substr(0, str.length - 2);
      }
    },

    getTotalPatients() {
      let total = 0;

      if (!this.doctorData.coveredstate) {
        return '';
      } else {
        for (const state of this.doctorData.coveredstate) {
          total += state.limit;
        }

        return total;
      }
    },

    async updateReassignment() {
      try {
        this.alert.show = false;
        this.buttonDisabled = true; // Sort doctors by selected

        const doctorlist = this.selectedDoctors.filter(val => {
          return val != null;
        });

        if (!doctorlist || doctorlist.length == 0) {
          this.showAlert('Please select at least one doctor to reassign to.');
          this.buttonDisabled = false;
          return;
        }

        const outFrom = Math.round(moment(this.outFrom).format('X')) + this.getSecondsFromTime(this.outFromTime);
        const outTo = Math.round(moment(this.outTo).format('X')) + this.getSecondsFromTime(this.outToTime);
        const body = {
          startdatestamp: outFrom,
          doctorid: this.doctorData.doctorid,
          doctorlist: doctorlist,
          assignmenttype: this.reassignmentType
        };

        if (outTo) {
          body.enddatestamp = outTo;
        }

        const {
          data
        } = await DoctorService.updateReassignment(this.rowData.id, body);

        if (data) {
          if (this.mode == 'approve') {
            await this.approveReassignment(this.rowData.id);
          }

          const doctorDataClone = _.cloneDeep(this.doctorData);

          Object.assign(doctorDataClone, data);
          this.$emit('refresh');
          this.buttonDisabled = false;
          this.showDialog = false;
        }
      } catch (err) {
        this.buttonDisabled = false;
        this.showAlert(err);
      }
    },

    async approveReassignment(id) {
      await DoctorService.approveReassignment(id);
      this.$emit('refresh');
    }

  }
};