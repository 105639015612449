//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { InventoryService } from '@/services/inventory.service';
import AddInventoryRestockDialog from '@/components/inventory/AddInventoryRestockDialog';
import EditInventoryRestockDialog from '@/components/inventory/EditInventoryRestockDialog';
import DeleteInventoryRestockDialog from '@/components/inventory/DeleteInventoryRestockDialog';
export default {
  components: {
    AddInventoryRestockDialog,
    EditInventoryRestockDialog,
    DeleteInventoryRestockDialog
  },

  data() {
    return {
      headers: [{
        label: 'Label',
        key: 'label'
      }, {
        label: 'Amount',
        key: 'amount'
      }, {
        label: 'Inventory Item',
        key: 'itemName'
      }, {
        label: 'Pharmacy',
        key: 'pharmacyName'
      }, {
        label: 'user',
        key: 'userName'
      }, {
        label: 'date',
        key: 'date'
      }, {
        label: 'Actions',
        key: 'edit'
      } // { label: '', key: 'delete' },
      ],
      count: null,
      currentPage: 1,
      selectedRow: null,
      items: []
    };
  },

  async mounted() {
    const {
      data
    } = await InventoryService.getInventoryRestocks(1);
    this.items = data.items;
    this.count = Number(data.totalCount);
  },

  methods: {
    getDate(date) {
      return new moment(date * 1000).format('MM/DD/YYYY');
    },

    openDialog(refname, rowData = null) {
      this.$refs[refname].showDialog = true;

      if (rowData) {
        console.log(refname);
        this.$refs[refname].rowData = rowData;
      }
    },

    async refresh() {
      const {
        data
      } = await InventoryService.getInventoryRestocks(this.currentPage);
      this.items = data.items;
      this.count = Number(data.totalCount);
    },

    async handlePagination(event, page) {
      this.items = [];
      const {
        data
      } = await InventoryService.getInventoryRestocks(page);
      this.items = data.items;
      this.count = Number(data.totalCount);
      this.currentPage = page;
    }

  }
};