export function getTextWidth (text, fontSize = 12, fontFamily = "Atten New") {
  const el = document.createElement("span");

  el.innerText = text;

  el.style.fontSize = `${fontSize}px`;
  el.style.visibility = "hidden";
  el.style.fontFamily = fontFamily;
  el.style.position = "absolute"; // Ensure the element doesn't affect the layout

  document.body.appendChild(el);

  const width = el.offsetWidth;

  document.body.removeChild(el);

  return width;
}