//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { ActionService } from '@/services/action.service';
import { PatientService } from '@/services/patient.service';
import { mapGetters } from 'vuex';
import TableLinks from '@/components/tables/partials/TableLinks';
import UploadDialog from '@/components/patients/UploadDialog';
import ActionDialog from '@/components/actions/ActionDialog';
import ActionHistory from '@/components/actions/ActionHistory';
import ResetPasswordDialog from '@/components/patients/ResetPasswordDialog';
import ResetEmailDialog from '@/components/patients/ResetEmailDialog';
import HSAReceiptDialog from '@/components/patients/HSAReceiptDialog';
import ResetIntakeDialog from '@/components/patients/ResetIntakeDialog';
import RetryPaymentDialog from '@/components/patients/RetryPaymentDialog';
import CancelPatientDialog from '@/components/patients/CancelPatientDialog';
import DismissDialog from '@/components/patients/DismissDialog';
import DoctorRequestListDialog from '@/components/patients/DoctorRequestListDialog';
import DoctorRequestDialog from '@/components/patients/DoctorRequestDialog';
import AssignPatientDialog from '@/components/patients/AssignPatientDialog';
import RefundRequestDialog from '@/components/patients/RefundRequestDialog';
import RefundConfirmationDialog from '@/components/patients/RefundConfirmationDialog';
import RevertRemovalDialog from '@/components/patients/RevertRemovalDialog';
import { patientViewMixin } from '@/mixins/patientView';
export default {
  components: {
    TableLinks,
    UploadDialog,
    ActionDialog,
    ActionHistory,
    ResetPasswordDialog,
    ResetEmailDialog,
    HSAReceiptDialog,
    ResetIntakeDialog,
    RetryPaymentDialog,
    CancelPatientDialog,
    DismissDialog,
    DoctorRequestListDialog,
    DoctorRequestDialog,
    AssignPatientDialog,
    RefundRequestDialog,
    RefundConfirmationDialog,
    RevertRemovalDialog
  },
  mixins: [patientViewMixin],
  props: ['type', 'headers', 'options'],

  data() {
    return {
      items: [],
      selectedRow: {},
      toast: {
        message: '',
        variant: ''
      },
      showEmptyResultsAlert: false,
      sortDesc: 'DESC',
      sortBy: 'lastupdateactivity'
    };
  },

  computed: { ...mapGetters({
      search: 'tables/getSearch',
      currentPage: 'tables/getCurrentPage',
      count: 'tables/getRowCount'
    })
  },
  watch: {
    async search(val) {
      if (val) {
        await this.searchPatients(val);
      }
    },

    currentPage(newVal) {
      this.getPatients(newVal, this.search, this.sortDesc, this.sortBy);
    }

  },

  async mounted() {
    var _this$options, _this$options2, _this$options3, _this$options4;

    await this.$_patientView_initHeights();
    this.sortDesc = ((_this$options = this.options) === null || _this$options === void 0 ? void 0 : _this$options.sortDesc) || 'DESC';
    this.sortBy = ((_this$options2 = this.options) === null || _this$options2 === void 0 ? void 0 : _this$options2.sortBy) || 'lastupdateactivity';
    this.getPatients(((_this$options3 = this.options) === null || _this$options3 === void 0 ? void 0 : _this$options3.page) || '', ((_this$options4 = this.options) === null || _this$options4 === void 0 ? void 0 : _this$options4.search) || '', this.sortDesc, this.sortBy);
  },

  methods: {
    async getPatients(page = '', search = '', sortDirection = 'DESC', sortField = 'lastupdateactivity') {
      this.items = [];
      const {
        data
      } = await PatientService.getPatients(this.type, page, search, sortDirection, sortField);
      this.items = data.items;
      this.$store.commit('tables/SET_ROW_COUNT', Number(data.count));
    },

    showToast(message, variant = 'error') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('alertToast');
    },

    formatBirthdayForDisplay(date) {
      if (date) {
        const dateArray = date.split('-');
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
      } else {
        return '(no data)';
      }
    },

    getDays(time, type) {
      const today = new Date();

      if (!time && type == 'action') {
        return 'No actions taken.';
      } else if (!time && type == 'stage') {
        return 'N/A';
      } else {
        const dayCount = Math.round((today.getTime() - time * 1000) / (1000 * 60 * 60 * 24));

        if (dayCount == 0) {
          return 'Today';
        } else if (dayCount == 1) {
          return '1 day';
        } else {
          return `${dayCount} days`;
        }
      }
    },

    async searchPatients(search) {
      this.showEmptyResultsAlert = false;
      this.$store.commit('tables/SET_ROW_COUNT', 0);
      this.$store.commit('tables/SET_CURRENT_PAGE', 1);
      await this.getPatients(this.currentPage, search, this.sortDesc, this.sortBy);

      if (this.count === 0) {
        this.showEmptyResultsAlert = true;
      }
    },

    sortChanged(e) {
      switch (e.sortBy) {
        case 'name':
          this.sortBy = 'firstname';
          break;

        default:
          this.sortBy = e.sortBy;
          break;
      }

      this.sortDesc = e.sortDesc ? 'DESC' : 'ASC';
      this.$store.commit('tables/SET_CURRENT_PAGE', 1);
      this.getPatients(this.currentPage, this.search, this.sortDesc, this.sortBy);
    },

    openUploadDialog(row) {
      this.selectedRow = row;
      this.$refs.UploadDialog.toggleDialog();
    },

    updatePhoto(userid, idphoto, personalphoto) {
      const rowItem = this.items.find(o => o.id == userid);

      if (idphoto) {
        rowItem.indentificationUrl = idphoto;
      }

      if (personalphoto) {
        rowItem.profileUrl = personalphoto;
      }
    },

    deleteItem(id) {
      this.items.splice(this.items.findIndex(o => o.id == id), 1);
      this.$emit('get-status-count');
    },

    openActionDialog(row) {
      this.selectedRow = row;
      this.$refs.ActionDialog.toggleDialog();
    },

    updateTime(id) {
      const today = new Date().getTime() / 1000;

      for (const itemIndex in this.items) {
        if (this.items[itemIndex].id == id) {
          this.items[itemIndex].datetimelastaction = today;
        }
      }
    },

    openHistoryDialog(row) {
      this.selectedRow = row;
      this.$refs.ActionHistory.toggleDialog();
    },

    openPasswordResetDialog(row) {
      this.selectedRow = row;
      this.$refs.ResetPasswordDialog.showDialog = true;
    },

    openResetEmail(row) {
      this.selectedRow = row;
      this.$refs.ResetEmailDialog.toggleDialog();
    },

    refreshEmail(id, email) {
      const rowItem = this.items.find(o => o.id == id);
      rowItem.email = email;
    },

    openHSADialog(row) {
      this.selectedRow = row;
      this.$refs.HSAReceiptDialog.showDialog = true;
    },

    openResetIntakeDialog(row) {
      this.selectedRow = row;
      this.$refs.ResetIntakeDialog.showDialog = true;
    },

    openRetryDialog(row) {
      this.selectedRow = row;
      this.$refs.RetryPaymentDialog.showDialog = true;
    },

    openCancelDialog(row) {
      this.selectedRow = row;
      this.$refs.CancelPatientDialog.showDialog = true;
    },

    openDismissDialog(row) {
      this.selectedRow = row;
      this.$refs.DismissDialog.showDialog = true;
    },

    async dequeueItem(id) {
      const {
        data
      } = await ActionService.dequeueItem(id, this.type);

      if (data) {
        this.deleteItem(id);
      }
    },

    async dequeueType(id, type) {
      const {
        data
      } = await ActionService.dequeueType(id, type);

      if (data) {
        this.items.splice(this.items.findIndex(o => o.orderid == id), 1);
      }
    },

    openRequestList(row) {
      this.selectedRow = row;
      this.$refs.DoctorRequestListDialog.showDialog = true;
    },

    openRequest(row) {
      this.selectedRow = row;
      this.$refs.DoctorRequestDialog.showDialog = true;
    },

    getDate(date) {
      return moment(date * 1000).format('MM/DD/YYYY LT');
    },

    openAcceptDialog(row) {
      this.selectedRow = row;
      this.$refs.AssignPatientDialog.showDialog = true;
    },

    openRefundRequest(row) {
      this.selectedRow = row;
      this.$refs.RefundRequestDialog.showDialog = true;
    },

    openRefundConfirmation(row) {
      this.selectedRow = row;
      this.$refs.RefundConfirmationDialog.showDialog = true;
    },

    openRevertDialog(row) {
      this.selectedRow = row;
      this.$refs.RevertRemovalDialog.showDialog = true;
    }

  }
};