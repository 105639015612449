/* unplugin-vue-components disabled */import __unplugin_components_3 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconRouted.vue';
import __unplugin_components_2 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/base/BaseTooltip.vue';
import __unplugin_components_1 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatBubbleAttachments.vue';
import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/routedMessages/RoutedMessagesBubbleArrow.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-w-full" }, [
    _c(
      "div",
      {
        staticClass: "tw-flex tw-items-start tw-gap-5 tw-w-full",
        class: [
          {
            "tw-flex-row-reverse": _vm.isMe
          }
        ]
      },
      [
        _c(
          "div",
          {
            staticClass:
              "tw-overflow-hidden tw-size-[30px] tw-rounded-[30px] tw-text-white tw-text-xs [ tw-flex tw-items-center tw-justify-center ]",
            class: [_vm.avatarDetails.class]
          },
          [_vm._v(" " + _vm._s(_vm.avatarDetails.text) + " ")]
        ),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-col tw-max-w-[60%] tw-relative" },
          [
            _c(__unplugin_components_0, {
              attrs: {
                member: _vm.member,
                "is-routed": !!_vm.message.messageroutedto,
                "for-admin-review": !!_vm.message.foradminreview
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "tw-px-[15px] tw-py-[10px] tw-rounded tw-whitespace-pre-line tw-mb-1",
                class: [_vm.bubbleBackgroundColorClass]
              },
              [
                _c(__unplugin_components_1, {
                  attrs: { message: _vm.message }
                }),
                _c("span", [_vm._v(" " + _vm._s(_vm.message.message) + " ")])
              ],
              1
            ),
            _c(
              "small",
              {
                staticClass:
                  "tw-text-[9px] tw-flex tw-gap-10 tw-justify-between tw-text-secondary-gray",
                class: {
                  "tw-text-right": _vm.isMe
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.friendlySentAt))]),
                _vm.message.messageroutedto
                  ? _c(__unplugin_components_2,
                      {
                        attrs: {
                          text: "This is a routed message",
                          placement: "bottom"
                        }
                      },
                      [_c(__unplugin_components_3, { staticClass: "tw-text-[12px]" })],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }