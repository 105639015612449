//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import $cookies from 'vue-cookies';
import jwt from 'jsonwebtoken';
export default {
  name: 'AdminDashboard',

  data() {
    return {
      hydrated: false
    };
  },

  computed: {
    formControl: function () {
      return this.$route.name == 'login';
    },
    logoColorSwitch: function () {
      return this.$route.name == 'signup' || this.$route.name == 'invitation-code';
    }
  },

  async mounted() {
    // await this.$apollo.provider.defaultClient.hydrated();
    this.hydrated = true;
    const logincookie = $cookies.get('accessToken');
    const token = jwt.decode(logincookie);

    if (logincookie && token['custom:role'] != 'ADMIN') {
      $cookies.remove('accessToken');
      this.$router.push('/login');
    }
  }

};