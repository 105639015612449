//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import { mapGetters } from 'vuex';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading'; // services

import { DoctorService } from '@/services/doctor.service';
import { AdminsService } from '@/services/admins.service'; // utils

import { parseHtml } from '@/utils/parseHtml';
export default {
  components: {
    InfiniteLoading
  },
  props: {
    isMessagesLoaded: {
      type: Boolean,
      default: false
    },
    isEventsHidden: {
      type: Boolean,
      default: false
    },
    isRecipientTyping: {
      type: Boolean,
      default: false
    },
    patientName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      doctors: [],
      admins: []
    };
  },

  computed: { ...mapGetters({
      messages: 'chat/getMessages',
      nextPage: 'chat/getNextPage',
      selectedConversation: 'chat/getSelectedConversation',
      showProfile: 'chat/getShowProfile'
    })
  },
  watch: {
    isMessagesLoaded() {
      this.scrollToBottom();
    },

    isRecipientTyping(val) {
      if (!val) {
        return;
      }

      this.scrollToBottom();
    }

  },

  async mounted() {
    const [{
      data: doctors
    }, {
      data: admins
    }] = await Promise.all([DoctorService.getAllDoctors(), AdminsService.getAdmins()]);
    this.doctors = doctors;
    this.admins = admins;
  },

  methods: {
    parseHtml,

    async scrollToBottom() {
      var _this$$refs$bottomOfP;

      await this.$nextTick();
      (_this$$refs$bottomOfP = this.$refs.bottomOfPage) === null || _this$$refs$bottomOfP === void 0 ? void 0 : _this$$refs$bottomOfP.scrollIntoView();
    },

    getAlignment(entry) {
      var _entry$data, _entry$data$patient, _entry$data2, _entry$data2$sender;

      if (entry.data.platform == 'INTERCOM' && entry.data.eventName == 'Outgoing' || entry.data.eventCategory == 'Notes' || entry.data.eventCategory == 'SMS' && entry.data.eventName == 'Outgoing' || (entry === null || entry === void 0 ? void 0 : entry.type) === 'MESSAGE' && (entry === null || entry === void 0 ? void 0 : (_entry$data = entry.data) === null || _entry$data === void 0 ? void 0 : (_entry$data$patient = _entry$data.patient) === null || _entry$data$patient === void 0 ? void 0 : _entry$data$patient.id) !== (entry === null || entry === void 0 ? void 0 : (_entry$data2 = entry.data) === null || _entry$data2 === void 0 ? void 0 : (_entry$data2$sender = _entry$data2.sender) === null || _entry$data2$sender === void 0 ? void 0 : _entry$data2$sender.id)) {
        return 'end';
      } else {
        return 'start';
      }
    },

    getStyle(entry) {
      if (entry.data.platform == 'INTERCOM' && entry.data.eventName == 'Outgoing' || entry.data.eventCategory == 'SMS' && entry.data.eventName == 'Outgoing') {
        return 'message-admin';
      } else if (entry.data.platform == 'INTERCOM' && entry.data.eventName == 'Incoming') {
        return 'message-patient';
      } else if (entry.data.eventCategory == 'Notes') {
        return 'message-notes';
      } else if (entry.type === 'MESSAGE') {
        var _entry$data3, _entry$data3$patient;

        return `${((_entry$data3 = entry.data) === null || _entry$data3 === void 0 ? void 0 : (_entry$data3$patient = _entry$data3.patient) === null || _entry$data3$patient === void 0 ? void 0 : _entry$data3$patient.id) === entry.data.sender.id ? 'message-patient patient' : 'message-admin admin'}`;
      } else {
        return 'message-event';
      }
    },

    getIcon(entry) {
      // Intercom override
      if (entry.data.eventCategory == 'Intercom') {
        var _this$patientName;

        return (_this$patientName = this.patientName) === null || _this$patientName === void 0 ? void 0 : _this$patientName.charAt(0);
      } else if (entry.data.eventCategory == 'Information Update' || entry.data.eventCategory == 'Admin Updated') {
        return '&#128221;';
      }

      switch (entry.data.eventName) {
        case 'Account Created':
          return '&#127874;';

        case 'Visit Started':
        case 'Questionnaire Completed':
        case 'Treatment Chosen':
        case 'Verification Started':
        case 'ID Uploaded':
        case 'Photo Uploaded':
        case 'Checkout Started':
          return '&#9989;';

        case 'ID Upload Skipped':
        case 'Photo Upload Skipped':
        case 'Identity Verification: Denied':
        case 'Follow Up Cancelled':
          return '&#9888;';

        case 'Checkout Complete':
        case 'Onboarding Completed':
          return '&#127881;';

        case 'Patient Accepted':
        case 'Identity Verification: Accepted':
          return '&#129351;';

        case 'Follow Up Started':
        case 'Follow Up Completed':
        case 'Follow Up Sent':
          return '&#128138;';

        case 'Dr Sent a Message':
          return '&#128104; &#9877;';

        case 'Patient Sent a Message':
        case 'Patient Messaged Doctor':
          return '&#128105;';

        case 'Photo Updated':
        case 'ID Updated':
          return '&#128247;';

        case 'Treatment Shipped':
          return '&#x1f4e6;';

        case 'Payment Cleared:':
          return '&#x1f4b5;';

        case 'Payment Failed':
          return '&#9940;';

        default:
          return '&#9889;';
      }
    },

    getDateTime(date) {
      return moment(date).format('LT MM/DD/YY');
    },

    getDate(date) {
      return moment(date).format('MM/DD/YY');
    },

    async messageScroll($state) {
      try {
        if (!this.loadingMessage && this.nextPage) {
          this.loadingMessage = true;
          const nextPageCursor = await this.$store.dispatch('chat/fetchMessages', {
            patientId: this.selectedConversation.patient.id,
            nextPage: this.nextPage
          });
          this.loadingMessage = false;

          if (nextPageCursor) {
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingMessage = false;
      }
    },

    getKeyName(key) {
      switch (key) {
        case 'firstname':
          return 'First Name';

        case 'lastname':
          return 'Last Name';

        case 'phoneno':
          return 'Phone Number';

        case 'address1':
          return 'Street Address';

        case 'address2':
          return 'Apartment/Suite';

        default:
          return key;
      }
    },

    formatInfoUpdateBirthday(input) {
      const date = new moment(input, 'YYYY-MM-DD');
      return date.format('MM-DD-YYYY');
    }

  }
};