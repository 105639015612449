/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        ref: "table",
        staticClass: "card-table",
        staticStyle: { "max-height": "calc(100vh - 25vh) !important" },
        attrs: {
          responsive: "",
          "select-mode": "single",
          fields: _vm.headers,
          items: _vm.items,
          "sticky-header": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }