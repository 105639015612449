//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { RequestService } from '@/services/request.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }

    }
  },

  data() {
    return {
      showDialog: false,
      fields: [{
        key: 'timestampcreated',
        text: 'Date'
      }, 'messagestatus', {
        key: 'doctornotes',
        text: 'Notes'
      }, 'view'],
      requestList: []
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        await this.refreshData();
      } else {
        this.requestList = [];
      }
    }

  },
  methods: {
    async refreshData() {
      await this.$nextTick();
      const {
        data
      } = await RequestService.listRequests(this.rowData.id);
      this.requestList = data;
    },

    getDate(date) {
      if (date == '-') {
        return '-';
      }

      return new Date(date * 1000).toDateString();
    }

  }
};