import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class RequestService extends BaseService {
  static async listRequests(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/forwardmessage`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getForwardedRequest(id, forwardedid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/forwardmessage/${forwardedid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async discardRequest(id, forwardedid, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/patient/${id}/forwardmessage/${forwardedid}/discard`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async completeRequest(id, forwardedid, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/patient/${id}/forwardmessage/${forwardedid}/complete`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async openForwardToChat(patientid, forwardedid) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${patientid}/forwardmessage/${forwardedid}/send`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
