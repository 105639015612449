/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        ref: "table",
        staticClass: "card-table",
        staticStyle: { "max-height": "calc(100vh - 25vh) !important" },
        attrs: {
          responsive: "",
          "select-mode": "single",
          fields: _vm.headers,
          items: _vm.items,
          "sticky-header": "",
          "show-empty": ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(date)",
            fn: function(row) {
              return [_vm._v(" " + _vm._s(_vm.getDate(row.item.date)) + " ")]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [_vm._v("No usage data found for the selected filters.")]
            },
            proxy: true
          }
        ])
      }),
      _vm.loading && this.items.length == 0
        ? _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", [_c("b-skeleton", { attrs: { width: "50%" } })], 1),
              _c(
                "b-col",
                { staticClass: "pl-3" },
                [_c("b-skeleton", { attrs: { width: "15%" } })],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pl-3" },
                [_c("b-skeleton", { attrs: { width: "15%" } })],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }