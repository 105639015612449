/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "tw-flex tw-w-full" }, [
        _c(
          "div",
          { staticClass: "card-status tw-flex tw-flex-1 tw-flex-col" },
          [
            _c(
              "div",
              { staticClass: "tw-flex" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "badge tw-py-1 tw-rounded-full tw-px-2 tw-mr-3",
                    class:
                      "status-" +
                      _vm.order["Status"].replace(/\s+/g, "-").toLowerCase()
                  },
                  [_vm._v(" " + _vm._s(_vm.order["Status"]) + " ")]
                ),
                _vm.order["Status"] === "Processing"
                  ? _c("v-icon", {
                      staticClass: "tw-mr-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        name: "forward",
                        fill: "#396B70",
                        align: "tw-align-baseline",
                        height: "tw-w-[21px]",
                        width: "tw-h-[21px]"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("rush")
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-col tw-flex-1 tw-text-right" },
          [
            _c("div", [
              _c(
                "a",
                {
                  staticClass:
                    "tw-uppercase tw-text-primary tw-tracking-[0.05em] hover:tw-text-primary tw-underline",
                  attrs: { href: _vm.order["Url"], target: "_blank" }
                },
                [_vm._v(" open in airtable ")]
              )
            ]),
            _c("div", { staticClass: "tw-pt-2" }, [
              _c("p", { staticClass: "tw-mb-0 tw-flex tw-justify-between" }, [
                _c("span", { staticClass: "text-muted tw-ml-7" }, [
                  _vm._v("Update in AT:")
                ]),
                _c("span", { staticClass: "text-black tw-ml-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parseDate(
                          _vm.order["Last Modified"],
                          "MM/dd/yy HH:mm:ss"
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _vm.viewAirtable
                ? _c(
                    "p",
                    { staticClass: "tw-mb-0 tw-flex tw-justify-between" },
                    [
                      _c("span", { staticClass: "text-muted tw-ml-7" }, [
                        _vm._v("Sent to AT:")
                      ]),
                      _c("span", { staticClass: "text-black tw-ml-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.parseDate(
                                _vm.order["Created"],
                                "MM/dd/yy HH:mm:ss"
                              )
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "tw-p-4 tw-pr-0" },
          [
            _c("b-icon", {
              staticClass: "card-icon tw-text-primary tw-cursor-pointer",
              attrs: {
                icon: _vm.viewAirtable ? "chevron-up" : "chevron-down",
                "font-scale": "1.4"
              },
              on: {
                click: function($event) {
                  _vm.viewAirtable = !_vm.viewAirtable
                }
              }
            })
          ],
          1
        )
      ]),
      _vm.viewAirtable
        ? [
            _c("div", { staticClass: "tw-flex tw-pt-4 tw-gap-x-4" }, [
              _c("div", { staticClass: "tw-flex-1 tw-flex tw-flex-col" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("Transaction Date:")
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parseDate(
                          _vm.order["Transaction Date (UTC)"],
                          "MM/dd/yy HH:mm:ss"
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "tw-flex-1 tw-flex tw-flex-col" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("Last Modified:")
                ]),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.parseDate(
                          _vm.order["Last Modified"],
                          "MM/dd/yy HH:mm:ss"
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "tw-flex tw-pt-4 tw-gap-x-4" }, [
              _c("div", { staticClass: "tw-flex-1 tw-flex tw-flex-col" }, [
                _c("span", { staticClass: "text-muted" }, [_vm._v("Product:")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.order["Name (from Order Item)"]))
                ])
              ]),
              _c("div", { staticClass: "tw-flex-1 tw-flex tw-flex-col" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("Quantity:")
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.order["Quantity to Deliver (from Order Item)"])
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "tw-flex tw-pt-4 tw-gap-x-4" }, [
              _c(
                "div",
                { staticClass: "tw-flex-1 tw-flex tw-flex-wrap tw-flex-col" },
                [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v("Address:")
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.order["Delivery Address"]))])
                ]
              ),
              _vm.order["Temp Address"]
                ? _c("div", { staticClass: "tw-flex-1 tw-flex tw-flex-col" }, [
                    _c("div", [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Temporary Address:")
                      ]),
                      _vm.order["Temp Address"]
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "tw-text-xs text-secondary tw-inline-block tw-ml-2 tw-uppercase tw-cursor-pointer",
                              on: { click: _vm.onAddressRemove }
                            },
                            [_vm._v(" Remove ")]
                          )
                        : _vm._e()
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.order["Temp Address"]))])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "tw-flex tw-pt-4 tw-gap-x-4" }, [
              _c(
                "div",
                { staticClass: "tw-flex-1 tw-flex tw-flex-wrap tw-flex-col" },
                [
                  _c("div", [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v("Special Shipping:")
                    ]),
                    _vm.order["Special Shipping"]
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "tw-text-xs text-secondary tw-inline-block tw-ml-2 tw-uppercase tw-cursor-pointer",
                            on: { click: _vm.onShippingRemove }
                          },
                          [_vm._v(" Remove ")]
                        )
                      : _vm._e()
                  ]),
                  _vm.order["Special Shipping"]
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.order["Special Shipping"]) + " "
                        )
                      ])
                    : _c("span", [_vm._v("None")])
                ]
              ),
              _vm.order["Address Change"]
                ? _c("div", { staticClass: "tw-flex-1 tw-flex tw-flex-col" }, [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v("Address Change:")
                    ]),
                    _c("span", { staticClass: "text-secondary" }, [
                      _vm._v(_vm._s(_vm.order["Address Change"]))
                    ])
                  ])
                : _vm._e()
            ]),
            _vm.order["TrackingNumber"]
              ? _c("div", { staticClass: "tw-flex tw-pt-4 tw-gap-x-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tw-flex-1 tw-flex tw-flex-wrap tw-flex-col"
                    },
                    [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Tracking Number:")
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.order["TrackingNumber"]))])
                    ]
                  )
                ])
              : _vm._e(),
            _vm.order["Status"] !== "Canceled"
              ? _c(
                  "div",
                  { staticClass: "tw-pt-4 tw-flex tw-gap-x-4" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "tw-px-4",
                        attrs: {
                          pill: "",
                          size: "sm",
                          variant: "outline-primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "airtable-address-update",
                              _vm.order
                            )
                          }
                        }
                      },
                      [_vm._v(" Update Address ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "tw-px-4",
                        attrs: {
                          pill: "",
                          size: "sm",
                          variant: "outline-primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "airtable-shipping-update",
                              _vm.order
                            )
                          }
                        }
                      },
                      [_vm._v(" Set Fedex ")]
                    ),
                    ["Processing", "Open", "Pending"].includes(
                      _vm.order["Status"]
                    )
                      ? _c(
                          "b-button",
                          {
                            staticClass: "tw-px-4",
                            attrs: {
                              pill: "",
                              size: "sm",
                              variant: "outline-secondary",
                              disabled: _vm.isButtonDisabled
                            },
                            on: { click: _vm.onHoldOrder }
                          },
                          [
                            _vm.isButtonDisabled
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _c("span", [_vm._v("Hold")])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.order["Status"] === "CS - HOLD"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "tw-px-4",
                            attrs: {
                              pill: "",
                              size: "sm",
                              variant: "outline-secondary",
                              disabled: _vm.isButtonDisabled
                            },
                            on: { click: _vm.onLiftOrder }
                          },
                          [
                            _vm.isButtonDisabled
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _c("span", [_vm._v("Lift Hold")])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }