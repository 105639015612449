//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SideModal from '@/components/modals/SideModal.vue';
import vClickOutside from 'v-click-outside';
import { PrescriptionService } from '@/services/prescription.service.js';
export default {
  components: {
    SideModal
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    showPricingModal: {
      type: Boolean,
      default: false
    },
    patientId: {
      type: String,
      default: ''
    },
    pricingversion: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      vcoConfig: {
        handler: this.onClickOutside,
        isActive: false
      },
      pricingList: [],
      fields: ['name', 'days', 'price']
    };
  },

  computed: {
    config() {
      return { ...this.vcoConfig
      };
    }

  },
  watch: {
    async showPricingModal(val) {
      if (val) {
        this.vcoConfig.isActive = true;
        const {
          data
        } = await PrescriptionService.getProductDosageAndUnit(this.patientId);
        const transformedData = [];

        for (const item of data) {
          for (const {
            dosage,
            detail
          } of item.details) {
            if (dosage) {
              const transformedItems = detail.map(val => ({
                id: item.id,
                name: `${item.name} ${dosage} - ${val.quantity} ${this.pluralize(Number(val.quantity), val.ship_unit)}`,
                price: `$${val.price.toFixed(2)}`,
                days: val.intervaldisplay ? val.intervaldisplay : 'N/A'
              })).filter(val => !transformedData.find(obj => val.id === (obj === null || obj === void 0 ? void 0 : obj.id) && val.name === (obj === null || obj === void 0 ? void 0 : obj.name) && val.price === (obj === null || obj === void 0 ? void 0 : obj.price) && val.quantity === (obj === null || obj === void 0 ? void 0 : obj.quantity)));
              transformedData.push(...transformedItems);
            }
          }

          transformedData.push({
            id: '',
            name: '',
            price: '',
            days: '',
            quantity: ''
          });
        }

        console.log(transformedData);
        this.pricingList = transformedData;
      } else {
        this.vcoConfig.isActive = false;
      }
    }

  },
  methods: {
    onClickOutside() {
      this.$emit('update:showPricingModal', false);
    },

    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    }

  }
};