/**
import { messageHeightMixin } from "@/mixins/messageHeightMixin";
 */

import Vue from 'vue'

// utils
import { watchResizeObserver } from "@/utils/watchResizeObserver";
import { getElementHeight } from '@/utils/getElementHeight'
import { prefixObservable } from '@/utils/prefixObservable'

const PREFIX = "$_message_";
const PAGE_TITLE_ID = "base-message-page-title";
const CHAT_CONTAINER_ID = "base-message-container";
const CHAT_HEADER_ID = "base-message-header";
const CHAT_FOOTER_ID = "base-message-footer";

const data = Vue.observable({
  chatHeaderHeight: 0,
  pageTitleHeight: 0,
  chatActionsHeight: 0,
  chatContainerMargins: 0,
  PAGE_TITLE_ID,
  CHAT_CONTAINER_ID,
  CHAT_HEADER_ID,
  CHAT_FOOTER_ID,
  bubbleListHeight: 0,
  conversationListHeight: 0,
  observer: null
});

async function initHeightObserver ({ hasFooter } = {}) {
  await Vue.nextTick()

  if (data.observer) {
    data.observer.disconnect()
  }

  const nodes = [
    `#${PAGE_TITLE_ID}`,
    `#${CHAT_CONTAINER_ID}`,
    `#${CHAT_HEADER_ID}`,
  ]

  if (hasFooter) {
    nodes.push(`#${CHAT_FOOTER_ID}`)
  }

  data.observer = await watchResizeObserver(
    nodes,
    entries => {
      const [title, container, header, footer] = entries;

      const containerMargin = parseInt(container.styles.marginRight)
      const pageTitleHeight = getElementHeight(title.target);
      const chatHeaderHeight = getElementHeight(header.target);
      const chatFooterHeight = footer ? getElementHeight(footer.target) : 0

      const viewportHeight = window.innerHeight

      data.bubbleListHeight = viewportHeight - pageTitleHeight - chatHeaderHeight - containerMargin
      data.conversationListHeight = viewportHeight - pageTitleHeight - chatHeaderHeight - chatFooterHeight - containerMargin
    }
  )
}

async function initFooterMutationObserver () {
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length) {
        const addedNode = mutation.addedNodes[0];
        if (addedNode.id === CHAT_FOOTER_ID) {
          initHeightObserver({ hasFooter: true })
        }
      }
    }
  });

  observer.observe(document, { childList: true, subtree: true });
}

export const messageHeightMixin = {
  computed: prefixObservable(data, PREFIX),

  mounted () {
    initHeightObserver()
    initFooterMutationObserver()
  },
}