/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: "1em",
          height: "1em",
          viewBox: "0 0 24 24"
        }
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d:
            "M10.837 21q-.299 0-.516-.202q-.217-.202-.267-.5l-.28-2.19q-.48-.145-1.036-.454q-.557-.31-.948-.664l-2.002.862q-.292.13-.575.028q-.282-.103-.444-.37l-1.157-2.014q-.143-.267-.087-.565q.056-.298.298-.485l1.74-1.31q-.044-.27-.073-.558q-.028-.288-.028-.559q0-.252.028-.53t.073-.626l-1.74-1.31q-.242-.186-.308-.484q-.065-.298.097-.565l1.157-1.975q.162-.267.435-.37q.273-.103.565.028l2.002.842q.448-.373.97-.673q.52-.3 1.013-.464l.3-2.19q.05-.298.267-.5T10.837 3h2.307q.298 0 .525.202q.227.202.277.5l.28 2.21q.576.201 1.017.463q.442.262.909.654l2.079-.842q.292-.131.565-.038q.273.093.435.36l1.157 1.995q.162.267.097.565q-.066.298-.308.485L18.36 10.92q.082.31.092.568q.01.26.01.511q0 .233-.02.491q-.019.259-.088.626l1.779 1.33q.242.186.307.484q.066.298-.096.565l-1.157 2.008q-.162.267-.448.363q-.285.096-.577-.034l-2.01-.862q-.467.392-.94.673q-.474.281-.985.444l-.28 2.21q-.05.298-.278.5q-.227.202-.525.202zm1.136-6.5q1.046 0 1.773-.727q.727-.727.727-1.773q0-1.046-.727-1.773q-.727-.727-1.773-.727q-1.052 0-1.776.727T9.473 12q0 1.046.724 1.773q.724.727 1.776.727"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }