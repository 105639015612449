import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';
import $cookies from 'vue-cookies';
import $router from '../router';
import store from '../store';

if (process.env.VUE_APP_SERVER == 'local') {
  $cookies.config(604800, '/');
} else {
  $cookies.config(604800, null, null, true);
}

export class AuthService extends BaseService {
  static async login(data) {
    try {
      const response = await this.request({ auth: false }).post(
        '/signin',
        data
      );
      if (response?.data?.body?.idToken) {
        _setAuthData({
          accessToken: response.data.body.idToken,
          exp: _parseTokenData(response.data.body.idToken).exp,
        });
        this.request({ auth: true })
          .get('user')
          .then((response) =>
            localStorage.setItem('userid', response.data.body.id)
          );
      }
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async signup(data) {
    try {
      const response = await this.request({ auth: false }).post(
        '/signup',
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateTemporaryPassword(data) {
    try {
      const response = await this.request({ auth: false }).put(
        '/user/updatetemporarypassword',
        data
      );
      if (response?.data?.body?.idToken) {
        _setAuthData({
          accessToken: response.data.body.idToken,
          exp: _parseTokenData(response.data.body.idToken).exp,
        });
        this.request({ auth: true })
          .get('user')
          .then((response) =>
            localStorage.setItem('userid', response.data.body.id)
          );
      }
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async logout() {
    try {
      const response = await this.request({ auth: true }).post('/signout');
      _resetAuthData();
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refreshTokens() {
    try {
      const response = await this.request({ auth: true }).post('/refreshtoken');

      _setAuthData({
        accessToken: response.data.body.idToken,
        exp: _parseTokenData(response.data.body.idToken).exp,
      });

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      if (error.response.data.message !== 'This token is still valid') {
        _resetAuthData();
        $router.push({ name: 'login' }).catch(() => {});
        throw new ErrorWrapper(error);
      }
    }
  }

  static hasRefreshToken() {
    return Boolean($cookies.get('refreshToken'));
  }

  static setRefreshToken(status) {
    if (!['', 'true'].includes(status)) {
      throw new Error(
        `setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`
      );
    }

    $cookies.set('refreshToken', status);
  }

  static getBearer() {
    return `Bearer ${$cookies.get('accessToken')}`;
  }

  static setBearer(accessToken) {
    $cookies.set('accessToken', accessToken);
  }

  static async getCurrent() {
    try {
      const response = await this.request({ auth: true }).get('user');
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData(accessToken) {
  let payload = '';
  let tokenData = {};

  try {
    payload = accessToken.split('.')[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

function _resetAuthData() {
  $cookies.remove('accessToken');
  localStorage.removeItem('userid');
  localStorage.removeItem('accessToken');
  store.dispatch('auth/resetState');
}

function _setAuthData({ accessToken } = {}) {
  AuthService.setRefreshToken('true');
  AuthService.setBearer(accessToken);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('token', `Bearer ${accessToken}`);
}
