/* unplugin-vue-components disabled */import __unplugin_components_3 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/base/BaseButton.vue';
import __unplugin_components_2 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/base/BaseToggle.vue';
import __unplugin_components_1 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconLoading.vue';
import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/base/BaseSlidePanel.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config
    ? _c(__unplugin_components_0,
        {
          model: {
            value: _vm.isVisibleMessageRouteConfigSlide,
            callback: function($$v) {
              _vm.isVisibleMessageRouteConfigSlide = $$v
            },
            expression: "isVisibleMessageRouteConfigSlide"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "tw-flex tw-flex-col tw-px-[32px] tw-py-[50px]",
              attrs: { id: _vm.DOCTOR_SLIDE_ID }
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "tw-text-dark-cyan tw-text-[22px] tw-font-bold tw-mb-[15px] tw-text-posey-green",
                  attrs: { id: _vm.DOCTOR_SLIDE_TITLE_ID }
                },
                [_vm._v(" Doctors Applied ")]
              ),
              _c(
                "div",
                {
                  staticClass: "tw-mb-[55px]",
                  attrs: { id: _vm.DOCTOR_SLIDE_SUBTITLE_ID }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "tw-text-posey-green tw-underline tw-underline-offset-2 tw-text-evergreen tw-text-[14px] tw-font-bold tw-cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.enableAllDoctorStatus()
                        }
                      }
                    },
                    [_vm._v(" TURN ALL ON ")]
                  )
                ]
              ),
              _c(
                "ul",
                {
                  staticClass:
                    "tw-flex tw-flex-col tw-list-none tw-p-0 tw-gap-[30px] tw-overflow-y-auto scrollable",
                  style: {
                    height: _vm.listHeight + "px",
                    maxHeight: _vm.listHeight + "px"
                  },
                  attrs: { id: _vm.DOCTOR_SLIDE_LIST }
                },
                [
                  _vm.isFetchingDoctors
                    ? _c(
                        "li",
                        {
                          staticClass:
                            "tw-my-auto tw-flex tw-items-center tw-justify-center"
                        },
                        [
                          _c(__unplugin_components_1, {
                            staticClass: "tw-size-[30px] tw-opacity-50"
                          })
                        ],
                        1
                      )
                    : _vm._l(_vm.doctors, function(doctor, doctorKey) {
                        return _c(
                          "li",
                          {
                            key: doctorKey,
                            staticClass: "tw-flex tw-gap-5 tw-items-center"
                          },
                          [
                            _c(__unplugin_components_2, {
                              model: {
                                value: _vm.doctorStatus[doctor.id],
                                callback: function($$v) {
                                  _vm.$set(_vm.doctorStatus, doctor.id, $$v)
                                },
                                expression: "doctorStatus[doctor.id] "
                              }
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "tw-text-black tw-text-[18px] tw-font-bold"
                              },
                              [
                                _vm._v(
                                  " Dr. " +
                                    _vm._s(doctor.firstname) +
                                    " " +
                                    _vm._s(doctor.lastname) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "tw-flex tw-justify-end" },
                [
                  _c(__unplugin_components_3,
                    {
                      attrs: {
                        id: _vm.DOCTOR_SLIDE_BUTTON,
                        color: "secondary",
                        loading: _vm.isUpdatingConfig
                      },
                      on: { click: _vm.saveDoctorStatus }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }