/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOrderManagerOpen
    ? _c(
        "div",
        {
          staticClass:
            "airtable-update tw-flex tw-flex-col tw-flex-grow !tw-bg-white",
          class:
            _vm.selectedPrescription.prescriptionstatus &&
            _vm.selectedPrescription.prescriptionstatus
              .replace(/\s+/g, "-")
              .toLowerCase()
        },
        [
          _vm.loading
            ? _c("AirtableOrderSkeleton")
            : _c(
                "div",
                {
                  staticClass:
                    "tw-sticky tw-justify-between tw-top-0 tw-flex tw-px-6 tw-py-6 tw-bg-white"
                },
                [
                  _c("div", { staticClass: "tw-flex tw-max-w-[350px]" }, [
                    _c("div", { staticClass: "tw-mr-2" }, [
                      _c("p", { staticClass: "tw-mb-1 tw-text-lg" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.selectedPrescription.medicinename) +
                            " | " +
                            _vm._s(_vm.selectedPrescription.dosage) +
                            " "
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "tw-flex" }, [
                    _c(
                      "span",
                      { staticClass: "card-status tw-text-lg tw-mr-4" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedPrescription.prescriptionstatus
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "svg",
                      {
                        staticClass: "tw-w-6 tw-h-6 tw-cursor-pointer",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          "stroke-width": "1.5",
                          stroke: "#1a6a72"
                        },
                        on: { click: _vm.close }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d:
                              "M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                          }
                        })
                      ]
                    )
                  ])
                ]
              ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass:
                "tw-flex-grow tw-overflow-y-auto tw-h-[calc(100vh-100px)]"
            },
            _vm._l(_vm.airtableOrders, function(order, i) {
              return _c(
                "div",
                {
                  key: "" + order["Order No"] + i,
                  staticClass: "tw-p-6 tw-pb-0"
                },
                [
                  _c("card-airtable-order", {
                    attrs: { order: order, index: i },
                    on: {
                      "airtable-address-update": _vm.onAirtableAddressUpdate,
                      "airtable-shipping-update": _vm.onAirtableShippingUpdate,
                      "refresh-self": _vm.getAirtableOrders,
                      rush: function($event) {
                        return _vm.$emit(
                          "open-rush",
                          Object.assign({}, _vm.selectedPrescription, {
                            fullname:
                              _vm.patientData.firstname +
                              " " +
                              _vm.patientData.lastname
                          })
                        )
                      }
                    }
                  }),
                  _c("hr", { staticClass: "tw-mb-0" })
                ],
                1
              )
            }),
            0
          ),
          _c("AirtableAddressDialog", {
            ref: "AirtableAddressDialog",
            attrs: { order: _vm.selectedAirtableOrder },
            on: { "refresh-self": _vm.getAirtableOrders }
          }),
          _c("AirtableShippingDialog", {
            ref: "AirtableShippingDialog",
            attrs: { order: _vm.selectedAirtableOrder },
            on: { "refresh-self": _vm.getAirtableOrders }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }