//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import { mapState } from "vuex"; // assets

import heightObserver from "@/assets/js/observeHeight.js"; // mixins

import { chatMixin } from "@/mixins/chat"; // utils

import { sleep } from '@/utils/sleep'; // assets

import fallbackUserProfile from '@/assets/images/user.png';
import { PatientService } from "@/services/patient.service";
import PatientsInfoSidePanel from "@/components/patients/PatientsInfoSidePanel.vue";
const ROUTE_TO = {
  DOCTOR: 'Doctor',
  ADMIN: 'Admin',
  BOTH: 'Both',
  DOCTOR_NO_ACTION: 'Doctor no action'
};
export default {
  components: {
    PatientsInfoSidePanel
  },
  mixins: [heightObserver, chatMixin],

  data() {
    return {
      fallbackUserProfile,
      isVisibleConfigPanel: false,
      isRoutingConversation: null,
      isLoadingNextConvoAfterRoute: false,
      ROUTE_TO,
      patient: null
    };
  },

  computed: { ...mapState("doctorMessageRouter", {
      doctorMessageRouterConfig: "config",
      messages: "messages",
      conversations: "conversations",
      isFetchingMessages: 'isFetchingMessages',
      selectedConversation: 'selectedConversation',
      recentlySentMessage: 'recentlySentMessage',
      revertableMessageRemainingTime: 'revertableMessageRemainingTime'
    })
  },
  watch: {
    async selectedConversation(val) {
      if (val) {
        const {
          data
        } = await PatientService.getPatient(this.selectedConversation.patient.id);
        this.patient = data;
      }
    }

  },

  async created() {
    this.$store.dispatch("doctorMessageRouter/getConfig");
    this.$_chat_initHeights();
  },

  methods: {
    async viewPatientInfo() {
      this.$refs.PatientsInfoSidePanel.show();
    },

    async routeConversation({
      routeTo
    }) {
      const {
        selectedConversation,
        messages,
        $store
      } = this;

      try {
        this.isRoutingConversation = routeTo;
        const shouldClearSwimlane = [ROUTE_TO.DOCTOR_NO_ACTION, ROUTE_TO.ADMIN].includes(routeTo);
        const forAdminReviewMsgIds = messages.filter(x => x.foradminreview).map(x => x.id);
        await Promise.all([forAdminReviewMsgIds.length ? $store.dispatch('doctorMessageRouter/routeConversation', {
          conversationId: selectedConversation.id,
          messageIds: forAdminReviewMsgIds,
          routeTo
        }) : Promise.resolve(), shouldClearSwimlane ? $store.dispatch('doctorMessageRouter/ignoreDoctorConversation', selectedConversation.id) : Promise.resolve()]);
        const loadingForNextConvoDelay = 2000;
        this.$bvToast.toast("Successfully routed ", {
          variant: "success",
          noCloseButton: true,
          autoHideDelay: loadingForNextConvoDelay,
          toaster: "b-toaster-top-right"
        });
        await sleep(loadingForNextConvoDelay);
        this.$store.commit('doctorMessageRouter/SET_STATE', {
          state: 'selectedConversation',
          value: null
        });
        this.$store.commit('doctorMessageRouter/REMOVE_CONVERSATION_BY_ID', selectedConversation.id);
        this.isRoutingConversation = null;
        this.isLoadingNextConvoAfterRoute = true;
        const nextConversation = this.conversations[0];

        if (nextConversation) {
          await this.$_chat_openConversation(nextConversation);
        }
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.isLoadingNextConvoAfterRoute = false;
        this.isRoutingConversation = null;
      }
    }

  }
};