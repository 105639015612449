/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "xl"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "action-history-container" },
        [
          _c(
            "div",
            { staticClass: "tw-flex tw-justify-between tw-items-center" },
            [
              _c("p", { staticClass: "tw-mb-4 tw-text-xl tw-text-eclipse" }, [
                _vm._v("Action History")
              ]),
              _c(
                "a",
                {
                  staticClass: "tw-inline-block tw-mr-8",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openActionDialog.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "12",
                        height: "13",
                        viewBox: "0 0 12 13",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d:
                            "M8.97923 1.26471C9.16243 1.08151 9.33507 1.03215 9.47658 1.03889C9.62637 1.04602 9.79003 1.11887 9.93588 1.26472L10.9508 2.27958C11.134 2.46278 11.1833 2.63543 11.1766 2.77694C11.1695 2.92672 11.0966 3.09039 10.9508 3.23624L8.2613 5.92571L4.41521 9.77179L1.26072 10.9547L2.44366 7.80027L8.97922 1.26472L8.97923 1.26471ZM4.77724 10.4904L2.78302 11.2383H11.2336V12.0383H0.577201H0L0.202669 11.4978L1.72501 7.43825L1.75544 7.35712L1.8167 7.29585L8.41353 0.699038L8.41354 0.699032C8.73778 0.374777 9.12333 0.221156 9.51464 0.239794C9.89768 0.258037 10.2415 0.438927 10.5016 0.699032L11.5165 1.71388L11.5165 1.71389C11.8407 2.03814 11.9943 2.42368 11.9757 2.81499C11.9575 3.19803 11.7766 3.54181 11.5165 3.80192L8.82699 6.49139L4.91963 10.3987L4.85837 10.46L4.77724 10.4904ZM8.04528 2.67103L9.54528 4.17103L10.111 3.60534L8.61097 2.10534L8.04528 2.67103Z",
                          fill: "currentColor"
                        }
                      })
                    ]
                  ),
                  _vm._v(" Action ")
                ]
              )
            ]
          ),
          !_vm.loaded
            ? _c("b-table", {
                attrs: {
                  fields: _vm.headers,
                  items: _vm.notes,
                  "show-empty": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "empty",
                      fn: function() {
                        return [_c("b-skeleton")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3638722707
                )
              })
            : _c("b-table", {
                attrs: {
                  "no-border-collapse": "",
                  fields: _vm.headers,
                  items: _vm.notes,
                  responsive: "",
                  striped: "",
                  id: "actionHistory",
                  "sticky-header": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(timestampcreated)",
                    fn: function(row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getDate(row.item.timestampcreated)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(noteby)",
                    fn: function(row) {
                      return [
                        row.item.noteby
                          ? _c("span", [
                              _vm._v(" " + _vm._s(row.item.noteby) + " ")
                            ])
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  },
                  {
                    key: "cell(notes)",
                    fn: function(row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "tw-w-[500px] tw-break-words" },
                          [_vm._v(" " + _vm._s(row.item.notes) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
          _c(
            "b-alert",
            { attrs: { variant: "warning", show: _vm.alert.show } },
            [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }