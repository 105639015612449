//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { ActionService } from '@/services/action.service';
export default {
  props: {
    socket: {
      type: Object,
      default: () => ({})
    },
    conversation: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      message: '',
      messageCategory: 'chat',
      isProcessing: false,
      macroIds: [],
      macroLoading: false
    };
  },

  computed: {
    hasMessageBody() {
      return this.message.trim().length > 0;
    },

    ...mapGetters({
      messageTemplate: 'chat/getMessageTemplate'
    }),
    ...mapState('auth', {
      currentUser: 'currentUser'
    })
  },
  watch: {
    messageCategory(newValue) {
      this.emitMessageCategory(newValue);
    },

    async messageTemplate(msgObject) {
      this.macroLoading = true;
      let msg = msgObject.content;

      if (msg) {
        if (msg.includes('{patient}')) {
          msg = msg.replaceAll('{patient}', this.conversation.patient.firstName);
        }

        if (msg.includes('{admin}')) {
          msg = msg.replaceAll('{admin}', this.currentUser.firstname);
        }

        if (msg.includes('{doctor}')) {
          msg = msg.replaceAll('{doctor}', `Dr. ${this.conversation.doctor.doctorLastName}`);
        }

        if (!this.macroIds.includes(msgObject.id)) {
          this.macroIds.push(msgObject.id);
        }

        this.message = this.message + msg + '\n';
      }

      this.macroLoading = false;
    },

    message(msg) {
      if (msg == '') {
        this.macroIds = [];
      }
    }

  },
  methods: {
    openMacroDialog() {
      this.$store.commit('chat/SET_OPEN_MACRO', true);
    },

    async sendMessage() {
      if (!navigator.onLine) {
        this.$bvToast.toast('No internet connection. Message not sent.', {
          variant: 'warning',
          noCloseButton: true,
          toaster: 'b-toaster-bottom-center'
        });
      }

      if (!this.hasMessageBody) {
        return;
      }

      if (this.messageCategory === 'chat') {
        const obj = {
          id: uuidv4(),
          patientId: this.conversation.patient.id,
          sentAt: Math.floor(Date.now() / 1000),
          message: this.message
        };

        if (this.macroIds.length > 0) {
          obj.messageTemplateIds = this.macroIds;
        }

        this.socket.emit('admin_chat:new_message', obj);
        this.macroIds = [];

        if (!this.conversation.status && !this.conversation.assignee) {
          this.$emit('assign-to-self');
        }
      } else {
        this.isProcessing = true;
        await ActionService.addActionNote(this.conversation.patient.id, {
          actionid: 'all',
          notes: this.message,
          action: 'Other',
          source: 'APC'
        });
        this.isProcessing = false;
      }

      this.$emit('message');
      this.message = '';
    },

    toggleMessageCategory() {
      this.messageCategory = this.messageCategory === 'chat' ? 'note' : 'chat';
    },

    emitMessageCategory() {
      this.$emit('messageCategoryChanged', this.messageCategory);
    }

  }
};