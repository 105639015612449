//
//
//
//
//
//
//
//
//
//
import Main from '@/components/tables/partials/Main';
export default {
  components: {
    Main
  },

  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        sortable: true
      }, {
        key: 'email',
        sortable: true
      }, {
        key: 'needspatientreplydatetime',
        label: 'In Stage',
        sortable: true
      }, {
        key: 'datetimelastaction',
        label: 'Last Action',
        sortable: true
      }, // {
      //   key: 'timesContacted',
      //   label: 'Times Contacted',
      //   sortable: true,
      // },
      {
        key: 'dismiss',
        label: 'Actions'
      }, {
        key: 'actions',
        label: ''
      }, {
        key: 'history',
        label: ''
      }, // { key: 'patientinfo', label: '' },
      {
        key: 'links',
        label: ''
      }],
      type: 'needspatientreply',
      options: {
        sortDesc: 'DESC',
        sortBy: 'needspatientreplydatetime'
      }
    };
  },

  methods: {
    viewInfo(row) {
      this.$emit('view-info', row);
    },

    getStatusCount() {
      this.$emit('get-status-count');
    }

  }
};