/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "lg"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "warning" },
              model: {
                value: _vm.alert.show,
                callback: function($$v) {
                  _vm.$set(_vm.alert, "show", $$v)
                },
                expression: "alert.show"
              }
            },
            [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
          ),
          _c(
            "b-row",
            { staticClass: "mb-4", attrs: { "align-h": "between" } },
            [
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icon_logo_violet.svg"),
                    alt: ""
                  }
                })
              ]),
              _c("b-col", { staticClass: "text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "tw-text-eclipse",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        _vm.showDialog = false
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { icon: "x-circle", "font-scale": "1.5" }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("span", { staticClass: "tw-text-primary" }, [
                  _vm._v("Update Airtable Address")
                ])
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-small text-muted",
                              attrs: { for: "deliveryaddress" }
                            },
                            [_vm._v(" Delivery Address* ")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "deliveryaddress" },
                            model: {
                              value: _vm.deliveryaddress,
                              callback: function($$v) {
                                _vm.deliveryaddress = $$v
                              },
                              expression: "deliveryaddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4", attrs: { "align-h": "end" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { offset: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-dark",
                                disabled: _vm.buttonDisabled
                              },
                              on: {
                                click: function($event) {
                                  _vm.showDialog = false
                                }
                              }
                            },
                            [_vm._v(" Discard ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "secondary",
                                disabled: _vm.buttonDisabled
                              },
                              on: { click: _vm.submitChanges }
                            },
                            [_vm._v(" Save ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }