/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./MessagesView.vue?vue&type=template&id=05a6aa6a&"
import script from "./MessagesView.vue?vue&type=script&lang=js&"
export * from "./MessagesView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05a6aa6a')) {
      api.createRecord('05a6aa6a', component.options)
    } else {
      api.reload('05a6aa6a', component.options)
    }
    module.hot.accept("./MessagesView.vue?vue&type=template&id=05a6aa6a&", function () {
      api.rerender('05a6aa6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/MessagesView.vue"
export default component.exports