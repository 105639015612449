/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "md"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("span", { staticClass: "text-small text-primary" }, [
                _vm._v("Label")
              ]),
              _c("br"),
              _c("b-form-input", {
                model: {
                  value: _vm.label,
                  callback: function($$v) {
                    _vm.label = $$v
                  },
                  expression: "label"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("span", { staticClass: "text-small text-primary" }, [
                _vm._v("Inventory Item")
              ]),
              _c("br"),
              _c("b-form-select", {
                attrs: { options: _vm.inventoryOptions },
                model: {
                  value: _vm.inventory_id,
                  callback: function($$v) {
                    _vm.inventory_id = $$v
                  },
                  expression: "inventory_id"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("span", { staticClass: "text-small text-primary" }, [
                _vm._v("Amount")
              ]),
              _c("br"),
              _c("b-form-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.amount,
                  callback: function($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("span", { staticClass: "text-small text-primary" }, [
                _vm._v("Pharmacy")
              ]),
              _c("br"),
              _c("b-form-select", {
                attrs: { options: _vm.pharmacyOptions },
                model: {
                  value: _vm.pharmacy_id,
                  callback: function($$v) {
                    _vm.pharmacy_id = $$v
                  },
                  expression: "pharmacy_id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-7" },
        [
          _c(
            "b-col",
            { attrs: { offset: "6", cols: "3" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "outline-dark",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "secondary",
                    disabled: _vm.loading
                  },
                  on: { click: _vm.update }
                },
                [_vm._v(" Update ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }