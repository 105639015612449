export function waitCallbackTruthy (callback) {
  return new Promise((resolve) => {
    function check () {
      if (callback() === true) {
        resolve();
      } else {
        requestAnimationFrame(check);
      }
    }
    check();
  });
}