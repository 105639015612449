//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import { mapGetters } from 'vuex'; // components

import ChatCategoryItem from './ChatCategoryItem.vue';
export default {
  components: {
    ChatCategoryItem
  },
  props: {
    category: {
      type: String,
      default: 'all'
    }
  },
  computed: { ...mapGetters({
      unreadCount: 'chat/getMetrics'
    }),

    categories() {
      return [{
        label: 'My Inbox',
        value: 'assigned',
        unreadCount: this.unreadCount.assigned
      }, {
        label: 'All',
        value: 'all',
        unreadCount: this.unreadCount.total
      }, {
        label: 'Unassigned',
        value: 'open',
        unreadCount: this.unreadCount.open
      }, {
        label: 'Closed',
        value: 'dismissed',
        unreadCount: 0
      }];
    }

  }
};