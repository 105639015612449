/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "35",
        height: "35",
        viewBox: "0 0 58 57",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M0.300781 30.7344V26.2595C0.300781 26.0954 0.37536 25.9462 0.390276 25.7821C0.765396 21.9499 1.91142 18.2332 3.75949 14.8551C5.60756 11.4771 8.11954 8.50762 11.1444 6.12506C14.1693 3.7425 17.6446 1.9961 21.3616 0.9908C25.0786 -0.0144974 28.9604 -0.258036 32.7739 0.275022C39.2799 1.18877 45.2698 4.32419 49.7283 9.14968C54.1868 13.9752 56.8395 20.1938 57.2368 26.7517C57.6283 32.5083 56.2153 38.2438 53.1945 43.1598C51.2023 46.4999 48.544 49.3944 45.385 51.663C42.226 53.9315 38.634 55.5254 34.8324 56.3458C33.5645 56.6293 32.2667 56.7337 30.9988 56.9425H26.5239C26.0913 56.9425 25.6587 56.8083 25.2112 56.7635C19.2834 56.0133 13.7398 53.424 9.3598 49.3598C4.97983 45.2955 1.98383 39.9608 0.793049 34.1056C0.584219 33.0018 0.494695 31.8084 0.300781 30.7344ZM45.96 40.7134V40.624L45.6915 40.1466C43.3197 36.0959 40.5083 32.3192 37.3084 28.8848C34.2586 25.437 30.3762 22.8281 26.0316 21.3072C25.8787 21.2714 25.7447 21.1795 25.6563 21.0497C25.5679 20.9198 25.5315 20.7615 25.5543 20.6061C25.5543 19.6664 25.5543 18.7117 25.5543 17.772C25.584 17.3796 25.4945 16.9874 25.2975 16.6467C25.1006 16.306 24.8053 16.0329 24.4505 15.8628C24.0927 15.6781 23.6851 15.6132 23.2876 15.6776C22.8902 15.7421 22.524 15.9325 22.2429 16.2208L11.6522 24.9618C11.4027 25.1414 11.1995 25.3777 11.0593 25.6513C10.9192 25.925 10.8461 26.2279 10.8461 26.5354C10.8461 26.8428 10.9192 27.1458 11.0593 27.4194C11.1995 27.693 11.4027 27.9295 11.6522 28.1091L14.6355 30.5704L22.1981 36.88C22.4834 37.1656 22.8501 37.3559 23.2479 37.4246C23.6457 37.4934 24.055 37.4373 24.4196 37.264C24.7842 37.0907 25.0862 36.8087 25.284 36.4568C25.4819 36.105 25.5659 35.7003 25.5245 35.2988C25.5245 34.2248 25.5245 33.1509 25.5245 32.077C25.5245 31.3311 25.5245 31.2714 26.3002 31.2714C28.2531 31.2817 30.1917 31.604 32.0429 32.2261C36.046 33.6133 39.769 35.7046 43.0364 38.4015C44.0507 39.1473 44.9904 39.9378 45.96 40.7582V40.7134Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }