/* unplugin-vue-components disabled */import __unplugin_components_1 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/patients/DoctorRequestDialog.vue';
import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/routedMessages/RoutedMessagesBubble.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-body",
    {
      ref: "message-dialogues",
      staticClass:
        "tw-p-0 tw-flex tw-flex-col-reverse tw-gap-[15px] tw-overflow-y-auto tw-px-2 tw-py-2 scrollable",
      style: {
        height: _vm.$_chat_bubbleListHeight + "px",
        maxHeight: _vm.$_chat_bubbleListHeight + "px"
      },
      attrs: { id: _vm.$_chat_CHAT_BUBBLE_CONTAINER_ID },
      on: {
        "&scroll": function($event) {
          return _vm.messageScroll.apply(null, arguments)
        }
      }
    },
    [
      _vm.isFetchingMessages || _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "tw-size-full tw-flex tw-justify-center tw-items-center tw-text-muted tw-select-none"
            },
            [_vm._v(" Loading conversation details ")]
          )
        : !_vm.selectedConversation
        ? _c(
            "div",
            {
              staticClass:
                "tw-size-full tw-flex tw-justify-center tw-items-center tw-text-muted tw-select-none"
            },
            [_vm._v(" Select patient to view conversation ")]
          )
        : !_vm.messages.length
        ? _c(
            "div",
            {
              staticClass:
                "tw-size-full tw-flex tw-justify-center tw-items-center tw-text-muted tw-select-none"
            },
            [_vm._v(" Start the conversation ")]
          )
        : _vm._l(_vm.messages, function(message, messageKey) {
            return _c(__unplugin_components_0, {
              key: messageKey,
              attrs: { message: message },
              on: { "view-forward-message": _vm.redirectToForwardedMessage }
            })
          }),
      _c(__unplugin_components_1, {
        ref: "DoctorRequestDialog",
        attrs: {
          "row-data": _vm.selectedForwardedRequest,
          "show-message-button": false
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }