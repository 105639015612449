//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AirtableService } from '@/services/airtable.service';
export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      specialShipping: ''
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        this.specialShipping = this.order['Special Shipping'];
      } else {
        this.specialShipping = '';
      }
    }

  },
  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },

    async submitChanges() {
      this.alert.show = false;

      try {
        this.buttonDisabled = true;
        await AirtableService.updateShipping(this.order['Prescription ID'], this.specialShipping);
        this.$emit('refresh-self', this.order['Prescription ID']);
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.buttonDisabled = false;
      }
    }

  }
};