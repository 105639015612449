/* unplugin-vue-components disabled */var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "tw-py-10 tw-mx-5", attrs: { id: "base-page-title" } },
    [
      _c(
        "h1",
        { staticClass: "mb-0 text-primary tw-text-[22px] tw-font-medium" },
        [_vm._t("title")],
        2
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }