//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PatientService } from '@/services/patient.service';
import SideModal from '@/components/modals/SideModal';
export default {
  components: {
    SideModal
  },
  props: {
    showIntakeModal: {
      type: Boolean,
      default: false
    },
    patientid: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      vcoConfig: {
        handler: this.onClickOutside,
        isActive: false
      },
      intake: {}
    };
  },

  watch: {
    async showIntakeModal(val) {
      if (val) {
        this.vcoConfig.isActive = true;
        const {
          data
        } = await PatientService.getPatientIntake(this.patientid);
        this.intake = data.medicalquestions.filter(o => o.hasOwnProperty('answer') && o.answer != '');
      } else {
        this.vcoConfig = {
          handler: this.onClickOutside,
          isActive: false
        };
        this.intake = {};
      }
    }

  },
  methods: {
    onClickOutside() {
      this.$emit('update:showIntakeModal', false);
    }

  }
};