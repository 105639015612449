import { format, fromUnixTime } from 'date-fns';
import moment from 'moment';

export function formatUnix (date, dateFormat) {
  return format(fromUnixTime(date), dateFormat);
}

export function toFiendlyDate (timestamp) {
  return moment(timestamp).format('MMM D, YYYY');
}

export function toFriendlyTime (timestamp) {
  return moment(timestamp).format('LT');
}