/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AddInventoryRestockDialog", {
        ref: "AddRestockDialog",
        on: { refresh: _vm.refresh }
      }),
      _c("EditInventoryRestockDialog", {
        ref: "EditRestockDialog",
        on: { refresh: _vm.refresh }
      }),
      _c("DeleteInventoryRestockDialog", {
        ref: "DeleteRestockDialog",
        on: { refresh: _vm.refresh }
      }),
      _c("b-table", {
        ref: "table",
        staticClass: "card-table",
        staticStyle: { "max-height": "calc(100vh - 25vh) !important" },
        attrs: {
          responsive: "",
          "select-mode": "single",
          fields: _vm.headers,
          items: _vm.items,
          "sticky-header": ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(date)",
            fn: function(row) {
              return [_vm._v(" " + _vm._s(_vm.getDate(row.item.date)) + " ")]
            }
          },
          {
            key: "cell(edit)",
            fn: function(row) {
              return [
                _c(
                  "a",
                  {
                    staticClass: "tw-underline",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openDialog("EditRestockDialog", row.item)
                      }
                    }
                  },
                  [_vm._v(" Edit ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "tw-underline ml-3",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.openDialog("DeleteRestockDialog", row.item)
                      }
                    }
                  },
                  [_vm._v(" Delete ")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        {
          staticClass:
            "tw-py-2 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-bg-white"
        },
        [
          [
            _c(
              "div",
              { staticClass: "overflow-auto" },
              [
                _vm.count && _vm.count > 20
                  ? _c("b-pagination", {
                      attrs: { "total-rows": _vm.count, "per-page": 20 },
                      on: { "page-click": _vm.handlePagination },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }