/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "stroke-miterlimit": "10"
      },
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 36 36",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "#fff",
          "fill-rule": "nonzero",
          stroke: "#000",
          "stroke-width": "0.09px"
        },
        attrs: {
          d:
            "M16.796,0.467l2.333,0c0.091,0.025 0.182,0.05 0.274,0.067c1.029,0.174 2.075,0.274 3.072,0.539c8.51,2.275 13.973,10.47 12.794,19.205c-0.589,4.375 -2.557,8.078 -5.936,10.943c-4.16,3.529 -8.992,4.874 -14.364,3.96c-4.741,-0.805 -8.552,-3.238 -11.325,-7.182c-2.532,-3.595 -3.545,-7.622 -3.072,-11.997c0.407,-3.753 1.909,-7.049 4.458,-9.839c2.666,-2.914 5.97,-4.724 9.864,-5.422c0.631,-0.116 1.262,-0.191 1.902,-0.282l-0,0.008Zm13.699,25.98c4.16,-6.036 3.446,-14.821 -2.79,-20.093c-6.094,-5.148 -15.293,-4.633 -20.74,1.171c-5.53,5.886 -5.023,14.09 -1.519,18.905c3.47,-3.421 7.638,-5.239 12.512,-5.239c4.882,0 9.042,1.819 12.537,5.264l0,-0.008Zm-5.539,-14.331c0.016,3.828 -3.122,6.991 -6.95,6.999c-3.852,0.009 -7.024,-3.105 -7.032,-6.916c-0.017,-3.935 3.097,-7.066 7.024,-7.074c3.819,0 6.949,3.139 6.966,6.991l-0.008,0Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }