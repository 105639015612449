/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": ""
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4 pr-4" },
        [
          _c("b-col", { attrs: { offset: "2" } }, [
            _c("h2", { staticClass: "heading tw-mb-2 tw-text-2xl" }, [
              _vm._v("Clear patient from queue")
            ]),
            _c("p", { staticClass: "tw-text-muted tw-text-base" }, [
              _vm._v(
                " Are you sure you want to clear this patient from your queue? "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v(" Back ")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary", pill: "", block: "" },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" Confirm ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }