//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    unreadCount: {
      type: [Number, String],
      default: 0
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  }
};