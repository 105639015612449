//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PatientService } from '@/services/patient.service';
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      alert: {
        show: false,
        message: ''
      },
      isRefunding: false,
      showDialog: false,
      subscriptions: [],
      refundvalues: {}
    };
  },

  computed: {
    total() {
      let price = 0;

      for (const num of Object.values(this.refundvalues)) {
        if (typeof num == 'string') {
          price += Number(num.replace(/[^0-9.]/g, ''));
        } else {
          price += num;
        }
      }

      return isNaN(price) ? 0 : price;
    },

    hasInvalidRefundValue() {
      return Object.values(this.refundValueValidAmountMap).some(isValid => !isValid);
    },

    refundValueValidAmountMap() {
      return Object.fromEntries(Object.entries(this.refundvalues).map(([subId, amount]) => {
        return [subId, isNaN(amount) ? false : true];
      }));
    }

  },
  watch: {
    async showDialog(show) {
      if (show) {
        this.isRefunding = false;
        await this.refreshData();

        for (const sub of this.subscriptions) {
          this.$set(this.refundvalues, sub.id, sub.refundamount); // this.refundvalues[sub.id] = sub.refundamount;
        }
      } else {
        this.alert.show = false;
        this.subscriptions = [];
        this.refundvalues = {};
      }
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },

    getDate(date) {
      if (date == '-') {
        return '-';
      }

      return new Date(date * 1000).toDateString();
    },

    async refreshData() {
      const {
        data
      } = await PrescriptionService.getPrescriptionForRefund(this.rowData.id, this.rowData.orderid);
      this.subscriptions = data;
    },

    getRefills(med) {
      if (med.refills) {
        const refills = med.refills - 1 - (med.paidrefills == 0 ? 0 : med.paidrefills - 1);

        if (refills == 0 && med.refills - 1 == 0) {
          return 'One time purchase';
        } else {
          return `${refills}/${med.refills - 1} refills`;
        }
      } else {
        return '-';
      }
    },

    async refund() {
      try {
        const body = {
          amount: this.total
        };
        this.isRefunding = true;
        await PatientService.refundPatient(this.rowData.id, this.rowData.orderid, body);
        this.$emit('delete-self', this.rowData.id);
        this.showDialog = false;
      } catch (err) {
        this.isRefunding = false;
        this.showAlert(err);
      }
    }

  }
};