//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    member: {
      type: Object,
      required: true
    },
    isRouted: {
      type: Boolean,
      default: false
    },
    forAdminReview: {
      type: Boolean
    }
  },
  computed: {
    positionClass() {
      return this.member.role === 'admin' ? 'tw-right-[-41px]' : 'tw-left-[-39px]';
    },

    colorClass() {
      const {
        member
      } = this;

      if (this.isRouted) {
        return 'tw-text-light-gray';
      } // Retain doctor color even if message is for admin review


      if (!this.forAdminReview && member.role !== 'doctor') {
        return 'tw-text-light-gray';
      }

      if (member.role === 'admin') {
        return 'tw-text-mischka-light';
      }

      if (member.role === 'doctor') {
        return 'tw-text-warm-gray';
      }

      if (member.role === 'patient') {
        return 'tw-text-zinc-300';
      }

      return '';
    }

  }
};