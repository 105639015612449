//
//
//
//
//
//
//
//
//
import Main from '@/components/tables/partials/Main';
export default {
  components: {
    Main
  },

  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        sortable: true
      }, {
        key: 'email',
        sortable: true
      }, {
        key: 'dateonboarded',
        label: 'In Stage',
        sortable: true
      }, {
        key: 'datetimelastaction',
        label: 'Last Action',
        sortable: true
      }, 'actions', {
        key: 'history',
        label: ''
      }, // { key: 'patientinfo', label: '' },
      {
        key: 'links',
        label: ''
      }],
      type: 'active'
    };
  },

  methods: {
    viewInfo(row) {
      this.$emit('view-info', row);
    },

    getStatusCount() {
      this.$emit('get-status-count');
    }

  }
};