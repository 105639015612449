//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import _ from "lodash"; // assets

import avatarFallbackImage from "@/assets/images/user.png"; // mixins

import { chatMixin } from "@/mixins/chat"; // socket

import socket from '@/socket';
export default {
  mixins: [chatMixin],

  data() {
    return {
      avatarFallbackImage,
      filter: "",
      blinkingConversations: {}
    };
  },

  computed: { ...mapState('doctorMessageRouter', ['conversations', 'isFetchingConversations', 'hasNextConversations', 'selectedConversation']),

    filteredConversations() {
      const FILTER_FIELDS = ['patient.firstName', 'patient.lastName', 'doctor.firstName', 'doctor.lastName'];
      return this.conversations.filter(convo => {
        return FILTER_FIELDS.some(field => {
          return _.get(convo, field, '').toLowerCase().includes(this.filter.toLowerCase());
        });
      });
    }

  },
  watch: {
    '$route.query.page': {
      handler(page) {
        this.fetchConversations(page || 1);
      },

      immediate: true
    }
  },

  mounted() {
    this.initSocketListeners();
  },

  methods: { ...mapActions("doctorMessageRouter", ["getConversations"]),

    onBlinkAnimationEnd(convo) {
      delete this.blinkingConversations[convo.id];

      this.blinkingConversations.__ob__.dep.notify();
    },

    initSocketListeners() {
      socket.ctx.on('doctor_router_chat:message', async data => {
        var _this$selectedConvers;

        this.fetchConversations(this.$route.query.page);
        data.conversation.forEach(async convo => {
          this.blinkingConversations[convo.id] = 1;
        });
        const receivedConvoIds = data.conversation.map(x => x.id);

        if (receivedConvoIds.includes((_this$selectedConvers = this.selectedConversation) === null || _this$selectedConvers === void 0 ? void 0 : _this$selectedConvers.id)) {
          await this.$store.dispatch('doctorMessageRouter/getConversationMessages', {
            conversationId: this.selectedConversation.id,
            skipLoading: true
          });
          await this.$_chat_scrollToBottom();
        }
      });
      socket.ctx.on('doctor_router_chat:conversation_closed', conversationId => {
        var _this$selectedConvers2;

        this.$store.commit('doctorMessageRouter/REMOVE_CONVERSATION_BY_ID', conversationId);

        if (conversationId === ((_this$selectedConvers2 = this.selectedConversation) === null || _this$selectedConvers2 === void 0 ? void 0 : _this$selectedConvers2.id)) {
          this.$store.commit('doctorMessageRouter/SET_STATE', {
            state: 'selectedConversation',
            value: null
          });
        }
      });
    },

    async fetchConversations(page) {
      await this.getConversations(page || 1);
    },

    getPatientName(convo) {
      return `${convo.patient.firstName} ${convo.patient.lastName}`;
    },

    getDoctorName(convo) {
      return `Dr. ${convo.doctor.firstName} ${convo.doctor.lastName}`;
    },

    async onConversationClick(conversation) {
      await this.$_chat_openConversation(conversation);
      this.$emit('open-convo', conversation);
    }

  }
};