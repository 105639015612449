//
//
//
//
//
//
//
//
//
import Main from '@/components/tables/partials/Main';
export default {
  components: {
    Main
  },

  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        sortable: true
      }, {
        key: 'timestampcancelled',
        label: 'Date',
        sortable: true
      }, {
        key: 'cancellationreason',
        label: 'Reason'
      }, {
        key: 'cancellationnotes',
        label: 'Notes'
      }, {
        key: 'view',
        label: 'Actions'
      }, {
        key: 'refund',
        label: ''
      }, {
        key: 'actions',
        label: ''
      }, {
        key: 'history',
        label: ''
      }, // { key: 'patientinfo', label: '' },
      {
        key: 'links',
        label: ''
      }],
      type: 'refundrequest',
      options: {
        sortDesc: 'DESC',
        sortBy: 'timestampcancelled'
      }
    };
  },

  methods: {
    viewInfo(row) {
      this.$emit('view-info', row);
    },

    getStatusCount() {
      this.$emit('get-status-count');
    }

  }
};