import { AdminsService } from '@/services/admins.service.js';

export default {
  namespaced: true,
  state: {
    adminList: [],
  },
  mutations: {
    SET_ADMIN_LIST(state, payload) {
      state.adminList = payload;
    },
  },
  actions: {
    async getAdminList({ commit }) {
      try {
        const { data } = await AdminsService.getAdmins();
        commit('SET_ADMIN_LIST', data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async createNewAdmin({ dispatch }, payload) {
      try {
        await AdminsService.createNewAdmin(payload);
        dispatch('getAdminList');
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async updateAdminDetails({ dispatch }, { adminid, payload }) {
      try {
        await AdminsService.updateAdminDetails(adminid, payload);
        dispatch('getAdminList');
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
