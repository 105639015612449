/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "md"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3" } }, [
            _c("h3", [_vm._v("Retry Payment")])
          ]),
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-small text-primary" }, [
                      _vm._v("Patient")
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.patientData.firstname) +
                          " " +
                          _vm._s(_vm.patientData.lastname)
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "3" } }, [
                            _c(
                              "span",
                              { staticClass: "text-small text-primary" },
                              [_vm._v("Subscriptions")]
                            )
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-left",
                              attrs: { offset: "3", cols: "3" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-small text-primary" },
                                [_vm._v("Retry")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.failedprescriptions, function(prescription) {
                        return _c(
                          "b-row",
                          { key: prescription.id, staticClass: "mt-2" },
                          [
                            _c("b-col", { attrs: { cols: "3" } }, [
                              _vm._v(
                                " " + _vm._s(prescription.medicinename) + " "
                              ),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "text-small text-muted" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        prescription.refills -
                                          prescription.paidrefills
                                      ) +
                                      " refills left "
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "3" }
                              },
                              [
                                _vm._v(
                                  " $" +
                                    _vm._s(prescription.totalamountwithtax) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-left",
                                attrs: { cols: "3" }
                              },
                              [
                                _c("b-checkbox", {
                                  attrs: {
                                    value: prescription.id,
                                    "unchecked-value": null
                                  },
                                  model: {
                                    value: _vm.toRetry,
                                    callback: function($$v) {
                                      _vm.toRetry = $$v
                                    },
                                    expression: "toRetry"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _c("b", [_vm._v("Total")])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "3" } },
                    [_c("b", [_vm._v("$" + _vm._s(_vm.total))])]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: { click: _vm.retryPayment }
                        },
                        [_vm._v(" Retry Payment ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }