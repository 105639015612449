/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        ref: "table",
        staticClass: "card-table pb-4",
        staticStyle: {
          "max-height": "calc(100vh - 300px) !important",
          height: "calc(100vh - 300px) !important"
        },
        attrs: {
          responsive: "",
          "select-mode": "single",
          fields: _vm.headers,
          items: _vm.items,
          "sticky-header": ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(startdatestamp)",
            fn: function(row) {
              return [
                _vm._v(" " + _vm._s(_vm.getDate(row.item.startdatestamp)) + " ")
              ]
            }
          },
          {
            key: "cell(enddatestamp)",
            fn: function(row) {
              return [
                _vm._v(" " + _vm._s(_vm.getDate(row.item.enddatestamp)) + " ")
              ]
            }
          },
          {
            key: "cell(name)",
            fn: function(row) {
              return [
                _vm.showEdit(row.item)
                  ? _c(
                      "a",
                      {
                        staticClass: "underlined",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "open-update-reassignment",
                              row.item
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " Dr. " +
                            _vm._s(row.item.firstname) +
                            " " +
                            _vm._s(row.item.lastname) +
                            " "
                        )
                      ]
                    )
                  : _c("span", { staticClass: "text-muted" }, [
                      _vm._v(
                        " Dr. " +
                          _vm._s(row.item.firstname) +
                          " " +
                          _vm._s(row.item.lastname) +
                          " "
                      )
                    ])
              ]
            }
          },
          {
            key: "cell(coveredstate)",
            fn: function(row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.getStateList(row.item.coveredstate)) + " "
                )
              ]
            }
          },
          {
            key: "cell(doctorlistdetails)",
            fn: function(row) {
              return _vm._l(row.item.doctorlistdetails, function(doctor) {
                return _c(
                  "span",
                  { key: row.item.id + "-" + doctor.doctorid },
                  [
                    _vm._v(
                      " " +
                        _vm._s(doctor.firstname) +
                        " " +
                        _vm._s(doctor.lastname) +
                        " "
                    ),
                    _vm._l(doctor.coveredstate, function(state) {
                      return _c(
                        "span",
                        {
                          key: row.item.id + "-" + state.state,
                          staticClass: "text-muted"
                        },
                        [_vm._v(" " + _vm._s(state.state) + " ")]
                      )
                    })
                  ],
                  2
                )
              })
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                row.item && row.item.reassignmentstatus == "PENDING"
                  ? _c(
                      "b-link",
                      {
                        staticClass: "tw-underline",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "open-approve-reassignment",
                              row.item
                            )
                          }
                        }
                      },
                      [_vm._v(" Approve ")]
                    )
                  : _c("span", { staticClass: "text-muted tw-underline" }, [
                      _vm._v("Approve")
                    ])
              ]
            }
          },
          {
            key: "cell(delete)",
            fn: function(row) {
              return [
                _vm.showDelete(row.item)
                  ? _c(
                      "b-link",
                      {
                        staticClass: "tw-underline",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "open-delete-reassignment",
                              row.item
                            )
                          }
                        }
                      },
                      [_vm._v(" Deny ")]
                    )
                  : _vm.showCancel(row.item)
                  ? _c(
                      "b-link",
                      {
                        staticClass: "tw-underline",
                        attrs: { href: "javscript:;" },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "open-cancel-reassignment",
                              row.item
                            )
                          }
                        }
                      },
                      [_vm._v(" Cancel ")]
                    )
                  : _c("span", { staticClass: "text-muted tw-underline" }, [
                      _vm._v("Cancel")
                    ])
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "tw-flex py-2 justify-content-end tw-mt-10" }, [
        _c(
          "div",
          { staticClass: "overflow-auto" },
          [
            _vm.currentTableRowCount
              ? _c("b-pagination", {
                  attrs: {
                    pills: "",
                    "total-rows": _vm.currentTableRowCount,
                    "per-page": 25
                  },
                  on: { "page-click": _vm.handlePaginationClick },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }