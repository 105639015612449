//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
import { subMonths, startOfMonth, endOfMonth, startOfYear, getUnixTime } from 'date-fns';
import { DoctorService } from '@/services/doctor.service';
export default {
  props: {
    currentFilter: {
      type: String,
      default: 'Last Month'
    }
  },

  data() {
    return {
      headers: [{
        key: 'name',
        label: 'Doctor'
      }, {
        key: 'accepted',
        label: 'Accepted'
      }, {
        key: 'verified',
        label: 'Verified'
      }, {
        key: 'noprescription',
        label: 'No Prescription'
      }, {
        key: 'notanswered',
        label: 'Not Answered'
      }, {
        key: 'discontinued',
        label: 'Discontinued'
      }, {
        key: 'nofollowup',
        label: 'No Follow up'
      }, // { key: 'timetorespond', label: 'Time to Respond' },
      {
        key: 'timetoaccept',
        label: 'Time to Accept'
      }],
      items: [],
      nextPage: '',
      selectedRow: null,
      start: '',
      end: '',
      searchText: ''
    };
  },

  watch: {
    async currentFilter(val) {
      // this.$store.commit('doctors/SET_DATE_FILTER', val);
      if (val == 'Last Month') {
        const startLastMonth = getUnixTime(startOfMonth(subMonths(new Date(), 1)));
        const endLastMonth = getUnixTime(endOfMonth(subMonths(new Date(), 1)));
        this.$store.commit('doctors/SET_FILTER_FROM', startLastMonth);
        this.$store.commit('doctors/SET_FILTER_TO', endLastMonth);
        this.getDoctorAnalytics(startLastMonth, endLastMonth);
      } else if (val == 'Month to Date') {
        const startMonth = getUnixTime(startOfMonth(new Date()));
        const currentDay = getUnixTime(new Date());
        this.$store.commit('doctors/SET_FILTER_FROM', startMonth);
        this.$store.commit('doctors/SET_FILTER_TO', currentDay);
        this.getDoctorAnalytics(startMonth, currentDay);
      } else if (val == 'Year to Date') {
        const yearStart = getUnixTime(startOfYear(new Date()));
        const currentDay = getUnixTime(new Date());
        this.$store.commit('doctors/SET_FILTER_FROM', yearStart);
        this.$store.commit('doctors/SET_FILTER_TO', currentDay);
        this.getDoctorAnalytics(yearStart, currentDay);
      } else if (val == 'All Time') {
        // ? Jan 2021 - Earliest possible month
        const start = getUnixTime(new Date(2021, 0, 1));
        const currentDay = getUnixTime(new Date());
        this.$store.commit('doctors/SET_FILTER_FROM', start);
        this.$store.commit('doctors/SET_FILTER_TO', currentDay);
        this.getDoctorAnalytics(start, currentDay);
      }
    }

  },

  async mounted() {
    const startMonth = getUnixTime(startOfMonth(new Date()));
    const currentDay = getUnixTime(new Date());
    this.$store.commit('doctors/SET_FILTER_FROM', startMonth);
    this.$store.commit('doctors/SET_FILTER_TO', currentDay);
    this.getDoctorAnalytics(startMonth, currentDay);
  },

  methods: {
    onScroll(e) {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
        this.getNextDoctorAnalytics();
      }
    },

    async getNextDoctorAnalytics() {
      if (!this.nextPage) {
        return;
      } else {
        const {
          data
        } = await DoctorService.getDoctorAnalytics(this.start, this.end, this.nextPage);
        const newData = data.items.map(item => ({
          name: `Dr. ${item.firstname} ${item.lastname}`,
          accepted: item.acceptedPatients,
          verified: item.verifiedPatients,
          noprescription: item.prescription,
          notanswered: item.unAnsweredFollowUps,
          discontinued: item.discontinued,
          nofollowup: item.nofollowup,
          timetoaccept: item.averageAcceptTime
        }));
        this.items = [...this.items, ...newData];

        if (data.nextpage) {
          this.nextPage = data.nextpage;
        } else {
          this.nextPage = '';
        }
      }
    },

    async getDoctorAnalytics(start, end) {
      this.start = start;
      this.end = end;
      const {
        data
      } = await DoctorService.getDoctorAnalytics(start, end);
      this.items = data.items.map(item => ({
        name: `Dr. ${item.firstname} ${item.lastname}`,
        accepted: item.acceptedPatients,
        verified: item.verifiedPatients,
        noprescription: item.prescription,
        notanswered: item.unAnsweredFollowUps,
        discontinued: item.discontinued,
        nofollowup: item.nofollowup,
        timetoaccept: item.averageAcceptTime
      }));

      if (data.nextpage) {
        this.nextPage = data.nextpage;
      }
    },

    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },

    onAnalyticsSearch: _.debounce(async function () {
      if (this.searchText) {
        const {
          data
        } = await DoctorService.getDoctorAnalyticsSearch(this.start, this.end, this.searchText);
        this.items = data.items.map(item => ({
          name: `Dr. ${item.firstname} ${item.lastname}`,
          accepted: item.acceptedPatients,
          verified: item.verifiedPatients,
          noprescription: item.prescription,
          notanswered: item.unAnsweredFollowUps,
          discontinued: item.discontinued,
          nofollowup: item.nofollowup,
          timetoaccept: item.averageAcceptTime
        }));
      } else {
        this.getDoctorAnalytics(this.start, this.end);
      }
    }, 350)
  }
};