//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import chat from '@/components/messages/Chat';
import { messageHeightMixin } from '@/mixins/messageHeightMixin';
export default {
  name: 'MessagesView',
  components: {
    chat
  },
  mixins: [messageHeightMixin],

  data() {
    return {
      userid: ''
    };
  },

  computed: { ...mapGetters({
      conversationList: 'message/FETCH_CONVERSATION'
    })
  },
  watch: {
    '$route.query.patientid': {
      async handler(val) {
        if (val) {
          await this.$store.dispatch('message/getSingleConversationList', {
            patientid: this.$route.query.patientid
          });
        } else {
          this.$store.dispatch('message/resetState');
          this.fetchConversationList();
        }
      },

      immediate: true
    }
  },

  async mounted() {
    this.userid = localStorage.getItem('userid');
  },

  methods: {
    async fetchConversationList() {
      await this.$store.dispatch('message/getConversationList', {
        isSearchEmpty: true
      });
    }

  }
};