export default {
  computed: {
    emailState() {
      let reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return this.email == '' ? null : reg.test(this.email) ? true : false;
    },
    passwordState() {
      let reg =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*(),.?";:{}|<>+=_-])(?=.{8,})/;
      return this.password == ''
        ? null
        : reg.test(this.password)
        ? true
        : false;
    },
  },
};
