//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "MaterialSymbolsLightSettingsRounded"
};