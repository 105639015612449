//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import { mapState, mapGetters } from 'vuex'; // services

import { AuthService } from '@/services/auth.service'; // components

import PatientIcon from '@/components/icon/PatientIcon';
import DoctorIcon from '@/components/icon/DoctorIcon';
import MessageIcon from '@/components/icon/MessageIcon';
import ChatIcon from '@/components/icon/ChatIcon';
import EarningsIcon from '@/components/icon/EarningsIcon';
import AdminIcon from '@/components/icon/AdminIcon';
import ProfileIcon from '@/components/icon/ProfileIcon';
import IconRouted from '@/components/icon/IconRouted';
import InventoryIcon from '@/components/icon/InventoryIcon'; // socket

import socket from '@/socket';
export default {
  components: {
    PatientIcon,
    DoctorIcon,
    MessageIcon,
    ChatIcon,
    EarningsIcon,
    IconRouted,
    AdminIcon,
    ProfileIcon,
    InventoryIcon
  },

  data() {
    return {
      currentYear: null,
      intervalFunction: null,
      showSidebar: true
    };
  },

  computed: { ...mapState('auth', ['currentUser']),
    ...mapGetters('auth', ['isSuperAdmin']),

    navList() {
      const navs = [{
        name: 'Patients',
        icon: PatientIcon,
        to: '/'
      }, {
        name: 'Doctors',
        icon: DoctorIcon,
        to: '/doctors'
      }, {
        name: 'Messages',
        icon: MessageIcon,
        to: '/messages'
      }, {
        name: 'Router',
        icon: IconRouted,
        iconClass: 'tw-text-[25px]',
        to: '/routed-messages',
        is_hidden: !this.isSuperAdmin
      }, {
        name: 'Chat',
        icon: ChatIcon,
        to: '/chat',
        hasBadge: true
      }, {
        name: 'Earnings',
        icon: EarningsIcon,
        to: '/earnings'
      }, {
        name: 'Admins',
        icon: AdminIcon,
        to: '/admins'
      }, {
        name: 'Inventory',
        icon: InventoryIcon,
        to: '/inventory'
      }];
      return navs.filter(x => !x.is_hidden);
    }

  },

  async created() {
    this.$store.dispatch('auth/getUser');
    socket.connect();
  },

  mounted() {
    const year = new Date().getFullYear();
    this.currentYear = year;
  },

  destroyed() {
    clearInterval(this.intervalFunction);
  },

  methods: {
    async onLogout() {
      await AuthService.logout();
      this.$router.push('/login');
    }

  }
};