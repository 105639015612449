/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {
      staticClass: "bg-primary tw-text-white",
      staticStyle: { height: "100vh" }
    },
    [
      _c(
        "b-col",
        { staticClass: "!tw-p-10", attrs: { cols: "12" } },
        [
          _c(
            "b-navbar-brand",
            { staticClass: "tw-mt-10 tw-mb-20", attrs: { to: "/" } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "35",
                    height: "28",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M30.846 19.216 18.232 6.603h-1.464L4.152 19.218 4.126 0H0v28h1.588l15.913-16.792 15.863 16.687.1.105H35V0h-4.18l.026 19.216Z",
                      fill: "#ffffff"
                    }
                  })
                ]
              )
            ]
          ),
          !_vm.isPasswordTemporary
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "tw-mb-20" },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "tw-mb-3 heading tw-text-[38px] tw-leading-[47px] tw-text-white"
                        },
                        [_vm._v(" Good to see you ")]
                      ),
                      _c("p", {}, [_vm._v(" Please sign in to continue ")]),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.alert.show,
                            variant: _vm.alert.type
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c("label", { attrs: { for: "email" } }, [
                        _vm._v("Email Address:")
                      ]),
                      _c("b-form-input", {
                        staticClass:
                          "tw-px-0 tw-border-b tw-border-white !tw-text-white focus:tw-border-white focus:tw-bg-transparent",
                        attrs: {
                          id: "email",
                          type: "email",
                          state: _vm.emailState,
                          disabled: _vm.processing,
                          required: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [_vm._v(" The email must have '@email.com' ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { "align-items": "center" }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col", attrs: { for: "password" } },
                            [_vm._v("Password:")]
                          )
                        ]
                      ),
                      _c("b-form-input", {
                        staticClass:
                          "tw-px-0 tw-border-b tw-border-white !tw-text-white focus:tw-border-white focus:tw-bg-transparent",
                        attrs: {
                          id: "password",
                          type: "password",
                          state: _vm.passwordState,
                          disabled: _vm.processing,
                          required: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [
                          _vm._v(
                            " Min. 8 characters with at least one capital letter, a number and a special character "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "tw-mb-3 tw-px-10",
                      attrs: {
                        type: "submit",
                        pill: "",
                        variant: "secondary",
                        size: "lg",
                        disabled: _vm.processing
                      },
                      on: { click: _vm.login }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.processing ? "Please wait" : "Login") +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "h1",
                    { staticClass: "display-4 mb-3 heading color-inherit" },
                    [_vm._v(" Good to see you ")]
                  ),
                  _c("p", [
                    _vm._v(
                      " You entered a temporary password. Kindly provide a new password for security purposes. "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "mb-4", attrs: { role: "group" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { "align-items": "center" }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "col", attrs: { for: "password" } },
                            [_vm._v("New Password:")]
                          )
                        ]
                      ),
                      _c("b-form-input", {
                        staticClass:
                          "tw-px-0 tw-border-b tw-border-[#CDFCB1] focus:tw-border-[#CDFCB1] focus:tw-bg-transparent",
                        attrs: {
                          id: "password",
                          type: "password",
                          disabled: _vm.processing,
                          required: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.loginWithNewPassword.apply(
                              null,
                              arguments
                            )
                          }
                        },
                        model: {
                          value: _vm.newPassword,
                          callback: function($$v) {
                            _vm.newPassword = $$v
                          },
                          expression: "newPassword"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-live-feedback" } },
                        [
                          _vm._v(
                            " Min. 8 characters with at least one capital letter, a number and a special character "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        type: "submit",
                        pill: "",
                        variant: "secondary",
                        size: "lg",
                        disabled: _vm.processing
                      },
                      on: { click: _vm.loginWithNewPassword }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.processing ? "Please wait" : "Continue") +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }