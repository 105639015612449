/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-text-base",
      class: {
        "tw-text-dark": _vm.isSelected,
        "tw-text-gray-500": !_vm.isSelected
      },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "p",
        {
          staticClass: "mb-0 tw-text-dark tw-gerstner",
          class: [_vm.isSelected ? "tw-font-bold" : "tw-font-normal"]
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _vm.unreadCount
        ? _c(
            "b-badge",
            {
              staticClass: "tw-px-2.5 tw-py-1",
              attrs: {
                pill: "",
                variant: _vm.isSelected ? "secondary" : "primary"
              }
            },
            [
              _c("span", { staticClass: "tw-text-xs" }, [
                _vm._v(_vm._s(_vm.unreadCount))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }