//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'MaterialSymbolsSearchRounded'
};