import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueApollo from 'vue-apollo';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

import { BootstrapVue, IconsPlugin, LayoutPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/scss/theme.scss';
import './tailwind.css';

import VuePageTransition from 'vue-page-transition';
import VueResizeObserver from 'vue-resize-observer';
import lineClamp from 'vue-line-clamp';
import vSelect from 'vue-select';
import FloatingVue from 'floating-vue';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import './modules/vee-validate';

Vue.use(LayoutPlugin, { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] });

Vue.use(PerfectScrollbar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VuePageTransition);
Vue.use(require('vue-shortkey'));
Vue.use(VueResizeObserver);
Vue.use(lineClamp);
Vue.use(FloatingVue);

Vue.component('VIcon', () => import('./icon/icon.vue'));
Vue.component('VSelect', vSelect);

import VueMask from 'v-mask';
Vue.use(VueMask);

import 'floating-vue/dist/style.css';

Vue.config.productionTip = false;

const config = {
  url: process.env.VUE_APP_CHAT_URL,
  region: 'us-west-2',
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.VUE_APP_CHAT_API_KEY,
  },
};

const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
};

export const appSyncClient = new AWSAppSyncClient(config, options);

const apolloProvider = new VueApollo({
  defaultClient: appSyncClient,
});

Vue.use(VueApollo);

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
