import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class PatientService extends BaseService {
  static async getPatients(
    type = 'all',
    page = '',
    search = '',
    sortDirection = 'DESC',
    sortField = 'lastupdateactivity'
  ) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/admin/patientDashboard/${type}`,
        {
          page,
          search,
          sortDirection,
          sortField,
        }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatient(id) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/patient/${id}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchPatient(query, tab = 'all') {
    try {
      let response;
      let encodedQuery = encodeURIComponent(query);
      if (tab == 'all') {
        response = await this.request({ auth: true }).get(
          `/admin/patient/search?contains=${encodedQuery}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/${tab}?search=${encodedQuery}`
        );
      }
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientVerification(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/verification`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadID(id, image) {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await this.request({ auth: true }).post(
        `/admin/upload/${id}/identification`,
        image,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async uploadPhoto(id, image) {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      console.log(config);
      console.log(image);
      const response = await this.request({ auth: true }).post(
        `/admin/upload/${id}/photo`,
        image,
        config
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getHSAReceipt(id, year) {
    try {
      let url = year
        ? `/admin/patient/${id}/hsareceipt?year=${year}`
        : `/admin/patient/${id}/hsareceipt`;
      const response = await this.request({ auth: true }).get(url);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFSAReceipt(id, year) {
    try {
      let url = year ? `/fsaReceipt/${id}/${year}` : `/fsaReceipt/${id}`;
      const response = await this.request({ auth: true, v2: true }).get(url);
      return response;
      // return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getNDCForm(id, year) {
    try {
      let url = year ? `/ndc/${id}/${year}` : `/ndc/${id}`;
      const response = await this.request({ auth: true, v2: true }).get(url);
      return response;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async applyOvernightShipping(id) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/admin/stripe/addOvernightShippingCharge/${id}`
      );
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }

  static async updateShippingAddress(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/user/patient/${id}/shipping`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateBillingAddress(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/user/patient/${id}/billing`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePatientData(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/user/patient/${id}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePatientEmail(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/user/patient/${id}/email`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientRecords(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/exportdata`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async resetPassword(id) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${id}/password/forgot`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async restartIntake(id) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/patient/${id}/resetonboarding`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async receiveUSPS(id, receiveUSPS) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/admin/patientReceiveUSPS/${id}`,
        {
          receiveUSPS,
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelPatient(id, data) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/transact/patient/${id}/cancel`,
        { data: { ...data } }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refundPatient(id, prescriptionid, body) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/patient/${id}/refund/${prescriptionid}`,
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async assignPatient(id, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/admin/patientVerification/${id}/accept`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getHistory(id, options) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/admin/patientInteraction/${id}`,
        options
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async exportRecords(status, from, to) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/status/${status}/export?from=${from}&to=${to}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removePatient(data) {
    try {
      const response = await this.request({ auth: true }).delete('/patient', {
        data: { ...data },
      });
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async revertRemove(data) {
    try {
      const response = await this.request({ auth: true }).put(
        '/patient/activate',
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async setPassword(email, password, confirmpassword) {
    try {
      const response = await this.request({ auth: true }).post(
        '/password/set',
        {
          email,
          password,
          newpassword: confirmpassword,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async banPatient(data, patientid) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${patientid}/ban`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async liftBanPatient(data, patientid) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${patientid}/unban`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientIntake(patientid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/admin/patientHealthQuestion/${patientid}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
