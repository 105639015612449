//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { formatDistanceToNowStrict } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import { formatDistance } from '@/services/util.js';
import IconForward from '@/components/icon/IconForward.vue';
import IconCircle from '@/components/icon/IconCircle.vue';
import IconChat from '@/components/icon/IconChat.vue';
export default {
  components: {
    IconForward,
    IconCircle,
    IconChat
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    conversation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formatTimeValue() {
      var _this$conversation;

      if ((_this$conversation = this.conversation) !== null && _this$conversation !== void 0 && _this$conversation.lastMessageActivity) {
        return formatDistanceToNowStrict(new Date(this.conversation.lastMessageActivity * 1000), {
          addSuffix: true,
          locale: { ...locale,
            formatDistance
          }
        });
      } else {
        return '';
      }
    },

    ...mapState('auth', ['currentUser'])
  }
};