//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { DoctorService } from '@/services/doctor.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      doctorData: [],
      doctorOptions: [],
      selectedDoctor: '',
      selectedDoctorStates: '',
      alert: {
        show: false,
        message: ''
      },
      outFrom: '',
      outFromTime: '',
      outTo: '',
      outToTime: '',
      reassignmentOptions: [{
        text: 'Temporary',
        value: 'TEMPORARY'
      }, {
        text: 'Permanent',
        value: 'PERMANENT'
      }],
      indefinite: false,
      reassignmentType: '',
      doctorsByState: [],
      doctorsToReassign: []
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        const {
          data
        } = await DoctorService.getAllDoctors();
        this.doctorData = data;

        for (let doctor of data) {
          this.doctorOptions.push({
            value: doctor.id,
            text: `Dr. ${doctor.firstname} ${doctor.lastname}`
          });
        }
      } else {
        this.doctorOptions = [];
        this.doctorsToReassign = [];
        this.doctorsByState = [];
        this.reassignmentType = '';
        this.outFrom = '';
        this.outTo = '';
        this.indefinite = false;
        this.alert.show = false;
        this.selectedDoctorStates = '';
        this.selectedDoctor = '';
        this.outFromTime = '';
        this.outToTime = '';
      }
    },

    reassignmentType(type) {
      this.indefinite = type == 'PERMANENT';
    },

    async selectedDoctor() {
      this.doctorsByState = [];
      this.doctorsToReassign = [];
      let selectedDoctor = this.doctorData.find(o => {
        return o.id == this.selectedDoctor;
      });
      this.selectedDoctorStates = this.getStates(selectedDoctor, false);

      for (let state of selectedDoctor.coveredstate) {
        const {
          data
        } = await DoctorService.getDoctorsByState(state.state);
        this.doctorsByState = [...this.doctorsByState, ...data];
      }

      const docToRemove = this.selectedDoctor;
      this.doctorsByState = this.doctorsByState.filter(function (val) {
        return val.id !== docToRemove;
      });
      await this.$nextTick();
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },

    getStates(doctor, abbrev = true) {
      let str = '';

      for (let state of doctor.coveredstate) {
        str += `${state.state}, `;
      }

      if (abbrev) {
        return str.substr(0, str.length - 2);
      } else {
        return `State(s): ${str.substr(0, str.length - 2)}`;
      }
    },

    getSecondsFromTime(time) {
      let timeArray = time.split(':');
      let hours = timeArray[0];
      let minutes = timeArray[1];
      let secondsInHours = Number(hours) * 60 * 60;
      let secondsInMinutes = Number(minutes) * 60;
      return secondsInHours + secondsInMinutes;
    },

    async addReassignment() {
      try {
        this.alert.show = false;

        if (!this.reassignmentType || !this.selectedDoctor || !this.outTo && this.reassignmentType == 'TEMPORARY' || !this.outToTime && this.reassignmentType == 'TEMPORARY' || !this.outFrom || !this.outFromTime) {
          this.showAlert('Please fill in all the fields.');
          return;
        }

        const outFrom = Math.round(moment(this.outFrom).format('X')) + this.getSecondsFromTime(this.outFromTime);
        const outTo = Math.round(moment(this.outTo).format('X')) + this.getSecondsFromTime(this.outToTime);
        const doctorlist = this.doctorsToReassign.filter(function (val) {
          return val !== null;
        });

        if (!doctorlist || doctorlist.length == 0) {
          this.showAlert('Please select at least one doctor to reassign to.');
          return;
        }

        const body = {
          startdatestamp: outFrom,
          doctorid: this.selectedDoctor,
          doctorlist: doctorlist,
          assignmenttype: this.reassignmentType
        };

        if (!this.indefinite) {
          body['enddatestamp'] = outTo;
        }

        const {
          data
        } = await DoctorService.addReassignment(body);

        if (data) {
          this.$emit('add-row', data);
          this.showDialog = false;
        }
      } catch (err) {
        this.showAlert(err);
      }
    }

  }
};