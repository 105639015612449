//
//
//
//
//
//
//
//
//
import Main from '@/components/tables/partials/Main';
export default {
  components: {
    Main
  },

  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        sortable: true
      }, {
        key: 'dateonboarded',
        label: 'Onboarded Date',
        sortable: true
      }, {
        key: 'state',
        label: 'State'
      }, {
        key: 'availabledoctor',
        label: 'Available Doctors'
      }, {
        key: 'assign',
        label: 'Actions'
      }, {
        key: 'actions',
        label: ''
      }, {
        key: 'history',
        label: ''
      }, // { key: 'patientinfo', label: '' },
      {
        key: 'links',
        label: ''
      }],
      type: 'notaccepted'
    };
  },

  methods: {
    viewInfo(row) {
      this.$emit('view-info', row);
    },

    getStatusCount() {
      this.$emit('get-status-count');
    }

  }
};