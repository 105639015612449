//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import constants from '@/constants';
export default {
  props: {
    count: {
      type: Object,

      default() {
        return {};
      }

    },
    tab: {
      type: String,
      default: 'all'
    }
  },

  data() {
    return {
      tableLinks: {}
    };
  },

  mounted() {
    this.tableLinks = constants.tableLinks;
  },

  methods: {
    setTab(tab) {
      this.$store.commit('patients/SET_TAB_NAME', tab.text);
      this.$emit('set-tab', tab.id);
    },

    getCategoryName(category) {
      return Object.keys(category)[0];
    }

  }
};