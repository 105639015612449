//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import { mapState } from 'vuex';
import _ from 'lodash';
import { format } from 'date-fns'; // components

import ChatMessageInputArea from '@/components/chat/chat-message-list/ChatMessageInputArea.vue';
import ChatMessagesContainer from '@/components/chat/chat-messages/ChatMessagesContainer.vue';
import ChatDismissModal from '@/components/chat/ChatDismissModal.vue';
import ChatReopenModal from '@/components/chat/ChatReopenModal.vue';
import ChatMacroDialog from '@/components/chat/ChatMacroDialog.vue';
import ChatMacroEditModal from '@/components/chat/ChatMacroEditModal.vue'; // constants

import { TYPING_INDICATOR } from '@/constants'; // utils

import { sleep } from '@/utils/sleep';
export default {
  components: {
    ChatMessagesContainer,
    ChatMessageInputArea,
    ChatDismissModal,
    ChatReopenModal,
    ChatMacroDialog,
    ChatMacroEditModal
  },
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    },
    isMessagesLoaded: {
      type: Boolean,
      default: false
    },
    socket: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: 'all'
    },
    onlineStatusResponse: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isEventsHidden: false,
      isAssigned: null,
      isRecipientTyping: false,
      onlineStatus: false,
      onlineStatusInterval: null,
      typersList: [],
      isNote: false,
      showMacroEdit: false
    };
  },

  computed: { ...mapState('chat', ['conversations', 'messages']),
    ...mapState('auth', ['currentUser']),

    stringifyTypers() {
      return this.typersList.map(typer => typer.name).join(', ');
    },

    bgModal() {
      return {
        color: this.showMacroEdit ? 'rgba(12, 53, 35, 0.2)' : 'rgba(12, 53, 35, 0.0)',
        show: this.showMacroEdit ? 'block' : 'none'
      };
    },

    patientName() {
      return `${this.conversation.patient.firstName} ${this.conversation.patient.lastName}`;
    }

  },
  watch: {
    conversation(conversation) {
      this.isAssigned = conversation === null || conversation === void 0 ? void 0 : conversation.status;
    },

    isMessagesLoaded(val) {
      if (!val) {
        this.resetTypersListState();
      }
    },

    messages(val) {
      if (val.length > 0) {
        const lastMessage = val[0];

        if (lastMessage.type === 'MESSAGE') {
          this.removeTypingIndicator({
            userId: lastMessage.data.sender.id
          });
        }
      }
    },

    onlineStatus(val) {
      if (!val) {
        this.removeTypingIndicator({
          userId: this.conversation.patient.id
        });
      }
    },

    onlineStatusResponse(val) {
      this.removeTypingIndicator({
        userId: val.userId
      });
    }

  },

  mounted() {
    this.startWatchingTyping();
    this.isOnline();
  },

  destroyed() {
    clearInterval(this.onlineStatusInterval);
  },

  methods: {
    startWatchingTyping() {
      var _this$socket, _this$socket2;

      (_this$socket = this.socket) === null || _this$socket === void 0 ? void 0 : _this$socket.emit('typing_activity:watch', {
        conversationIds: [this.conversation.id]
      });
      (_this$socket2 = this.socket) === null || _this$socket2 === void 0 ? void 0 : _this$socket2.on('typing_activity:typing', this.handleTypingActivity);
    },

    handleTypingActivity(payload) {
      if (!payload.conversationId.includes(this.conversation.id)) {
        return;
      }

      if (payload.userId === this.currentUser.id) {
        return;
      }

      this.isRecipientTyping = true;
      this.addToTypersList(payload);
    },

    async addToTypersList(payload) {
      const typerName = `${payload.firstName} ${payload.lastName}`;
      const isTyperIncluded = this.typersList.some(typer => typer.name === typerName);

      if (!isTyperIncluded) {
        this.typersList.push({
          name: typerName,
          userId: payload.userId
        });
        await sleep(10000);
        this.removeTypingIndicator(payload);
      }
    },

    removeTypingIndicator(payload) {
      this.typersList = this.typersList.filter(typer => typer.userId !== payload.userId);
      this.isRecipientTyping = this.typersList.length > 0;
    },

    onType: _.throttle(function () {
      var _this$socket3;

      if (this.isNote) return;
      (_this$socket3 = this.socket) === null || _this$socket3 === void 0 ? void 0 : _this$socket3.emit('typing_activity:typing', {
        conversationId: [this.conversation.id]
      });
    }, TYPING_INDICATOR.THROTTLE_TIME),

    async assignConversation(conversationid, assign) {
      await this.$store.dispatch('chat/assignConversation', {
        conversationid,
        assign
      });
      this.$store.dispatch('chat/getMetrics');

      if (this.category === 'assigned') {
        if (!assign) {
          const filteredConversation = this.conversations.filter(conversation => conversation.id !== this.conversation.id);
          this.$store.dispatch('chat/updateConversations', filteredConversation);
        }

        this.$store.dispatch('chat/deselectConversation');
        this.$store.dispatch('chat/clearMessages');
      }

      if (this.category === 'open') {
        if (assign) {
          const filteredConversation = this.conversations.filter(conversation => conversation.id !== this.conversation.id);
          this.$store.dispatch('chat/updateConversations', filteredConversation);
        }
      }

      this.isAssigned = !this.isAssigned;

      if (this.isAssigned) {
        this.$store.dispatch('chat/refreshAssignee');
      } else {
        this.$store.dispatch('chat/setAssigneeManual', null);
      }
    },

    onShowDismissModal() {
      this.$refs.ChatDismissModal.showDialog = true;
    },

    onShowReopenModal() {
      this.$refs.ChatReopenModal.showDialog = true;
    },

    async dismissConversation() {
      await this.$store.dispatch('chat/dismissConversation', {
        patientId: this.conversation.patient.id,
        conversationId: this.conversation.id
      });
      this.$refs.ChatDismissModal.showDialog = false;
      this.$store.dispatch('chat/deselectConversation');
      this.$store.dispatch('chat/clearMessages');
      this.$store.dispatch('chat/getMetrics');
    },

    async reopenConversation() {
      await this.$store.dispatch('chat/reopenConversation', {
        patientId: this.conversation.patient.id
      });
      this.$refs.ChatReopenModal.showDialog = false;
      this.$store.dispatch('chat/deselectConversation');
      this.$store.dispatch('chat/clearMessages');
      this.$store.commit('chat/REMOVE_CONVERSATION', this.conversation.id);
    },

    toggleProfile() {
      this.$store.dispatch('chat/viewPatient', this.conversation.patient.id);
      this.$store.dispatch('chat/toggleProfile');
    },

    async onMessageSent() {
      var _this$$refs$ChatMessa;

      await sleep(1000);
      this.$store.dispatch('chat/moveChatToTop', this.conversation.id);
      (_this$$refs$ChatMessa = this.$refs.ChatMessagesContainer.$refs.bottomOfPage) === null || _this$$refs$ChatMessa === void 0 ? void 0 : _this$$refs$ChatMessa.scrollIntoView({
        behavior: 'smooth'
      });
    },

    async isOnline() {
      const checkOnlineStatus = async () => {
        const newOnlineStatus = this.conversation.isOnline;

        if (newOnlineStatus !== this.onlineStatus) {
          this.onlineStatus = newOnlineStatus;

          if (newOnlineStatus) {
            clearInterval(this.onlineStatusInterval);
          } else {
            this.onlineStatusInterval = setInterval(() => {
              this.onlineStatus = this.conversation.isOnline;
            }, 1000);
          }
        }

        window.requestAnimationFrame(checkOnlineStatus);
      };

      checkOnlineStatus();
    },

    selectedCategoryTab(category) {
      return this.category === category;
    },

    handleMessageCategoryChanged(newCategory) {
      this.isNote = newCategory === 'note';
    },

    formatDate(date, formatString) {
      return format(new Date(date), formatString);
    },

    resetTypersListState() {
      this.isRecipientTyping = false;
      this.typersList = [];
    }

  }
};