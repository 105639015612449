export function scrollToBottomHandler (element, options) {
  const optionsWithDefaults = Object.assign({
    animate: false
  }, options)

  if (optionsWithDefaults.animate) {
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    })
  } else {
    element.scrollTop = element.scrollHeight
  }
}

export function scrollToBottom (element, options) {
  scrollToBottomHandler(element, options)
}