/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tw-p-6" }, [
    _c(
      "div",
      {
        staticClass:
          "tw-border tw-border-solid tw-border-slate-300 tw-rounded-lg",
        class: _vm.messageCategory !== "chat" ? "tw-bg-[#FDEDAF]" : ""
      },
      [
        _c(
          "div",
          [
            _c("b-form-textarea", {
              staticClass:
                "focus:tw-border-none tw-border-none tw-bg-transparent",
              class:
                _vm.messageCategory !== "chat" ? "focus:tw-bg-[#FDEDAF]" : "",
              attrs: { rows: "6", "max-rows": "6", "no-resize": "" },
              on: {
                input: function($event) {
                  return _vm.$emit("input")
                }
              },
              model: {
                value: _vm.message,
                callback: function($$v) {
                  _vm.message = $$v
                },
                expression: "message"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tw-p-2 tw-flex tw-justify-between tw-items-center" },
          [
            _c(
              "div",
              { staticClass: "tw-flex tw-items-center tw-space-x-2" },
              [
                _vm.macroLoading
                  ? _c("b-spinner", { attrs: { small: "", variant: "muted" } })
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "tw-cursor-pointer",
                        attrs: { title: "Insert macro" },
                        on: { click: _vm.openMacroDialog }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 14.84 18.51",
                              width: "13",
                              height: "16",
                              fill: "#828282"
                            }
                          },
                          [
                            _c("path", {
                              staticClass: "cls-1",
                              attrs: {
                                d:
                                  "M.19,6.01h14.64c0,1.63,0,3.23,0,4.82,0,2.06.02,4.13,0,6.19-.02,1.45-.75,1.86-1.99,1.14-1.32-.76-2.65-1.51-3.94-2.31-.89-.55-1.74-.64-2.67-.1-1.49.87-2.99,1.72-4.51,2.53-.89.48-1.68.13-1.69-.83-.04-3.76,0-7.51.01-11.27,0-.06.11-.12.16-.17Z"
                              }
                            }),
                            _c("path", {
                              staticClass: "cls-1",
                              attrs: {
                                d:
                                  "M14.75,3.63H.08C-.13,1.4,1.01.04,3.13.02c2.85-.03,5.7-.03,8.55,0,2.13.02,3.27,1.36,3.08,3.61Z"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer",
                    on: { click: _vm.toggleMessageCategory }
                  },
                  [
                    _vm.messageCategory === "chat"
                      ? _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "16",
                              viewBox: "0 0 20 16",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M5.47189 16V13.1734C5.47189 12.8316 5.3693 12.7264 5.03599 12.7264H1.19017C1.06229 12.7395 0.933437 12.7395 0.80556 12.7264C0.67056 12.7079 0.546334 12.6409 0.45483 12.5374C0.363326 12.4339 0.310467 12.3006 0.305618 12.1611V0.946739C0.299169 0.876778 0.299169 0.806305 0.305618 0.736344C0.305618 0.223616 0.523547 0.0133495 1.02351 0.000202625H18.7017C18.8484 -0.00373316 18.991 0.0497916 19.1005 0.149934C19.2101 0.250077 19.2783 0.389354 19.2913 0.539269C19.3035 0.64847 19.3035 0.75874 19.2913 0.867941V11.8455C19.3039 11.9547 19.3039 12.065 19.2913 12.1742C19.2819 12.3198 19.2205 12.4567 19.1188 12.5586C19.017 12.6605 18.8822 12.7203 18.7401 12.7264H9.228C9.13784 12.7223 9.04787 12.7376 8.96393 12.7715C8.87998 12.8055 8.80394 12.8573 8.74085 12.9235L5.5873 15.8552L5.47189 16Z",
                                fill: "#828282"
                              }
                            })
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "20",
                              height: "20",
                              fill: "none",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#828282",
                                d:
                                  "M17.5 21h1v-2.5H21v-1h-2.5V15h-1v2.5H15v1h2.5V21Zm.5 2q-2.075 0-3.538-1.463T13 18q0-2.075 1.463-3.538T18 13q2.075 0 3.538 1.463T23 18q0 2.075-1.463 3.538T18 23ZM7 9h10V7H7v2Zm4.675 12H5q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h14q.825 0 1.413.588T21 5v6.7q-.725-.35-1.463-.525T18 11q-.275 0-.513.012t-.487.063V11H7v2h6.125q-.45.425-.813.925T11.675 15H7v2h4.075q-.05.25-.063.488T11 18q0 .825.15 1.538T11.675 21Z"
                              }
                            })
                          ]
                        ),
                    _c("p", { staticClass: "tw-mb-0 tw-text-[#828282]" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.messageCategory === "chat"
                              ? "Winona Chat"
                              : "Notes"
                          ) +
                          " "
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm.hasMessageBody
              ? _c("b-tooltip", { attrs: { target: "send-button" } }, [
                  _vm._v(" Send message ")
                ])
              : _vm._e(),
            _c(
              "div",
              {
                class: !_vm.hasMessageBody
                  ? "tw-opacity-50 !tw-cursor-default"
                  : "!tw-cursor-pointer",
                attrs: { id: "send-button" },
                on: { click: _vm.sendMessage }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "22",
                      height: "19",
                      viewBox: "0 0 22 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M0.601562 7.28363V0L21.6016 9.10453L0.601562 18.5732V10.9254L12.7033 9.10453L0.601562 7.28363Z",
                        fill: "#828282"
                      }
                    })
                  ]
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }