//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AdminsTable from '@/components/tables/AdminsTable';
import NewAdminDialog from '@/components/admins/NewAdminDialog';
export default {
  components: {
    AdminsTable,
    NewAdminDialog
  },

  data() {
    return {
      adminInfo: null,
      isTransfer: false
    };
  },

  methods: {
    onRoleChange(adminInfo) {
      this.adminInfo = adminInfo;
      this.isTransfer = false;
      this.$refs.newAdminDialog.showDialog = true;
    },

    onRoleTransfer(adminInfo) {
      this.adminInfo = adminInfo;
      this.isTransfer = true;
      this.$refs.newAdminDialog.showDialog = true;
    }

  }
};