import { ChatService } from '@/services/chat.service.js';

export default {
  namespaced: true,
  state: {
    converstationList: [],
    conversationListNextPage: [],
    conversationListPageNumber: 1,
    maxPageNumber: 1,

    conversationForwardRequests: [],
  },
  mutations: {
    APPEND_CONVERSATION(state, converstationList) {
      state.converstationList = [
        ...state.converstationList,
        ...converstationList,
      ];
    },
    SET_CONVERSATION(state, converstationList) {
      state.converstationList = converstationList;
    },
    SET_CONVERSATION_LIST_NEXT_PAGE(state, conversationListNextPage) {
      state.conversationListNextPage.push(conversationListNextPage);
    },
    SET_CONVERSATION_LIST_PAGE_NUMBER(state, conversationListPageNumber) {
      if (conversationListPageNumber > state.maxPageNumber) {
        state.maxPageNumber = conversationListPageNumber;
      }
      state.conversationListPageNumber = conversationListPageNumber;
    },
    SET_CONVERSATION_FORWARD_REQUESTS(state, conversationForwardRequests) {
      state.conversationForwardRequests = conversationForwardRequests;
    },
    RESET_STATE(state) {
      state.conversationListNextPage = [];
      state.conversationListPageNumber = 1;
      state.maxPageNumber = 1;
    },
  },
  getters: {
    FETCH_CONVERSATION(state) {
      return state.converstationList;
    },
  },
  actions: {
    async getConversationList({ commit, state }, payload) {
      try {
        if (state.maxPageNumber <= state.conversationListPageNumber) {
          const { data: conversation } = await ChatService.listConversation(
            state.conversationListNextPage[state.maxPageNumber - 1]
          );
          commit(
            payload?.isSearchEmpty ? 'SET_CONVERSATION' : 'APPEND_CONVERSATION',
            conversation.items
          );
          commit('SET_CONVERSATION_LIST_NEXT_PAGE', conversation.nextpage);
          return conversation;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getSingleConversationList({ commit }, { patientid }) {
      try {
        const { data: conversation } = await ChatService.listSingleConversation(
          patientid
        );
        commit('SET_CONVERSATION', [conversation]);
        return conversation;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async searchConversationList({ commit, dispatch }, searchQuery) {
      try {
        if (searchQuery) {
          const { data: conversation } = await ChatService.searchConversation(
            searchQuery
          );
          commit('SET_CONVERSATION', conversation);
          commit('RESET_STATE');
          return conversation;
        } else {
          commit('RESET_STATE');
          await dispatch('getConversationList', { isSearchEmpty: true });
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async ignoreMessage({ dispatch }, { conversationid }) {
      await ChatService.ignoreMessage(conversationid);
      dispatch('dashboard/getDashboardData', null, { root: true });
    },
    async snoozeMessage({ dispatch }, { conversationid }) {
      await ChatService.snoozeMessage(conversationid);
      dispatch('dashboard/getDashboardData', null, { root: true });
    },
    async needPatientReply(context, conversationid) {
      try {
        await ChatService.needPatientReply(conversationid);
      } catch (e) {
        console.error(e);
        return e;
      }
    },
    setConversationListPage({ commit }, pageNumber) {
      commit('SET_CONVERSATION_LIST_PAGE_NUMBER', pageNumber);
    },
    nextConversationListPage({ commit, state }) {
      commit(
        'SET_CONVERSATION_LIST_PAGE_NUMBER',
        (state.conversationListPageNumber += 1)
      );
    },
    previousConversationListPage({ commit, state }) {
      commit(
        'SET_CONVERSATION_LIST_PAGE_NUMBER',
        (state.conversationListPageNumber -= 1)
      );
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },

    async markAsRead(context, { conversationid, messageid }) {
      try {
        return ChatService.markAsRead(conversationid, messageid).then(
          (data) => data
        );
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async markAsUnread(context, { conversationid }) {
      try {
        return ChatService.markAsUnread(conversationid).then((data) => data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getUnreadMessageCount({ rootState }) {
      try {
        if (rootState?.auth?.currentUser?.id) {
          const { data } = await ChatService.getUnreadMessageCount(
            rootState?.auth?.currentUser?.id
          );
          return data;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async forwardRequest({ dispatch }, { conversationid, payload }) {
      try {
        const { data } = await ChatService.forwardRequest(
          conversationid,
          payload
        );
        dispatch('getConversationForwardRequests', { conversationid });
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getConversationForwardRequests({ commit }, { conversationid }) {
      try {
        const { data } = await ChatService.getConversationForwardRequests(
          conversationid
        );
        commit('SET_CONVERSATION_FORWARD_REQUESTS', data);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getSingleForwardRequest(
      context,
      { conversationid, forwardedmessageid }
    ) {
      try {
        const { data } = await ChatService.getSingleForwardRequest(
          conversationid,
          forwardedmessageid
        );
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};
