import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class LookupService extends BaseService {
  static async getCancelReason() {
    try {
      const response = await this.request({ auth: true }).get(
        '/lookup/cancelreason'
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getStates() {
    try {
      const response = await this.request({ auth: false }).get('/lookup/state');
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
