export default {
  namespaced: true,
  state: {
    displayAirtable: false,
    airtableDisplayPrescription: {},
  },
  mutations: {
    SET_DISPLAY_AIRTABLE(state, display) {
      state.displayAirtable = display;
    },
    SET_AIRTABLE_DISPLAY_PRESCRIPTION(state, prescription) {
      state.airtableDisplayPrescription = prescription;
    }
  },
  getters: {
    getSelectedPrescriptionId(state) {
      return state.airtableDisplayPrescription?.id;
    }
  },
  actions: {
    openAirtableDisplay({ commit }, prescription) {
      commit('SET_AIRTABLE_DISPLAY_PRESCRIPTION', prescription);
      commit('SET_DISPLAY_AIRTABLE', true);
    },
    closeAirtableDisplay({ commit }) {
      commit('SET_AIRTABLE_DISPLAY_PRESCRIPTION', {});
      commit('SET_DISPLAY_AIRTABLE', false);
    },
    clearSelectedPrescription({ commit }) {
      commit('SET_AIRTABLE_DISPLAY_PRESCRIPTION', {});
    }
  }
}