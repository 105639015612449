export function prefixObservable (observables, prefix) {
  return Object.keys(observables).reduce((acc, curr) => {
    acc[`${prefix}${curr}`] = {
      get () {
        return observables[curr];
      },
    };

    return acc;
  }, {});
}
