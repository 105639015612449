//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// libs
import { mapState } from 'vuex'; // components

import ChatMessageListItem from './chat-message-list/ChatMessageListItem.vue';
export default {
  components: {
    ChatMessageListItem
  },
  props: {
    category: {
      type: String,
      default: 'all'
    },
    isProcessing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedConversation: ''
    };
  },

  computed: { ...mapState('chat', ['conversations', 'isFetchingConversations']),

    chatTitles() {
      return this.category === 'assigned' ? 'My Inbox' : this.category === 'all' ? 'All' : this.category === 'open' ? 'Unassigned' : 'Closed';
    }

  },
  methods: {
    onConversationSelect(conversation) {
      this.selectedConversation = conversation.id;
      this.$emit('select', conversation);
    }

  }
};