import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class MacroService extends BaseService {
  static async getAllMacros(category) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/templatecategory/${category}/macro/all`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFrequentlyUsedMacros(category) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/templatecategory/${category}/macro`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async createMacro(category, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/templatecategory/${category}/macro`,
        data
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateMacro(category, id, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/templatecategory/${category}/macro/${id}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteMacro(category, id) {
    try {
      const response = await this.request({ auth: true, v2: true }).delete(
        `/templatecategory/${category}/macro/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}