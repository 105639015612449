//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { formatUnix } from "@/utils/time";
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: { ...mapState('auth', ['currentUser']),
    ...mapState('doctorMessageRouter', ['selectedConversation']),

    isMe() {
      const {
        currentUser,
        message
      } = this;
      return currentUser.id === message.userid;
    },

    friendlySentAt() {
      return formatUnix(this.message.sentat, "MMMM d, yyyy h:mmaaa");
    },

    bubbleBackgroundColorClass() {
      const {
        member,
        message
      } = this;

      if (message.messageroutedto) {
        return 'tw-bg-light-gray';
      } // Retain doctor color even if message is for admin review


      if (!message.foradminreview && member.role !== 'doctor') {
        return 'tw-bg-light-gray';
      }

      if (member.role === 'admin') {
        return 'tw-bg-mischka-light';
      }

      if (member.role === 'doctor') {
        return 'tw-bg-warm-gray';
      }

      if (member.role === 'patient') {
        return 'tw-bg-zinc-300';
      }

      return '';
    },

    member() {
      const {
        message,
        currentUser,
        selectedConversation
      } = this;
      const userId = message.userid;

      if (userId === selectedConversation.doctor.id) {
        return {
          id: selectedConversation.doctor.id,
          firstname: selectedConversation.doctor.firstname,
          lastname: selectedConversation.doctor.lastname,
          role: 'doctor'
        };
      }

      if (userId === selectedConversation.patient.id) {
        return {
          id: selectedConversation.patient.id,
          firstname: selectedConversation.patient.firstname,
          lastname: selectedConversation.patient.lastname,
          role: 'patient'
        };
      }

      return {
        id: currentUser.id,
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        role: 'admin'
      };
    },

    avatarDetails() {
      const {
        member
      } = this;

      if (member.role === 'admin') {
        return {
          text: 'A',
          class: 'tw-bg-eclipse'
        };
      }

      if (member.role === 'doctor') {
        return {
          text: 'D',
          class: 'tw-bg-secondary'
        };
      }

      if (member.role === 'patient') {
        return {
          text: 'P',
          class: 'tw-bg-primary'
        };
      }

      return null;
    }

  }
};