//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Services
import { ActionService } from '@/services/action.service';
import { mapGetters } from 'vuex';
import TableList from '@/components/tables/list/TableList'; // Tables

import AllTable from '@/components/tables/AllTable';
import ActiveTable from '@/components/tables/ActiveTable';
import FailedTable from '@/components/tables/FailedTable';
import NeedsReplyTable from '@/components/tables/NeedsReplyTable';
import FollowUpStartTable from '@/components/tables/FollowUpStartTable';
import FollowUpCompleteTable from '@/components/tables/FollowUpCompleteTable';
import FullyCancelledTable from '@/components/tables/FullyCancelledTable';
import OnboardingTable from '@/components/tables/OnboardingTable';
import VerificationRequiredTable from '@/components/tables/VerificationRequiredTable';
import VerificationRequiredDoctorTable from '@/components/tables/VerificationRequiredDoctorTable';
import DoctorRequestTable from '@/components/tables/DoctorRequestTable';
import RefundRequestTable from '@/components/tables/RefundRequestTable';
import NotAcceptedTable from '@/components/tables/NotAcceptedTable';
import InactiveTable from '@/components/tables/InactiveTable';
import RemovedPatientsTable from '@/components/tables/RemovedPatientsTable';
import OrderHoldsTable from '@/components/tables/OrderHoldsTable';
import CardExpiredTable from '@/components/tables/CardExpiredTable';
import TransferRxTable from '@/components/tables/TransferRxTable';
import AddressVerificationRequiredTable from '@/components/tables/AddressVerificationRequiredTable';
import VoicemailTable from '@/components/tables/VoicemailTable';
import NoFollowUpTable from '@/components/tables/NoFollowUpTable';
import BannedTable from '@/components/tables/BannedTable';
import CancelledByStripeTable from '@/components/tables/CancelledByStripeTable';
import PrescriptionEndBeforeFollowupTable from '@/components/tables/PrescriptionEndBeforeFollowupTable';
import ScriptEndBeforeFollowupNoDhea from '@/components/tables/ScriptEndBeforeFollowupNoDhea';
import CardUpdatedWithoutPrescriptionRestartTable from '@/components/tables/CardUpdatedWithoutPrescriptionRestartTable'; // Dialogs

import ActionDialog from '@/components/actions/ActionDialog';
import ActionHistory from '@/components/actions/ActionHistory';
import PatientsInfoSidePanel from '@/components/patients/PatientsInfoSidePanel';
import { patientViewMixin } from '@/mixins/patientView';
export default {
  components: {
    TableList,
    AllTable,
    ActiveTable,
    FailedTable,
    NeedsReplyTable,
    FollowUpStartTable,
    FollowUpCompleteTable,
    FullyCancelledTable,
    OnboardingTable,
    VerificationRequiredTable,
    VerificationRequiredDoctorTable,
    DoctorRequestTable,
    RefundRequestTable,
    NotAcceptedTable,
    InactiveTable,
    RemovedPatientsTable,
    OrderHoldsTable,
    CardExpiredTable,
    TransferRxTable,
    AddressVerificationRequiredTable,
    BannedTable,
    // PatientInfoView,
    ActionDialog,
    ActionHistory,
    VoicemailTable,
    NoFollowUpTable,
    CancelledByStripeTable,
    PrescriptionEndBeforeFollowupTable,
    ScriptEndBeforeFollowupNoDhea,
    CardUpdatedWithoutPrescriptionRestartTable,
    PatientsInfoSidePanel
  },
  mixins: [patientViewMixin],

  beforeRouteLeave(to, from, next) {
    this.$store.commit('patients/RESET_TAB');
    next();
  },

  data() {
    return {
      showTableList: true,
      search: '',
      tab: 'all',
      count: {
        all: '-',
        active: '-',
        inactive: '-',
        failedtransaction: '-',
        needspatientreply: '-',
        followupstart: '-',
        followupcompletion: '-',
        fullycancelled: '-',
        onboarding: '-',
        verificationrequired: '-',
        doctorrequest: '-',
        refundrequest: '-',
        notaccepted: '-',
        'cs-hold': '-',
        'card-expired': '-',
        transferrx: '-',
        deleted: '-',
        failedaddress: '-',
        voicemail: '-',
        'no-follow-up': '-',
        banned: '-',
        'cancelled-by-stripe': '-',
        'prescription-end-before-followup': '-',
        'card-updated-without-prescription-restart': '-'
      },
      refList: {
        all: 'AllTable',
        active: 'ActiveTable',
        inactive: 'InactiveTable',
        failedtransaction: 'FailedTable',
        needspatientreply: 'NeedsReplyTable',
        followupstart: 'FollowUpStartTable',
        followupcompletion: 'FollowUpCompleteTable',
        fullycancelled: 'FullyCancelledTable',
        onboarding: 'OnboardingTable',
        verificationrequired: 'VerificationRequiredTable',
        verificationrequireddoctor: 'VerificationRequiredDoctorTable',
        doctorrequest: 'DoctorRequestTable',
        refundrequest: 'RefundRequestTable',
        notaccepted: 'NotAcceptedTable',
        'cs-hold': 'OrderHoldsTable',
        'card-expired': 'CardExpiredTable',
        transferrx: 'TransferRxTable',
        failedaddress: 'AddressVerificationRequiredTable',
        voicemail: 'VoicemailTable',
        'no-follow-up': 'NoFollowUpTable',
        banned: 'BannedTable',
        'cancelled-by-stripe': 'CancelledByStripeTable',
        'prescription-end-before-followup': 'PrescriptionEndBeforeFollowupTable',
        'script-end-before-followup-no-dhea': 'ScriptEndBeforeFollowupNoDhea',
        'card-updated-without-prescription-restart': 'CardUpdatedWithoutPrescriptionRestartTable'
      },
      selectedRow: {
        id: ''
      },
      showInfoViewModal: false,
      toast: {
        message: '',
        variant: ''
      },
      // Generic alert component
      alert: {
        show: false,
        message: ''
      },
      patientInfoViewCheck: false,
      currentPage: this.$store.state.currentPage
    };
  },

  computed: { ...mapGetters({
      tabName: 'patients/getTabName',
      pageNumberStore: 'tables/getCurrentPage',
      currentTableRowCount: 'tables/getRowCount'
    }),

    showEmptyResultsAlert() {
      return this.$store.state.patients.emptyResultsAlert;
    }

  },
  watch: {
    pageNumberStore() {
      this.currentPage = this.pageNumberStore;
    },

    currentPage(val) {
      this.$store.commit('tables/SET_CURRENT_PAGE', val);
    },

    tab() {
      this.$store.commit('patients/SET_TAB', this.tab);
      this.$store.commit('patients/SET_SHOW_EMPTY_SEARCH_ALERT', false);
      this.getStatusCount();
      this.alert.show = false;
      this.search = '';
      this.$store.commit('tables/SET_SEARCH', '');
    }

  },

  async mounted() {
    await this.loadPatients();
  },

  methods: {
    async loadPatients() {
      this.$store.commit('tables/SET_CURRENT_PAGE', 1);
      this.currentPage = 1;

      if (this.$route.name == 'patientprofile' && this.$route.params.patientid) {
        try {
          this.selectedRow.id = this.$route.params.patientid;
          await this.$nextTick();
          this.$refs.PatientsInfoSidePanel.show();
        } catch (err) {
          console.log(err);
          this.showAlert('A patient with this ID could not be found.');
        }
      }

      this.patientInfoViewCheck = true;
      await this.getStatusCount();
    },

    searchPatients() {
      this.$store.commit('tables/SET_SEARCH', this.search);
    },

    handlePaginationClick(event, pageNumber) {
      this.$store.commit('tables/SET_CURRENT_PAGE', pageNumber); // this.$refs[this.refList[this.tab]].handlePaginationClick(
      //   event,
      //   pageNumber
      // );
    },

    setTab(tab) {
      this.tab = tab;
      this.currentPage = 1;
    },

    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },

    async getStatusCount() {
      try {
        const {
          data
        } = await ActionService.getStatusCount();
        this.count = { ...data
        };
      } catch (err) {
        console.log('Could not get status counts');
      }
    },

    async viewInfo(row) {
      this.selectedRow = row;
      this.alert.show = false;
      this.showInfoViewModal = true;
      await this.$nextTick();
      this.$refs.PatientsInfoSidePanel.show();
    },

    showToast(message, variant = 'danger') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('viewToast');
    },

    updateTime(id) {
      this.$refs[this.refList[this.tab]].updateTime(id);
    },

    clearSearch() {
      this.search = '';
      this.$refs.searchInput.focus();
      this.$store.commit('tables/SET_SEARCH', ' ');
    }

  }
};