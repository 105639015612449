import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class AirtableService extends BaseService {
  static async getAirtableOrders(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/getorder`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateAddress(prescriptionorderid, address) {
    try {
      const response = await this.request({ auth: true }).post(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/update/address`,
        {
          address,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateShipping(prescriptionorderid, shipping) {
    try {
      const response = await this.request({ auth: true }).post(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/update/shipping`,
        {
          shipping,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removeAddress(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/delete/address`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removeShipping(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/delete/shipping`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async holdOrder(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/status/hold`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async liftOrder(prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/airtable/status/lift`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
