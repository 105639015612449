/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-3 pt-4" },
    [
      _c(
        "b-row",
        { staticClass: "py-4 d-flex align-items-center" },
        [
          _c("b-col", [
            _c("h1", { staticClass: "h1 ml-3 mb-0" }, [_vm._v("Admins")])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", pill: "", block: "" },
                  on: {
                    click: function($event) {
                      _vm.$refs.newAdminDialog.showDialog = true
                    }
                  }
                },
                [_vm._v(" New ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("NewAdminDialog", {
        ref: "newAdminDialog",
        attrs: { admin: _vm.adminInfo, "is-transfer": _vm.isTransfer },
        on: {
          hide: function($event) {
            _vm.adminInfo = null
          }
        }
      }),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("AdminsTable", {
            on: {
              "role-change": _vm.onRoleChange,
              "role-transfer": _vm.onRoleTransfer
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }