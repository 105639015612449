import Vue from 'vue';
import { ChatService } from '@/services/chat.service.js';

export default {
  namespaced: true,
  state: {
    conversations: [],
    messages: [],
    filteredMessages: [],
    isFetchingConversations: false,
    nextPage: '',
    selectedConversation: null,
    conversationCount: {},
    showEvents: true,
    showProfile: false,
    selectedPatient: null,
    category: '',
    assignee: null,
    macroDialog: false,
    messageTemplate: {},
  },
  mutations: {
    SET_OPEN_MACRO(state, open) {
      state.macroDialog = open;
    },
    SET_ASSIGNEE(state, name) {
      state.assignee = name;
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
    SET_CONVERSATIONS(state, conversations) {
      Vue.set(state, 'conversations', conversations);
    },
    SET_SELECTED_CONVERSATION(state, conversation) {
      state.selectedConversation = conversation;
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages;
    },
    SET_NEXT_PAGE(state, nextPage) {
      state.nextPage = nextPage;
    },
    SET_CONVERSATION_ASSIGNMENT_STATUS(state, status) {
      state.selectedConversation.status = status;
    },
    SET_CONVERSATION_COUNT(state, category, count) {
      state.conversationCount[category] = count;
    },
    SET_FILTERED_MESSAGES(state, messages) {
      state.filteredMessages = messages;
    },
    SET_SHOW_EVENTS(state, show) {
      state.showEvents = show;
    },
    SET_SHOW_PROFILE(state, show) {
      state.showProfile = show;
    },
    SET_SELECTED_PATIENT(state, id) {
      state.selectedPatient = id;
    },
    SET_MESSAGE_TEMPLATE(state, msg) {
      state.messageTemplate = msg;
    },
    SET_STATE (state, payload) {
      state[payload.state] = payload.value
    },
    REMOVE_CONVERSATION (state, conversationId) {
      state.conversations = state.conversations.filter(conversation => conversation.id !== conversationId)
    }
  },
  actions: {
    // Manual setting to minimize calls
    setAssigneeManual({ commit }, name) {
      commit('SET_ASSIGNEE', name);
    },
    async refreshAssignee({ commit, state }) {
      const { data } = await ChatService.getAssignedAdmin(
        state.selectedConversation.patient.id
      );
      if (data.assignee) {
        commit(
          'SET_ASSIGNEE',
          `${data.assignee.firstName} ${data.assignee.lastName}`
        );
      } else {
        commit('SET_ASSIGNEE', null);
      }
    },
    moveChatToTop({ state }, conversationid) {
      const conversationindex = state.conversations.findIndex(
        (o) => o.id == conversationid
      );
      state.conversations.unshift(
        state.conversations.splice(conversationindex, 1)[0]
      );
    },
    async setCategory({ commit }, category) {
      commit('SET_CATEGORY', category);
    },
    async toggleEvents({ commit, state }) {
      commit('SET_SHOW_EVENTS', !state.showEvents);
    },
    async getMetrics({ state }) {
      const { data } = await ChatService.getMetrics();

      state.conversationCount = data;
    },
    async fetchConversations({ commit }, { type }) {
      try {
        commit('SET_STATE', {
          state: 'isFetchingConversations',
          value: true
        });

        commit('SET_CONVERSATIONS', []);

        const {
          data
        } = await ChatService.getAdminPatientConversations(type?.toUpperCase());

        commit('SET_CONVERSATIONS', data);

        return data;
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          state: 'isFetchingConversations',
          value: false
        });
      }
    },
    async fetchDismissedConversations({ commit }, { type, limit, cursor }) {
      try {
        commit('SET_STATE', {
          state: 'isFetchingConversations',
          value: true
        });

        commit('SET_CONVERSATIONS', []);

        const {
          data
        } = await ChatService.getAdminPatientDismissedConversations(type?.toLowerCase(), limit, cursor);

        commit('SET_CONVERSATIONS', data);

        return data;
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          state: 'isFetchingConversations',
          value: false
        });
      }
    },
    async getOnlineStatus({ commit, state }, payload) {
      const { data: patientOnlineList } = await ChatService.getOnlineStatus(
        payload
      );

      const conversations = state.conversations.map((conversation) => {
        const correspondingUser = patientOnlineList.find(
          (patient) => patient.userId === conversation.patient.id
        );

        if (correspondingUser) {
          conversation.isOnline = correspondingUser.online;
        }

        return conversation;
      });

      commit('SET_CONVERSATIONS', conversations);
    },
    updateOnlineStatus({ commit, state }, { userId, status }) {
      const conversations = state.conversations.map((conversation) => {
        if (conversation.patient.id === userId) {
          conversation.isOnline = status === 'online';
        }

        return conversation;
      });

      commit('SET_CONVERSATIONS', conversations);
    },
    async selectConversation({ commit }, conversation) {
      const { data: status } = await ChatService.getConversationAssignment(
        conversation.id
      );
      conversation.status = status.status;
      conversation.assignee = status.assignee;
      if (status.assignee) {
        const assigneeObj = status.assignee[Object.keys(status.assignee)[0]];
        const name = `${assigneeObj.firstName} ${assigneeObj.lastName}`;
        commit('SET_ASSIGNEE', name);
      } else {
        commit('SET_ASSIGNEE', null);
      }
      commit('SET_SELECTED_CONVERSATION', conversation);
      return conversation
    },
    async fetchMessages({ commit, state }, { patientId, nextPage }) {
      const { data: messages } = await ChatService.getAdminPatientMessages(
        patientId,
        nextPage
      );
      commit('SET_MESSAGES', [...state.messages, ...messages.data]);
      commit('SET_NEXT_PAGE', messages.nextPageCursor || null);

      return messages.nextPageCursor;
    },
    updateConversations({ commit }, conversations) {
      commit('SET_CONVERSATIONS', conversations);
    },
    async assignConversation({ commit, dispatch }, payload) {
      let res = null;
      if (payload.assign) {
        res = await ChatService.assignConversation(payload.conversationid);
      } else {
        res = await ChatService.unassignConversation(payload.conversationid);
      }
      dispatch('getMetrics');
      commit('SET_CONVERSATION_ASSIGNMENT_STATUS', payload.assign);
      return res;
    },
    async dismissConversation(
      { state },
      { patientId, conversationId }
    ) {
      const { data } = await ChatService.dismissPatientConversation(patientId);
      if (data) {
        state.conversations = state.conversations.filter(
          (o) => o.id != conversationId
        );
      }
    },
    async reopenConversation(
      _,
      { patientId }
    ) {
      const { data } = await ChatService.reopenPatientConversation(patientId);
      return data
    },
    deselectConversation({ commit }) {
      commit('SET_SELECTED_CONVERSATION', null);
    },
    appendMessage({ commit, state }, message) {
      commit('SET_MESSAGES', [message, ...state.messages]);
    },
    clearMessages({ commit }) {
      commit('SET_MESSAGES', []);
    },
    toggleProfile({ commit, state }) {
      commit('SET_SHOW_PROFILE', !state.showProfile);
    },
    viewPatient({ commit }, id) {
      commit('SET_SELECTED_PATIENT', id);
    },
  },
  getters: {
    getMetrics(state) {
      return state.conversationCount;
    },
    getConversations(state) {
      return state.conversations;
    },
    getSelectedConversation(state) {
      return state.selectedConversation;
    },
    getMessages(state) {
      return state.messages;
    },
    getNextPage(state) {
      return state.nextPage;
    },
    getFilteredMessages(state) {
      return state.filteredMessages;
    },
    getShowEvents(state) {
      return state.showEvents;
    },
    getShowProfile(state) {
      return state.showProfile;
    },
    getSelectedPatient(state) {
      return state.selectedPatient;
    },
    getAssignee(state) {
      return state.assignee;
    },
    getMessageTemplate(state) {
      return state.messageTemplate;
    }
  },
};
