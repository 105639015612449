//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import _ from 'lodash';
import { watchResizeObserver } from '@/utils/watchResizeObserver';
import { makeId } from '@/utils/makeId';
import { getElementHeight } from '@/utils/getElementHeight';
import { waitCallbackTruthy } from '@/utils/waitCallbackTruthy';
const UNIQ_ID = makeId();
const DOCTOR_SLIDE_ID = 'admin-doctor-slide' + UNIQ_ID;
const DOCTOR_SLIDE_TITLE_ID = 'admin-doctor-slide__title' + UNIQ_ID;
const DOCTOR_SLIDE_SUBTITLE_ID = 'admin-doctor-slide__subtitle' + UNIQ_ID;
const DOCTOR_SLIDE_LIST = 'admin-doctor-slide__list' + UNIQ_ID;
const DOCTOR_SLIDE_BUTTON = 'admin-doctor-slide__button' + UNIQ_ID;
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      DOCTOR_SLIDE_ID,
      DOCTOR_SLIDE_TITLE_ID,
      DOCTOR_SLIDE_SUBTITLE_ID,
      DOCTOR_SLIDE_LIST,
      DOCTOR_SLIDE_BUTTON,
      listHeight: 0,
      doctorStatus: {}
    };
  },

  computed: { ...mapState("doctors", {
      doctors: "doctors",
      isFetchingDoctors: 'isFetchingDoctors'
    }),
    ...mapState("doctorMessageRouter", {
      config: "config",
      isUpdatingConfig: 'isUpdatingConfig'
    }),
    isVisibleMessageRouteConfigSlide: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      }

    }
  },
  watch: {
    async value(val) {
      if (val) {
        this.initData();
      }
    }

  },

  async created() {
    this.initData();
  },

  methods: {
    async initData() {
      await waitCallbackTruthy(() => !!this.config);
      this.initDoctors();
      this.initObservers();
    },

    async initDoctors() {
      const {
        config,
        $store
      } = this;
      await $store.dispatch("doctors/getDoctors");
      this.doctors.forEach(doctor => {
        const status = config.doctorStatus[doctor.id] === undefined ? true : config.doctorStatus[doctor.id];
        this.$set(this.doctorStatus, doctor.id, status);
      });
    },

    async initObservers() {
      watchResizeObserver([`#${DOCTOR_SLIDE_ID}`, `#${DOCTOR_SLIDE_TITLE_ID}`, `#${DOCTOR_SLIDE_SUBTITLE_ID}`, `#${DOCTOR_SLIDE_BUTTON}`], async entries => {
        const [slide, title, subtitle, button] = entries;

        const slideOffset = _.sum([parseInt(slide.styles.paddingTop), parseInt(slide.styles.paddingBottom), parseInt(slide.styles.marginTop), parseInt(slide.styles.marginBottom)]);

        const titleHeight = getElementHeight(title.target);
        const subtitleHeight = getElementHeight(subtitle.target);
        const buttonHeight = getElementHeight(button.target);
        this.listHeight = window.innerHeight - slideOffset - titleHeight - subtitleHeight - buttonHeight;
      });
    },

    async saveDoctorStatus() {
      const {
        config,
        doctorStatus
      } = this;
      await this.$store.dispatch("doctorMessageRouter/updateConfig", { ...config,
        doctorStatus
      });
    },

    async enableAllDoctorStatus() {
      this.doctorStatus = this.doctors.reduce((acc, curr) => {
        return { ...acc,
          [curr.id]: true
        };
      }, {});
      this.config.doctorStatus = { ...this.config.doctorStatus,
        ...this.doctorStatus
      };

      this.config.__ob__.dep.notify();

      this.doctorStatus.__ob__.dep.notify();

      await this.$store.dispatch("doctorMessageRouter/updateConfig", this.config);
    }

  }
};