import Vue from 'vue'

export const querySelectorAsync = async (
  selector,
  { timeout = 99999, throwError = true } = {}
) => {
  await Vue.nextTick();

  const startTime = Date.now();
  let el;

  do {
    el = document.querySelector(selector);

    if (Date.now() - startTime >= timeout) {
      const errorMessage = `Timeout while waiting for selector ${selector}`;
      if (throwError) {
        throw new Error(errorMessage);
      } else {
        console.warn(errorMessage);
        break;
      }
    }

    await new Promise((resolve) => {
      requestAnimationFrame(resolve);
    });
  } while (!el);

  return el;
};
