const environment = process.env.VUE_APP_SERVER;
let constants = null;

/**
 * Note: These constants are for non-sensitive information
 * such as URLs for redirection only. In the case that the env
 * variable reading fails, production variables are used
 * by default.
 */

// Define data that's the same for all environments here
export const TYPING_INDICATOR = {
  THROTTLE_TIME: 800,
};

const tableLinks = [
  {
    none: [
      { id: 'all', text: 'All' },
      { id: 'active', text: 'Active' },
      { id: 'inactive', text: 'Inactive' },
      { id: 'banned', text: 'Banned' },
      { id: 'fullycancelled', text: 'Fully Cancelled' },
      { id: 'deleted', text: 'Removed' },
      { id: 'transferrx', text: 'TransferRX' },
    ],
  },
  {
    Lifecycle: [
      { id: 'onboarding', text: 'Onboarding' },
      { id: 'failedaddress', text: 'Address Verification' },
      {
        id: 'verificationrequired',
        text: 'Verification Required (Patient)',
      },
      {
        id: 'verificationrequireddoctor',
        text: 'Verification Required (Doctor)',
      },
      { id: 'notaccepted', text: 'Not Accepted' },
    ],
  },
  {
    'Follow Up': [
      { id: 'followupstart', text: 'Follow Up Start' },
      { id: 'followupcompletion', text: 'Follow Up Complete' },
    ],
  },
  {
    Actions: [
      { id: 'needspatientreply', text: 'Needs Reply' },
      { id: 'cs-hold', text: 'Order Holds' },
      { id: 'doctorrequest', text: 'Doctor Request' },
      { id: 'voicemail', text: 'Voicemail' },
    ],
  },
  {
    Payment: [
      { id: 'failedtransaction', text: 'Failed Transaction' },
      { id: 'card-expired', text: 'Card Expired' },
      { id: 'cancelled-by-stripe', text: 'Cancelled By Stripe' },
      { id: 'refundrequest', text: 'Refund Request' },
    ],
  },
  {
    Monitoring: [
      { id: 'no-follow-up', text: 'No Follow Up' },
      {
        id: 'prescription-end-before-followup',
        text: 'Prescription End Before FU',
      },
      {
        id: 'card-updated-without-prescription-restart',
        text: 'Card Updated w/o Prescription Restart',
      },
      {
        id: 'script-end-before-followup-no-dhea',
        text: 'Script End Before Followup (No DHEA)',
      },
    ],
  },
];

// Define data that's different for each environment here
if (environment == 'staging' || environment == 'local') {
  constants = Object.freeze({
    // Filter category names and respective badge colors
    pillIds: {
      SMS: 'badge-sms',
      Intercom: 'badge-intercom',
      Event: 'badge-default',
      'Information Update': 'badge-information',
      'Admin Update': 'badge-information',
      Onboarding: 'badge-primary',
      Orders: 'badge-portal',
      Refund: 'badge-refund',
      'Follow Up': 'badge-primary',
      'Doctor Reassigned': 'badge-reassign',
      Notes: 'badge-information',
      JustCall: 'badge-information',
      'Support Interaction': 'badge-intercom',
      Message: 'badge-primary',
    },
    tableLinks,
    macroCategoryId: '5ad584bf-3436-4139-aec9-7bca07e896ce',
  });
} else {
  // Production
  constants = Object.freeze({
    pillIds: {
      SMS: 'badge-sms',
      Intercom: 'badge-intercom',
      Event: 'badge-default',
      'Information Update': 'badge-information',
      'Admin Update': 'badge-information',
      Onboarding: 'badge-primary',
      Orders: 'badge-portal',
      Refund: 'badge-refund',
      'Follow Up': 'badge-primary',
      'Doctor Reassigned': 'badge-reassign',
      Notes: 'badge-information',
      JustCall: 'badge-information',
      'Support Interaction': 'badge-intercom',
    },
    tableLinks,
    macroCategoryId: '1c6d96ac-cb01-4a02-a0c1-4fcf52f71725',
  });
}

export default constants;
