import { AuthService } from '@/services/auth.service.js';

export default {
  namespaced: true,
  state: {
    currentUser: {},
  },
  mutations: {
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    RESET_STATE(state) {
      state.currentUser = {};
    },
  },
  getters: {
    isSuperAdmin (state) {
      return state.currentUser?.roletype === 'SUPER ADMIN'
    },
    getCurrentUser(state) {
      return state.currentUser;
    }
  },
  actions: {
    async getUser({ commit }) {
      try {
        const { data } = await AuthService.getCurrent();
        commit('SET_CURRENT_USER', data);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
};
