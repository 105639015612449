// Used for communication in between Main table and the patient view.
export default {
  namespaced: true,
  state: {
    search: '',
    currentPage: 1,
    rowCount: 0,
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.currentPage = currentPage;
    },
    SET_ROW_COUNT(state, count) {
      state.rowCount = count;
    },
  },
  getters: {
    getSearch(state) {
      return state.search;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getRowCount(state) {
      return state.rowCount;
    },
  },
};
