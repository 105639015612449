import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

const baseUrl = process.env.VUE_APP_DOCTOR_ADMIN_MESSAGE_API_URL;

export class DoctorMessageRouterService extends BaseService {
  static async getConversations (params) {
    try {
      const response = await this.request({ auth: true, baseUrl }).get(
        `/conversations`, { params }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteConversation (conversationId) {
    try {
      const response = await this.request({ auth: true, baseUrl }).delete(
        `/conversations/${conversationId}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversationMessages (conversationId) {
    try {
      const response = await this.request({ auth: true, baseUrl }).get(
        `/conversations/${conversationId}/messages`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConfig (adminid, data) {
    try {
      const response = await this.request({ auth: true, baseUrl }).get(
        `/config`,
        data
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateConfig (data) {
    try {
      const response = await this.request({ auth: true, baseUrl }).post(
        `/config`,
        data
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async revertLastMessage ({ conversationId, messageIds }) {
    try {
      const response = await this.request({ auth: true, baseUrl }).patch(
        `/conversations/${conversationId}/revert`,
        { messageIds }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async routeConversation ({ conversationId, messageIds, routeTo }) {
    try {
      const response = await this.request({ auth: true, baseUrl }).post(
        `/conversations/${conversationId}/route`,
        {
          messageIds,
          routeTo
        }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteMessage ({ conversationId, messageId }) {
    try {
      const response = await this.request({ auth: true, baseUrl }).delete(
        `/conversations/${conversationId}/messages/${messageId}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markConversationAsRead (conversationId) {
    try {
      const response = await this.request({ auth: true, baseUrl }).patch(
        `/conversations/${conversationId}/mark-as-read`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}