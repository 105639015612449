//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { DoctorService } from '@/services/doctor.service.js';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    },
    mode: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      }
    };
  },

  methods: {
    getDateTime(date) {
      return moment(date * 1000).format('MM/DD/YYYY LTS');
    },

    async removeEntry() {
      try {
        if (this.mode == 'cancel') {
          const {
            data
          } = await DoctorService.cancelReassignment(this.rowData.id);

          if (data) {
            console.log(data);
            this.$emit('show-toast', 'Reassignment cancelled successfully!');
            this.$emit('refresh');
            this.showDialog = false;
          }
        } else if (this.mode == 'deny') {
          const {
            data
          } = await DoctorService.deleteReassignment(this.rowData.id);

          if (data) {
            console.log(data);
            this.$emit('show-toast', 'Reassignment cancelled successfully!');
            this.$emit('refresh');
            this.showDialog = false;
          }
        }
      } catch (e) {
        this.alert = {
          show: true,
          message: e.message
        };
      }
    },

    onHide() {
      this.alert = {
        show: false,
        message: ''
      };
    }

  }
};