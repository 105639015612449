/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "p-4",
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "lg"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "end" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "b-button",
                { attrs: { variant: "link" }, on: { click: _vm.close } },
                [
                  _c("b-icon", {
                    attrs: { icon: "x-circle", variant: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: "warning" } },
                [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3" } }, [
            _c("h4", [_vm._v("Forwarded Request")])
          ]),
          _c("b-col", { attrs: { cols: "4" } }, [
            _c("span", { staticClass: "text-muted" }, [_vm._v("Doctor")]),
            _c("br"),
            _c("p", [_vm._v(_vm._s(_vm.forwardedMessageData.doctorname))])
          ]),
          _c("b-col", { attrs: { cols: "4", offset: "1" } }, [
            _c("span", { staticClass: "text-muted" }, [_vm._v("Date")]),
            _c("br"),
            _c("p", [
              _vm._v(
                _vm._s(_vm.getDate(_vm.forwardedMessageData.timestampcreated))
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "5", offset: "3" } }, [
            _c("span", { staticClass: "text-muted" }, [_vm._v("Patient")]),
            _c("br"),
            _vm.rowData
              ? _c("p", [_vm._v(_vm._s(_vm.forwardedMessageData.patientname))])
              : _vm._e()
          ]),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    pill: "",
                    size: "lg",
                    block: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.redirectToIntercom(
                        _vm.forwardedMessageData.patientid
                      )
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/intercom.svg"),
                      width: "120"
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { offset: "3" } }, [
            _c("span", { staticClass: "text-muted" }, [_vm._v("Doctor Notes")]),
            _c("p", [_vm._v(_vm._s(_vm.forwardedMessageData.doctornotes))])
          ])
        ],
        1
      ),
      _c("hr"),
      _c(
        "b-row",
        { staticClass: "chat-background p-4" },
        [
          _c(
            "b-col",
            _vm._l(_vm.reversedMessages, function(message, index) {
              return _c(
                "b-row",
                {
                  key: message.id,
                  staticClass: "mx-5 my-2",
                  attrs: {
                    "align-h": _vm.isSentByPatient(message.userid)
                      ? "start"
                      : "end"
                  }
                },
                [
                  index == 0
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-small text-muted text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getDateTime(message.sentat * 1000)) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  index > 0 &&
                  _vm.nextDay(
                    message.sentat,
                    _vm.forwardedMessageData.messagelist[index - 1].sentat
                  )
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-small text-muted text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getDateTime(message.sentat * 1000)) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  index == 0 ||
                  _vm.reversedMessages[index - 1].userid != message.userid
                    ? _c(
                        "b-col",
                        {
                          class: [
                            _vm.isSentByPatient(message.userid)
                              ? "text-left"
                              : "text-right"
                          ],
                          attrs: { cols: "12" }
                        },
                        [
                          _c("span", { staticClass: "text-small text-muted" }, [
                            _vm._v(
                              " Sent by " +
                                _vm._s(_vm.idNames[message.userid]) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.isSentByPatient(message.userid)
                    ? _c("b-col", { attrs: { cols: "2" } }, [
                        _c("span", { staticClass: "text-small text-muted" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getTime(message.sentat * 1000)) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  message.attachment || message.type == "attachment"
                    ? _c(
                        "b-col",
                        {
                          staticClass: "py-2",
                          class: [
                            _vm.isSentByPatient(message.userid)
                              ? ""
                              : "text-right",
                            _vm.isSentByPatient(message.userid)
                              ? "patient-message"
                              : "doctor-message"
                          ],
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("p", { staticClass: "tw-mb-0" }, [
                            _vm._v(_vm._s(message.message))
                          ]),
                          _c(
                            "ul",
                            { staticClass: "tw-mb-0" },
                            _vm._l(message.attachment, function(attachment) {
                              return _c("li", { key: attachment.id }, [
                                attachment.type == "application/pdf"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: attachment.url,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(attachment.filename) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c("div", { staticClass: "photo-holder" }, [
                                      _c("div", { staticClass: "photo" }, [
                                        _c("img", {
                                          attrs: {
                                            src: attachment.url,
                                            alt: ""
                                          }
                                        })
                                      ])
                                    ])
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _c(
                        "b-col",
                        {
                          staticClass: "py-2",
                          class: [
                            _vm.isSentByPatient(message.userid)
                              ? ""
                              : "text-right",
                            _vm.isSentByPatient(message.userid)
                              ? "patient-message"
                              : "doctor-message"
                          ],
                          attrs: { cols: "auto" }
                        },
                        [_vm._v(" " + _vm._s(message.message) + " ")]
                      ),
                  _vm.isSentByPatient(message.userid)
                    ? _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "2" } },
                        [
                          _c("span", { staticClass: "text-small text-muted" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getTime(message.sentat * 1000)) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { offset: "4" } },
            [
              _vm.showMessageButton
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-dark",
                        block: "",
                        pill: "",
                        disabled: _vm.viewOnly || _vm.processing
                      },
                      on: { click: _vm.redirectToMessages }
                    },
                    [_vm._v(" Message Doctor ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "secondary",
                    block: "",
                    pill: "",
                    disabled: _vm.viewOnly || _vm.processing
                  },
                  on: { click: _vm.completeRequest }
                },
                [_vm._v(" Complete ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }