/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticStyle: { padding: "0 20px" } },
    [
      _c(
        "b-col",
        [
          _c("b-form-textarea", {
            staticClass: "quick-note py-3",
            attrs: {
              rows: "2",
              placeholder: "Write a note...",
              disabled: _vm.disableInput,
              "no-resize": ""
            },
            model: {
              value: _vm.note,
              callback: function($$v) {
                _vm.note = $$v
              },
              expression: "note"
            }
          })
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "d-flex align-items-center", attrs: { cols: "1" } },
        [
          _c("span", { staticClass: "text-small text-muted mr-2" }, [
            _vm._v(_vm._s(_vm.note.trim().length))
          ]),
          _c(
            "a",
            {
              staticClass: "text-primary",
              attrs: { href: "javascript:;" },
              on: { click: _vm.send }
            },
            [
              !_vm.disableInput
                ? _c("b-icon", {
                    attrs: {
                      icon: "cursor-fill",
                      rotate: "45",
                      "font-scale": "1.5",
                      variant: _vm.iconColor
                    }
                  })
                : _c("b-spinner", { attrs: { variant: "primary" } })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }