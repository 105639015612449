import patients from './patients';
import earnings from './earnings';
import message from './message';
import admins from './admins';
import auth from './auth';
import doctors from './doctors';
import inventory from './inventory';
import chat from './chat';
import tables from './tables';
import doctorMessageRouter from "./doctor-message-router";
import infoview from './infoview';

export default {
  doctorMessageRouter,
  patients,
  earnings,
  message,
  admins,
  auth,
  doctors,
  inventory,
  chat,
  tables,
  infoview,
};
