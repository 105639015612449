//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import ChatCategoryColumn from '../components/chat/ChatCategoryColumn.vue';
import ChatMessageListColumn from '../components/chat/ChatMessageListColumn.vue';
import ChatMessagesColumn from '../components/chat/ChatMessagesColumn.vue';
import ChatProfileDisplay from '@/components/chat/ChatProfileDisplay';
import { ChatService } from '@/services/chat.service';
export default {
  components: {
    ChatCategoryColumn,
    ChatMessageListColumn,
    ChatMessagesColumn,
    ChatProfileDisplay
  },

  data() {
    return {
      selectedCategory: '',
      isMessagesLoaded: false,
      socket: null,
      onlineStatusInterval: null,
      onlineStatusResponse: null,
      chatMessageColumnKey: uuidv4(),
      selectedPatientFromQuery: null,
      isProcessing: false,
      sortedConversations: []
    };
  },

  computed: { ...mapGetters({
      conversations: 'chat/getConversations',
      selectedConversation: 'chat/getSelectedConversation',
      showProfile: 'chat/getShowProfile',
      currentUser: 'auth/getCurrentUser'
    })
  },
  watch: {
    selectedConversation() {
      this.chatMessageColumnKey = uuidv4();
    },

    conversations(val) {
      this.sortedConversations = val;
    },

    sortedConversations(val) {
      this.$nextTick(() => {
        this.$store.dispatch('chat/updateConversations', val);
      });
    }

  },

  async created() {
    var _singleConversation$d, _singleConversation$d2, _selectedConversation, _singleConversation$d3, _singleConversation$d4;

    this.selectedPatientFromQuery = this.$route.params.userId;
    await Promise.all([this.$store.dispatch('chat/fetchConversations', {
      type: null
    }), this.$store.dispatch('chat/getMetrics')]);

    if (!this.selectedPatientFromQuery) {
      this.selectedCategory = 'all';
      return;
    } // WIN-39730: Admin-Patient Chat Link Handling


    this.isProcessing = true;
    const singleConversation = await ChatService.getSingleAdminPatientConversations(this.selectedPatientFromQuery);

    if (!(singleConversation !== null && singleConversation !== void 0 && (_singleConversation$d = singleConversation.data) !== null && _singleConversation$d !== void 0 && _singleConversation$d.id)) {
      this.$bvToast.toast("You have entered an incorrect or invalid chat link. Please double-check and enter a valid chat link.", {
        variant: "danger",
        noCloseButton: true,
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center"
      });
      this.selectedCategory = 'all';
      this.isProcessing = false;
      return;
    }

    if (singleConversation !== null && singleConversation !== void 0 && (_singleConversation$d2 = singleConversation.data) !== null && _singleConversation$d2 !== void 0 && _singleConversation$d2.dismissed) {
      this.selectedCategory = 'dismissed';
      await this.$store.dispatch('chat/fetchDismissedConversations', {
        type: 'DISMISSED',
        limit: '',
        cursor: ''
      });
    }

    const selectedConversation = this.conversations.find(conversation => conversation.patient.id === this.selectedPatientFromQuery); // If conversation is included in the conversation all list else grab the data of the fetched conversation

    const conversationData = selectedConversation ? selectedConversation : singleConversation.data;
    await this.$store.dispatch('chat/selectConversation', conversationData);
    const isAssigned = (selectedConversation === null || selectedConversation === void 0 ? void 0 : (_selectedConversation = selectedConversation.assignee) === null || _selectedConversation === void 0 ? void 0 : _selectedConversation[this.currentUser.id]) || ((_singleConversation$d3 = singleConversation.data) === null || _singleConversation$d3 === void 0 ? void 0 : (_singleConversation$d4 = _singleConversation$d3.assignee) === null || _singleConversation$d4 === void 0 ? void 0 : _singleConversation$d4[this.currentUser.id]);

    if (this.selectedCategory !== 'dismissed') {
      if (isAssigned) {
        this.selectedCategory = 'assigned';
        await this.onCategoryUpdate('assigned');
      } else {
        this.selectedCategory = 'all';
        await this.onCategoryUpdate('all');
      }
    } // Put conversation on top of the list


    const restConversations = this.conversations.filter(conversation => conversation.patient.id !== conversationData.patient.id);
    this.$set(this, 'sortedConversations', [conversationData, ...restConversations]);
    this.$nextTick(() => {
      this.$refs.chatMessageListColumn.selectedConversation = conversationData.id;
      this.onConversationSelect(conversationData);
    });
    this.isProcessing = false;
  },

  mounted() {
    this.connectToSocket();
    this.pollOnlineStatus();
  },

  destroyed() {
    this.socket.disconnect();
    this.$store.dispatch('chat/deselectConversation');
  },

  beforeDestroy() {
    clearTimeout(this.onlineStatusInterval);
  },

  methods: {
    async onConversationSelect(conversation) {
      this.$router.push(`/chat/${conversation.patient.id}`);
      await this.$store.dispatch('chat/selectConversation', conversation); // Sets patient profile view

      this.$store.dispatch('chat/viewPatient', conversation.patient.id);
      this.$store.dispatch('chat/clearMessages');
      this.isMessagesLoaded = false;
      await this.fetchMessages(conversation.patient.id);
    },

    async fetchMessages(patientId) {
      await this.$store.dispatch('chat/fetchMessages', {
        patientId
      });
      this.isMessagesLoaded = true;
    },

    updateConversationById(conversationId, updatedProperties) {
      const conversations = [...this.conversations];
      const index = conversations.findIndex(conv => conv.id === conversationId);

      if (index !== -1) {
        conversations[index] = { ...conversations[index],
          ...updatedProperties
        };
      }

      return conversations;
    },

    connectToSocket() {
      if (localStorage.getItem('accessToken')) {
        this.socket = io(process.env.VUE_APP_WEBSOCKET_URL, {
          auth: {
            token: localStorage.getItem('accessToken')
          },
          transports: ['websocket', 'polling']
        });
        this.socket.on('connect', () => {
          console.log('Connected to server');
        });
        this.socket.on('connect_error', err => {
          console.log(err);
          this.socket.io.opts.transports = ['polling', 'websocket'];
        });
        this.socket.on('request_error', err => {
          console.log(err);
        });
        this.socket.on('validation_error', err => {
          console.log(err);
        });
        this.socket.on('message_delivery_confirmed', message => {
          console.log(message);

          if (message.conversationId === this.selectedConversation.id) {
            this.$store.dispatch('chat/appendMessage', {
              type: message.type,
              id: message.id,
              data: { ...message,
                ...(message.senderRole === 'PATIENT' ? {
                  patient: message.sender
                } : {
                  admin: message.sender
                })
              }
            });
            const updatedConversations = this.updateConversationById(message.conversationId, {
              sender: message.sender,
              lastMessage: message.message,
              lastMessageSenderRole: message.senderRole,
              lastMessageActivity: message.sentAt,
              lastMessageType: message.type
            });
            this.$store.dispatch('chat/updateConversations', updatedConversations);
          } else {
            this.$store.dispatch('chat/fetchConversations', {
              type: this.selectedCategory === 'all' ? null : this.selectedCategory
            });
            this.$store.dispatch('chat/getMetrics');
          } // this.onConversationUpdate(message);

        });
        this.socket.on('admin_chat:message', message => {
          var _this$selectedConvers;

          console.log(message);

          if (message.conversationId === ((_this$selectedConvers = this.selectedConversation) === null || _this$selectedConvers === void 0 ? void 0 : _this$selectedConvers.id)) {
            this.$store.dispatch('chat/appendMessage', {
              type: message.type,
              id: message.id,
              data: { ...message,
                ...(message.senderRole === 'PATIENT' ? {
                  patient: message.sender
                } : {
                  admin: message.sender
                })
              }
            });
            const updatedConversations = this.updateConversationById(message.conversationId, {
              sender: message.sender,
              lastMessage: message.message,
              lastMessageSenderRole: message.senderRole,
              lastMessageActivity: message.sentAt,
              lastMessageType: message.type
            });
            this.$store.dispatch('chat/updateConversations', updatedConversations);
          } else {
            this.$store.dispatch('chat/fetchConversations', {
              type: this.selectedCategory === 'all' ? null : this.selectedCategory
            });
            this.$store.dispatch('chat/getMetrics');
          }

          console.log('Received message', message);
          this.scrollToBottom(); // this.onConversationUpdate(message);
        });
        this.socket.on('patient_note_added', note => {
          if (note.patientId === this.selectedConversation.patient.id) {
            this.$store.dispatch('chat/appendMessage', {
              type: 'NOTE',
              id: note.id,
              data: { ...note,
                type: 'NOTE'
              }
            });
            const updatedConversations = this.updateConversationById(note.patientId, {
              sender: {
                firstName: note.author.firstName
              },
              lastMessage: note.messageBody,
              lastMessageSenderRole: 'ADMIN',
              lastMessageActivity: note.timestampInteraction,
              lastMessageType: 'NOTE'
            });
            this.$store.dispatch('chat/updateConversations', updatedConversations);
          }

          console.log('Received note', note);
        });
        this.socket.on('admin_chat:patient_admin_assignment_changed', data => {
          if (!data.from && this.selectedCategory === 'unassigned') {
            const conversations = [...this.conversations];
            const index = conversations.findIndex(conv => conv.id === data.patientId);

            if (index !== -1) {
              conversations.splice(index, 1);
              this.$store.dispatch('chat/updateConversations', conversations);
            }
          }
        });
        this.socket.on('admin_chat:conversation_dismissed', data => {
          console.log('Conversation dismissed', data);
          const conversations = [...this.conversations];
          const index = conversations.findIndex(conv => conv.id === data.patientId);

          if (index !== -1) {
            conversations.splice(index, 1);
            this.$store.dispatch('chat/updateConversations', conversations);
            this.$store.dispatch('chat/getMetrics');
          }
        });
        this.socket.on('admin_chat:conversation_reopened', data => {
          console.log('Conversation reopened', data);
          const conversations = [...this.conversations];
          const index = conversations.findIndex(conv => conv.id === data.patient.id);

          if (index !== -1) {
            conversations.splice(index, 1);
            this.$store.dispatch('chat/updateConversations', conversations);
            this.$store.dispatch('chat/getMetrics');
          }
        });
        this.socket.on('online_status', data => {
          this.onlineStatusResponse = data;
          this.$store.dispatch('chat/updateOnlineStatus', data);
        });
      }
    },

    async pollOnlineStatus() {
      const patientIdList = this.conversations.map(conversation => conversation.patient.id);
      await this.$store.dispatch('chat/getOnlineStatus', {
        users: patientIdList
      });
      this.onlineStatusInterval = setTimeout(() => {
        this.pollOnlineStatus();
      }, 10000);
    },

    scrollToBottom() {
      const bottomOfPageElem = document.querySelector('#bottomOfPage');

      if (bottomOfPageElem) {
        setTimeout(() => {
          bottomOfPageElem.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
          });
          console.log("Scrolled to bottom");
        }, 500);
      }
    },

    async onCategoryUpdate(category) {
      if (category !== 'dismissed' && this.selectedPatientFromQuery) {
        this.selectedPatientFromQuery = null;
      }

      clearTimeout(this.onlineStatusInterval);
      this.pollOnlineStatus();
      this.$router.push(`/chat`);
      this.$store.dispatch('chat/deselectConversation');

      if (this.showProfile) {
        this.$store.dispatch('chat/toggleProfile');
      }

      this.$refs.chatMessageListColumn.selectedConversation = '';
      await this.fetchConversationByCategory(category);
    },

    async fetchConversationByCategory(category) {
      const type = category.toUpperCase();
      this.$store.dispatch('chat/setCategory', type);

      if (type === 'OPEN') {
        await this.$store.dispatch('chat/fetchConversations', {
          type: 'OPEN'
        });
      } else if (type === 'DISMISSED') {
        await this.$store.dispatch('chat/fetchDismissedConversations', {
          type: 'DISMISSED',
          limit: '',
          cursor: ''
        });
      } else if (type !== 'ALL') {
        await this.$store.dispatch('chat/fetchConversations', {
          type
        });
      } else {
        await this.$store.dispatch('chat/fetchConversations', {
          type: null
        });
      }
    }

  }
};