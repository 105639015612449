/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        {
          staticClass: "d-none d-xl-block",
          attrs: { id: _vm.$_message_PAGE_TITLE_ID, cols: "12" }
        },
        [
          _c(
            "b-row",
            {
              staticClass: "mt-5 mb-5 mx-xl-4",
              attrs: { "align-v": "center" }
            },
            [
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c(
                  "h1",
                  {
                    staticClass: "mb-0 text-primary",
                    staticStyle: { "font-size": "22px", "font-weight": "500" }
                  },
                  [_vm._v(" Messages ")]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [_c("chat", { attrs: { conversationlist: _vm.conversationList } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }