export default {
  namespaced: true,
  state: {
    searchResults: [],
    searchQuery: '',
    tab: 'all',
    tabName: 'Patients',
    filters: {},
    emptyResultsAlert: false,
  },
  mutations: {
    EMPTY_SEARCH_RESULTS(state) {
      state.searchResults = [];
      state.searchQuery = '';
    },
    SET_SEARCH_RESULTS(state, patients) {
      state.searchResults = patients;
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query;
    },
    SET_TAB(state, tab) {
      state.tab = tab;
      if (state.searchQuery) {
        state.searchResults = [];
        state.searchQuery = '';
      }
    },
    RESET_TAB(state) {
      state.tabName = 'Patients';
    },
    SET_TAB_NAME(state, name) {
      state.tabName = name;
    },
    SET_SHOW_EMPTY_SEARCH_ALERT(state, show) {
      state.emptyResultsAlert = show;
    },
    CLEAR_FILTERS(state) {
      state.filters = {};
    },
    FILTER_DATA(state, filters) {
      state.filters = filters;
    },
  },
  getters: {
    getSearchQuery(state) {
      return state.searchQuery;
    },
    getSearchResults(state) {
      return state.searchResults;
    },
    getTabName(state) {
      return state.tabName;
    },
  },
};
