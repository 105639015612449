/* unplugin-vue-components disabled */import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatCategoryItem.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h1",
      {
        staticClass:
          "h1 tw-font-gerstner tw-text-lg tw-text-violet-950 tw-uppercase tw-tracking-widest tw-mb-20"
      },
      [_vm._v(" Chat ")]
    ),
    _c(
      "div",
      { staticClass: "space-y-4" },
      _vm._l(_vm.categories, function(categoryItem) {
        return _c(__unplugin_components_0, {
          key: categoryItem.value,
          staticClass: "tw-mb-4",
          attrs: {
            "is-selected": _vm.category === categoryItem.value,
            "unread-count": categoryItem.unreadCount,
            label: categoryItem.label
          },
          on: {
            click: function($event) {
              _vm.$emit("update:category", categoryItem.value)
              _vm.$emit("category-update", categoryItem.value)
            }
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }