/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d:
            "M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z",
          opacity: ".5"
        }
      }),
      _c(
        "path",
        {
          attrs: {
            fill: "currentColor",
            d: "M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
          }
        },
        [
          _c("animateTransform", {
            attrs: {
              attributeName: "transform",
              dur: "1s",
              from: "0 12 12",
              repeatCount: "indefinite",
              to: "360 12 12",
              type: "rotate"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }