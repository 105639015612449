import _ from 'lodash'

import { querySelectorAsync } from '@/utils/querySelectorAsync';

export async function watchResizeObserver (elsOrSelectors, callback) {
  const elementsPromises = _.castArray(elsOrSelectors).map(elOrSelector =>
    typeof elOrSelector === 'string'
      ? querySelectorAsync(elOrSelector)
      : Promise.resolve(elOrSelector)
  );

  const elements = await Promise.all(elementsPromises);

  const resizeObserver = new ResizeObserver(() => {
    const entries = elements.map((el) => {
      return {
        target: el,
        styles: window.getComputedStyle(el),
        contentRect: el.getBoundingClientRect(),
      };
    });

    callback(entries);
  });

  elements.forEach((el) => resizeObserver.observe(el));

  return resizeObserver
}
