/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scrollContainer",
      staticClass: "tw-p-6",
      attrs: { id: "scroll-container", "infinite-wrapper": "" }
    },
    [
      _vm.isMessagesLoaded && _vm.nextPage
        ? _c(
            "infinite-loading",
            {
              attrs: { direction: "top", "force-use-infinite-wrapper": "true" },
              on: { infinite: _vm.messageScroll }
            },
            [
              _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                _vm._v(" No more message ")
              ])
            ]
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "tw-flex tw-flex-col-reverse" },
        _vm._l(_vm.messages, function(entry) {
          return _c(
            "b-col",
            { key: entry.id, attrs: { cols: "12" } },
            [
              ["MESSAGE", "ATTACHMENT"].includes(entry.type)
                ? [
                    _c(
                      "b-row",
                      { attrs: { "align-h": _vm.getAlignment(entry) } },
                      [
                        _vm.getAlignment(entry) == "start"
                          ? _c(
                              "b-col",
                              {
                                staticClass: "tw-flex custom-col-1",
                                class: _vm.showProfile
                                  ? "text-right"
                                  : "text-left",
                                attrs: { cols: 1 }
                              },
                              [
                                _c("b-avatar", {
                                  attrs: {
                                    src: entry.data.patient.avatarUrl,
                                    size: "40px"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            staticClass: "message px-5 py-3",
                            class: _vm.getStyle(entry),
                            attrs: { cols: 8 }
                          },
                          [
                            entry.data.attachments &&
                            entry.data.attachments.length > 0
                              ? _c("div", [
                                  entry.data.message
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-whitespace-pre-line tw-break-all"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(entry.data.message) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-flex-col tw-gap-2 tw-bg-transparent"
                                    },
                                    _vm._l(entry.data.attachments, function(
                                      chatAttachment,
                                      i
                                    ) {
                                      return _c(
                                        "div",
                                        { key: i },
                                        [
                                          chatAttachment.type.includes(
                                            "image"
                                          ) &&
                                          !["tiff", "heic", "svg"].some(
                                            function(type) {
                                              return chatAttachment.type.includes(
                                                type
                                              )
                                            }
                                          )
                                            ? _c(
                                                "b-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c("b-col", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            chatAttachment.url,
                                                          variant: "link",
                                                          download: "",
                                                          target: "_blank",
                                                          tabindex: "-1"
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "tw-p-1 tw-bg-white tw-rounded tw-border-rounded tw-max-w-full tw-object-contain tw-h-[250px]",
                                                          attrs: {
                                                            src:
                                                              chatAttachment.url,
                                                            width: "250px",
                                                            loading: "lazy"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "b-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          chatAttachment.url,
                                                        variant: "link",
                                                        download: ""
                                                      }
                                                    },
                                                    [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon:
                                                            "arrow-down-circle-fill",
                                                          variant: "primary"
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            chatAttachment.filename
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-whitespace-pre-line tw-break-all"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(entry.data.message) + " "
                                    )
                                  ]
                                )
                          ]
                        ),
                        _vm.getAlignment(entry) == "end"
                          ? _c(
                              "b-col",
                              {
                                staticClass: "custom-col-1",
                                attrs: { cols: 1 }
                              },
                              [
                                entry.data.sender.firstName
                                  ? _c("b-avatar", {
                                      staticStyle: {
                                        "background-color":
                                          "#37514d !important",
                                        color: "#ffffff !important"
                                      },
                                      attrs: {
                                        size: "40px",
                                        text: entry.data.sender.firstName.charAt(
                                          0
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      {
                        staticClass: "mt-2 mb-3",
                        attrs: { "align-h": _vm.getAlignment(entry) }
                      },
                      [
                        _vm.getAlignment(entry) == "start"
                          ? _c("b-col", {
                              staticClass: "custom-col-1",
                              attrs: { cols: "1" }
                            })
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "text-small", attrs: { cols: "8" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    class:
                                      entry.data.sender.role === "PATIENT"
                                        ? "text-left"
                                        : "text-right",
                                    attrs: {
                                      order:
                                        entry.data.sender.role === "PATIENT"
                                          ? 1
                                          : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "16",
                                          height: "12",
                                          viewBox: "0 0 20 16",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M5.47189 16V13.1734C5.47189 12.8316 5.3693 12.7264 5.03599 12.7264H1.19017C1.06229 12.7395 0.933437 12.7395 0.80556 12.7264C0.67056 12.7079 0.546334 12.6409 0.45483 12.5374C0.363326 12.4339 0.310467 12.3006 0.305618 12.1611V0.946739C0.299169 0.876778 0.299169 0.806305 0.305618 0.736344C0.305618 0.223616 0.523547 0.0133495 1.02351 0.000202625H18.7017C18.8484 -0.00373316 18.991 0.0497916 19.1005 0.149934C19.2101 0.250077 19.2783 0.389354 19.2913 0.539269C19.3035 0.64847 19.3035 0.75874 19.2913 0.867941V11.8455C19.3039 11.9547 19.3039 12.065 19.2913 12.1742C19.2819 12.3198 19.2205 12.4567 19.1188 12.5586C19.017 12.6605 18.8822 12.7203 18.7401 12.7264H9.228C9.13784 12.7223 9.04787 12.7376 8.96393 12.7715C8.87998 12.8055 8.80394 12.8573 8.74085 12.9235L5.5873 15.8552L5.47189 16Z",
                                            fill: "#828282"
                                          }
                                        })
                                      ]
                                    ),
                                    entry.data.sender
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                entry.data.sender.firstName
                                              ) +
                                              " "
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _c(
                                  "b-col",
                                  {
                                    class:
                                      entry.data.sender.role === "PATIENT"
                                        ? "text-right"
                                        : "text-left",
                                    attrs: {
                                      order:
                                        entry.data.sender.role === "PATIENT"
                                          ? 2
                                          : 1
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getDateTime(
                                            entry.data.sentAt * 1000
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.getAlignment(entry) == "end"
                          ? _c("b-col", {
                              staticClass: "custom-col-1",
                              attrs: { cols: "1" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              !["MESSAGE", "ATTACHMENT"].includes(entry.type) &&
              !_vm.isEventsHidden
                ? [
                    _c(
                      "b-row",
                      { attrs: { "align-h": _vm.getAlignment(entry) } },
                      [
                        _vm.getAlignment(entry) == "start"
                          ? _c(
                              "b-col",
                              {
                                staticClass: "custom-col-1",
                                attrs: { cols: "1" }
                              },
                              [
                                _c("b-avatar", {
                                  staticClass: "tw-bg-[#CCD6E1]",
                                  attrs: { size: "40px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.getIcon(entry))
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          {
                            staticClass: "message px-5 py-3",
                            class: _vm.getStyle(entry),
                            attrs: { cols: 8 }
                          },
                          [
                            _vm.getStyle(entry) == "message-event"
                              ? _c("span", { staticClass: "ml-n3" }, [
                                  _vm._v(
                                    " " + _vm._s(entry.data.eventName) + " "
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    entry.data.eventCategory ==
                                    "Doctor Reassigned"
                                      ? _c(
                                          "b-row",
                                          { staticClass: "tw-block" },
                                          [
                                            entry.data.transferredBy
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        entry.data.transferredBy
                                                          .firstName +
                                                          " " +
                                                          entry.data
                                                            .transferredBy
                                                            .lastName
                                                      ) +
                                                      " reassigned the doctor "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        entry.data.requestedBy
                                                          .firstName +
                                                          " " +
                                                          entry.data.requestedBy
                                                            .lastName
                                                      ) +
                                                      " reassigned the doctor "
                                                  )
                                                ]),
                                            _c(
                                              "span",
                                              { staticClass: "bold" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " from " +
                                                      _vm._s(
                                                        entry.data.doctorFrom
                                                          .firstName +
                                                          " " +
                                                          entry.data.doctorFrom
                                                            .lastName
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    " to " +
                                                      _vm._s(
                                                        entry.data.doctorTo
                                                          .firstName +
                                                          " " +
                                                          entry.data.doctorTo
                                                            .lastName
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      entry.data
                                                        .assignmentType ===
                                                        "PERMANENT"
                                                        ? " permanently."
                                                        : " until " +
                                                            _vm.getDate(
                                                              entry.data
                                                                .endDateStamp *
                                                                1000
                                                            ) +
                                                            "."
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : entry.data.eventCategory == "Intercom"
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: { id: entry.id }
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "tw-break-all",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.parseHtml(
                                                                entry.data
                                                                  .messageBody
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : entry.data.eventCategory === "JustCall"
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [_vm._v("Note By:")]
                                                      ),
                                                      _c("b", {
                                                        staticClass: "ml-1"
                                                      }),
                                                      _c("br")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                entry.data.eventCategory ==
                                                "JustCall"
                                                  ? _c(
                                                      "b-row",
                                                      [
                                                        _c("b-col", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-muted"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Call Status:"
                                                              )
                                                            ]
                                                          ),
                                                          _c("p", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  entry.data
                                                                    .callStatus
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Note Content:"
                                                          )
                                                        ]
                                                      ),
                                                      _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              entry.data
                                                                .messageBody
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : entry.data.eventCategory == "Notes"
                                      ? _c(
                                          "b-row",
                                          { staticClass: "tw-break-all" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(entry.data.messageBody) +
                                                " "
                                            )
                                          ]
                                        )
                                      : entry.data.eventName ===
                                        "Follow Up Created"
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [_vm._v("Scheduled:")]
                                                      ),
                                                      _c(
                                                        "b",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              entry.data
                                                                .scheduledDate
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("br")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [_vm._v("Doctor:")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-inline-block ml-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                entry.data
                                                                  .messageBody
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : entry.data.eventName ===
                                        "Follow Up Updated"
                                      ? _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [_vm._v("New Date:")]
                                                      ),
                                                      _c(
                                                        "b",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              entry.data
                                                                .scheduledDate
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("br")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Original Date:"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "b",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              entry.data
                                                                .previousDate
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("br")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c("b-col", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted"
                                                        },
                                                        [_vm._v("Doctor:")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-inline-block ml-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                entry.data
                                                                  .messageBody
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : entry.data.eventCategory == "SMS"
                                      ? _c(
                                          "b-row",
                                          { staticClass: "tw-break-all" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(entry.data.messageBody) +
                                                " "
                                            )
                                          ]
                                        )
                                      : entry.data.eventName ===
                                        "Patient Cancelled"
                                      ? _c(
                                          "b-row",
                                          { staticClass: "text-muted" },
                                          [
                                            entry.data.cancelledByRole ===
                                            "ADMIN"
                                              ? [
                                                  _vm._v(" Admin "),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "tw-text-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          entry.data
                                                            .cancelledByName
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " has cancelled patient "
                                                  ),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "tw-text-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          entry.data.patientName
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(". ")
                                                ]
                                              : entry.data.cancelledByRole ===
                                                "DOCTOR"
                                              ? [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "tw-text-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Dr. " +
                                                          _vm._s(
                                                            entry.data
                                                              .cancelledByName
                                                          )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " has cancelled patient "
                                                  ),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "tw-text-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          entry.data.patientName
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(". ")
                                                ]
                                              : [
                                                  _vm._v(" Patient "),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "tw-text-black"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          entry.data.patientName
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " has cancelled her Winona Subscription. "
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      : _c(
                                          "b-row",
                                          { staticClass: "text-muted" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(entry.data.messageBody) +
                                                " "
                                            )
                                          ]
                                        )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            entry.data.eventName == "Treatment Shipped"
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Tracking Number:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.trackingNo
                                                      ? entry.data.trackingNo
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Delivery Address:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.deliveryAddress
                                                      ? entry.data
                                                          .deliveryAddress
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : entry.data.eventName == "Payment Failed"
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Payment Failed:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.paymentFailed
                                                      ? entry.data.paymentFailed
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Stripe Decline Code:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.stripeDeclineCode
                                                      ? entry.data
                                                          .stripeDeclineCode
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Stripe Error:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.stripeDesc
                                                      ? entry.data.stripeDesc
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Next Step:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.stripeNextStep
                                                      ? entry.data
                                                          .stripeNextStep
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : entry.data.eventName == "Email Updated"
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "text-muted" },
                                                  [_vm._v("Email:")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      entry.data.changedFrom
                                                        .email
                                                    ) +
                                                    " "
                                                ),
                                                _c("b-icon", {
                                                  attrs: { icon: "arrow-right" }
                                                }),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      entry.data.changedTo.email
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : entry.data.eventName == "Address Verification"
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Entered Address:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.enteredAddress
                                                      ? entry.data
                                                          .enteredAddress
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Suggested Address:")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    entry.data.suggestedAddress
                                                      ? entry.data
                                                          .suggestedAddress
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : entry.data.eventCategory == "Admin Update" ||
                                entry.data.eventCategory ==
                                  "Information Update" ||
                                entry.data.event == "Patient Migrated"
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      _vm._l(entry.data.changedFrom, function(
                                        prop,
                                        key
                                      ) {
                                        return _c(
                                          "b-row",
                                          { key: key },
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-muted text-capitalize"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getKeyName(key)
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" : "),
                                                key == "birthday"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatInfoUpdateBirthday(
                                                              prop
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(_vm._s(prop))
                                                    ]),
                                                _c("b-icon", {
                                                  attrs: { icon: "arrow-right" }
                                                }),
                                                key == "birthday"
                                                  ? _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatInfoUpdateBirthday(
                                                              entry.data
                                                                .changedTo[key]
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            entry.data
                                                              .changedTo[key]
                                                          ) +
                                                          " "
                                                      ),
                                                      !entry.data.changedTo[key]
                                                        ? _c("span", [
                                                            _vm._v("(none)")
                                                          ])
                                                        : _vm._e()
                                                    ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : entry.data.eventCategory == "Refund"
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted text-small"
                                                },
                                                [_vm._v("Refund:")]
                                              ),
                                              _c("br"),
                                              entry.data.issuedBy
                                                ? _c("b", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          entry.data.issuedBy
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " refunded the following with a total amount of $" +
                                                  _vm._s(
                                                    entry.data.refundAmount
                                                  ) +
                                                  ": "
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "mt-3" },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      [
                                                        _c(
                                                          "b-table-simple",
                                                          [
                                                            _c(
                                                              "b-tbody",
                                                              [
                                                                _c(
                                                                  "b-tr",
                                                                  [
                                                                    _c("b-th", [
                                                                      _vm._v(
                                                                        "Prescription Order No."
                                                                      )
                                                                    ]),
                                                                    _c("b-th", [
                                                                      _vm._v(
                                                                        "Medicine Name"
                                                                      )
                                                                    ]),
                                                                    _c("b-th", [
                                                                      _vm._v(
                                                                        "Refund Amount"
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "b-tr",
                                                                  [
                                                                    _c("b-td", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            entry
                                                                              .data
                                                                              .prescription
                                                                              .prescriptionorderno
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]),
                                                                    _c("b-td", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            entry
                                                                              .data
                                                                              .prescription
                                                                              .medicinename
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]),
                                                                    _c("b-td", [
                                                                      _vm._v(
                                                                        "$" +
                                                                          _vm._s(
                                                                            entry
                                                                              .data
                                                                              .refundAmount
                                                                          )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : entry.data.eventCategory ==
                                "Support Interaction"
                              ? _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [_vm._v("Record URL:")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            href: entry.data.transcriptionUrl
                                          }
                                        },
                                        [_vm._v(" Listen here ")]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.getAlignment(entry) == "end"
                          ? _c(
                              "b-col",
                              {
                                staticClass: "custom-col-1",
                                attrs: { cols: 1 }
                              },
                              [
                                entry.data.eventCategory === "Notes"
                                  ? _c("b-avatar", {
                                      staticStyle: {
                                        "background-color":
                                          "#37514d !important",
                                        color: "#ffffff !important"
                                      },
                                      attrs: {
                                        size: "40px",
                                        text: entry.data.noteBy.charAt(0)
                                      }
                                    })
                                  : _c("b-avatar")
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      {
                        staticClass: "mt-2 mb-3",
                        attrs: { "align-h": _vm.getAlignment(entry) }
                      },
                      [
                        _vm.getAlignment(entry) == "start"
                          ? _c("b-col", {
                              staticClass: "custom-col-1",
                              attrs: { cols: "1" }
                            })
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "text-small", attrs: { cols: "8" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    class:
                                      entry.data.eventCategory === "Notes"
                                        ? "text-right"
                                        : "text-left",
                                    attrs: {
                                      order:
                                        entry.data.eventCategory === "Notes"
                                          ? 2
                                          : 1
                                    }
                                  },
                                  [
                                    entry.data.eventCategory == "Notes"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(entry.data.noteBy) +
                                              " "
                                          )
                                        ])
                                      : [
                                          entry.data.eventCategory == "Intercom"
                                            ? _c("span", [
                                                entry.data.eventName ==
                                                "Incoming"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.patientName
                                                          ) +
                                                          " - "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      entry.data.eventCategory
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      entry.data.eventCategory
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                  ],
                                  2
                                ),
                                _c(
                                  "b-col",
                                  {
                                    class:
                                      entry.data.eventCategory === "Notes"
                                        ? "text-left"
                                        : "text-right",
                                    attrs: {
                                      order:
                                        entry.data.eventCategory === "Notes"
                                          ? 1
                                          : 2
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          entry.data.timestampInteraction
                                            ? _vm.getDateTime(
                                                entry.data
                                                  .timestampInteraction * 1000
                                              )
                                            : _vm.getDateTime(
                                                entry.data.actionDateTimestamp *
                                                  1000
                                              )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.getAlignment(entry) == "end"
                          ? _c("b-col", {
                              staticClass: "custom-col-1",
                              attrs: { cols: "1" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        1
      ),
      _c("div", { staticClass: "mt-2" }, [
        _c("div", { ref: "bottomOfPage", attrs: { id: "bottomOfPage" } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }