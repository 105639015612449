/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-dropdown" }, [
    _c(
      "div",
      { ref: "button", on: { mouseover: _vm.show, mouseleave: _vm.hide } },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      _vm._b(
        {
          ref: "popper",
          staticClass:
            "base-dropdown__popper tw-absolute tw-rounded-md tw-border tw-border-gray-100 tw-shadow-lg tw-bg-gray-800 tw-text-gray-100 tw-px-3 tw-py-2 tw-text-[11px] tw-z-10 tw-max-w-[200px] tw-w-auto tw-text-pretty"
        },
        "div",
        _vm.$attrs,
        false
      ),
      [
        _vm._t("popper", function() {
          return [_vm._v(" " + _vm._s(_vm.text) + " ")]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }