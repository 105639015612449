/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ResetPasswordDialog.vue?vue&type=template&id=524d663e&"
import script from "./ResetPasswordDialog.vue?vue&type=script&lang=js&"
export * from "./ResetPasswordDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('524d663e')) {
      api.createRecord('524d663e', component.options)
    } else {
      api.reload('524d663e', component.options)
    }
    module.hot.accept("./ResetPasswordDialog.vue?vue&type=template&id=524d663e&", function () {
      api.rerender('524d663e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/patients/ResetPasswordDialog.vue"
export default component.exports