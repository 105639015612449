//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { computePosition, flip, shift, offset, autoUpdate } from '@floating-ui/dom'; // utils

import { getRef } from '@/utils/getRef';
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  },

  data() {
    return {
      cleanup: null,
      button: null,
      popper: null
    };
  },

  methods: {
    async getElements() {
      if (this.button && this.popper) {
        return {
          button: this.button,
          popper: this.popper
        };
      }

      const [button, popper] = await Promise.all([getRef.call(this, 'button'), getRef.call(this, 'popper')]);
      this.button = button;
      this.popper = popper;
      return {
        button,
        popper
      };
    },

    async update() {
      const {
        popper,
        button
      } = await this.getElements();
      const {
        x,
        y
      } = await computePosition(button, popper, {
        placement: this.placement,
        strategy: 'fixed',
        middleware: [flip(), shift({
          padding: 5
        }), offset(5)]
      });
      Object.assign(popper.style, {
        left: `${x}px`,
        top: `${y}px`
      });
    },

    async show() {
      const {
        popper
      } = await this.getElements();
      this.update();
      await this.$nextTick();

      if (!this.cleanup) {
        await this.autoUpdate();
      }

      popper.style.display = 'inline-block';
    },

    async hide() {
      const {
        popper
      } = await this.getElements();
      popper.style.display = 'none';

      if (this.cleanup) {
        this.cleanup();
        this.cleanup = null;
      }
    },

    async autoUpdate() {
      const {
        popper,
        button
      } = await this.getElements();
      this.cleanup = autoUpdate(button, popper, this.update);
    }

  }
};