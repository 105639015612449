//
//
//
//
//
//
//
//
//
//
//
//
import SideBar from '@/components/dashboard/SideBar'; // import TopBar from '@/components/dashboard/TopBar';

export default {
  name: 'Home',
  components: {
    SideBar // TopBar,

  }
};