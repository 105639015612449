/* unplugin-vue-components disabled */import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/icon/IconLoading.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "tw-p-4 tw-flex tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-slate-300 tw-h-[69px]"
      },
      [
        _c(
          "h1",
          {
            staticClass:
              "h1 tw-text-lg tw-black tw-uppercase tw-tracking-widest tw-mb-0"
          },
          [_vm._v(" " + _vm._s(_vm.category ? _vm.chatTitles : "") + " ")]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "tw-overflow-y-auto",
        staticStyle: { height: "calc(100vh - 69px)" }
      },
      [
        _vm.isFetchingConversations ||
        _vm.isProcessing ||
        !_vm.conversations.length
          ? _c(
              "div",
              {
                staticClass:
                  "tw-flex tw-items-center tw-justify-center tw-h-[100px] tw-gap-2 tw-text-gray-500"
              },
              [
                _vm.isFetchingConversations || _vm.isProcessing
                  ? [_vm._v(" Loading "), _c(__unplugin_components_0)]
                  : !_vm.conversations.length
                  ? [_vm._v(" Empty conversations ")]
                  : _vm._e()
              ],
              2
            )
          : _vm._l(_vm.conversations, function(conversation) {
              return _c("chat-message-list-item", {
                key:
                  conversation.id +
                  "-" +
                  (conversation.isOnline ? "online" : "offline") +
                  "'}",
                staticClass:
                  "tw-border-0 tw-border-b tw-border-solid tw-border-slate-300",
                attrs: {
                  conversation: conversation,
                  "is-selected": conversation.id === _vm.selectedConversation
                },
                on: { select: _vm.onConversationSelect }
              })
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }