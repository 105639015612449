/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SideModal", {
    attrs: {
      show: _vm.showIntakeModal,
      "show-header": false,
      "body-class": "intake-modal",
      title: "View Intake",
      "z-index": 9999
    },
    on: {
      exit: function($event) {
        return _vm.$emit("update:showIntakeModal", false)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "tw-px-2 tw-py-6 d-flex justify-content-between align-items-center"
              },
              [
                _c("p", { staticClass: "mb-0 tw-text-xl font-weight-bold" }, [
                  _vm._v(" Health Questions ")
                ]),
                _c(
                  "a",
                  {
                    staticClass:
                      "text-uppercase tw-cursor-pointer tw-text-eclipse",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$emit("update:showIntakeModal", false)
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { icon: "x-circle", "font-scale": "1.5" }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "tw-max-h-[calc(100vh-120px)] tw-overflow-y-auto"
              },
              _vm._l(_vm.intake, function(question, index) {
                return _c(
                  "b-row",
                  {
                    key: question.id,
                    staticClass: "mt-4 pl-3",
                    staticStyle: { "max-width": "95%" }
                  },
                  [
                    _c("b-col", [
                      _vm._v(
                        " " +
                          _vm._s(index + 1) +
                          ". " +
                          _vm._s(question.question) +
                          " "
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "tw-mt-2 tw-mb-0 tw-max-h-[200px] tw-overflow-y-auto",
                          style: {
                            color: question.highlightcolor
                              ? question.highlightcolor
                              : "initial"
                          }
                        },
                        [_vm._v(" " + _vm._s(question.answer) + " ")]
                      ),
                      _c("hr", { staticClass: "tw-mt-0 tw-border-[#37514D]" })
                    ])
                  ],
                  1
                )
              }),
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }