//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
import { subMonths, startOfMonth, endOfMonth, startOfYear, getUnixTime, isBefore } from 'date-fns';
import moment from 'moment';
import { InventoryService } from '@/services/inventory.service';
export default {
  props: {
    currentFilter: {
      type: String,
      default: 'Month to Date'
    }
  },

  data() {
    return {
      headers: [{
        label: 'Product Name',
        key: 'productname'
      }],
      selectedRow: null,
      items: [],
      loading: false
    };
  },

  watch: {
    async currentFilter(val) {
      this.getInventoryUsageFromFilter(val);
    }

  },

  async mounted() {
    // Month to date
    await this.getInventoryUsageFromFilter(this.currentFilter);
  },

  methods: {
    getDate(date) {
      return new moment(date * 1000).format('MM/DD/YYYY');
    },

    async getInventoryUsageFromFilter(filtername) {
      let start = null;
      let end = null;
      let today = getUnixTime(new Date());

      if (filtername == 'Last Month') {
        start = getUnixTime(startOfMonth(subMonths(new Date(), 1)));
        end = getUnixTime(endOfMonth(subMonths(new Date(), 1)));
      } else if (filtername == 'Month to Date') {
        start = getUnixTime(startOfMonth(new Date()));
        end = today;
      } else if (filtername == 'Year to Date') {
        start = getUnixTime(startOfYear(new Date()));
        end = today;
      } else if (filtername == 'All Time') {
        // ? Jan 2021 - Earliest possible month
        start = getUnixTime(new Date(2021, 0, 1));
        end = today;
      } else if (filtername == 'Custom') {
        this.$emit('get-custom-usage');
        return;
      }

      this.$store.commit('inventory/SET_FILTERS', start, end);
      await this.getInventoryUsage(start, end);
    },

    async getInventoryUsage(from = null, to = null) {
      this.loading = true;
      const {
        data
      } = await InventoryService.getInventoryUsage(from, to);
      let itemsByProduct = [];
      let objectKeys = Object.keys(data);

      if (objectKeys.length == 0) {
        this.$emit('error-toast', 'No entries found!', 'No entries exist in this date range.');
        this.items = [];
        this.loading = false;
        return;
      } // Loop by state


      for (let state of objectKeys) {
        // Loop through items in state
        let itemKeys = Object.keys(data[state]);

        for (let product of itemKeys) {
          // Check if item is already on the table
          let exists = itemsByProduct.find(o => o.productname == product); // Add item to the table

          if (!exists) {
            let item = {
              productname: product
            }; // Assign the state Usage to the new item

            item[`${state} Usage`] = data[state][product];
            itemsByProduct.push(item); // Add state to header

            this.headers.push(`${state} Usage`);
          } else {
            // Add state Usage to item
            let index = itemsByProduct.findIndex(o => o.productname == product);
            itemsByProduct[index][`${state} Usage`] = data[state][product]; // Add state to header

            this.headers.push(`${state} Usage`);
          }
        }
      }

      this.items = itemsByProduct;
      this.loading = false;
    }

  }
};