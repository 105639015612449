/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-mx-10 pt-4" },
    [
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c("b-col", { staticClass: "d-flex align-items-center" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h1", { staticClass: "h1 mb-0 tw-mr-4" }, [
                _vm._v(" Doctors ")
              ]),
              _c(
                "a",
                {
                  staticClass: "analytics-btn",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isViewingAnalytics = !_vm.isViewingAnalytics
                    }
                  }
                },
                [
                  _c("u", [
                    _vm._v(
                      _vm._s(
                        _vm.isViewingAnalytics ? "View Data" : "View Analytics"
                      )
                    )
                  ])
                ]
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _vm.tab == "doctorreassignment" && !_vm.isViewingAnalytics
                ? _c(
                    "b-button",
                    {
                      staticClass: "tw-tracking-wide reassignment-btn",
                      attrs: { variant: "primary", pill: "", block: "" },
                      on: {
                        click: function($event) {
                          _vm.$refs.NewReassignmentDialog.showDialog = true
                        }
                      }
                    },
                    [_vm._v(" New Reassignment ")]
                  )
                : _vm._e(),
              _vm.isViewingAnalytics
                ? _c(
                    "b-dropdown",
                    {
                      attrs: { variant: "link", right: "", "no-caret": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "dropdown-toggle d-letter-spacing",
                                    attrs: {
                                      id: "sidebarIcon",
                                      href: "#",
                                      role: "button",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "account-body d-none d-sm-none d-md-block mr-4"
                                      },
                                      [
                                        _c(
                                          "h3",
                                          { staticClass: "mb-0" },
                                          [[_vm._v(_vm._s(_vm.currentFilter))]],
                                          2
                                        )
                                      ]
                                    ),
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "caret-down-fill",
                                        "font-scale": "0.75"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1899209073
                      )
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.currentFilter = "Last Month"
                            }
                          }
                        },
                        [_vm._v(" Last Month ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.currentFilter = "Month to Date"
                            }
                          }
                        },
                        [_vm._v(" Month to Date ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.currentFilter = "Year to Date"
                            }
                          }
                        },
                        [_vm._v(" Year to Date ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.currentFilter = "All Time"
                            }
                          }
                        },
                        [_vm._v(" All Time ")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          nativeOn: {
                            "!click": function($event) {
                              $event.stopPropagation()
                              _vm.showCustom = true
                            }
                          }
                        },
                        [_vm._v(" Custom ")]
                      ),
                      _vm.showCustom ? _c("b-dropdown-divider") : _vm._e(),
                      _vm.showCustom
                        ? _c(
                            "b-dropdown-form",
                            [
                              _c(
                                "span",
                                { staticClass: "text-small text-muted" },
                                [_vm._v("From")]
                              ),
                              _c("b-form-select", {
                                attrs: { options: _vm.months },
                                model: {
                                  value: _vm.fromMonth,
                                  callback: function($$v) {
                                    _vm.fromMonth = $$v
                                  },
                                  expression: "fromMonth"
                                }
                              }),
                              _c("b-form-select", {
                                attrs: { options: _vm.years },
                                model: {
                                  value: _vm.fromYear,
                                  callback: function($$v) {
                                    _vm.fromYear = $$v
                                  },
                                  expression: "fromYear"
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "text-small text-muted" },
                                [_vm._v("To")]
                              ),
                              _c("b-form-select", {
                                attrs: { options: _vm.months },
                                model: {
                                  value: _vm.toMonth,
                                  callback: function($$v) {
                                    _vm.toMonth = $$v
                                  },
                                  expression: "toMonth"
                                }
                              }),
                              _c("b-form-select", {
                                attrs: { options: _vm.years },
                                model: {
                                  value: _vm.toYear,
                                  callback: function($$v) {
                                    _vm.toYear = $$v
                                  },
                                  expression: "toYear"
                                }
                              }),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-2",
                                  attrs: { variant: "primary", size: "sm" },
                                  on: { click: _vm.getEarningData }
                                },
                                [_vm._v(" Search ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      !_vm.isViewingAnalytics
        ? _c("b-row", { staticClass: "my-4 ml-1" }, [
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "h4",
                  class: [_vm.tab == "all" ? "tab-selected" : "tab-unselected"],
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      _vm.tab = "all"
                    }
                  }
                },
                [
                  _vm._v(" All "),
                  _c(
                    "b-badge",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        pill: "",
                        variant: _vm.tab == "all" ? "secondary" : "primary"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.count.all) + " ")]
                  )
                ],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "ml-4 h4",
                  class: [
                    _vm.tab == "doctorreassignment"
                      ? "tab-selected"
                      : "tab-unselected"
                  ],
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      _vm.tab = "doctorreassignment"
                    }
                  }
                },
                [
                  _vm._v(" Doctors Out of Office "),
                  _c(
                    "b-badge",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        pill: "",
                        variant:
                          _vm.tab == "doctorreassignment"
                            ? "secondary"
                            : "primary"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.count.doctorreassignment) + " ")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("EditMaxDailyDialog", {
        ref: "EditMaxDailyDialog",
        attrs: { "row-data": _vm.selectedRow },
        on: { "fetch-doctors": _vm.fetchDoctors }
      }),
      _c("NewReassignmentDialog", {
        ref: "NewReassignmentDialog",
        on: { "add-row": _vm.addRow }
      }),
      _c("UpdateReassignmentDialog", {
        ref: "UpdateReassignmentDialog",
        attrs: { "row-data": _vm.selectedRow, mode: _vm.dialogMode },
        on: { refresh: _vm.refresh }
      }),
      _c("ConfirmDeleteCancelDialog", {
        ref: "ConfirmDeleteCancelDialog",
        attrs: { "row-data": _vm.selectedRow, mode: _vm.dialogMode },
        on: { "show-toast": _vm.showToast, refresh: _vm.refresh }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isViewingAnalytics,
              expression: "isViewingAnalytics"
            }
          ],
          attrs: { "no-body": "" }
        },
        [
          _c("DoctorAnalyticsTable", {
            ref: "DoctorAnalyticsTable",
            attrs: { "current-filter": _vm.currentFilter }
          })
        ],
        1
      ),
      _c(
        "b-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isViewingAnalytics,
              expression: "!isViewingAnalytics"
            }
          ],
          staticClass: "tw-border-0",
          attrs: { "no-body": "" }
        },
        [
          _vm.tab == "all"
            ? _c("DoctorTable", {
                ref: "DoctorTable",
                on: { "edit-max-daily": _vm.editMaxDaily }
              })
            : _vm._e(),
          _vm.tab == "doctorreassignment"
            ? _c("DoctorReassignTable", {
                ref: "DoctorReassignTable",
                on: {
                  "open-update-reassignment": _vm.openUpdateReassign,
                  "open-approve-reassignment": _vm.openApproveReassign,
                  "open-delete-reassignment": _vm.openDeleteReassign,
                  "open-cancel-reassignment": _vm.openCancelReassign
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-toast",
        {
          attrs: {
            id: "alertToast",
            variant: _vm.toast.variant,
            "no-close-button": "",
            "auto-hide-delay": "2500",
            toaster: "b-toaster-top-center",
            solid: ""
          }
        },
        [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }