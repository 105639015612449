/* unplugin-vue-components disabled */import __unplugin_components_3 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatMacroEditModal.vue';
import __unplugin_components_2 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatReopenModal.vue';
import __unplugin_components_1 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatDismissModal.vue';
import __unplugin_components_0 from '/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/src/components/chat/ChatMacroDialog.vue';
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.conversation
    ? _c(
        "div",
        { staticClass: "tw-h-screen tw-flex tw-flex-col" },
        [
          _c(__unplugin_components_0, {
            on: {
              "open-edit": function($event) {
                _vm.showMacroEdit = true
              }
            }
          }),
          _c(
            "div",
            {
              staticClass:
                "tw-p-4 tw-flex tw-justify-between tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
            },
            [
              _c("div", { staticClass: "tw-flex tw-items-center tw-gap-2" }, [
                _c(
                  "div",
                  { staticClass: "tw-relative" },
                  [
                    _c("b-avatar", {
                      attrs: {
                        variant: "tertiary",
                        size: "35",
                        src: _vm.conversation.patient.profileUrl
                      }
                    }),
                    _vm.onlineStatus
                      ? _c(
                          "svg",
                          {
                            staticClass:
                              "tw-absolute -tw-right-0.5 -tw-top-0.5",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "15",
                              height: "15",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#33926A",
                                d:
                                  "M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", [
                  _c("p", { staticClass: "tw-m-0 tw-text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.conversation.patient.firstName) +
                        " " +
                        _vm._s(_vm.conversation.patient.lastName) +
                        " "
                    )
                  ]),
                  _c(
                    "p",
                    { staticClass: "tw-m-0 tw-text-slate-400 tw-text-xs" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDate(
                              _vm.conversation.patient.birthday,
                              "MM/dd/yy"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "tw-flex tw-space-x-4" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    attrs: { href: "javascript:;", title: "Patient Details" },
                    on: { click: _vm.toggleProfile }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "18",
                          viewBox: "0 0 21 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M20.0355 18.7165C13.3555 18.7165 6.71554 18.7165 0.0855418 18.7165C-0.494458 15.5465 1.94554 12.0265 5.26554 11.4365C6.68554 11.1865 8.16554 11.2365 9.62554 11.2465C11.3755 11.2565 13.1655 11.1665 14.8755 11.4565C18.1655 12.0065 20.4655 15.3465 20.0355 18.7165Z",
                            fill: "#828282"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M14.875 5.00646C14.955 7.66646 12.855 9.88646 10.185 9.94646C7.50504 10.0165 5.33504 7.94646 5.24504 5.25646C5.15504 2.60646 7.20504 0.376458 9.80504 0.286458C12.635 0.196458 14.785 2.19646 14.875 5.00646Z",
                            fill: "#828282"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", {
                  staticClass:
                    "tw-border-[0.5px] tw-border-slate-300 tw-border-solid"
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.selectedCategoryTab("dismissed"),
                        expression: "!selectedCategoryTab('dismissed')"
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        attrs: { href: "javascript:;", title: "Dismiss" },
                        on: { click: _vm.onShowDismissModal }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "18",
                              viewBox: "0 0 24 19",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0.0859375 10.1495C0.75346 9.50001 1.43 8.85955 2.0885 8.21007C2.60268 7.70492 3.10782 7.18172 3.65807 6.61343C5.37198 8.23713 7.05883 9.83377 8.79078 11.4665C12.4261 7.741 16.0433 4.03354 19.6966 0.290009C20.9054 1.48974 22.0961 2.67144 23.2959 3.86215C23.2959 3.89824 23.2959 3.9253 23.2959 3.96138C23.1605 4.06963 23.0162 4.16885 22.8989 4.28612C18.3165 8.94975 13.7341 13.6134 9.16063 18.286C9.05238 18.4033 8.98022 18.5567 8.88099 18.71C8.64646 18.4935 8.52016 18.3853 8.39387 18.259C5.72378 15.6701 3.05369 13.0812 0.383604 10.4833C0.293398 10.393 0.194179 10.3119 0.0949531 10.2307C0.0859325 10.2217 0.0859375 10.1856 0.0859375 10.1495Z",
                                fill: "#828282"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedCategoryTab("dismissed"),
                        expression: "selectedCategoryTab('dismissed')"
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        attrs: { href: "javascript:;", title: "Reopen" },
                        on: { click: _vm.onShowReopenModal }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 64 64",
                              width: "20",
                              height: "18",
                              fill: "none"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "m8,28h48v18c0,4.94-3.06,8-8,8h-14v-13.17l5.17,5.17,2.83-2.83-10-10-10,10,2.83,2.83,5.17-5.17v13.17h-14c-4.94,0-8-3.06-8-8v-18Zm27.24-12c-3.04,0-5.1-.85-7.24-3-2.15-2.15-4.21-3-7.24-3h-12.76v14h48v-8h-20.76Z",
                                fill: "#828282"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "tw-px-6 tw-py-2 tw-flex tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
            },
            [
              _c(
                "div",
                {
                  staticClass: "tw-cursor-pointer hover:tw-underline",
                  on: {
                    click: function($event) {
                      _vm.isEventsHidden = !_vm.isEventsHidden
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isEventsHidden ? "Show events" : "Hide events"
                      ) +
                      " "
                  )
                ]
              )
            ]
          ),
          _c("chat-messages-container", {
            key: _vm.conversation.id,
            ref: "ChatMessagesContainer",
            staticClass: "tw-flex-1 tw-overflow-y-auto",
            attrs: {
              "is-messages-loaded": _vm.isMessagesLoaded,
              "is-recipient-typing": _vm.isRecipientTyping,
              "is-events-hidden": _vm.isEventsHidden,
              "patient-name": _vm.patientName
            }
          }),
          _c(
            "div",
            { staticClass: "tw-relative" },
            [
              _vm.isRecipientTyping && _vm.isMessagesLoaded
                ? _c(
                    "div",
                    { staticClass: "tw-absolute tw-left-6 tw-top-0.5" },
                    [
                      _c("p", { staticClass: "tw-text-muted tw-text-sm" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.stringifyTypers) +
                            " " +
                            _vm._s(_vm.typersList.length > 1 ? "are" : "is") +
                            " typing... "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c("ChatMessageInputArea", {
                attrs: { socket: _vm.socket, conversation: _vm.conversation },
                on: {
                  "assign-to-self": function($event) {
                    return _vm.assignConversation(_vm.conversation.id, true)
                  },
                  input: _vm.onType,
                  message: _vm.onMessageSent,
                  messageCategoryChanged: _vm.handleMessageCategoryChanged
                }
              })
            ],
            1
          ),
          _c(__unplugin_components_1, {
            ref: "ChatDismissModal",
            on: { dismiss: _vm.dismissConversation }
          }),
          _c(__unplugin_components_2, {
            ref: "ChatReopenModal",
            on: { reopen: _vm.reopenConversation }
          }),
          _c(__unplugin_components_3, {
            ref: "ChatMacroEditModal",
            attrs: { show: _vm.showMacroEdit },
            on: {
              "update:show": function($event) {
                _vm.showMacroEdit = $event
              }
            }
          }),
          _c("div", {
            staticClass: "background",
            style: {
              "background-color": _vm.bgModal.color,
              display: _vm.bgModal.show
            },
            on: {
              click: function($event) {
                _vm.showMacroEdit = false
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }